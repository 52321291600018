import React, { useEffect } from "react";
import { Button, Dialog, Textarea } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { AddCommentDialogWrap } from "./add-comment-dialog.style";
import { TCommentsDetails } from "@_types";
import { AddCommentDialogProps } from "./add-comment-dialog.props";

const AddCommentDialog = ({
  open,
  onClose,
  onCommentAdd,
  commentToEdit,
}: AddCommentDialogProps) => {
  const theme = useTheme();
  const { handleSubmit, control, reset } = useForm<TCommentsDetails>({
    defaultValues: {
      comment: commentToEdit?.comment || "",
    },
  });

  useEffect(() => {
    if (open) {
      reset({ comment: commentToEdit?.comment || "" });
    }
  }, [commentToEdit, open, reset]);

  const onSubmit = (data: TCommentsDetails) => {
    onCommentAdd({
      ...data,
      identifier: commentToEdit?.identifier,
      orderReferenceId: commentToEdit?.orderReferenceId,
      customerId: commentToEdit?.customerId,
    });
    reset();
    onClose();
  };

  const dialogText = commentToEdit ? "Edit Comment" : "Add Comment";

  return (
    <Dialog
      theme={theme}
      size={"sm"}
      onCloseDialog={onClose}
      open={open}
      title={dialogText}
      customFooter={
        <>
          <Button
            theme={theme}
            type="button"
            secondary
            text="Cancel"
            onClick={onClose}
          />
          <Button
            theme={theme}
            type="button"
            primary
            text="Post Comment"
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }
    >
      <AddCommentDialogWrap theme={theme}>
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <Textarea
              theme={theme}
              placeholder="Type here..."
              label={`${dialogText} *`}
              fullWidth
              type="text"
              id={"textAreaId"}
              rows={3}
              {...field}
            />
          )}
        />
      </AddCommentDialogWrap>
    </Dialog>
  );
};

export default AddCommentDialog;
