import { AuthenticationTypes } from "@helpers";
import { useEffect, useState } from "react";

export const ProtectedRoute = ({
  children,
  authenticationType = AuthenticationTypes.UNITY,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    switch (authenticationType) {
      case AuthenticationTypes.UNITY:
        // Implement When Unity Marketplace authentication required.
        break;
    }
  }, []);
  return isAuthenticated ? (
    children
  ) : (
    <>
      {/* <Loader>
                <CircleLoader theme={theme} />
            </Loader> */}
    </>
  );
};
