import { WithdrawConfirmationData } from "@_types/proposal";
import { ConfirmationDialog } from "@components";
import { AppDialogKeys, OrderStatus } from "@helpers/enum";
import { Button, ISnackbarProps, Snackbar } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { useChangeProposalStatus } from "@hooks/mutations";
import { useState } from "react";
import { PROPOSAL_STATUS_TO_ALLOWED_STATUS_MAP } from "@helpers/const";

type WithdrawConfirmationDialogProps = {
  dialogData: WithdrawConfirmationData;
  closeDialog: () => void;
  open: boolean;
};

export const WithdrawConfirmationDialog = ({
  dialogData,
  open,
  closeDialog,
}: WithdrawConfirmationDialogProps) => {
  if (!open) return <></>;

  const [snackbarState, setSnackbarState] = useState<{
    open: boolean;
    message: string;
    variant?: ISnackbarProps["variant"];
  }>({
    open: false,
    message: "",
    variant: null,
  });

  const { mutateAsync: withdrawProposal } = useChangeProposalStatus();
  const allowedStatuses: OrderStatus[] =
    PROPOSAL_STATUS_TO_ALLOWED_STATUS_MAP[dialogData.status];

  const handleWithdrawProposal = async () => {
    if (!allowedStatuses.includes(OrderStatus.WITHDRAWN)) {
      setSnackbarState({
        message: "Cannot Withdraw Proposal at this stage.",
        open: true,
        variant: "error",
      });
      return;
    }
    try {
      setSnackbarState({
        message: "Withdrawing Proposal...",
        open: true,
        variant: null,
      });
      await withdrawProposal({
        identifier: dialogData.identifier,
        current_status: dialogData.status,
        next_status: OrderStatus.WITHDRAWN,
      });
      setSnackbarState({
        message: "Status Changed Successfully",
        open: true,
        variant: null,
      });
      closeDialog();
    } catch (err) {
      setSnackbarState({
        message: "Error occurred while changing status",
        open: true,
        variant: "error",
      });
    }
  };

  const theme = useTheme();
  return (
    <>
      <ConfirmationDialog
        open={open}
        dialogIcon="DialogAlertIcon"
        title="Do you want to withdraw proposal?"
        message={
          <>
            <span className="text-primary">
              {(dialogData as WithdrawConfirmationData)?.referenceNumber}
            </span>{" "}
            {(dialogData as WithdrawConfirmationData)?.name}
          </>
        }
        actionArea={
          <>
            <Button theme={theme} secondary text="No" onClick={closeDialog} />
            <Button
              theme={theme}
              danger
              text="Yes"
              onClick={handleWithdrawProposal}
            />
          </>
        }
        snackbarState={snackbarState}
        resetSnackbar={() =>
          setSnackbarState({ message: "", open: false, variant: undefined })
        }
      />
    </>
  );
};
