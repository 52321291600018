import { styled } from "@mui/material";

export const MainContainer = styled("div")(({ theme }) => ({
  width: "100%",
  // display: "flex",
  // justifyContent: "center",

  ".container": {
    marginLeft: 8,
    width: "100%",
    padding: "10px",
  },

  ".with-bg": {
    maxWidth: "100%",
    padding: 40,
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,

    "&.p-0": {
      padding: 0,

      // ".main-title": {
      //     padding: 40 , paddingBottom: 32
      // }
    },
  },
  [theme.breakpoints.down("md")]: {
    // marginTop: -36,

    ".with-bg": {
      padding: 24,
      // "&.p-0": {
      //     ".main-title": {
      //         padding: 24
      //     }
      // },
    },
  },
}));
