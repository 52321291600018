export enum AmortizationMethod {
  Annuity360 = "Annuity30-360",
  AnnuityActual360 = "AnnuityActual-360",
  AnnuityActual365 = "AnnuityActual-365",
}
export enum APRCalculationMethod {
  Annuity360 = "Annuity30-360",
  AnnuityActual360 = "AnnuityActual-360",
  AnnuityActual365 = "AnnuityActual-365",
}
export enum InitialCashOutFlowFlatRateMethod {
  IncludingVATAndCommission = "IncludingVATAndCommission",
  ExcludingVATAndCommission = "ExcludingVATAndCommission",
}
export enum CommissionCalculationMethod {
  PercentageOfFinancedAmount = "PercentageOfFinancedAmount",
  PercentageOfInterestIncome = "PercentageOfInterestIncome",
}
export enum RentalType {
  Auto = "Auto",
  Structured = "Structured",
  Zero = "Zero",
}
export enum FeeType {
  OneTime = "OneTime",
  Periodic = "Periodic",
}
export enum FeeFrequencyOneTime {
  WithSpecificPayment = "WithSpecificPayment",
  OnSpecificDate = "OnSpecificDate",
  WithLastPayment = "WithLastPayment",
  WithFirstPayment = "WithFirstPayment",
  OnStartDate = "OnStartDate",
}
export enum FeeFrequencyPeriodic {
  WithEveryPayment = "WithEveryPayment",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  SemiAnnual = "SemiAnnual",
  Annual = "Annual",
}
export enum VatTreatment {
  VATPaidUpfront = "VATPaidUpfront",
  VATDeferral = "VATDeferral",
  VATAmortizeOverTheTerm = "VATAmortizeOverTheTerm",
}
export enum FeeName {
  DocFee = "Document Fee",
  AnnualAdminFee = "Annual Admin Fee",
  OTPFee = "OTP Fee",
}
export enum Currency {
  GBP = "£",
  dollar = "$",
}
export enum FinanceType {
  HirePurchase = "Hire Purchase",
  FinanceLease = "Finance Lease",
  Loan = "Loan",
}
export enum PaymentMode {
  Advance = "Advance",
  Arrear = "Arrear",
}
export enum BalloonCollection {
  OneMonthAfterLastPayment = 1,
  WithLastPayment = 0,
}

export enum RateTypes {
  NominalRate = "Nominal Rate",
  APR = "APR",
  FlatRate = "Flat Rate",
}
export enum FieldType {
  CommissionPercentage = "CommissionPercentage",
  VATPaymentNumber = "VAT.PaymentNumber",
  Deposit = "Deposit",
  Rate = "Rate",
  RateType = "RateType",
  AdvancePayments = "AdvancePayments",
  RegularPayments = "RegularPayments",
  BalloonPayment = "BalloonPayment",
  BalloonCollection = "BalloonCollection",
  VATAmount = "VAT.Amount",
  AssetCost = "AssetCost",
  StartDate = "StartDate",
  FundingCost = "FundingCost",
  FlatRateExclCommission = "FlatRateExclCommission",
  VATDeferred = "VATDeferred",
  StepPaymentsApplicable = "StepPaymentsApplicable",
  StepPaymentsStructureApplicable = "StepPaymentsStructureApplicable",
  VATAmountAutoCalculateApplicable = "VATAmountAutoCalculateApplicable",
  IrregularPaymentsApplicable = "IrregularPaymentsApplicable",
  PDFTemplateApplicable = "PDFTemplateApplicable",
  WordTemplateApplicable = "WordTemplateApplicable",
  Frequency = "Frequency",
}

export enum ControlType {
  TextBox = "TextBox",
  DropDown = "DropDown",
  DatePicker = "DatePicker",
}

export enum SolveFor {
  PeriodicPayment = "Periodic Payment",
  FinanceAmount = "Finance Amount",
  Commmission = "Commission",
  Deposit = "Deposit",
  RV = "RV/Balloon",
  Rates = "Rates",
}

export enum PeriodicInterestCalculationMethod {
  Simple = "Simple",
  CompoundedMonthlyDirectConversion = "CompoundedMonthlyDirectConversion",
  CompoundedMonthlyFirstAnnualThenPeriodic = "CompoundedMonthlyFirstAnnualThenPeriodic",
}

export enum CommissionType {
  Percentage = "%",
  Amount = "£",
}

export enum FeeOccurances {
  OnStartDate = "OnStartDate",
  WithFirstPayment = "WithFirstPayment",
  WithLastPayment = "WithLastPayment",
}

export enum Field {
  CommissionPercentage = "Commission Percentage",
  VATPaymentNumber = "VAT Payment Number",
  Deposit = "Deposit",
  Rate = "Rate",
  RateType = "Rate Type",
  AdvancePayments = "Advance Payments",
  RegularPayments = "Regular Payments",
  BalloonPayment = "Balloon Payment",
  BalloonCollection = "Balloon Collection",
  VATAmount = "VAT Amount",
  AssetCost = "Asset Cost",
  StartDate = "Start Processing Date",
  FundingCost = "Funding Cost Percentage",
  FlatRateExclCommission = "Flat Rate Excl. Commission",
  VATDeferred = "VAT Deferred",
  StepPaymentsApplicable = "Step Payments Applicable",
  StepPaymentsStructureApplicable = "Step Payments Structure Applicable",
  VATAmountAutoCalculateApplicable = "VAT Amount Auto Calculate Applicable",
  IrregularPaymentsApplicable = "Irregular Payments Applicable",
  PDFTemplateApplicable = "PDF Template Applicable",
  WordTemplateApplicable = "Word Template Applicable",
  Frequency = "Payment Frequency",
}

export enum FinanceAmountRateType {
  GrossYield = "GrossYield",
  NetYield = "NetYield",
}

export enum HaydockTemplateName {
  HaydockFinanceTemplate1 = "Haydock Finance Template 1",
  HaydockFinanceTemplate2 = "Haydock Finance Template 2",
}

export enum VatDeferralType {
  Month = "Month",
  Rental = "Rental",
}

export enum StepType {
  StepUp = "Up",
  StepDown = "Down",
}

export enum PaymentFrequency {
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  SemiAnnual = "SemiAnnual",
  Annual = "Annual",
}
