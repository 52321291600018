import { Typography, Box, useTheme } from "@mui/material";
import { QuotationFiltersWrap } from "./new-quotation-header.style";
import { ASSET_INFO, CUSTOMER_TYPES, BROKER_NAMES } from "@helpers/const";
import { BrokerSpecifcFormFields } from "@_types/forms";
import { Select } from "@ntpkunity/controls";
import { getSelectValues } from "@helpers/utils";
import { QuotationFormController } from "@ntpkunity/controls-common";
import { useFormContext } from "react-hook-form";

export const NewQuotationFiltersHeader = () => {
  const theme = useTheme();
  const { control } = useFormContext<BrokerSpecifcFormFields>();

  return (
    <QuotationFiltersWrap className="quotation-filters-wrap">
      <Box className="filters-area">
        <Box className="filter-item">
          <Typography
            variant="body2"
            component={"span"}
            display={"block"}
            className="text-muted"
          >
            Broker:
          </Typography>
          <QuotationFormController
            name="brokerName"
            control={control}
            render={({ field }) => (
              <Select
                theme={theme}
                disablePortal={false}
                items={getSelectValues(BROKER_NAMES)}
                {...field}
              />
            )}
          />
        </Box>
        <Box className="filter-item">
          <Typography
            variant="body2"
            component={"span"}
            display={"block"}
            className="text-muted"
          >
            Asset Type:
          </Typography>
          <QuotationFormController
            name="asset.type"
            control={control}
            render={({ field }) => (
              <Select
                disablePortal={false}
                theme={theme}
                items={getSelectValues(ASSET_INFO)}
                {...field}
              />
            )}
          />
        </Box>
        <Box className="filter-item">
          <Typography
            variant="body2"
            component={"span"}
            display={"block"}
            className="text-muted"
          >
            Client Type:
          </Typography>
          <QuotationFormController
            name="clientType"
            control={control}
            render={({ field }) => (
              <Select
                disablePortal={false}
                theme={theme}
                items={getSelectValues(CUSTOMER_TYPES)}
                {...field}
              />
            )}
          />
        </Box>
      </Box>
    </QuotationFiltersWrap>
  );
};
