import React, { FC } from "react";

const EditIcon: FC<{ className?: string; onClick: (e: unknown) => void }> = ({
  className,
  onClick,
}) => (
  <svg
    className={className}
    onClick={onClick}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99951 15H15.7495"
      stroke="#9A9EB2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3745 2.62499C12.6729 2.32662 13.0776 2.159 13.4995 2.159C13.7084 2.159 13.9153 2.20015 14.1084 2.2801C14.3014 2.36006 14.4768 2.47725 14.6245 2.62499C14.7722 2.77272 14.8894 2.94811 14.9694 3.14114C15.0493 3.33417 15.0905 3.54105 15.0905 3.74999C15.0905 3.95892 15.0493 4.1658 14.9694 4.35883C14.8894 4.55186 14.7722 4.72725 14.6245 4.87499L5.24951 14.25L2.24951 15L2.99951 12L12.3745 2.62499Z"
      stroke="#9A9EB2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
