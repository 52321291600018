import {
  BalloonCollectionsArrear,
  TrueFalseValues,
  frequency,
  rateTypes,
  vatDeferralType,
} from "@flexCommon/constants";
import {
  ControlType,
  Field,
  FieldType,
  RateTypes,
  VatDeferralType,
} from "@flexCommon/enums";
import { IFieldProp } from "@interfaces/configuration.interface";

export const FieldData: IFieldProp[] = [
  {
    Name: FieldType.CommissionPercentage,
    DefaultValue: 0,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.TextBox,
    Label: Field.CommissionPercentage,
  },
  {
    Name: FieldType.VATPaymentNumber,
    DefaultValue: 3,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.TextBox,
    Label: Field.VATPaymentNumber,
  },
  {
    Name: FieldType.AdvancePayments,
    DefaultValue: 0,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.TextBox,
    Label: Field.AdvancePayments,
  },
  {
    Name: FieldType.RegularPayments,
    DefaultValue: 0,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.TextBox,
    Label: Field.RegularPayments,
  },
  {
    Name: FieldType.Rate,
    DefaultValue: 0,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.TextBox,
    Label: Field.Rate,
  },
  {
    Name: FieldType.BalloonPayment,
    DefaultValue: 0,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.TextBox,
    Label: Field.BalloonPayment,
  },
  {
    Name: FieldType.Deposit,
    DefaultValue: 0,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.TextBox,
    Label: Field.Deposit,
  },
  {
    Name: FieldType.VATAmount,
    DefaultValue: 0,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.TextBox,
    Label: Field.VATAmount,
  },
  {
    Name: FieldType.AssetCost,
    DefaultValue: 0,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.TextBox,
    Label: Field.AssetCost,
  },
  {
    Name: FieldType.RateType,
    DefaultValue: RateTypes.NominalRate,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.DropDown,
    Options: rateTypes,
    Label: Field.RateType,
  },
  {
    Name: FieldType.BalloonCollection,
    DefaultValue: 1,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.DropDown,
    Options: BalloonCollectionsArrear,
    Label: Field.BalloonCollection,
  },
  {
    Name: FieldType.FundingCost,
    DefaultValue: 0,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.TextBox,
    Label: Field.FundingCost,
  },
  {
    Name: FieldType.FlatRateExclCommission,
    DefaultValue: "Output Value",
    Disabled: true,
    Hidden: false,
    ControlType: ControlType.TextBox,
    Label: Field.FlatRateExclCommission,
  },
  {
    Name: FieldType.VATDeferred,
    DefaultValue: VatDeferralType.Rental,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.DropDown,
    Options: vatDeferralType,
    Label: Field.VATDeferred,
  },
  {
    Name: FieldType.StepPaymentsApplicable,
    DefaultValue: false,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.DropDown,
    Options: TrueFalseValues,
    Label: Field.StepPaymentsApplicable,
  },
  {
    Name: FieldType.StepPaymentsStructureApplicable,
    DefaultValue: false,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.DropDown,
    Options: TrueFalseValues,
    Label: Field.StepPaymentsStructureApplicable,
  },
  {
    Name: FieldType.VATAmountAutoCalculateApplicable,
    DefaultValue: false,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.DropDown,
    Options: TrueFalseValues,
    Label: Field.VATAmountAutoCalculateApplicable,
  },
  {
    Name: FieldType.IrregularPaymentsApplicable,
    DefaultValue: true,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.DropDown,
    Options: TrueFalseValues,
    Label: Field.IrregularPaymentsApplicable,
  },
  {
    Name: FieldType.PDFTemplateApplicable,
    DefaultValue: true,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.DropDown,
    Options: TrueFalseValues,
    Label: Field.PDFTemplateApplicable,
  },
  {
    Name: FieldType.WordTemplateApplicable,
    DefaultValue: true,
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.DropDown,
    Options: TrueFalseValues,
    Label: Field.WordTemplateApplicable,
  },
  {
    Name: FieldType.Frequency,
    DefaultValue: "Monthly",
    Disabled: false,
    Hidden: false,
    ControlType: ControlType.DropDown,
    Options: frequency,
    Label: Field.Frequency,
  },
];
