import "react";
import { ButtonGroup, PageHeader } from "@components";
import { useTheme } from "@mui/material";

export const DealerProposalPageHeader = () => {
  const theme = useTheme();
  return (
    <PageHeader
      title="Proposals & Quotations"
      actionArea={
        <>
          <ButtonGroup
            items={[
              {
                title: "Track Proposals",
                onClick: () => {},
                selected: false,
              },
              {
                title: "Dealer Proposals",
                onClick: () => {},
                selected: true,
              },
              {
                title: "Draft Proposals",
                onClick: () => {},
                selected: false,
              },
              {
                title: "Quotations",
                onClick: () => {},
                selected: false,
              },
            ]}
          />
        </>
      }
    />
  );
};
