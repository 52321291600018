import * as React from "react";
import { NumericFormat } from "react-number-format";

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  thousandSeparator: boolean;
  scale: number;
  prefix: string;
  allowNegative: boolean;
  allowLeadingZeros: boolean;
  suffix: string;
};

export const NumericFormatCustom = React.forwardRef<HTMLElement, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const {
      onChange,
      thousandSeparator = true,
      prefix = "",
      suffix = "",
      scale = 2,
      allowNegative = false,
      allowLeadingZeros = false,
      ...other
    } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={thousandSeparator}
        valueIsNumericString
        prefix={prefix}
        suffix={suffix}
        decimalScale={scale}
        fixedDecimalScale={true}
        allowNegative={allowNegative}
        allowLeadingZeros={allowLeadingZeros}
      />
    );
  }
);
