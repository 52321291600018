import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { Router } from "@router";
import { BROKER_APP_THEME, BROKER_APP_THEME_WRAP } from "styles";
import { SnackbarContextProvider } from "@contexts/snackbar";

export default function Root(props) {
  return <App {...props} />;
}

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        retry: 1,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={BROKER_APP_THEME}>
          <BROKER_APP_THEME_WRAP
            className="global-theme-wrap"
            theme={BROKER_APP_THEME}
          >
            <SnackbarContextProvider>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </SnackbarContextProvider>
          </BROKER_APP_THEME_WRAP>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
};
