import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const TotalAssetCostWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.total-asset-cost-wrap": {
    ".stack-heading": {
      textAlign: "center",
    },
    ".toggle-stack": {
      height: 108,
      overflow: "hidden",
      ".stack-heading": {
        display: "block",
        ".MuiTypography-h2": {
          fontSize: 28,
          lineHeight: "40px",
        },
      },
    },
    ".isToggle": {
      ".toggle-stack": {
        height: "auto",
        overflow: "hidden",
        padding: 0,
        ".stack-heading": {
          display: "none",
        },
      },
      ".toggle-btn": {
        ".MuiButtonBase-root": {
          svg: {
            rotate: "180deg",
          },
        },
      },
    },
    ".toggle-btn": {
      position: "absolute",
      left: 0,
      right: 0,
      zIndex: 13,
      bottom: "-22px",
      display: "flex",
      justifyContent: "center",
      ".btn": {
        backgroundColor: theme.palette.common.white,
        boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.16)",
        borderRadius: 30,
        width: 40,
        height: 40,
        "&.icon-only": {
          padding: 0,
          "&:hover": {
            backgroundColor: theme.palette.common.white,
          },
          "&:focus": {
            backgroundColor: theme.palette.common.white,
          },
        },
      },
    },
  },
}));
