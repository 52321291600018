import { CustomerDetail } from "@interfaces/requestparams.flex";
import { validateEmail } from "@validations/validate-save-configuration";

export const ValidateRetrieveQuotation = (state: CustomerDetail) => {
  if (state.TrackingID == "" || state.TrackingID == undefined) {
    return {
      visible: true,
      error: "Please provide valid refrence number",
    };
  } else if (validateEmail(state.CustomerEmailAddress, false)) {
    return {
      visible: true,
      error: "Please provide valid customer email address.",
    };
  } else if (validateEmail(state.VendorEmailAddress, false)) {
    return {
      visible: true,
      error: "Please provide valid introducer email address.",
    };
  }
};
