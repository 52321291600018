import { IconButton, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Snackbar as SnackbarControl } from "@ntpkunity/controls";
import React, { useEffect } from "react";
import { useSnackbarContext } from "@contexts/snackbar";

const Snackbar = () => {
  const { resetSnackbar, snackbar } = useSnackbarContext();
  const theme = useTheme();

  // INFO: As snackbar is coming from root level context
  // we need to reset state when the component unmounts
  useEffect(() => {
    return () => {
      resetSnackbar();
    };
  }, []);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={resetSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div>
      <SnackbarControl
        theme={theme}
        open={snackbar.open}
        onClose={resetSnackbar}
        message={snackbar.message}
        variant={snackbar.variant}
        action={action}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      />
    </div>
  );
};

export default Snackbar;
