import * as React from "react";
import {
  Box,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuListProps,
  MenuProps,
  Theme,
  styled,
} from "@mui/material";
import { ReactNode } from "react";

interface IanchorOrigin {
  vertical: string;
  horizontal: string;
}
interface ItransformOrigin {
  vertical: string;
  horizontal: string;
}

export interface IMenuItemOptions {
  optionText: any;
  optionkey?: string;
  optionValue?: any;
  disabled?: boolean;
  className?: string | undefined;
}

export declare type IMenuProps = Partial<MenuProps> &
  Partial<MenuItemProps> &
  Partial<MenuListProps> & {
    options: IMenuItemOptions[];
    disablePortal?: boolean;
    render: (e: (ev: any) => void) => ReactNode;
    handleSelect?: (option: any) => void;
    theme: Theme;
    anchorOrigin?: IanchorOrigin;
    transformOrigin?: ItransformOrigin;
    handleOptionClick?: (e: any, key?: any, value?: any) => void;
    customChildren?: ReactNode;
  };

const ITEM_HEIGHT = 48;

const StyledMenu = styled(Menu, {
  // shouldForwardProp: props => isPropValid(props),
})<Partial<IMenuProps>>(({ theme }) => ({
  "&.u-dropdown-menu": {
    ".MuiMenu-paper": {
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: [theme.palette.grey[50]],
      boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.16)",
      minWidth: 145,
      padding: 8,
      color: theme.palette.grey[600],
      scrollbarWidth: "thin",
      borderRadius: theme.shape.borderRadius,
      backgroundImage: "none",
      ".MuiMenu-list": {
        padding: 0,
      },
      "&::-webkit-scrollbar": {
        width: 7,
        height: 7,
      },
      "&::-webkit-scrollbar-track": {
        padding: "0 1px",
        backgroundColor: "#f0f0f0",
      },
      "&::-webkit-scrollbar-thumb": {
        transition: "all 0.3s",
        backgroundColor: "#cdcdcd",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#a6a6a6",
      },
    },
    ".u-dropdown-item": {
      padding: "12px 24px 12px 16px",
      borderRadius: theme.shape.borderRadius,
      fontWeight: theme.typography.fontWeightRegular,
      fontFamily: theme.typography.fontFamily,
      ".menu-icon": {
        width: 24,
        height: 24,
        marginRight: 16,
        path: {
          stroke: theme.palette.grey[300],
        },
      },
      "&:hover": {
        backgroundColor: [theme.customVariables.tableStripedBg],
        color: theme.palette.grey[900],
        // fontWeight: theme.typography.fontWeightMedium,
        ".menu-icon": {
          path: {
            stroke: theme.palette.grey[500],
          },
        },
      },
      ".MuiTouchRipple-root": {
        display: "none",
      },
    },
    ".custom-child-wrap": {
      fontFamily: theme.typography.fontFamily,
    },
  },
}));

export const MenuList: React.FC<IMenuProps> = ({
  options,
  disablePortal,
  render,
  theme,
  handleOptionClick,
  anchorOrigin,
  transformOrigin,
  disabled,
  customChildren,
  handleSelect,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleOption = (e: any, key = "", value: any = ""): void => {
    handleClick(e);
    handleClose();
    if (handleOptionClick != null) {
      handleOptionClick(e, key, value);
    }
  };

  return (
    <>
      {render(handleClick)}
      <StyledMenu
        className="u-dropdown-menu"
        id="long-menu"
        theme={theme}
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disablePortal={disablePortal}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {customChildren && (
          <Box className="custom-child-wrap">{customChildren}</Box>
        )}
        {options.map((option, index) => (
          <MenuItem
            disabled={option.disabled}
            className={`u-dropdown-item ${option.className as string}`}
            key={index}
            onClick={(e) => {
              if (handleSelect) {
                handleSelect(option);
              }
              handleOption(e, option.optionkey, option.optionValue);
            }}
          >
            {option.optionText}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};
