import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const DialogContentWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.dialog-content-wrap": {
    ".stack-wrap": {
      ".stack-header": {
        ".title": {
          ".MuiTypography-root": {
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            fontWeight: theme.customVariables.fontWeightSemiBold,
          },
        },
      },
    },
    ".custom-dropdown": {
      display: "flex",
      alignItems: "center",
      gap: 4,
      ".u-form-group": {
        height: 20,
        ".u-form-control": {
          "&.u-select": {
            height: 20,
            color: theme.palette.grey[900],
            fontWeight: theme.customVariables.fontWeightSemiBold,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            ".MuiInputBase-input": {
              padding: "0px 28px 0px 0px",
            },
            ".MuiSelect-icon": {
              right: 0,
              path: {
                stroke: theme.palette.grey[900],
              },
            },
            fieldset: {
              border: "none",
            },
          },
        },
      },
    },
    ".comments-area": {
      borderTop: "1px solid" + theme.palette.divider,
    },
  },
}));
