import { Stack } from "@components";
import { useTheme } from "@mui/material";
import { Box, Button, Grid, Icon, Typography } from "@ntpkunity/controls";
import CustomerDetailRow from "./customer-detail-row/customer-detail-row.component";
import EditCustomerDetailPopup from "../../../customer-proposal/customer-detail-edit-popup/customer-detail-edit-popup.component";
import { useState } from "react";

export const CustomerDetails = ({ hasEdit }: { hasEdit?: boolean }) => {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Stack
        paddingXs={3}
        paddingMd={3}
        title="Customer Details"
        actionArea={
          <>
            {hasEdit && (
              <Button
                defaultBtn
                theme={theme}
                onClick={() => setDialogOpen(true)}
                iconText={<Icon name="SignatureIcon" />}
              ></Button>
            )}
          </>
        }
      >
        <Grid theme={theme} container columnSpacing={3} mt={2}>
          <CustomerDetailRow label="Client Type" value={"Company"} />
          <CustomerDetailRow label="Company Type" value={"Limited Company"} />
          <CustomerDetailRow label="Company Name" value={"Alexander J."} />
          <CustomerDetailRow label="Trading As" value={"Sample Trading Name"} />
          <CustomerDetailRow label="Registration Number" value={"ABC-1234"} />
          <Grid theme={theme} item lg={3} md={4} sm={6} xs={6}>
            <Typography
              theme={theme}
              component="span"
              variant="caption"
              className="label"
              children="Address"
            />
          </Grid>
          <Grid theme={theme} item lg={9} md={8} sm={6} xs={6}>
            <Typography
              theme={theme}
              component="small"
              variant="caption"
              className="value"
              children={"Acacia Avenue, Silhurst, SH15 6BP"}
            />
          </Grid>
        </Grid>
      </Stack>
      <EditCustomerDetailPopup
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};
