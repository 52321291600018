import {
  WorkQueueTabs,
  OrderStatus,
  ProposalTabs,
  AssetCategories,
  ClientTypes,
  AssetTypes,
  AmountType,
  DashboardRangeFilter,
  OrderStatusCategory,
  DocumentTypes,
  TableRowOptions,
} from "@helpers/enum";
import { ICountry } from "@interfaces/config.interface";
import {
  DashboardForm,
  TCompanyDetails,
  TIndividualDetails,
  TProposalForm,
} from "@_types";
import { DefaultValues } from "react-hook-form";
import {
  RateType,
  ProductType,
  VatTreatment,
} from "@ntpkunity/controls-common";

export const LOCAL_STORAGE_KEYS = {
  MODE: "mode",
};
const BROKER_BASE_ROUTE = "/broker-app";
const AUTHENTICATION_ROUTE = "/authentication";

export const APP_ROUTES = {
  BASE_URL: BROKER_BASE_ROUTE,
  UNKNOW_URL: BROKER_BASE_ROUTE + "/*",
  DASHBOARD: BROKER_BASE_ROUTE + "/dashboard",
  QUOTATION: BROKER_BASE_ROUTE + "/quotation",
  NEW_QUOTATION: BROKER_BASE_ROUTE + "/new-quotation",
  PROPOSAL: BROKER_BASE_ROUTE + "/proposal",
  WORKQUEUE: BROKER_BASE_ROUTE + "/work-queue",
  CONFIGURATIONS: BROKER_BASE_ROUTE + "/configurations",
  SETUPS: BROKER_BASE_ROUTE + "/setups",
  ANALYTICS: BROKER_BASE_ROUTE + "/analytics",
  DIARY: BROKER_BASE_ROUTE + "/diary",
  QUOTATION_COMPARISON: BROKER_BASE_ROUTE + "/quotation-comparison",
  DOCUMENT: BROKER_BASE_ROUTE + "/document",
  SEND_FOR_PAYOUT: BROKER_BASE_ROUTE + "/send-for-payout",
  SIGN_IN: BROKER_BASE_ROUTE + AUTHENTICATION_ROUTE + "/sign-in",
  DEALER_PROPOSAL: BROKER_BASE_ROUTE + "/work-queue/dealer-proposal",
  CUSTOMER_PROPOSAL: BROKER_BASE_ROUTE + "/customer-proposal",
  SIGNATORY_STATUS: BROKER_BASE_ROUTE + "/signatory-status",
  LENDER_DASHBOARD: BROKER_BASE_ROUTE + "/lender-dashboard",
  FINANCIAL_CONFIGURATIONS: BROKER_BASE_ROUTE + "/financial-configurations",
};

/***** Hard Coded for Demo Purposes *****/
export const ASSET_INFO = [
  "Hard Asset - Vehicle - New vehicle",
  "Hard Asset - Vehicle - Used vehicle",
  "Hard Asset - Vehicle - Classic Cars",
  "Hard Asset - Equipment - Agricultural Equipment",
  "Hard Asset - Equipment - Office Equipment",
  "Hard Asset - Equipment - Construction Equipment",
] as const;

export const CUSTOMER_TYPES = [...Object.values(ClientTypes)] as const;

export const CUSTOMER_TYPES_DISABLED = {
  [ClientTypes.GOV_ORG]: true,
  [ClientTypes.NON_PROFIT_ORG]: true,
  [ClientTypes.SOLE_TRADER]: true,
};

export const BROKER_NAMES = ["Unity Broker", "Appexnow Broker"] as const;

export const PROPOSAL_DETAILS = {
  dealerNames: [
    "Classic Cars Dealer",
    "Machinery Traders",
    "Molson Equipment Dealer",
    "North Truck Dealers",
  ],
  lenderNames: [
    "United Trust Bank",
    "Close Brothers Bank",
    "Haydock Finance",
    "Lloyds Bank",
  ],
  brokerNames: ["Unity Broker", "Appexnow Broker"],
  productTypes: [
    ProductType.HIRE_PURCHASE,
    ProductType.FINANCE_LEASE,
    ProductType.LOAN,
  ],
  financeTypes: ["Regulated", "Unregulated"],
} as const;

export const ASSET_CATEGORIES = ["Soft Asset", "Hard Asset"] as const;

export const ASSET_TYPES = {
  [AssetCategories.HARD_ASSET]: [AssetTypes.VEHICLE, AssetTypes.EQUIPMENT],
  [AssetCategories.SOFT_ASSET]: [AssetTypes.SOFTWARE],
} as const;

export const ASSET_SUB_TYPES = {
  [AssetTypes.VEHICLE]: [
    "Electric Vehicle",
    "Classic Car",
    "Used Vehicle",
    "New Vehicle",
    "Commercial Vehicle",
  ],
  [AssetTypes.EQUIPMENT]: [
    "Agricultural Equipment",
    "Construction Equipment",
    "Office Equipment",
  ],
  [AssetTypes.SOFTWARE]: [],
} as const;

export const ASSET_CONDITIONS = {
  [AssetCategories.HARD_ASSET]: ["New", "Used"],
  [AssetCategories.SOFT_ASSET]: [],
} as const;
const COMPANY_DOCUMENT_TYPES = [
  "Financial Statements",
  "Directors Approval",
  "Company Registration Cerificate",
  "Business Proposal",
] as const;
export const DOCUMENT_TYPES = {
  [ClientTypes.INDIVIDUAL]: ["Id Document", "Proof of Address"],
  [ClientTypes.LIMITED_COMPANY]: COMPANY_DOCUMENT_TYPES,
  [ClientTypes.PARTNERSHIP]: COMPANY_DOCUMENT_TYPES,
  [ClientTypes.NON_PROFIT_ORG]: COMPANY_DOCUMENT_TYPES,
  [ClientTypes.SOLE_TRADER]: COMPANY_DOCUMENT_TYPES,
  [ClientTypes.GOV_ORG]: COMPANY_DOCUMENT_TYPES,
} as const;

export const ALLOWED_FILE_TYPES = {
  [DocumentTypes.CREDIT_DOCUMENTS]: [
    "application/pdf",
    "image/jpeg",
    "image/png",
  ],
} as const;

export const GB_COUNTRY: ICountry = {
  is_active: true,
  is_deleted: false,
  id: 228,
  name: "United Kingdom",
  code: "44",
  country_code: "GB",
  address_template: {
    is_active: true,
    is_deleted: false,
    id: 4,
    country_id: 229,
    field1_label: "City",
    field1_visible: true,
    field2_label: null,
    field2_visible: null,
    field3_label: "Post Code",
    field3_visible: true,
    field4_label: "County",
    field4_visible: true,
    field5_label: null,
    field5_visible: null,
    field6_label: null,
    field6_visible: null,
  },
};
/****************************************/

export const INDIVIDUAL_LABEL_AND_KEYS: {
  label: string;
  key: keyof TIndividualDetails;
}[] = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "ID Number", key: "idNumber" },
  { label: "Email Address", key: "emailAddress" },
  { label: "Contact Number", key: "contactNumber" },
];

export const COMPANY_LABEL_AND_KEYS: {
  label: string;
  key: keyof TCompanyDetails;
}[] = [
  { label: "Company Name", key: "companyName" },
  { label: "Registration Number", key: "companyRegNum" },
  { label: "Trading As", key: "tradingAs" },
  { label: "Years in Business", key: "yearsInBusiness" },
];

export const CURRENCY_CODES = {
  GBP: "GBP",
};

export const LOCALES = {
  GB: "en-GB",
};

export const WORK_QUEUE_TABS = [
  {
    title: "Track Proposals",
    value: WorkQueueTabs.TRACK_PROPOSALS,
  },
  {
    title: "Dealer Proposal",
    value: WorkQueueTabs.DEALER_PROPOSALS,
  },
  {
    title: "Draft Proposals",
    value: WorkQueueTabs.DRAFT_PROPOSALS,
  },
  {
    title: "Quotations",
    value: WorkQueueTabs.QUOTATIONS,
  },
];

export const DEALER_WORK_QUEUE_TABS = [
  {
    title: "Track Proposals",
    value: WorkQueueTabs.TRACK_PROPOSALS,
  },
  {
    title: "Draft Proposals",
    value: WorkQueueTabs.DRAFT_PROPOSALS,
  },
  {
    title: "Quotations",
    value: WorkQueueTabs.QUOTATIONS,
  },
];

export const QUEUE_TO_STATUS_MAP = {
  [WorkQueueTabs.DRAFT_PROPOSALS]: [OrderStatus.DRAFT],
  [WorkQueueTabs.TRACK_PROPOSALS]: [
    OrderStatus.ACCEPTED,
    OrderStatus.SUBMITTED,
    OrderStatus.CONDITIONED,
    OrderStatus.DECLINED,
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.DOCUMENT_SENT,
    OrderStatus.PAID_OUT,
    OrderStatus.SENT_FOR_PAYOUT,
    OrderStatus.WITHDRAWN,
  ],
  [WorkQueueTabs.DASHBOARD_PROPOSALS]: [
    OrderStatus.ACCEPTED,
    OrderStatus.SUBMITTED,
    OrderStatus.CONDITIONED,
    OrderStatus.DECLINED,
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.DOCUMENT_SENT,
    OrderStatus.PAID_OUT,
    OrderStatus.SENT_FOR_PAYOUT,
  ],
};

export const PROPOSAL_STATUSES_BY_CATEGORY = {
  [OrderStatusCategory.ACTIVE]: [
    OrderStatus.SUBMITTED,
    OrderStatus.ACCEPTED,
    OrderStatus.CONDITIONED,
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.SENT_FOR_PAYOUT,
    OrderStatus.DOCUMENT_SENT,
  ],
  [OrderStatusCategory.CLOSED]: [
    OrderStatus.DECLINED,
    OrderStatus.WITHDRAWN,
    OrderStatus.PAID_OUT,
  ],
};

export const DRAFT_QUEUE_STATUSES = [
  {
    text: "All",
    value: "all",
  },
  ...QUEUE_TO_STATUS_MAP[WorkQueueTabs.DRAFT_PROPOSALS].map((status) => ({
    text: status,
    value: status,
  })),
];

export const TRACK_QUEUE_STATUSES = [
  {
    text: "All",
    value: "all",
  },
  ...QUEUE_TO_STATUS_MAP[WorkQueueTabs.TRACK_PROPOSALS].map((status) => ({
    text: status,
    value: status,
  })),
];

export const PROPOSAL_STEP_ITEMS_INITIAL_STATE = [
  { title: ProposalTabs.PROPOSAL_DETAILS, disabled: false },
  { title: ProposalTabs.ASSET_DETAILS, disabled: false },
  { title: ProposalTabs.FINANCE_DETAILS, disabled: false },
  { title: ProposalTabs.CREDIT_DOCUMENTS, disabled: false },
  { title: ProposalTabs.SUMMARY, disabled: false },
];

export const PROPOSAL_FORM_INITIAL_STATE: DefaultValues<TProposalForm> = {
  proposalDetails: {
    brokerName: PROPOSAL_DETAILS.brokerNames[0],
    productType: ProductType.HIRE_PURCHASE,
  },
  customerDetails: {
    clientType: CUSTOMER_TYPES[0],
    directorDetails: [],
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      zipCode: "",
      county: "",
    },
  },
};

export const MAX_NUMBER_OF_QUOTATIONS = 3;
export const MIN_NUMBER_OF_QUOTATIONS = 2;

export const PROPOSAL_PRODUCT_TYPES = [
  {
    text: ProductType.FINANCE_LEASE,
    value: ProductType.FINANCE_LEASE,
  },
  {
    text: ProductType.HIRE_PURCHASE,
    value: ProductType.HIRE_PURCHASE,
  },
  {
    text: ProductType.LOAN,
    value: ProductType.LOAN,
  },
];

export const DASHBOARD_RANGE_FILTER_VALUES = [
  {
    text: DashboardRangeFilter.WEEK,
    value: DashboardRangeFilter.WEEK,
  },
  {
    text: DashboardRangeFilter.MONTH,
    value: DashboardRangeFilter.MONTH,
  },
  {
    text: DashboardRangeFilter.QUARTER,
    value: DashboardRangeFilter.QUARTER,
  },
  {
    text: "Calendar Year",
    value: DashboardRangeFilter.CALENDER_YEAR,
  },
];

export const DASHBOARD_FORM_DEFAULT_VALUES: DefaultValues<DashboardForm> = {
  rangeFilter: DashboardRangeFilter.MONTH,
  totalRangeFilter: DashboardRangeFilter.MONTH,
};

export const PROPOSAL_STATUS_TO_ALLOWED_STATUS_MAP = {
  [OrderStatus.DRAFT]: [OrderStatus.SUBMITTED],
  [OrderStatus.SUBMITTED]: [
    OrderStatus.CONDITIONED,
    OrderStatus.ACCEPTED,
    OrderStatus.DECLINED,
    OrderStatus.WITHDRAWN,
  ],
  [OrderStatus.CONDITIONED]: [
    OrderStatus.ACCEPTED,
    OrderStatus.SUBMITTED,
    OrderStatus.DECLINED,
    OrderStatus.WITHDRAWN,
  ],
  [OrderStatus.ACCEPTED]: [
    OrderStatus.WITHDRAWN,
    OrderStatus.DOCUMENT_SENT,
    OrderStatus.DOCUMENT_RECEIVED,
    OrderStatus.SENT_FOR_PAYOUT,
  ],
  [OrderStatus.DECLINED]: [OrderStatus.SUBMITTED, OrderStatus.WITHDRAWN],
  [OrderStatus.WITHDRAWN]: [OrderStatus.SUBMITTED],
  [OrderStatus.DOCUMENT_SENT]: [OrderStatus.DOCUMENT_RECEIVED],
  [OrderStatus.DOCUMENT_RECEIVED]: [OrderStatus.SENT_FOR_PAYOUT],
  [OrderStatus.SENT_FOR_PAYOUT]: [OrderStatus.PAID_OUT],
  [OrderStatus.PAID_OUT]: [],
};

export const PROPOSAL_STATUS_TO_ROW_OPTION_MAP = {
  [OrderStatus.DRAFT]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.SUBMITTED]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.WITHDRAW,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.CONDITIONED]: [
    TableRowOptions.VIEW_CONDITIONS,
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.WITHDRAW,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.ACCEPTED]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.WITHDRAW,
    TableRowOptions.GENERATE_DOCUMENTS,
    TableRowOptions.CHECK_SIGNATORY_STATUS,
    TableRowOptions.SEND_FOR_PAYOUT,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.DECLINED]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.WITHDRAWN]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.DOCUMENT_SENT]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.WITHDRAW,
    TableRowOptions.CHECK_SIGNATORY_STATUS,
    TableRowOptions.SEND_FOR_PAYOUT,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.DOCUMENT_RECEIVED]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.WITHDRAW,
    TableRowOptions.CHECK_SIGNATORY_STATUS,
    TableRowOptions.SEND_FOR_PAYOUT,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.SENT_FOR_PAYOUT]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.MANUAL_STATUS_CHANGE,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.UPLOAD,
    TableRowOptions.DOWNLOAD,
    TableRowOptions.CHECK_SIGNATORY_STATUS,
    TableRowOptions.SEND_FOR_PAYOUT,
    TableRowOptions.OPEN_DIARY,
  ],
  [OrderStatus.PAID_OUT]: [
    TableRowOptions.VIEW_EDIT,
    TableRowOptions.PROPOSAL_SUMMARY,
    TableRowOptions.OPEN_DIARY,
  ],
};

export const PROPOSAL_OPTION_TEXT_MAP = {
  [TableRowOptions.VIEW_EDIT]: "View/Edit Proposal",
  [TableRowOptions.PROPOSAL_SUMMARY]: "View Proposal Summary",
  [TableRowOptions.UPLOAD]: "Upload Documents",
  [TableRowOptions.DOWNLOAD]: "Download Proposal",
  [TableRowOptions.WITHDRAW]: "Withdraw Proposal",
  [TableRowOptions.OPEN_DIARY]: "Diary",
  [TableRowOptions.VIEW_CONDITIONS]: "View Conditions",
  [TableRowOptions.GENERATE_DOCUMENTS]: "Generate Documents",
  [TableRowOptions.CHECK_SIGNATORY_STATUS]: "Check Signatories Status",
  [TableRowOptions.SEND_FOR_PAYOUT]: "Send for Payout",
  [TableRowOptions.MANUAL_STATUS_CHANGE]: "Manually Change Status",
};

export const PAYOUT_STATUS = [
  {
    status: "Signed",
    color: "ind-success",
  },
  {
    status: "Signed",
    color: "ind-success",
  },
  {
    status: "Signed",
    color: "ind-success",
  },
];

export const PROPOSAL_SUMMARY_STATUS = [
  {
    status: "Out For Signature",
    color: "ind-primary",
  },
  {
    status: "Out For Signature",
    color: "ind-primary",
  },
  {
    status: "Out For Signature",
    color: "ind-primary",
  },
];

export const DOCUMENT_SIGNATORY_STATUS = [
  {
    status: "Signed",
    color: "ind-success",
  },
  {
    status: "Signed",
    color: "ind-success",
  },
  {
    status: "Out For Signature",
    color: "ind-primary",
  },
];
