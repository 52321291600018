import {
  LayoutWithSideNavComponent,
  PageHeader,
  ConfigurationIframe,
} from "@components";
import { useTheme } from "@mui/material";
import { Box, Button, DuoTab } from "@ntpkunity/controls";
import { PageContent } from "@pages/configuration/configuration-page.style";

export const ConfigurationPage = () => {
  const theme = useTheme();
  return (
    <LayoutWithSideNavComponent theme={theme}>
      <PageHeader
        title="Configurations"
        // actionArea={
        //   <>
        //     <Button theme={theme} secondary type="button" text='Save Changes' onClick={()=>{}} />
        //   </>
        // }
      />
      <PageContent theme={theme} className="page-content">
        <Box
          theme={theme}
          className="config-container"
          m={{ xs: "0 -24px -24px -24px", md: "0 -40px -24px -40px" }}
          sx={{ backgroundColor: "#E5E5E5" }}
        >
          <DuoTab
            varient="underline"
            theme={theme}
            items={[
              {
                title: "Quotation Configurations",
                content: (
                  <>
                    <ConfigurationIframe />
                  </>
                ),
              },
            ]}
            defaultTabIndex={0}
          />
        </Box>
      </PageContent>
    </LayoutWithSideNavComponent>
  );
};
