import { Box, Snackbar } from "@ntpkunity/controls";
import { QuotationFields as QuotationFieldsControl } from "@ntpkunity/controls-common";
import { useTheme } from "@mui/material";
import { MIN_NUMBER_OF_QUOTATIONS } from "@helpers/const";

type QuotationFieldsProps = {
  headerText: string;
  totalQuotations: number;
  errorMessage?: string;
  onDelete?: () => void;
  onReset?: () => void;
  onErrorClose: () => void;
};

export const QuotationFields = ({
  headerText,
  totalQuotations,
  errorMessage,
  onDelete,
  onReset,
  onErrorClose,
}: QuotationFieldsProps) => {
  const theme = useTheme();
  return (
    <Box theme={theme} className="comparison-box">
      <QuotationFieldsControl
        theme={theme}
        hideDeleteBtn={totalQuotations === MIN_NUMBER_OF_QUOTATIONS}
        headerText={headerText}
        onDelete={onDelete}
        onReset={onReset}
      />
      <Snackbar
        theme={theme}
        open={!!errorMessage}
        message={errorMessage}
        variant="error"
        autoHideDuration={10000}
        onClose={(_, reason) => {
          if (reason !== "clickaway") onErrorClose();
        }}
      />
    </Box>
  );
};
