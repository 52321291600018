import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AssetDetailsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.asset-details-wrap": {
    ".stack-mobile": {
      [theme.breakpoints.up("md")]: {
        padding: 0,
        backgroundColor: "transparent",
        border: "none",
      },
      ".u-accordian-wrap": {
        [theme.breakpoints.up("md")]: {
          backgroundColor: "transparent",
          ".u-accordian-body": {
            padding: 0,
          },
        },
      },
    },
  },
}));
