import { Dialog } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { PreviousCommentsWrap } from "./previous-comments.style";
import { PreviousComments } from "./previous-comments.component";

export const PreviousCommentsDialog = ({ open, onClose }) => {
  const theme = useTheme();
  return (
    <Dialog
      theme={theme}
      size="sm"
      title="Audit Trail"
      onCloseDialog={onClose}
      noFooter
      open={open}
    >
      <PreviousCommentsWrap
        theme={theme}
        className="previous-comments-popup-wrap"
      >
        <PreviousComments />
      </PreviousCommentsWrap>
    </Dialog>
  );
};
