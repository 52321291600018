import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PageContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.page-content": {
    ".quotation-tool-container": {
      [theme.breakpoints.down("md")]: {
        margin: "24px -16px -24px -16px",
      },
      [theme.breakpoints.up("md")]: {
        margin: "0px -40px -24px -40px",
        minHeight: "calc(100vh - 164px)",
      },
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 287,
    },
    ".quotation-tool-wrap": {
      ".quotation-box": {
        "&.fields-wrap": {
          height: "calc(100vh - 196px)",
          [theme.breakpoints.down("md")]: {
            height: "auto",
          },
        },
        "&.results": {
          height: "calc(100vh - 196px)",
        },
        "&.rates": {
          ".scrollable-container": {
            minHeight: "calc(50vh - 150px)",
            maxHeight: "calc(50vh - 150px)",
            "&.info-collapsed": {
              minHeight: "calc(100vh - 315px)",
              maxHeight: "calc(100vh - 315px)",
            },
          },
        },
        "&.info": {
          ".scrollable-container": {
            minHeight: "calc(50vh - 214px)",
            maxHeight: "calc(50vh - 214px)",
          },
        },
      },
    },
  },
}));
