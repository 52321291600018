import { ReactNode, forwardRef, useImperativeHandle, useState } from "react";
import { Typography } from "@ntpkunity/controls";
import {
  PieChart,
  ResponsiveContainer,
  Pie,
  Sector,
  Label,
  Tooltip,
  Cell,
  Legend,
} from "recharts";
import { useTheme } from "@mui/material";
import { ChartBaseData } from "@_types";
import { PieChartWrap } from "./pie-chart.style";

const ActiveShape = (props) => {
  return (
    <g>
      <Sector
        cx={props.cx}
        cy={props.cy}
        innerRadius={props.innerRadius}
        outerRadius={props.outerRadius + 5}
        startAngle={props.startAngle}
        endAngle={props.endAngle}
        fill={props.payload.color}
      />
    </g>
  );
};

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
  label?: string;
}) => {
  const theme = useTheme();
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: theme.palette.grey[900],
          color: theme.palette.common.white,
          borderRadius: theme.shape.borderRadius,
          padding: "2px 8px",
        }}
      >
        <Typography theme={theme} component="small" variant="caption">
          {payload[0].name}: {payload[0].value}
        </Typography>
      </div>
    );
  }
  return null;
};

type PieChartControlProps = {
  data: ChartBaseData[];
  radius: number;
  donutWidth: "full" | number;
  onClick?: (props: any, index: number, activeIndex) => void;
  centerText?: string | ReactNode;
};

export const ProposalPieChart = forwardRef(
  (props: PieChartControlProps, ref: React.ForwardedRef<any>) => {
    const { data, radius, centerText, onClick, donutWidth = "full" } = props;
    const [activeIndex, setActiveIndex] = useState(null);
    const onMouseClick = (props: any, index: number) => {
      if (activeIndex !== index) {
        setActiveIndex(index);
        onClick && onClick?.(props, index, true);
      } else {
        setActiveIndex(null);
        onClick && onClick?.(props, index, false);
      }
    };
    useImperativeHandle(ref, () => {
      return {
        updateIndex: (index) => {
          setActiveIndex(index);
        },
      };
    });

    const innerRadius = donutWidth === "full" ? 0 : radius - donutWidth;
    const totalCount = data.reduce((prev, curr) => prev + curr.count, 0);
    const theme = useTheme();
    return (
      <PieChartWrap theme={theme} className="pie-chart-wrap">
        <ResponsiveContainer width="100%" height="100%" maxHeight={250}>
          <PieChart
            width={250}
            height={250}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <Pie
              activeIndex={activeIndex}
              activeShape={(props) => <ActiveShape {...props} />}
              data={data}
              cx={"50%"}
              cy={125}
              innerRadius={innerRadius}
              outerRadius={radius}
              dataKey="count"
              nameKey="status"
              onClick={onMouseClick}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="widget-main-number fw-sbold"
                        >
                          {totalCount}
                        </tspan>
                        {centerText && (
                          <tspan
                            className="text-muted sub-text"
                            x={viewBox.cx}
                            y={(viewBox.cy || 0) + 24}
                            style={{ maxWidth: 136 }}
                          >
                            {centerText}
                          </tspan>
                        )}
                      </text>
                    );
                  }
                }}
              />

              {data.map((item, index) => (
                <Cell key={`pie-cell-${index}`} fill={item.color} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend iconType="circle" onClick={onMouseClick} />
          </PieChart>
        </ResponsiveContainer>
      </PieChartWrap>
    );
  }
);
