import { Button, Icon, Menu } from "@ntpkunity/controls";
import { PageHeader, QuotationHandleRef } from "@components";
import { useTheme } from "@mui/material";
import { BrokerFields } from "./broker-fields.component";
import { FormProvider, useForm } from "react-hook-form";
import { BrokerSpecifcFormFields } from "@_types";
import { useSnackbarContext } from "@contexts/snackbar";
import {
  useConvertQuotationToProposal,
  useDownloadDocs,
  useSaveQuotation,
} from "@hooks/mutations";
import { APP_ROUTES } from "@helpers";
import { useNavigate } from "react-router-dom";
import { DocumentTemplateType, TableRowOptions } from "@helpers/enum";
import {
  errorInQuotationDetails,
  getBrokerQuotationPayload,
  getQuotationDownloadPayload,
} from "@helpers/utils";
import { useState } from "react";

type PageHeaderProps = {
  selectedQuotationRef: QuotationHandleRef;
  disableBtns: boolean;
};

export const QuotationComparisonPageHeader = ({
  selectedQuotationRef,
  disableBtns,
}: PageHeaderProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const formMethods = useForm<BrokerSpecifcFormFields>();
  const [quotationIdentifier, setQuotationIdentifier] = useState();
  const { setSnackbar } = useSnackbarContext();
  const { mutateAsync: saveQuotation } = useSaveQuotation();
  const { mutateAsync: convertToProposal } = useConvertQuotationToProposal();
  const { mutate: downloadDocs } = useDownloadDocs();

  const handleSaveQuotation = async () => {
    const error = errorInQuotationDetails(formMethods.getValues());
    if (error.isError) {
      setSnackbar({ message: error.message, open: true, variant: "error" });
      return false;
    }
    const refHandle = selectedQuotationRef.ref.current;
    try {
      setSnackbar({
        open: true,
        message: "Saving Quotation...",
      });
      const brokerSpecificValues = formMethods.getValues();
      const payload = refHandle.getSaveQuotationPayload();
      const response = await saveQuotation(
        getBrokerQuotationPayload(payload, brokerSpecificValues)
      );
      setQuotationIdentifier(response.data.identifier);
      setSnackbar(
        {
          open: true,
          message: "Quotation Saved Successfully",
        },
        2000
      );
      return response;
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Error Occurred while saving Quotation",
        variant: "error",
      });
    }
  };

  const handleConvertToProposal = async () => {
    try {
      let identifier = quotationIdentifier;
      if (!quotationIdentifier) {
        const response = await handleSaveQuotation();
        if (!response) {
          return;
        }
        identifier = response.data.identifier;
      }
      setSnackbar({ open: true, message: "Converting To Proposal..." });
      await convertToProposal(identifier);
      navigate(`${APP_ROUTES.PROPOSAL}/${identifier}`);
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Error occurred while converting to proposal",
        variant: "error",
      });
    }
  };

  const handleDownloadQuotation = () => {
    const refHandle = selectedQuotationRef.ref.current;
    const { getValues: getQuotationValues } =
      refHandle.getQuotationFormContext();
    const calculations = refHandle.getCalculationsContext();
    const brokerSpecificValues = formMethods.getValues();
    const downloadQuotationPayload = getQuotationDownloadPayload(
      { ...getQuotationValues(), ...brokerSpecificValues },
      calculations.data
    );
    downloadDocs({
      data_placeholders: downloadQuotationPayload,
      template_type: DocumentTemplateType.QUOTATION,
      template_name: "My_Quotation_Template_Word_2.docx",
      generate_base64: false,
      is_bookmark: false,
    });
  };

  const handleOptionClick = (_, key: TableRowOptions) => {
    switch (key) {
      case TableRowOptions.CONVERT_TO_PROPOSAL:
        handleConvertToProposal();
        break;
      case TableRowOptions.DOWNLOAD:
        handleDownloadQuotation();
        break;
    }
  };

  return (
    <FormProvider {...formMethods}>
      <PageHeader
        title={false}
        hasInput
        inputName="name"
        inputPlaceholder="Enter Quotation Name..."
        shouldUseForm={true}
        actionArea={
          <>
            <BrokerFields />
            <Menu
              theme={theme}
              options={[
                {
                  optionText: "Convert to proposal",
                  optionkey: TableRowOptions.CONVERT_TO_PROPOSAL,
                  disabled: disableBtns,
                },
                {
                  optionText: "Download Quotation",
                  optionkey: TableRowOptions.DOWNLOAD,
                  disabled: disableBtns,
                },
              ]}
              handleOptionClick={handleOptionClick}
              render={(cb) => (
                <Button
                  secondary
                  theme={theme}
                  iconText={<Icon name="MoreIcon" />}
                  onClick={cb}
                ></Button>
              )}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            />
            <Button
              theme={theme}
              type="button"
              primary
              disabled={disableBtns}
              text="Save Quotation"
              onClick={handleSaveQuotation}
            />
          </>
        }
      />
    </FormProvider>
  );
};
