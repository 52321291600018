import { Stack } from "@components";
import { useTheme } from "@mui/material";
import { Grid, Typography } from "@ntpkunity/controls";
import { TProposalForm } from "@_types";
import { useFormContext } from "react-hook-form";
import { ClientTypes } from "@helpers/enum";
import CustomerDetailRow from "./customer-detail-row/customer-detail-row.component";
import {
  INDIVIDUAL_LABEL_AND_KEYS,
  COMPANY_LABEL_AND_KEYS,
} from "@helpers/const";

export const CustomerDetails = () => {
  const theme = useTheme();
  const { getValues } = useFormContext<TProposalForm>();
  const customerDetails = getValues("customerDetails");
  const clientType = customerDetails.clientType;

  const isIndividual = clientType === ClientTypes.INDIVIDUAL;
  const clientTypeToShow = isIndividual ? ClientTypes.INDIVIDUAL : "Company";

  const address = customerDetails.address;

  const getFormattedAddress = () => {
    const items = [
      address.addressLine1,
      address.addressLine2,
      address.city,
      address.county,
      address.zipCode,
    ].filter(Boolean);
    return items.reduce((prev, cur, index) => {
      if (index) return `${prev}, ${cur}`;
      return cur;
    }, "");
  };

  return (
    <Stack paddingXs={3} paddingMd={3}>
      <Typography
        theme={theme}
        component="h4"
        variant="h4"
        children="Customer Details"
        sx={{ mb: 2 }}
      />
      <Grid theme={theme} container columnSpacing={3}>
        <CustomerDetailRow label="Client Type" value={clientTypeToShow} />
        {isIndividual ? (
          <>
            {INDIVIDUAL_LABEL_AND_KEYS.map((entry) => {
              return (
                <CustomerDetailRow
                  key={`customerDetails.${entry.key}`}
                  label={entry.label}
                  value={customerDetails[entry.key]}
                />
              );
            })}
          </>
        ) : (
          <>
            {COMPANY_LABEL_AND_KEYS.map((entry) => {
              return (
                <CustomerDetailRow
                  key={`customerDetails.${entry.key}`}
                  label={entry.label}
                  value={customerDetails[entry.key]}
                />
              );
            })}
          </>
        )}
        <Grid theme={theme} item lg={3} md={4} sm={6} xs={6}>
          <Typography
            theme={theme}
            component="span"
            variant="caption"
            className="label"
            children="Address"
          />
        </Grid>
        <Grid theme={theme} item lg={9} md={8} sm={6} xs={6}>
          <Typography
            theme={theme}
            component="b"
            variant="caption"
            className="value"
            children={getFormattedAddress()}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
