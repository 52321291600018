import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const UploadAdditionalDocumentsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.document-dialog-wrap": {
    ".u-custom-file-upload": {
      marginBottom: 0,
      ".root-drag-file": {
        height: 254,
      },
    },
    ".file-preview": {
      marginTop: 8,
    },
  },
}));
