import {
  Accordion,
  Box,
  Button,
  DataTable,
  Icon,
  Input,
  Menu,
  Pagination,
  Switch,
} from "@ntpkunity/controls";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { SignatoriesTableWrap } from "./add-signatories.style";
import { Stack } from "@components/stack";
import { DataTableWrap } from "@styles/data-table-wrap";
import AddSignatoriesDialog from "./add-signatories-dialog.component";
import { useState } from "react";

const SignatoriesTable = () => {
  const theme = useTheme();
  const [openSignatoriesDialog, setSignatoriesDialog] = useState(false);
  return (
    <SignatoriesTableWrap theme={theme} className="signatories-table-wrap">
      <Stack bgLight paddingMd={2} paddingXs={2}>
        <DataTableWrap className="table-pagination-button">
          <Box theme={theme} className="scroll">
            <Box className="scroll-hide spr-border" theme={theme} />
          </Box>
          <DataTable
            theme={theme}
            theadChildren={
              <>
                <TableRow className="has-sorting">
                  <TableCell>
                    First Name
                    <span className="sort-action">
                      <Button
                        theme={theme}
                        onClick={() => {}}
                        iconText={<Icon name="AscDescIcon" />}
                      />
                    </span>
                  </TableCell>
                  <TableCell>
                    Last Name
                    <span className="sort-action">
                      <Button
                        theme={theme}
                        onClick={() => {}}
                        iconText={<Icon name="AscDescIcon" />}
                      />
                    </span>
                  </TableCell>
                  <TableCell>
                    Email
                    <span className="sort-action">
                      <Button
                        theme={theme}
                        onClick={() => {}}
                        iconText={<Icon name="AscDescIcon" />}
                      />
                    </span>
                  </TableCell>
                  <TableCell>
                    Position
                    <span className="sort-action">
                      <Button
                        theme={theme}
                        onClick={() => {}}
                        iconText={<Icon name="AscDescIcon" />}
                      />
                    </span>
                  </TableCell>
                  <TableCell>
                    State
                    <span className="sort-action">
                      <Button
                        theme={theme}
                        onClick={() => {}}
                        iconText={<Icon name="AscDescIcon" />}
                      />
                    </span>
                  </TableCell>
                  <TableCell>
                    Guarantor
                    <span className="sort-action">
                      <Button
                        theme={theme}
                        onClick={() => {}}
                        iconText={<Icon name="AscDescIcon" />}
                      />
                    </span>
                  </TableCell>
                  <TableCell className="action-cell fixed-cell" />
                </TableRow>
                <TableRow className="filters-row">
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        type={""}
                        startAdornment={""}
                        endAdornment={""}
                        id={""}
                        placeholder="Search"
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        type={""}
                        startAdornment={""}
                        endAdornment={""}
                        id={""}
                        placeholder="Search"
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        type={""}
                        startAdornment={""}
                        endAdornment={""}
                        id={""}
                        placeholder="Search"
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        type={""}
                        startAdornment={""}
                        endAdornment={""}
                        id={""}
                        placeholder="Search"
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        type={""}
                        startAdornment={""}
                        endAdornment={""}
                        id={""}
                        placeholder="Search"
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        type={""}
                        startAdornment={""}
                        endAdornment={""}
                        id={""}
                        placeholder="Search"
                      />
                      <Button
                        defaultBtn
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                  <TableCell className="action-cell fixed-cell" />
                </TableRow>
              </>
            }
            tbodyChildren={
              <>
                <TableRow className="child-tr">
                  <TableCell>Sample Name</TableCell>
                  <TableCell>Sample Name</TableCell>
                  <TableCell>sample@sample.com</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>
                    <Switch
                      theme={theme}
                      label="Mandatory"
                      switchEnabled
                      varient="basic"
                    />
                  </TableCell>
                  <TableCell>
                    <Switch theme={theme} label="No" varient="basic" />
                  </TableCell>
                  <TableCell className="action-cell fixed-cell">
                    <Menu
                      theme={theme}
                      options={[
                        {
                          optionText: "View/Edit",
                          optionkey: "view-edit",
                          optionValue: "0",
                        },
                        {
                          optionText: "Delete",
                          optionkey: "delete",
                          optionValue: "1",
                        },
                      ]}
                      handleOptionClick={() => {}}
                      render={(cb) => (
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="MoreIcon" />}
                          onClick={cb}
                        ></Button>
                      )}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow className="child-tr">
                  <TableCell>Sample Name</TableCell>
                  <TableCell>Sample Name</TableCell>
                  <TableCell>sample@sample.com</TableCell>
                  <TableCell>Broker</TableCell>
                  <TableCell>
                    <Switch
                      theme={theme}
                      label="Mandatory"
                      switchEnabled
                      varient="basic"
                    />
                  </TableCell>
                  <TableCell>
                    <Switch theme={theme} label="No" varient="basic" />
                  </TableCell>
                  <TableCell className="action-cell fixed-cell">
                    <Menu
                      theme={theme}
                      options={[
                        {
                          optionText: "View/Edit",
                          optionkey: "view-edit",
                          optionValue: "0",
                        },
                        {
                          optionText: "Delete",
                          optionkey: "delete",
                          optionValue: "1",
                        },
                      ]}
                      handleOptionClick={() => {}}
                      render={(cb) => (
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="MoreIcon" />}
                          onClick={cb}
                        ></Button>
                      )}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow className="child-tr">
                  <TableCell>Sample Name</TableCell>
                  <TableCell>Sample Name</TableCell>
                  <TableCell>sample@sample.com</TableCell>
                  <TableCell>Director/Partner</TableCell>
                  <TableCell>
                    <Switch theme={theme} label="Optional" varient="basic" />
                  </TableCell>
                  <TableCell>
                    <Switch
                      theme={theme}
                      label="Yes"
                      switchEnabled
                      varient="basic"
                    />
                  </TableCell>
                  <TableCell className="action-cell fixed-cell">
                    <Menu
                      theme={theme}
                      options={[
                        {
                          optionText: "View/Edit",
                          optionkey: "view-edit",
                          optionValue: "0",
                        },
                        {
                          optionText: "Delete",
                          optionkey: "delete",
                          optionValue: "1",
                        },
                      ]}
                      handleOptionClick={() => {}}
                      render={(cb) => (
                        <Button
                          defaultBtn
                          theme={theme}
                          iconText={<Icon name="MoreIcon" />}
                          onClick={cb}
                        ></Button>
                      )}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    />
                  </TableCell>
                </TableRow>
              </>
            }
          />
          <Pagination theme={theme} />
        </DataTableWrap>
        <Box theme={theme} mt={2}>
          <Button
            theme={theme}
            fullWidth
            secondary
            text={"Add New Signatory"}
            onClick={() => setSignatoriesDialog(true)}
          />
        </Box>
      </Stack>
      {openSignatoriesDialog && (
        <AddSignatoriesDialog
          open={openSignatoriesDialog}
          onClose={() => setSignatoriesDialog(false)}
        />
      )}
    </SignatoriesTableWrap>
  );
};

export default SignatoriesTable;
