import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  useTheme,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSliderOpen } from "@store/actions/configuration.actions";
import { RootState } from "@store/reducers";
import {
  Card,
  List,
  TextBox,
  FormattedValue,
  Scroll,
  AccordianWrap,
  Button,
  Dropdown,
} from "@components/quotation/shared/controls";
import { Inputs } from "@components/quotation/inputs/inputs.component";
import { Rentals } from "@components/quotation/rentals/rentals.component";
import Box from "@mui/material/Box";
import {
  FinanceType,
  Currency,
  FieldType,
  AmortizationMethod,
} from "@flexCommon/enums";
import { IFieldProp } from "@interfaces/configuration.interface";
import {
  setAlert,
  setFinancialRequestObj,
} from "@store/actions/financialCalculation.actions";
import Icon from "@components/quotation/shared/assets/Icon";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers/const";
import { CalculationWrap } from "@components/quotation/configuration.style";
import { getDisabledProp, getHiddenProp } from "@helpers/common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IStoreState } from "@interfaces/requestparams.flex";
import { frequency } from "@flexCommon/constants";
import clsx from "clsx";

export const RatesCard = (store: IStoreState) => {
  return (
    <Card className="rates-mbl card-rates" sx={{ mb: 1 }}>
      <Box className="card-header">Rates</Box>
      <Box className="card-body with-scroll">
        <Scroll className={store.storeState.RequestObj.toggle}>
          <List className="with-hover">
            <ul>
              <li>
                <Box className="left">Gross Yield:</Box>
                <Grid item className="right">
                  <FormattedValue
                    lazyLoad
                    loading={!store.storeState.RatesVisibility.grossYield}
                    scale={2}
                    suffix="%"
                    value={store.storeState.ResponseObj?.rates?.grossYield}
                  />
                </Grid>
              </li>
              <li>
                <Box className="left">Net Yield:</Box>
                <Grid item className="right">
                  <FormattedValue
                    lazyLoad
                    loading={!store.storeState.RatesVisibility.netYield}
                    scale={2}
                    suffix="%"
                    value={store.storeState.ResponseObj?.rates?.netYield}
                  />
                </Grid>
              </li>
              <li>
                <Box className="left">APR:</Box>
                <Grid item className="right">
                  <FormattedValue
                    lazyLoad
                    loading={!store.storeState.RatesVisibility.aprExclComm}
                    scale={2}
                    suffix="%"
                    value={
                      store.storeState.ResponseObj?.rates?.aprExclCommission
                    }
                  />
                </Grid>
              </li>
              <li>
                <Box className="left">Flat Rate Incl. Commission:</Box>
                <Grid item className="right">
                  <FormattedValue
                    lazyLoad
                    loading={!store.storeState.RatesVisibility.flatRateInclComm}
                    scale={2}
                    suffix="%"
                    value={
                      store.storeState.ResponseObj?.rates
                        ?.flatRateInclCommission
                    }
                  />
                </Grid>
              </li>
              {GetFlatRateExclCommission(store)}
            </ul>
          </List>
        </Scroll>
      </Box>
    </Card>
  );
};

const GetFlatRateExclCommission = (store: IStoreState) => {
  if (store.storeState.RequestObj.hideFlatRateExcl) {
    return <div></div>;
  } else {
    return (
      <li>
        <Box className="left">Flat Rate Excl. Commission:</Box>
        <Grid item className="right">
          <FormattedValue
            lazyLoad
            loading={!store.storeState.RatesVisibility.flatRateExclComm}
            scale={2}
            suffix="%"
            value={store.storeState.ResponseObj?.rates?.flatRateExclCommission}
          />
        </Grid>
      </li>
    );
  }
};

export const CreateQuotation = ({
  fieldPropsState,
  financeType,
  setFinanceType,
}: {
  fieldPropsState: IFieldProp[];
  financeType: FinanceType;
  setFinanceType: React.Dispatch<React.SetStateAction<FinanceType>>;
}) => {
  const storeState = useSelector(
    (state: RootState) => state.reducerFinancialCalculation
  );

  const [toggle, setToggle] = useState(true);
  const [cardExpanded, setCardExpanded] = useState("scroll h-md");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editableCell, setEditableCell] = useState<number>(-1);
  const [editableCellText, setEditableCellText] = useState("");

  const handleClose = () => {
    dispatch(setSliderOpen(false));
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(setAlert(false));
    }, 10000);
  }, [storeState.Alert]);

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const getAlert = () => {
    if (storeState.Alert) {
      return (
        <Alert
          severity="warning"
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            right: 10,
            zIndex: "100",
          }}
          onClose={() => dispatch(setAlert(false))}
        >
          You are using default Configuration. To Update Configuration -{" "}
          <Link
            href="#"
            onClick={() => {
              let search = window.location.search;
              let params = new URLSearchParams(search);
              let apiKey = params.get("x-api-key");
              if (apiKey != null) {
                navigate({
                  pathname: APP_ROUTES.CONFIGURATIONS,
                  search: `${"?x-api-key="}${apiKey}`,
                });
              } else {
                navigate(APP_ROUTES.CONFIGURATIONS);
              }
            }}
          >
            Click Here
          </Link>{" "}
          to do so.
        </Alert>
      );
    }
  };
  const getFundingCost = () => {
    if (
      !getHiddenProp(fieldPropsState, FieldType.FundingCost) &&
      storeState.GrossProfitApplicable
    ) {
      return (
        <li>
          <Box className="left">Funding Cost:</Box>
          {editableCell === -2 ? (
            <Box className="right editable-text">
              <TextBox
                type="text"
                label=""
                value={storeState.RequestObj.FundingCost}
                masking
                endAdornment="%"
                thousandSeparater={false}
                onChange={(e) => {
                  setEditableCellText(e.target.value);
                }}
              />
              <Button
                defaultBtn
                iconText={<Icon name="TickIcon" />}
                onClick={() => {
                  dispatch(
                    setFinancialRequestObj({
                      ...storeState.RequestObj,
                      FundingCost: Number(editableCellText),
                    })
                  );
                  setEditableCell(-1);
                }}
              />
              <Button
                defaultBtn
                iconText={
                  <Icon
                    name="CloseBlack"
                    onClick={(_e) => {
                      setEditableCell(-1);
                    }}
                  />
                }
              />
            </Box>
          ) : (
            <Box className="right editable-text">
              <span>
                {typeof storeState.RequestObj.FundingCost === "number"
                  ? storeState.RequestObj.FundingCost.toFixed(2)
                  : Number(storeState.RequestObj.FundingCost).toFixed(2)}{" "}
                %
              </span>
              <Button
                defaultBtn
                iconText={
                  <Icon
                    name="EditIcon"
                    onClick={(_e) => {
                      setEditableCell(-2);
                      setEditableCellText(
                        storeState.RequestObj.FundingCost.toString()
                      );
                    }}
                  />
                }
              />
            </Box>
          )}
        </li>
      );
    } else {
      return <div></div>;
    }
  };
  // const isMobile = width <= 959;
  const theme = useTheme();
  return (
    <>
      <CalculationWrap
        className={clsx({
          "calculation calc-mbl results": true,
          "has-quote-header": true,
        })}
      >
        {getAlert()}
        {/* <QuotationFiltersHeader /> */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <Box className="with-bg p-0">
              <Inputs
                fieldProps={fieldPropsState}
                financeType={financeType}
                handleFinanceTypeChange={(e) => {
                  setFinanceType(e as FinanceType);
                  dispatch(
                    setFinancialRequestObj({
                      ...storeState.RequestObj,
                      FinanceType: e,
                    })
                  );
                }}
                configState={storeState.CalculationConfiguration}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <Rentals />
          </Grid>
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <RatesCard storeState={storeState} />
            <Card className="card-info">
              <AccordianWrap className="without-lines">
                <Accordion
                  expanded={toggle}
                  onChange={(e, expanded) => {
                    if (expanded) {
                      dispatch(
                        setFinancialRequestObj({
                          ...storeState.RequestObj,
                          toggle: "scroll h-md",
                        })
                      );
                      setCardExpanded("scroll h-md");
                      setToggle(true);
                    } else {
                      setToggle(false);
                      dispatch(
                        setFinancialRequestObj({
                          ...storeState.RequestObj,
                          toggle: "scroll h-md toggle",
                        })
                      );
                      setCardExpanded("scroll h-md toggle");
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box className="title">Information</Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="card-body with-scroll">
                      <Scroll
                        className={toggle ? "scroll h-xs" : "scroll h-xs anim"}
                      >
                        <List>
                          <ul>
                            {getFundingCost()}
                            {storeState.RequestObj.Fee?.map((_fee, index) => (
                              <li key={index}>
                                <Box className="left">{_fee.Name}:</Box>
                                {editableCell === index ? (
                                  <Box className="right editable-text">
                                    <TextBox
                                      type="text"
                                      label=""
                                      value={_fee.Amount}
                                      masking
                                      startAdornment={Currency.GBP}
                                      decimalScale={2}
                                      thousandSeparater={false}
                                      onChange={(e) => {
                                        setEditableCellText(e.target.value);
                                      }}
                                      id={`editInternetPrice${index}`}
                                    />
                                    <Button
                                      defaultBtn
                                      iconText={<Icon name="TickIcon" />}
                                      onClick={() => {
                                        let newfees = [
                                          ...storeState.RequestObj.Fee,
                                        ];
                                        newfees[index].Amount =
                                          Number(editableCellText);
                                        setEditableCell(-1);
                                      }}
                                    />
                                    <Button
                                      defaultBtn
                                      iconText={
                                        <Icon
                                          name="CloseBlack"
                                          onClick={(_e) => {
                                            setEditableCell(-1);
                                          }}
                                        />
                                      }
                                    />
                                  </Box>
                                ) : (
                                  <Box className="right editable-text">
                                    <span>{`${Currency.GBP} ${Number(
                                      _fee.Amount
                                    ).toFixed(2)}`}</span>
                                    <Button
                                      defaultBtn
                                      iconText={
                                        <Icon
                                          name="EditIcon"
                                          onClick={(_e) => {
                                            setEditableCell(index);
                                            setEditableCellText(
                                              _fee.Amount.toString()
                                            );
                                          }}
                                        />
                                      }
                                    />
                                  </Box>
                                )}
                              </li>
                            ))}
                            {!getHiddenProp(
                              fieldPropsState,
                              FieldType.Frequency
                            ) ? (
                              <li>
                                <Box className="left">Payment Frequency:</Box>
                                <Box className="right editable">
                                  <Dropdown
                                    label=""
                                    value={storeState.RequestObj.Frequency}
                                    disabled={
                                      getDisabledProp(
                                        fieldPropsState,
                                        FieldType.Frequency
                                      ) ||
                                      storeState.CalculationConfiguration
                                        ?.AmortizationMethod ==
                                        AmortizationMethod.Annuity360
                                    }
                                    onChange={(e) => {
                                      dispatch(
                                        setFinancialRequestObj({
                                          ...storeState.RequestObj,
                                          Frequency: e.target.value,
                                        })
                                      );
                                    }}
                                    items={frequency}
                                  />
                                  {/* <Icon name="EditIcon" /> */}
                                </Box>{" "}
                              </li>
                            ) : (
                              <div></div>
                            )}
                            <li>
                              <Box className="left">Payment Mode:</Box>
                              <Box className="right list-header">
                                {storeState.RequestObj.PaymentMode}
                              </Box>
                            </li>
                            <li>
                              <Box className="left">
                                Total Number of Payments:
                              </Box>
                              <Box className="right list-header">
                                {storeState.RequestObj.RegularPayments == 0
                                  ? 0
                                  : storeState.RequestObj.RegularPayments}
                              </Box>
                            </li>
                          </ul>
                        </List>
                      </Scroll>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </AccordianWrap>
            </Card>
          </Grid>
        </Grid>
      </CalculationWrap>
    </>
  );
};
