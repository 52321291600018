import React, { FC } from "react";

const ExportIcon: FC<{ className?: string; onClick: (e: unknown) => void }> = ({
  className,
  onClick,
}) => (
  <svg
    className={className}
    onClick={onClick}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_13738_10441)">
      <path
        d="M7.33301 11L12.333 16L17.333 11"
        stroke="#12131A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.333 4V16"
        stroke="#12131A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.33301 17V18C4.33301 18.7956 4.64908 19.5587 5.21169 20.1213C5.7743 20.6839 6.53736 21 7.33301 21H17.333C18.1287 21 18.8917 20.6839 19.4543 20.1213C20.0169 19.5587 20.333 18.7956 20.333 18V17"
        stroke="#12131A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13738_10441">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.333008)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ExportIcon;
