import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const StackWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.stack-wrap": {
    backgroundColor: theme.palette.common.white,
    position: "relative",
    borderRadius: 8,
    "&.bg-light": {
      backgroundColor: "#F7F7F7",
    },
    "&.bg-dark": {
      backgroundColor: theme.palette.grey[900],
    },
    "&.has-shadow": {
      boxShadow: "0px 16px 16px -8px rgba(0, 0, 0, 0.24)",
    },
    "&.d-flex": {
      display: "flex",
    },
    "&.d-none": {
      display: "none",
    },
    "&.justify-content-between": {
      justifyContent: "space-between",
    },
    ".stack-header": {
      display: "flex",
      alignItems: "center",
      gap: 16,
      ".title": {
        display: "flex",
        alignItems: "center",
        gap: 16,
        flexGrow: 1,
      },
      ".action-area": {
        display: "flex",
        alignItems: "center",
        gap: 16,
        flexShrink: 0,
        ".btn": {
          "&.with-icon": {
            "&.icon-only": {
              padding: 0,
            },
          },
        },
      },
    },
    ".loader-wrap": {
      minHeight: 150,
    },
  },
}));
