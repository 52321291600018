import { styled } from "@mui/material";
import { CustomColors } from "@themes/mui-theme";

export const List = styled("div")(({ theme }) => ({
  width: "100%",
  display: "inline-block",

  ul: {
    width: "100%",
    display: "inline-block",
    listStyle: "none",
    padding: 0,
    margin: 0,

    li: {
      display: "flex",
      justifyContent: "space-between",
      listStyle: "none",
      padding: 0,
      margin: "0 0 16px 0",
      fontSize: theme.typography.htmlFontSize,
      color: theme.palette.grey[600],

      ".editable": {
        display: "flex",
        ".u-form-group": {
          float: "right",
          marginBottom: 0,
          ".u-form-control": {
            height: "auto",
            fontSize: theme.typography.htmlFontSize,
            ".MuiInputBase-input": {
              padding: "0 5px",
              fontSize: theme.typography.htmlFontSize,
              color: theme.palette.grey[900],
              fontWeight: theme.customVariables.fontWeightSemiBold,
              textAlign: "right",
            },
            ".MuiOutlinedInput-notchedOutline, fieldset": {
              border: "0 solid transparent",
            },
            "&.u-select .MuiSelect-icon": {
              display: "none",
            },
          },
        },
        svg: {
          float: "right",
          height: 18,
        },
      },
      ".editable-text": {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        ".u-form-group": {
          marginBottom: 0,
          width: "150px",
        },
        ".MuiButtonBase-root": {
          padding: "0px !important",
        },
      },

      "&.list-header, .list-header": {
        color: theme.palette.grey[900],
        fontWeight: theme.customVariables.fontWeightSemiBold,
      },

      ".left": {
        marginRight: 16,
        whiteSpace: "nowrap",
      },

      ".right": {
        marginLeft: 16,
        textAlign: "right",
        svg: {
          width: 22,
        },
        span: {
          fontSize: theme.typography.fontSize,
          color: theme.palette.grey[900],
          fontWeight: 600,
        },
      },

      // ".left, .right": {
      //   width: "48%",
      // },

      "&:last-child": {
        margin: 0,
      },
    },
  },

  "&.with-hover": {
    ul: {
      li: {
        margin: "0 -5px",
        padding: 10,
        borderRadius: theme.shape.borderRadius,

        "&:last-child": {
          margin: "0 -5px",
        },

        "&:hover": {
          backgroundColor: CustomColors.listHover,
        },
      },
    },
  },

  "&.list-with-bg": {
    backgroundColor: theme.palette.common.white,
    border: "1px solid" + theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    ul: {
      li: {
        padding: "14px 16px",
        margin: "0",
        borderBottom: "1px solid" + theme.palette.grey[100],

        "&:last-child": {
          margin: 0,
          borderBottom: "0 solid transparent",
        },
      },
    },
  },
}));
