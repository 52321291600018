import { useEffect, useState } from "react";
import {
  AmortizationMethod,
  FieldType,
  VatDeferralType,
  VatTreatment,
  FinanceType as FinanceTypeFlex,
} from "@flexCommon/enums";
import { GetConfiguration } from "@services/configuration.service";
import { getQuotationByIdentifier } from "@services/dms.service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/reducers";
import {
  setAlert,
  setCalculationConfiguration,
  setCalculationInProgress,
  setFinancialRequestObj,
  setGrossProfitApplicable,
  setFinancialResponseObj,
  setQuotationDetails,
  setCalculatedNFA,
  setGrossProfit,
  setCalculatedUpfrontPayment,
  setCalculatedCommission,
  setNotification,
} from "@store/actions/financialCalculation.actions";
import {
  IConfigurationInputs,
  IFieldProp,
} from "@interfaces/configuration.interface";
import { getHiddenProp } from "@helpers/common";
import { getDefaultQuoteConfig } from "@helpers/utils";

import { Dispatch, UnknownAction } from "redux";
import { IRequestObj, IResponseObj, QuotationDetails } from "@interfaces";
import { IReducerFinancialCalculation } from "@store/reducers/financialCalculation.reducer";
import { BalloonCollectionsArrear } from "@flexCommon/constants";

const populateRequestObj = (
  quotationData: any,
  reduxState: IReducerFinancialCalculation,
  dispatch: Dispatch<UnknownAction>
) => {
  const requestObj: IRequestObj = {
    ...reduxState.RequestObj,
    AssetCost: quotationData.selling_price,
    StartDate: quotationData.start_date,
    Deposit: quotationData.down_payment,
    DepositAmount: quotationData.down_payment,
    AdvancePayments: quotationData.no_of_advance_payments,
    RegularPayments: quotationData.no_of_regular_payments,
    Frequency: quotationData.rental_frequency,
    VAT: {
      type: quotationData.deferred_type,
      Amount: quotationData.deferral_amount,
      Treatment: quotationData.vat_treatment,
      PaymentNumber: quotationData.tax_amount_number || 0,
    },
    BalloonPayment: quotationData.rv_balloon_value,
    ResidualValue: quotationData.rv_balloon_value,
    BalloonCollection: BalloonCollectionsArrear.find(
      (collection) => collection.text === quotationData.balloon_collection
    ).value,
    FinanceType: quotationData.finance_type,
    CommissionType: quotationData.commission_type,
    RateType: quotationData.rate_type,
    Rate: quotationData.margin,
    PaymentMode: quotationData.rental_mode,
    CommissionAmount: quotationData.commission_amount,
    CommissionPercentage:
      quotationData.commission_type === "£"
        ? ""
        : quotationData.commission_amount,
    StepPaymentStructure: [],
  };

  dispatch(setFinancialRequestObj(requestObj));
};

const populateResponseObj = (
  quotationData: any,
  reduxState: IReducerFinancialCalculation,
  dispatch: Dispatch<UnknownAction>
) => {
  const responseObj: IResponseObj = {
    ...reduxState.ResponseObj,
    rates: {
      ...reduxState.ResponseObj.rates,
      grossYield: quotationData.meta_data?.gross_yield,
      netYield: quotationData.meta_data?.net_yield,
      aprExclCommission: quotationData.meta_data?.apr,
      flatRateInclCommission: quotationData.meta_data?.flat_rate_commission_inc,
      flatRateExclCommission: quotationData.meta_data?.flat_rate_commission_exc,
    },
    rentals: quotationData.order_payments
      ?.map((rental) => ({
        identifier: rental.identifier,
        startTerm: rental.payment_number_from,
        endTerm: rental.payment_number_to,
        rentalAmount: rental.amount,
        rentalType: rental.payment_type,
      }))
      .sort((a, b) =>
        a.startTerm > b.startTerm ? 1 : b.startTerm > a.startTerm ? -1 : 0
      ),
  };
  dispatch(setFinancialResponseObj(responseObj));
};

const populateQuotationDetails = (
  quotationData: any,
  dispatch: Dispatch<UnknownAction>
) => {
  const quotationDetails: QuotationDetails = {
    name: quotationData.name,
    brokerName: quotationData.introducer_name,
    asset: {
      type: quotationData.assets[0].asset_type,
      identifier: quotationData.assets[0].identifier,
    },
    clientType: quotationData.customer.customer_type,
  };
  dispatch(setQuotationDetails(quotationDetails));
};

export const populateCalculatedQuotation = (
  quotationData: any,
  reduxState: IReducerFinancialCalculation,
  dispatch: Dispatch<UnknownAction>
) => {
  populateRequestObj(quotationData, reduxState, dispatch);
  populateResponseObj(quotationData, reduxState, dispatch);
  populateQuotationDetails(quotationData, dispatch);
  dispatch(setCalculatedNFA(quotationData.finance_amount));
  dispatch(setGrossProfit(quotationData.gross_profit));
  dispatch(setCalculatedUpfrontPayment(quotationData.due_at_signing));

  const calculatedCommission =
    quotationData.commission_type === "£"
      ? quotationData.commission_amount
      : ((Number(quotationData.selling_price) -
          Number(quotationData.down_payment)) /
          100) *
        Number(quotationData.commission_amount);
  dispatch(setCalculatedCommission(calculatedCommission));
};

export const useGetDataForQuotation = (quotationId: string | undefined) => {
  const isEditMode = !!quotationId;
  const [financeType, setFinanceType] = useState<FinanceTypeFlex>(
    FinanceTypeFlex.HirePurchase
  );
  const [quotationLoaded, setQuotationLoaded] = useState(false);
  const [fieldPropsState, setFieldPropsState] = useState<IFieldProp[]>([]);

  const dispatch = useDispatch();
  const storeState = useSelector(
    (state: RootState) => state.reducerFinancialCalculation
  );

  const applyDefaultValues = (fieldProps: IFieldProp[]): any => {
    var _obj: any = {
      ...storeState.RequestObj,
    };
    fieldProps?.map((prop) => {
      if (prop.Name.includes(".")) {
        var parentPropName = prop.Name.split(".")[0];
        prop.Name.split(".")
          ?.slice(1)
          ?.map((p: any) => {
            _obj[parentPropName][p] = prop.DefaultValue;
          });
      } else {
        _obj[prop.Name] = prop.DefaultValue;
      }
    });
    return _obj;
  };

  useEffect(() => {
    setQuotationLoaded(false);
    if (isEditMode) {
      getQuotationByIdentifier(quotationId)
        .then((quotation) => {
          setFinanceType(quotation.finance_type);
          populateCalculatedQuotation(quotation, storeState, dispatch);
          setQuotationLoaded(true);
        })
        .catch(() => {
          dispatch(
            setNotification({
              message: "Error Occurred while fetching quotation",
              visible: true,
            })
          );
        });
    }
  }, [quotationId]);

  useEffect(() => {
    if (isEditMode && !quotationLoaded) return;

    var vatObject = storeState.RequestObj.VAT;
    if (
      financeType === FinanceTypeFlex.FinanceLease ||
      financeType === FinanceTypeFlex.Loan
    ) {
      vatObject = {
        ...storeState.RequestObj.VAT,
        Amount: 0,
        Treatment: VatTreatment.VATPaidUpfront,
      };
    }
    vatObject.type = VatDeferralType.Rental;

    GetConfiguration(financeType).then((response) => {
      if (response.Data == undefined) {
        dispatch(
          setCalculationConfiguration(getDefaultQuoteConfig(financeType))
        );
        dispatch(setAlert(true));
      } else {
        dispatch(setAlert(false));
        setCalculationInProgress(false);
        var configurations: IConfigurationInputs = response.Data;
        setFieldPropsState(configurations.FieldProps ?? []);

        if (isEditMode) {
          dispatch(
            setFinancialRequestObj({
              ...storeState.RequestObj,
              Tax: configurations.Tax,
              Fee: configurations.Fees,
            })
          );
        } else {
          var defaultValues = applyDefaultValues(
            configurations.FieldProps ?? []
          );
          var vatType =
            configurations.FieldProps?.find(
              (x) => x.Name === FieldType.VATDeferred
            )?.DefaultValue ?? VatDeferralType.Rental;
          if (
            configurations.AmortizationMethod == AmortizationMethod.Annuity360
          ) {
            vatType = VatDeferralType.Rental;
          }
          dispatch(setGrossProfitApplicable(configurations.GrossProfit));
          dispatch(
            setFinancialRequestObj({
              ...defaultValues,
              StepPaymentStructure: [],
              Fee: configurations.Fees,
              StartDate: new Date().toISOString(),
              VAT: {
                ...vatObject,
                type: vatType,
              },
              Tax: configurations.Tax,
              hideFlatRateExcl: getHiddenProp(
                configurations.FieldProps ?? [],
                FieldType.FlatRateExclCommission
              ),
              DepositAmount: configurations.FieldProps?.find(
                (x) => x.Name == FieldType.Deposit
              )?.DefaultValue,
              StepPaymentsApplicable: configurations.FieldProps?.find(
                (x) => x.Name == FieldType.StepPaymentsApplicable
              )?.DefaultValue,
              StepPaymentsStructureApplicable: configurations.FieldProps?.find(
                (x) => x.Name == FieldType.StepPaymentsStructureApplicable
              )?.DefaultValue,
              IFieldProps: configurations.FieldProps ?? [],
            })
          );
        }

        dispatch(setCalculationConfiguration(configurations));
      }
    });
  }, [financeType, isEditMode, quotationLoaded]);

  return {
    financeType,
    setFinanceType,
    fieldPropsState,
  };
};
