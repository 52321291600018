import React from "react";
import { useTheme } from "@mui/material";
import { Stack } from "@components/stack";
import {
  Accordion,
  Avatar,
  Box,
  DateRange,
  DateRangePicker,
  Icon,
  Typography,
} from "@ntpkunity/controls";

export const CommunicationHistoryList = () => {
  const theme = useTheme();
  return (
    <Stack paddingMd={3} paddingXs={3} bgLight>
      <DateRangePicker
        placeholderText="00/00/0000 - 00/00/0000"
        theme={theme}
        onFocus={() => {}}
        onChange={() => {}}
        open={false}
        fullWidth
        endAdornment={<Icon name="CalendarIcon" />}
      />
      <Box theme={theme} className="days-list-wrap">
        <Accordion
          theme={theme}
          items={[
            {
              title: "Today",
              content: (
                <>
                  <Box theme={theme} className="list-item-wrap">
                    <Box
                      className="list-item selected"
                      theme={theme}
                      onClick={() => {}}
                    >
                      <Box theme={theme} className="list-img">
                        <Avatar theme={theme}>SK</Avatar>
                      </Box>
                      <Box theme={theme} className="list-content">
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          display={"block"}
                        >
                          Friedrich Gammill updated dealer profile.
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          display={"flex"}
                          mt={0.5}
                          className="text-muted"
                          alignItems={"center"}
                          gap={1}
                        >
                          <span>Aug 00, 0000</span>{" "}
                          <span className="seperator">|</span>{" "}
                          <span>00:00 am</span>
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="list-item" theme={theme} onClick={() => {}}>
                      <Box theme={theme} className="list-img">
                        <Avatar theme={theme}>SK</Avatar>
                      </Box>
                      <Box theme={theme} className="list-content">
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          display={"block"}
                        >
                          Friedrich Gammill updated dealer profile.
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          display={"flex"}
                          mt={0.5}
                          className="text-muted"
                          alignItems={"center"}
                          gap={1}
                        >
                          <span>Aug 00, 0000</span>{" "}
                          <span className="seperator">|</span>{" "}
                          <span>00:00 am</span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              ),
              key: "string 1",
              isExpanded: true,
            },
            {
              title: "Yesterday",
              content: (
                <>
                  <Box theme={theme} className="list-item-wrap">
                    <Box className="list-item" theme={theme} onClick={() => {}}>
                      <Box theme={theme} className="list-img">
                        <Avatar theme={theme}>SK</Avatar>
                      </Box>
                      <Box theme={theme} className="list-content">
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          display={"block"}
                        >
                          Friedrich Gammill updated dealer profile.
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          display={"flex"}
                          mt={0.5}
                          className="text-muted"
                          alignItems={"center"}
                          gap={1}
                        >
                          <span>Aug 00, 0000</span>{" "}
                          <span className="seperator">|</span>{" "}
                          <span>00:00 am</span>
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="list-item" theme={theme} onClick={() => {}}>
                      <Box theme={theme} className="list-img">
                        <Avatar theme={theme}>SK</Avatar>
                      </Box>
                      <Box theme={theme} className="list-content">
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          display={"block"}
                        >
                          Friedrich Gammill updated dealer profile.
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          display={"flex"}
                          mt={0.5}
                          className="text-muted"
                          alignItems={"center"}
                          gap={1}
                        >
                          <span>Aug 00, 0000</span>{" "}
                          <span className="seperator">|</span>{" "}
                          <span>00:00 am</span>
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="list-item" theme={theme} onClick={() => {}}>
                      <Box theme={theme} className="list-img">
                        <Avatar theme={theme}>SK</Avatar>
                      </Box>
                      <Box theme={theme} className="list-content">
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          display={"block"}
                        >
                          Friedrich Gammill updated dealer profile.
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          display={"flex"}
                          mt={0.5}
                          className="text-muted"
                          alignItems={"center"}
                          gap={1}
                        >
                          <span>Aug 00, 0000</span>{" "}
                          <span className="seperator">|</span>{" "}
                          <span>00:00 am</span>
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="list-item" theme={theme} onClick={() => {}}>
                      <Box theme={theme} className="list-img">
                        <Avatar theme={theme}>SK</Avatar>
                      </Box>
                      <Box theme={theme} className="list-content">
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          display={"block"}
                        >
                          Friedrich Gammill updated dealer profile.
                        </Typography>
                        <Typography
                          theme={theme}
                          variant="caption"
                          component={"small"}
                          display={"flex"}
                          mt={0.5}
                          className="text-muted"
                          alignItems={"center"}
                          gap={1}
                        >
                          <span>Aug 00, 0000</span>{" "}
                          <span className="seperator">|</span>{" "}
                          <span>00:00 am</span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              ),
              key: "string 2",
              isExpanded: true,
            },
          ]}
        />
      </Box>
    </Stack>
  );
};
