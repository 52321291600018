import Entry from "../entry/entry.component";
import { useCalculationsContext } from "@ntpkunity/controls-common";

const Rates = () => {
  const {
    data: { rates },
  } = useCalculationsContext();

  return (
    <>
      <Entry title="Gross Yield" value={`${rates.grossYield}%`} />
      <Entry title="Net Yield" value={`${rates.netYield}%`} />
      <Entry title="APR" value={`${rates.apr}%`} />
      <Entry
        title="Flat Rate Excl. Commission"
        value={`${rates.flatRateExclCommission}%`}
      />
      <Entry
        title="Flat Rate Incl. Commission"
        value={`${rates.flatRateInclCommission}%`}
      />
    </>
  );
};

export default Rates;
