import { useMutation } from "react-query";
import {
  exportDocumentsInWord,
  generateDocumentPresignedUrl,
} from "@services/flex-reporting.service";

export const useDownloadDocs = () => {
  return useMutation(downloadDocs);
};

export const usePreviewDocs = () => {
  return useMutation(previewDocs);
};

const downloadDocs = async (payload: any) => {
  const { key } = await exportDocumentsInWord(payload);
  const { template_url } = await generateDocumentPresignedUrl({ key });
  const link = document.createElement("a");
  link.href = template_url;
  link.setAttribute("download", `Quotation_${new Date()}.docx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const previewDocs = async (key: any) => {
  const { template_url } = await generateDocumentPresignedUrl({ key });
  return template_url;
};
