import { useTheme } from "@mui/material";
import { FinancialConfigTabsWrap } from "./config-tabs.style";
import { ScrollableTabs } from "@ntpkunity/controls";
import { FinancialConfigurationFields } from "@components";

export const FinancialConfigurationTabs = () => {
  const theme = useTheme();
  return (
    <FinancialConfigTabsWrap
      theme={theme}
      className="financial-config-tabs-wrap"
    >
      <ScrollableTabs
        theme={theme}
        items={[
          {
            title: "Hire purchase",
            content: (
              <>
                <FinancialConfigurationFields />
              </>
            ),
          },
          {
            title: "Finance Lease",
            content: (
              <>
                <FinancialConfigurationFields />
              </>
            ),
          },
          {
            title: "Loan",
            content: (
              <>
                <FinancialConfigurationFields />
              </>
            ),
          },
        ]}
        scrollButtons={"auto"}
        defaultTabIndex={0}
        onChange={() => {}}
      />
    </FinancialConfigTabsWrap>
  );
};
