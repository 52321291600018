import "react";
import { Grid, Input, Select } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";

const AssetForm = () => {
  const theme = useTheme();
  return (
    <>
      <Grid theme={theme} container item spacing={2}>
        <Grid theme={theme} item xs={12} md={4} lg={3}>
          <Select
            theme={theme}
            disablePortal={false}
            label={"Asset Make"}
            fullWidth
            items={[
              {
                text: "Option 1",
                value: "0",
              },
              {
                text: "Option 2",
                value: "1",
              },
            ]}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4} lg={3}>
          <Select
            theme={theme}
            disablePortal={false}
            label={"Asset Brand"}
            fullWidth
            items={[
              {
                text: "Option 1",
                value: "0",
              },
              {
                text: "Option 2",
                value: "1",
              },
            ]}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4} lg={3}>
          <Select
            theme={theme}
            disablePortal={false}
            label={"Asset Model"}
            fullWidth
            items={[
              {
                text: "Option 1",
                value: "0",
              },
              {
                text: "Option 2",
                value: "1",
              },
            ]}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4} lg={3}>
          <Select
            theme={theme}
            disablePortal={false}
            label={"Model Year"}
            fullWidth
            items={[
              {
                text: "Option 1",
                value: "0",
              },
              {
                text: "Option 2",
                value: "1",
              },
            ]}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4} lg={3}>
          <Input theme={theme} fullWidth label="Serial Number" type={"text"} />
        </Grid>
      </Grid>
    </>
  );
};

export default AssetForm;
