import { InternalAxiosRequestConfig } from "axios";

export const requestIntercepter = (config: InternalAxiosRequestConfig<any>) => {
  // Logic to add the bearer and x-api-key
  switch (config.baseURL) {
    default: {
      config.headers["x-api-key"] = new URLSearchParams(
        window.location.search
      ).get("x-api-key");
      break;
    }
  }
  return config;
};
