import "react";
import {
  Box,
  Button,
  DataTable,
  Icon,
  Input,
  Menu,
  Pagination,
} from "@ntpkunity/controls";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { DealerProposalWrap } from "./dealer-proposal-page.style";
import { DataTableWrap } from "@styles/data-table-wrap";

export const DealerProposalTable = () => {
  const theme = useTheme();
  return (
    <DealerProposalWrap theme={theme} className="add-proposal-table-wrap">
      <DataTableWrap className="table-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DataTable
          theme={theme}
          theadChildren={
            <>
              <TableRow className="has-sorting">
                <TableCell>
                  State
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {}}
                      iconText={<Icon name="AscDescIcon" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Dealer name
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {}}
                      iconText={<Icon name="AscDescIcon" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Proposal
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {}}
                      iconText={<Icon name="AscDescIcon" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Finance Type
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {}}
                      iconText={<Icon name="AscDescIcon" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Asset Description
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {}}
                      iconText={<Icon name="AscDescIcon" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Price
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {}}
                      iconText={<Icon name="AscDescIcon" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Date Submitted
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {}}
                      iconText={<Icon name="AscDescIcon" />}
                    />
                  </span>
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
              <TableRow className="filters-row">
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    />
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              <TableRow className="child-tr">
                <TableCell>
                  <span className="status-indicator ind-purple"></span>
                  New
                </TableCell>
                <TableCell>Sample Dealer Name</TableCell>
                <TableCell>Sample Name - A000000</TableCell>
                <TableCell>Hire Purchase</TableCell>
                <TableCell>
                  Sample asset description1, Sample asset description 2
                </TableCell>
                <TableCell className="text-right">
                  £ <span>00,000.00</span>
                </TableCell>
                <TableCell>00/00/0000</TableCell>
                <TableCell className="action-cell fixed-cell">
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View Proposal",
                        optionkey: "view-proposal",
                        optionValue: "0",
                      },
                      {
                        optionText: "Edit Proposal",
                        optionkey: "edit-proposal",
                        optionValue: "1",
                      },
                      {
                        optionText: "View Proposal Summary",
                        optionkey: "view-proposal-summary",
                        optionValue: "2",
                      },
                      {
                        optionText: "Upload Documents",
                        optionkey: "upload-documents",
                        optionValue: "3",
                      },
                      {
                        optionText: "Download Proposal",
                        optionkey: "download-proposal",
                        optionValue: "4",
                      },
                      {
                        optionText: "Diary",
                        optionkey: "diary",
                        optionValue: "5",
                      },
                    ]}
                    handleOptionClick={() => {}}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={cb}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className="child-tr">
                <TableCell>
                  <span className="status-indicator ind-purple"></span>
                  New
                </TableCell>
                <TableCell>Sample Dealer Name</TableCell>
                <TableCell>Sample Name - A000000</TableCell>
                <TableCell>Hire Purchase</TableCell>
                <TableCell>
                  Sample asset description1, Sample asset description 2
                </TableCell>
                <TableCell className="text-right">
                  £ <span>00,000.00</span>
                </TableCell>
                <TableCell>00/00/0000</TableCell>
                <TableCell className="action-cell fixed-cell">
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View Proposal",
                        optionkey: "view-proposal",
                        optionValue: "0",
                      },
                      {
                        optionText: "Edit Proposal",
                        optionkey: "edit-proposal",
                        optionValue: "1",
                      },
                      {
                        optionText: "View Proposal Summary",
                        optionkey: "view-proposal-summary",
                        optionValue: "2",
                      },
                      {
                        optionText: "Upload Documents",
                        optionkey: "upload-documents",
                        optionValue: "3",
                      },
                      {
                        optionText: "Download Proposal",
                        optionkey: "download-proposal",
                        optionValue: "4",
                      },
                      {
                        optionText: "Diary",
                        optionkey: "diary",
                        optionValue: "5",
                      },
                    ]}
                    handleOptionClick={() => {}}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={cb}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className="child-tr">
                <TableCell>
                  <span className="status-indicator ind-purple"></span>
                  New
                </TableCell>
                <TableCell>Sample Dealer Name</TableCell>
                <TableCell>Sample Name - A000000</TableCell>
                <TableCell>Hire Purchase</TableCell>
                <TableCell>
                  Sample asset description1, Sample asset description 2
                </TableCell>
                <TableCell className="text-right">
                  £ <span>00,000.00</span>
                </TableCell>
                <TableCell>00/00/0000</TableCell>
                <TableCell className="action-cell fixed-cell">
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View Proposal",
                        optionkey: "view-proposal",
                        optionValue: "0",
                      },
                      {
                        optionText: "Edit Proposal",
                        optionkey: "edit-proposal",
                        optionValue: "1",
                      },
                      {
                        optionText: "View Proposal Summary",
                        optionkey: "view-proposal-summary",
                        optionValue: "2",
                      },
                      {
                        optionText: "Upload Documents",
                        optionkey: "upload-documents",
                        optionValue: "3",
                      },
                      {
                        optionText: "Download Proposal",
                        optionkey: "download-proposal",
                        optionValue: "4",
                      },
                      {
                        optionText: "Diary",
                        optionkey: "diary",
                        optionValue: "5",
                      },
                    ]}
                    handleOptionClick={() => {}}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={cb}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className="child-tr">
                <TableCell>
                  <span className="status-indicator ind-purple"></span>
                  New
                </TableCell>
                <TableCell>Sample Dealer Name</TableCell>
                <TableCell>Sample Name - A000000</TableCell>
                <TableCell>Hire Purchase</TableCell>
                <TableCell>
                  Sample asset description1, Sample asset description 2
                </TableCell>
                <TableCell className="text-right">
                  £ <span>00,000.00</span>
                </TableCell>
                <TableCell>00/00/0000</TableCell>
                <TableCell className="action-cell fixed-cell">
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View Proposal",
                        optionkey: "view-proposal",
                        optionValue: "0",
                      },
                      {
                        optionText: "Edit Proposal",
                        optionkey: "edit-proposal",
                        optionValue: "1",
                      },
                      {
                        optionText: "View Proposal Summary",
                        optionkey: "view-proposal-summary",
                        optionValue: "2",
                      },
                      {
                        optionText: "Upload Documents",
                        optionkey: "upload-documents",
                        optionValue: "3",
                      },
                      {
                        optionText: "Download Proposal",
                        optionkey: "download-proposal",
                        optionValue: "4",
                      },
                      {
                        optionText: "Diary",
                        optionkey: "diary",
                        optionValue: "5",
                      },
                    ]}
                    handleOptionClick={() => {}}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={cb}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className="child-tr">
                <TableCell>
                  <span className="status-indicator ind-purple"></span>
                  New
                </TableCell>
                <TableCell>Sample Dealer Name</TableCell>
                <TableCell>Sample Name - A000000</TableCell>
                <TableCell>Hire Purchase</TableCell>
                <TableCell>
                  Sample asset description1, Sample asset description 2
                </TableCell>
                <TableCell className="text-right">
                  £ <span>00,000.00</span>
                </TableCell>
                <TableCell>00/00/0000</TableCell>
                <TableCell className="action-cell fixed-cell">
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View Proposal",
                        optionkey: "view-proposal",
                        optionValue: "0",
                      },
                      {
                        optionText: "Edit Proposal",
                        optionkey: "edit-proposal",
                        optionValue: "1",
                      },
                      {
                        optionText: "View Proposal Summary",
                        optionkey: "view-proposal-summary",
                        optionValue: "2",
                      },
                      {
                        optionText: "Upload Documents",
                        optionkey: "upload-documents",
                        optionValue: "3",
                      },
                      {
                        optionText: "Download Proposal",
                        optionkey: "download-proposal",
                        optionValue: "4",
                      },
                      {
                        optionText: "Diary",
                        optionkey: "diary",
                        optionValue: "5",
                      },
                    ]}
                    handleOptionClick={() => {}}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={cb}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className="child-tr">
                <TableCell>
                  <span className="status-indicator ind-purple"></span>
                  New
                </TableCell>
                <TableCell>Sample Dealer Name</TableCell>
                <TableCell>Sample Name - A000000</TableCell>
                <TableCell>Hire Purchase</TableCell>
                <TableCell>
                  Sample asset description1, Sample asset description 2
                </TableCell>
                <TableCell className="text-right">
                  £ <span>00,000.00</span>
                </TableCell>
                <TableCell>00/00/0000</TableCell>
                <TableCell className="action-cell fixed-cell">
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View Proposal",
                        optionkey: "view-proposal",
                        optionValue: "0",
                      },
                      {
                        optionText: "Edit Proposal",
                        optionkey: "edit-proposal",
                        optionValue: "1",
                      },
                      {
                        optionText: "View Proposal Summary",
                        optionkey: "view-proposal-summary",
                        optionValue: "2",
                      },
                      {
                        optionText: "Upload Documents",
                        optionkey: "upload-documents",
                        optionValue: "3",
                      },
                      {
                        optionText: "Download Proposal",
                        optionkey: "download-proposal",
                        optionValue: "4",
                      },
                      {
                        optionText: "Diary",
                        optionkey: "diary",
                        optionValue: "5",
                      },
                    ]}
                    handleOptionClick={() => {}}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={cb}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className="child-tr">
                <TableCell>
                  <span className="status-indicator ind-purple"></span>
                  New
                </TableCell>
                <TableCell>Sample Dealer Name</TableCell>
                <TableCell>Sample Name - A000000</TableCell>
                <TableCell>Hire Purchase</TableCell>
                <TableCell>
                  Sample asset description1, Sample asset description 2
                </TableCell>
                <TableCell className="text-right">
                  £ <span>00,000.00</span>
                </TableCell>
                <TableCell>00/00/0000</TableCell>
                <TableCell className="action-cell fixed-cell">
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View Proposal",
                        optionkey: "view-proposal",
                        optionValue: "0",
                      },
                      {
                        optionText: "Edit Proposal",
                        optionkey: "edit-proposal",
                        optionValue: "1",
                      },
                      {
                        optionText: "View Proposal Summary",
                        optionkey: "view-proposal-summary",
                        optionValue: "2",
                      },
                      {
                        optionText: "Upload Documents",
                        optionkey: "upload-documents",
                        optionValue: "3",
                      },
                      {
                        optionText: "Download Proposal",
                        optionkey: "download-proposal",
                        optionValue: "4",
                      },
                      {
                        optionText: "Diary",
                        optionkey: "diary",
                        optionValue: "5",
                      },
                    ]}
                    handleOptionClick={() => {}}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={cb}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className="child-tr">
                <TableCell>
                  <span className="status-indicator ind-purple"></span>
                  New
                </TableCell>
                <TableCell>Sample Dealer Name</TableCell>
                <TableCell>Sample Name - A000000</TableCell>
                <TableCell>Hire Purchase</TableCell>
                <TableCell>
                  Sample asset description1, Sample asset description 2
                </TableCell>
                <TableCell className="text-right">
                  £ <span>00,000.00</span>
                </TableCell>
                <TableCell>00/00/0000</TableCell>
                <TableCell className="action-cell fixed-cell">
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View Proposal",
                        optionkey: "view-proposal",
                        optionValue: "0",
                      },
                      {
                        optionText: "Edit Proposal",
                        optionkey: "edit-proposal",
                        optionValue: "1",
                      },
                      {
                        optionText: "View Proposal Summary",
                        optionkey: "view-proposal-summary",
                        optionValue: "2",
                      },
                      {
                        optionText: "Upload Documents",
                        optionkey: "upload-documents",
                        optionValue: "3",
                      },
                      {
                        optionText: "Download Proposal",
                        optionkey: "download-proposal",
                        optionValue: "4",
                      },
                      {
                        optionText: "Diary",
                        optionkey: "diary",
                        optionValue: "5",
                      },
                    ]}
                    handleOptionClick={() => {}}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={cb}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className="child-tr">
                <TableCell>
                  <span className="status-indicator ind-purple"></span>
                  New
                </TableCell>
                <TableCell>Sample Dealer Name</TableCell>
                <TableCell>Sample Name - A000000</TableCell>
                <TableCell>Hire Purchase</TableCell>
                <TableCell>
                  Sample asset description1, Sample asset description 2
                </TableCell>
                <TableCell className="text-right">
                  £ <span>00,000.00</span>
                </TableCell>
                <TableCell>00/00/0000</TableCell>
                <TableCell className="action-cell fixed-cell">
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View Proposal",
                        optionkey: "view-proposal",
                        optionValue: "0",
                      },
                      {
                        optionText: "Edit Proposal",
                        optionkey: "edit-proposal",
                        optionValue: "1",
                      },
                      {
                        optionText: "View Proposal Summary",
                        optionkey: "view-proposal-summary",
                        optionValue: "2",
                      },
                      {
                        optionText: "Upload Documents",
                        optionkey: "upload-documents",
                        optionValue: "3",
                      },
                      {
                        optionText: "Download Proposal",
                        optionkey: "download-proposal",
                        optionValue: "4",
                      },
                      {
                        optionText: "Diary",
                        optionkey: "diary",
                        optionValue: "5",
                      },
                    ]}
                    handleOptionClick={() => {}}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={cb}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className="child-tr">
                <TableCell>
                  <span className="status-indicator ind-purple"></span>
                  New
                </TableCell>
                <TableCell>Sample Dealer Name</TableCell>
                <TableCell>Sample Name - A000000</TableCell>
                <TableCell>Hire Purchase</TableCell>
                <TableCell>
                  Sample asset description1, Sample asset description 2
                </TableCell>
                <TableCell className="text-right">
                  £ <span>00,000.00</span>
                </TableCell>
                <TableCell>00/00/0000</TableCell>
                <TableCell className="action-cell fixed-cell">
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View Proposal",
                        optionkey: "view-proposal",
                        optionValue: "0",
                      },
                      {
                        optionText: "Edit Proposal",
                        optionkey: "edit-proposal",
                        optionValue: "1",
                      },
                      {
                        optionText: "View Proposal Summary",
                        optionkey: "view-proposal-summary",
                        optionValue: "2",
                      },
                      {
                        optionText: "Upload Documents",
                        optionkey: "upload-documents",
                        optionValue: "3",
                      },
                      {
                        optionText: "Download Proposal",
                        optionkey: "download-proposal",
                        optionValue: "4",
                      },
                      {
                        optionText: "Diary",
                        optionkey: "diary",
                        optionValue: "5",
                      },
                    ]}
                    handleOptionClick={() => {}}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={cb}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className="child-tr">
                <TableCell>
                  <span className="status-indicator ind-purple"></span>
                  New
                </TableCell>
                <TableCell>Sample Dealer Name</TableCell>
                <TableCell>Sample Name - A000000</TableCell>
                <TableCell>Hire Purchase</TableCell>
                <TableCell>
                  Sample asset description1, Sample asset description 2
                </TableCell>
                <TableCell className="text-right">
                  £ <span>00,000.00</span>
                </TableCell>
                <TableCell>00/00/0000</TableCell>
                <TableCell className="action-cell fixed-cell">
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View Proposal",
                        optionkey: "view-proposal",
                        optionValue: "0",
                      },
                      {
                        optionText: "Edit Proposal",
                        optionkey: "edit-proposal",
                        optionValue: "1",
                      },
                      {
                        optionText: "View Proposal Summary",
                        optionkey: "view-proposal-summary",
                        optionValue: "2",
                      },
                      {
                        optionText: "Upload Documents",
                        optionkey: "upload-documents",
                        optionValue: "3",
                      },
                      {
                        optionText: "Download Proposal",
                        optionkey: "download-proposal",
                        optionValue: "4",
                      },
                      {
                        optionText: "Diary",
                        optionkey: "diary",
                        optionValue: "5",
                      },
                    ]}
                    handleOptionClick={() => {}}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={cb}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow className="child-tr">
                <TableCell>
                  <span className="status-indicator ind-purple"></span>
                  New
                </TableCell>
                <TableCell>Sample Dealer Name</TableCell>
                <TableCell>Sample Name - A000000</TableCell>
                <TableCell>Hire Purchase</TableCell>
                <TableCell>
                  Sample asset description1, Sample asset description 2
                </TableCell>
                <TableCell className="text-right">
                  £ <span>00,000.00</span>
                </TableCell>
                <TableCell>00/00/0000</TableCell>
                <TableCell className="action-cell fixed-cell">
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: "View Proposal",
                        optionkey: "view-proposal",
                        optionValue: "0",
                      },
                      {
                        optionText: "Edit Proposal",
                        optionkey: "edit-proposal",
                        optionValue: "1",
                      },
                      {
                        optionText: "View Proposal Summary",
                        optionkey: "view-proposal-summary",
                        optionValue: "2",
                      },
                      {
                        optionText: "Upload Documents",
                        optionkey: "upload-documents",
                        optionValue: "3",
                      },
                      {
                        optionText: "Download Proposal",
                        optionkey: "download-proposal",
                        optionValue: "4",
                      },
                      {
                        optionText: "Diary",
                        optionkey: "diary",
                        optionValue: "5",
                      },
                    ]}
                    handleOptionClick={() => {}}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        theme={theme}
                        iconText={<Icon name="MoreIcon" />}
                        onClick={cb}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  />
                </TableCell>
              </TableRow>
            </>
          }
        />
        <Pagination theme={theme} />
      </DataTableWrap>
    </DealerProposalWrap>
  );
};
