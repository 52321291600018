import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const CommunicationHistoryWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.communication-history-wrap": {
    ".fw-sbold": {
      fontWeight: theme.customVariables.fontWeightSemiBold,
    },
    ".date-range-picker": {
      ".react-datepicker__input-container": {
        input: {
          backgroundColor: "transparent",
        },
      },
      "&.icon": {
        svg: {
          position: "absolute",
          right: 12,
          top: 12,
          width: 16,
          height: 16,
        },
      },
    },
    ".list-item-wrap": {
      ".list-item": {
        display: "flex",
        alignItems: "flex-start",
        gap: 16,
        padding: "16px 8px",
        cursor: "pointer",
        borderBottom: "1px solid" + theme.palette.divider,
        [theme.breakpoints.down("md")]: {
          padding: "16px 12px",
        },
        "&.selected": {
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.shape.borderRadius,
          borderBottom: "none",
          [theme.breakpoints.down("md")]: {
            backgroundColor: "#f7f7f7",
          },
        },
        ".seperator": {
          color: theme.palette.grey[50],
        },
      },
    },
    ".u-accordian-wrap": {
      backgroundColor: "transparent",
      marginBottom: 0,
      "&:last-child": {
        marginBottom: 0,
      },
      ".u-accordion-header": {
        padding: "8px 0",
        minHeight: 24,
        ".MuiAccordionSummary-content": {
          margin: 0,
          ".main-title": {
            position: "relative",
            fontSize: 10,
            lineHeight: "16px",
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.grey[600],
            textTransform: "uppercase",
          },
        },
        ".MuiAccordionSummary-expandIconWrapper": {
          marginLeft: 16,
        },
      },
      ".u-accordian-body": {
        borderBottom: "none",
        padding: "0 0 0 0",
      },
      "&.Mui-expanded": {
        backgroundColor: "transparent",
      },
    },
    ".detail-head": {
      display: "flex",
      alignItems: "flex-start",
      gap: 16,
      borderBottom: "1px solid" + theme.palette.divider,
      paddingBottom: 16,
    },
  },
  "&.communication-history-dialog": {
    ".content-container": {
      ".stack-wrap": {
        padding: 0,
        "&.bg-light": {
          backgroundColor: theme.palette.common.white,
        },
      },
      ".back-btn-wrap": {
        margin: "-24px -24px 16px -24px",
        borderBottom: "1px solid" + theme.palette.divider,
        padding: "16px 24px",
        ".btn": {
          color: theme.palette.grey[600],
          fontWeight: theme.typography.fontWeightRegular,
          "&.with-icon": {
            "&.btn-default": {
              svg: {
                width: 24,
                height: 24,
              },
              "&:hover": {
                color: theme.palette.grey[600],
              },
            },
          },
        },
      },
    },
  },
}));
