import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@styles";

export const ProposalSummaryWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.proposal-summary-wrap": {
    ".label, .value": {
      fontSize: theme.typography.fontSize,
      color: theme.palette.grey[600],
      lineHeight: "20px",
    },

    ".checkbox": {
      ".custom-checkbox-wrap": {
        ".u-custom-control-label": {
          margin: 0,
        },
      },
    },

    ".value": {
      color: CustomColors.textDark,
      fontWeight: theme.typography.fontWeightMedium,
    },

    ".subtitle": {
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.grey[600],
    },

    ".directors-details": {
      [theme.breakpoints.up("md")]: {
        ".u-accordian-wrap": {
          ".u-accordion-header": {
            cursor: "text",
            ".MuiAccordionSummary-expandIconWrapper": {
              display: "none",
            },
          },
          ".MuiCollapse-root.MuiCollapse-vertical ": {
            minHeight: "auto !important",
            height: "auto !important",
            visibility: "visible !important",
          },
        },
      },
      ".u-table-wrap": {
        ".u-table-container": {
          maxHeight: 280,
        },
      },
    },
  },
}));
