import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const CompanyNameWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.company-name-wrap": {
    ".u-form-group": {
      marginBottom: 0,
    },
    ".list": {
      ".custom-checkbox-wrap": {
        ".u-custom-control-label": {
          margin: "0 0 8px",
          ".MuiFormControlLabel-label": {
            color: theme.palette.grey[900],
            fontWeight: theme.customVariables.fontWeightSemiBold,
          },
        },
      },
      ".list-item": {
        paddingLeft: 32,
      },
    },
  },
}));
