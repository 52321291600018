const enum ActionTypes {
  SET_FINANCE_LEASE_CONFIG = "SET_FINANCE_LEASE_CONFIG",
  SET_HIRE_PURCHASE_CONFIG = "SET_HIRE_PURCHASE_CONFIG",
  SET_LOAN_CONFIG = "SET_LOAN_CONFIG",
  SET_FINANCIAL_REQUEST_DATA = "SET_FINANCIAL_REQUEST_DATA",
  SET_FINANCIAL_RESPONSE_DATA = "SET_FINANCIAL_RESPONSE_DATA",
  SET_NOTIFICATION = "SET_NOTIFICATION",
  SET_RENTAL_CALCULATION_PROGRESS = "SET_RENTAL_CALCULATION_PROGRESS",
  SET_CALCULATED_NFA = "SET_CALCULATED_NFA",
  SET_CALCULATED_COMMISSION = "SET_CALCULATED_COMMISSION",
  SET_SLIDER_OPEN = "SET_SLIDER_OPEN",
  SET_RATES_VISIBILITY = "SET_RATES_VISIBILITY",
  SET_CALCULATION_IN_PROGRESS = "SET_CALCULATION_IN_PROGRESS",
  SET_CALCULATION_CONFIGURATION = "SET_CALCULATION_CONFIGURATION",
  SET_CALCULATION_UPFRONTPAYMENT = "SET_CALCULATION_UPFRONTPAYMENT",
  SET_NOTIFICATION_CONFIGURATION = "SET_NOTIFICATION_CONFIGURATION",
  SET_ALERT = "SET_ALERT",
  SET_SOLVE_FOR = "SET_SOLVE_FOR",
  SET_RESET_VALUES = "SET_RESET_VALUES",
  SET_GROSS_PROFIT = "SET_GROSS_PROFIT",
  SET_CUSTOMER_DETAIL = "SET_CUSTOMER_DETAIL",
  SET_RETRIVE_DETAIL = "SET_RETRIVE_DETAIL",
  SET_RETRIVED_DATA = "SET_RETRIVED_DATA",
  SET_GROSS_PROFIT_APPLICABLE = "SET_GROSS_PROFIT_APPLICABLE",
  SET_QUOTATION_DETAILS = "SET_QUOTATION_DETAILS",
  RESET_FINANCIAL_CALCULATION = "RESET_FINANCIAL_CALCULATION",
}
export default ActionTypes;
