import { styled } from "@mui/material/styles";
import { Box, BoxProps } from "@mui/material";

export const QuotationFiltersWrap = styled(
  Box,
  {}
)<Partial<BoxProps>>(({ theme }) => ({
  "&.quotation-filters-wrap": {
    padding: "16px 40px",
    backgroundColor: theme.palette.common.white,
    borderBottom: "1px solid" + theme.palette.divider,
    display: "flex",
    alignItems: "center",
    ".filters-area": {
      display: "flex",
      alignItems: "center",
      gap: 16,
      justifyContent: "flex-end",
      flexGrow: 1,
      ".filter-item": {
        display: "flex",
        alignItems: "center",
        gap: 4,
        padding: "8px 12px",
        backgroundColor: "#F5F5F7",
        borderRadius: 8,
        ".text-muted": {
          lineHeight: "normal",
          color: "#4D4D4D",
          [theme.breakpoints.down("md")]: {
            flexShrink: 0,
          },
        },
        ".u-form-group": {
          ".MuiFormControl-root": {
            height: 24,
          },
          ".u-form-control": {
            "&.u-select": {
              height: 24,
              ".MuiInputBase-input": {
                padding: "0px 22px 0px 0",
                fontWeight: theme.customVariables.fontWeightSemiBold,
                fontSize: theme.typography.body2.fontSize,
                lineHeight: theme.typography.body1.lineHeight,
              },
              ".MuiSelect-icon": {
                width: 18,
                height: 18,
                right: 0,
              },
              fieldset: {
                border: "none",
              },
            },
          },
          [theme.breakpoints.down("md")]: {
            flexGrow: 1,
            width: "50%",
          },
          "@media(max-width:442.95px)": {
            width: "30%",
          },
        },
        [theme.breakpoints.down("md")]: {
          maxWidth: "47.8%",
          flexGrow: 1,
          "&:first-of-type": {
            width: "100%",
            maxWidth: "100%",
          },
        },
        "@media(max-width:442.95px)": {
          maxWidth: "47%",
        },
      },
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        justifyContent: "center",
        flexDirection: "row",
      },
    },
    ".action-area": {
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
      gap: 16,
      ".btn": {
        "&.btn-primary": {
          padding: "12px 24px",
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      padding: "24px 40px",
      position: "fixed",
      width: "100%",
      top: 222,
      zIndex: 1201,
    },
  },
}));
