import { StepperProgress, Snackbar } from "@components";
import {
  ProposalSummary,
  AssetDetails,
  ProposalDetails,
  CreditDocuments,
  FinanceDetails,
  ProposalFooter,
} from "@components/proposal";
import { useTheme } from "@mui/material";
import ProposalPageHeader from "@components/proposal/page-header/page-header.component";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Icon } from "@ntpkunity/controls";
import { zodResolver } from "@hookform/resolvers/zod";
import { ProposalSchema } from "@models";
import {
  PROPOSAL_FORM_INITIAL_STATE,
  PROPOSAL_STEP_ITEMS_INITIAL_STATE,
} from "@helpers/const";
import { TProposalForm } from "@_types";
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import { ProposalFieldArraysProvider } from "@contexts/proposal-form";
import { ProposalProps } from "./proposal.props";
import { useProposalStepItems } from "@hooks/proposal";

import {
  CalculationsContextProvider,
  ConfigurationContextProvider,
  QuotationFormProvider,
} from "@ntpkunity/controls-common";

export const Proposal = ({
  proposalData,
  proposalSummaryPageReference,
  creditDocumentPageReference,
}: ProposalProps) => {
  const isEditMode = !!proposalData;
  const formMethods = useForm<TProposalForm>({
    defaultValues: isEditMode
      ? proposalData.proposal
      : PROPOSAL_FORM_INITIAL_STATE,
    resolver: zodResolver(ProposalSchema),
  });

  const directorFieldArrayMethods = useFieldArray({
    control: formMethods.control,
    name: "customerDetails.directorDetails",
  });

  const assetFieldArrayMethods = useFieldArray({
    control: formMethods.control,
    name: "assets",
  });

  const documentsFieldArrayMethods = useFieldArray({
    control: formMethods.control,
    name: "documents",
  });

  const theme = useTheme();

  const {
    activeIndex,
    setActiveIndex,
    stepItems,
    goToNextTab,
    goToPrevTab,
    getNextButtonText,
  } = useProposalStepItems({ watch: formMethods.watch });
  const [proposalSubmitted, setProposalSubmitted] = useState(false);

  useEffect(() => {
    if (proposalSummaryPageReference) {
      setActiveIndex(PROPOSAL_STEP_ITEMS_INITIAL_STATE.length - 1);
    } else if (creditDocumentPageReference)
      setActiveIndex(PROPOSAL_STEP_ITEMS_INITIAL_STATE.length - 2);
  }, []);
  return (
    <>
      <ConfigurationContextProvider>
        <CalculationsContextProvider>
          <QuotationFormProvider
            defaultValues={proposalData?.quotation?.formValues}
          >
            <FormProvider {...formMethods}>
              <ProposalFieldArraysProvider
                value={{
                  directorMethods: directorFieldArrayMethods,
                  assetMethods: assetFieldArrayMethods,
                  documentMethods: documentsFieldArrayMethods,
                }}
              >
                <ProposalPageHeader
                  isLastStep={
                    activeIndex + 1 == PROPOSAL_STEP_ITEMS_INITIAL_STATE.length
                  }
                  showActionBtn={!proposalSubmitted}
                />
                <StepperProgress
                  stepItems={stepItems}
                  setActiveIndex={setActiveIndex}
                  activeIndex={activeIndex}
                  onPrevButtonClick={goToPrevTab}
                  stepTitle="Proposal Details"
                  nextStepTitle={getNextButtonText()}
                  hideStepItems={proposalSubmitted}
                  actionArea={
                    <>
                      <Button
                        defaultBtn
                        theme={theme}
                        onClick={() => {}}
                        iconText={<Icon name="SaveDiskIcon" />}
                      />
                    </>
                  }
                >
                  <ProposalDetails />
                  <AssetDetails />
                  <Box
                    theme={theme}
                    className="quote-container"
                    m={{ xs: 0, md: "-16px -40px -16px -40px" }}
                  >
                    <FinanceDetails
                      calculations={proposalData?.quotation?.calculations}
                    />
                  </Box>
                  <CreditDocuments />
                  <ProposalSummary proposalSubmitted={proposalSubmitted} />
                </StepperProgress>
                {!proposalSubmitted && (
                  <ProposalFooter
                    activeIndex={activeIndex}
                    onNextButtonClick={goToNextTab}
                    onPrevButtonClick={goToPrevTab}
                    setProposalSubmitted={setProposalSubmitted}
                    getNextButtonText={getNextButtonText}
                  />
                )}

                <Snackbar />
              </ProposalFieldArraysProvider>
            </FormProvider>
          </QuotationFormProvider>
        </CalculationsContextProvider>
      </ConfigurationContextProvider>
    </>
  );
};
