import { useTheme } from "@mui/material";
import { CreditDocumentsWrap } from "@components/proposal/credit-documents/credit-document.style";
import {
  CreditDocumentTable,
  DocumentDialog,
} from "@components/proposal/credit-documents";
import { useState } from "react";
import { CreditDocumentProps } from "./credit-document.props";
import ViewDocumentDialog from "./document-dialog/view-document-dialog/view-document-dialog.component";

const CreditDocuments = ({ showAddBtn = true }: CreditDocumentProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [editDocumentId, setEditDocumentId] = useState<null | string>(null);
  const theme = useTheme();
  return (
    <>
      <CreditDocumentsWrap theme={theme}>
        <CreditDocumentTable
          setOpenDialog={setOpenDialog}
          setEditDocumentId={setEditDocumentId}
          showAddBtn={showAddBtn}
          setOpenViewDialog={setOpenViewDialog}
        />
      </CreditDocumentsWrap>
      <DocumentDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        editDocumentId={editDocumentId}
        setEditDocumentId={setEditDocumentId}
      />
      <ViewDocumentDialog
        openDialog={openViewDialog}
        setOpenDialog={setOpenViewDialog}
        editDocumentId={editDocumentId}
        setEditDocumentId={setEditDocumentId}
      ></ViewDocumentDialog>
    </>
  );
};

export default CreditDocuments;
