import { useTheme } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { Input, Select } from "@ntpkunity/controls";
import { TInputDef } from "@components/proposal/proposal-details/types";

const ControlledFormElement = (props: TInputDef) => {
  const theme = useTheme();
  const { control } = useFormContext();
  if (props.formElement === "Input") {
    return (
      <Controller
        name={props.name}
        control={control}
        render={({ field }) => {
          return (
            <Input
              theme={theme}
              fullWidth
              placeholder={props.placeHolder}
              label={props.label}
              type={props.type}
              masking={props.masking}
              maskAlphaNumeric={props.maskAlphaNumeric}
              inputMask={props.inputMask}
              maskNumeric={props.maskNumeric}
              startAdornment={props.startAdornment}
              maskDecimalScale={props.maskDecimalScale}
              value={field.value}
              readOnly={props.disabled}
              {...field}
              onChange={(value) => {
                const sanitizedValue = !!props.sanitizeChange
                  ? props.sanitizeChange(value)
                  : value;
                field.onChange(sanitizedValue);
              }}
            />
          );
        }}
      />
    );
  } else {
    return (
      <Controller
        name={props.name}
        control={control}
        disabled={!!props.disabled}
        render={({ field }) => (
          <Select
            theme={theme}
            label={props.label}
            items={props.items}
            {...field}
          />
        )}
      />
    );
  }
};

export default ControlledFormElement;
