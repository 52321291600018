import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const DealerProposalWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.add-proposal-table-wrap": {
    ".table-pagination-button": {
      ".u-table-wrap": {
        ".u-table-container": {
          [theme.breakpoints.up("lg")]: {
            maxHeight: "calc(100vh - 260px)",
          },
        },
      },
    },
  },
}));
