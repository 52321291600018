import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const DocumentsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.documents-wrap": {
    ".stack-wrap": {
      ".stack-header": {
        [theme.breakpoints.down("md")]: {
          flexWrap: "wrap",
          gap: 8,
        },
      },
    },
  },
}));
