import React from "react";
import { useTheme } from "@mui/material";
import { CommunicationHistoryWrap } from "./communication-history.style";
import { Grid } from "@ntpkunity/controls";
import { CommunicationHistoryList } from "./communication-history-list.component";
import { CommunicationHistoryDetails } from "./communication-history-details.component";

export const CommunicationHistory = () => {
  const theme = useTheme();
  return (
    <CommunicationHistoryWrap
      theme={theme}
      className="communication-history-wrap"
    >
      <Grid theme={theme} container item spacing={1}>
        <Grid theme={theme} item md={5} lg={5}>
          <CommunicationHistoryList />
        </Grid>
        <Grid theme={theme} item md={7} lg={7}>
          <CommunicationHistoryDetails />
        </Grid>
      </Grid>
    </CommunicationHistoryWrap>
  );
};
