import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const FinancialConfigTabsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.financial-config-tabs-wrap": {
    ".u-scrollable-tabs": {
      ".tab-list-underline": {
        borderBottom: "none",
        backgroundColor: "#F6F7F9",
        borderRadius: theme.shape.borderRadius,
        minHeight: 40,
      },
      ".MuiTabs-flexContainer": {
        padding: 2,
      },
      ".u-tab-btn": {
        padding: "8px 20px",
        color: theme.palette.grey[700],
        borderRadius: 6,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        fontFamily: theme.typography.fontFamily,
        whiteSpace: "nowrap",
        position: "relative",
        marginRight: 0,
        flexDirection: "row",
        gap: 8,
        minHeight: 40,
        flexGrow: 1,
        maxWidth: "100%",
        ".icon": {
          width: 8,
          height: 8,
          borderRadius: 16,
          path: {
            stroke: "none",
          },
          "&.icon-green": {
            backgroundColor: theme.palette.success.main,
          },
          "&.icon-error": {
            backgroundColor: theme.palette.error.main,
          },
        },
        "&.Mui-selected": {
          backgroundColor: theme.palette.common.white,
          fontWeight: theme.typography.fontWeightBold,
          boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.16)",
          color: theme.palette.grey[900],
          "&::before": {
            display: "none",
          },
          "+": {
            ".u-tab-btn": {
              "&::before": {
                display: "none",
              },
            },
          },
        },
        "&::before": {
          display: "block",
          content: '" "',
          backgroundColor: theme.palette.grey[200],
          width: 1,
          height: 20,
          position: "absolute",
          left: 0,
          top: "50%",
          transform: "translateY(-50%)",
        },
        "&:first-child": {
          "&::before": {
            display: "none",
          },
        },
      },
      ".MuiTabs-indicator": {
        display: "none",
      },
    },
  },
}));
