import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AdditionalDetailsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.additional-details-wrap": {},
}));

export const AdditionalDetailsListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.additional-details-list-wrap": {
    ".list-collapse": {
      "&:first-child": {
        marginTop: 0,
      },
    },
  },
}));

export const AdditionalDetailsContentWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.additional-details-content-wrap": {
    ".accordion-box": {
      borderRadius: theme.shape.borderRadius,
      ".u-accordian-wrap": {
        backgroundColor: "#f7f7f7",
        borderRadius: theme.shape.borderRadius,
        padding: 24,
        marginBottom: 8,
        "&:last-child": {
          marginBottom: 0,
        },
        ".u-accordion-header": {
          ".main-title": {
            fontSize: theme.typography.body1.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
            fontWeight: theme.typography.fontWeightBold,
          },
          padding: 0,
        },
        ".u-accordian-body": {
          padding: "16px 0 0",
        },
        [theme.breakpoints.down("md")]: {
          padding: "24px 16px",
        },
      },
    },
    ".address-form": {
      ".address-layout-wrap": {
        ".MuiGrid-container": {
          marginTop: -16,
          ".MuiGrid-item": {
            paddingTop: 16,
            "&:first-child": {
              display: "none",
            },
          },
        },
      },
    },
  },
}));
