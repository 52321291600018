function getMonthName(monthNumber: number) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return monthNames[monthNumber];
}

export const DateHelper = (pDate: string | number | Date) => {
  let date = new Date(pDate);
  let DD = null;
  if (date.getDate() < 10) {
    DD = `0${date.getDate()}`;
  } else {
    DD = date.getDate();
  }
  return `${DD}-${getMonthName(date.getMonth())}-${date.getFullYear()}`;
};
