import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const CustomerDetailPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.customer-proposal-wrap": {
    ".fw-medium": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    ".address-form": {
      ".address-layout-wrap": {
        ".MuiGrid-container": {
          marginTop: -16,
          ".MuiGrid-item": {
            paddingTop: 16,
            "&:first-child": {
              display: "none",
            },
          },
        },
      },
    },
  },
}));
