import { Grid, Typography } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { EntryProps } from "./entry.props";

const Entry = ({ title, value }: EntryProps) => {
  const theme = useTheme();
  return (
    <>
      <Grid theme={theme} item xs={7}>
        <Typography
          theme={theme}
          component="span"
          variant="caption"
          className="label"
          children={title}
        />
      </Grid>
      <Grid theme={theme} item xs={5} textAlign="right">
        <Typography
          theme={theme}
          component="b"
          variant="caption"
          className="value"
          children={value}
        />
      </Grid>
    </>
  );
};

export default Entry;
