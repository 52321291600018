import { Button, Icon, Menu } from "@ntpkunity/controls";
import { NewQuotationFiltersHeader, PageHeader } from "@components";
import { useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbarContext } from "@contexts/snackbar";
import { BrokerQuotationForm, BrokerSpecifcFormFields } from "@_types";
import {
  useConvertQuotationToProposal,
  useDownloadDocs,
  useSaveQuotation,
  useUpdateQuotation,
} from "@hooks/mutations";
import { APP_ROUTES } from "@helpers";
import { DocumentTemplateType, TableRowOptions } from "@helpers/enum";
import {
  useCalculationsContext,
  useGetMutateQuotationPayload,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import {
  errorInQuotationDetails,
  getBrokerQuotationPayload,
  getQuotationDownloadPayload,
} from "@helpers/utils";
import { useForm, FormProvider } from "react-hook-form";
import { useState } from "react";

type NewQuotationPageHeaderProps = {
  brokerDefaultValues?: BrokerSpecifcFormFields;
};

export const NewQuotationPageHeader = ({
  brokerDefaultValues,
}: NewQuotationPageHeaderProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const params = useParams<{ quotationId?: string }>();
  const quotationId = params.quotationId;
  const isEditMode = !!quotationId;
  const [quotationIdentifier, setQuotationIdentifier] = useState(quotationId);

  const formMethods = useForm<BrokerSpecifcFormFields>({
    defaultValues: !!brokerDefaultValues ? brokerDefaultValues : undefined,
  });
  const { data: calculations, calculated } = useCalculationsContext();
  const { mutateAsync: saveQuotation } = useSaveQuotation();
  const { mutateAsync: updateQuotation } = useUpdateQuotation();
  const { mutateAsync: convertToProposal } = useConvertQuotationToProposal();
  const { mutateAsync: downloadDocs } = useDownloadDocs();

  const getSaveQuotationPayload = useGetMutateQuotationPayload({
    forUpdate: !!quotationId,
  });
  const { setSnackbar } = useSnackbarContext();
  const {
    getValues: getQuotationValues,
    formState: { isDirty: formDirty },
  } = useQuotationFormContext<BrokerQuotationForm>();

  const disableBtn = !calculated || formDirty;

  const handleSaveQuotation = async () => {
    const error = errorInQuotationDetails(formMethods.getValues());
    if (error.isError) {
      setSnackbar({ message: error.message, open: true, variant: "error" });
      return;
    }
    const brokerSpecificValues = formMethods.getValues();
    const quotationPayload = getSaveQuotationPayload();

    const payload = getBrokerQuotationPayload(
      quotationPayload,
      brokerSpecificValues,
      isEditMode
    );

    try {
      setSnackbar({ message: "Saving Quotation", open: true });
      if (isEditMode) {
        await updateQuotation({ ...payload, identifier: quotationId });
        setSnackbar({ message: "Quotation Saved Successfully", open: true });
        return quotationIdentifier;
      } else {
        const response = await saveQuotation(payload);
        setQuotationIdentifier(response.data.identifier);
        setSnackbar({ message: "Quotation Saved Successfully", open: true });
        return response.data.identifier as string;
      }
    } catch {
      setSnackbar({
        open: true,
        message: "Error Occurred while saving Quotation",
        variant: "error",
      });
    }
  };

  const handleConvertToProposal = async () => {
    try {
      let identifier = quotationIdentifier;
      if (!quotationIdentifier) {
        const response = await handleSaveQuotation();
        if (!response) {
          return;
        }
        identifier = response;
      }
      setSnackbar({ open: true, message: "Converting To Proposal..." });
      await convertToProposal(identifier);
      navigate(`${APP_ROUTES.PROPOSAL}/${identifier}`);
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Error occurred while converting to proposal",
        variant: "error",
      });
    }
  };

  const handleDownloadQuotation = async () => {
    try {
      const brokerSpecificValues = formMethods.getValues();
      const downloadQuotationPayload = getQuotationDownloadPayload(
        { ...getQuotationValues(), ...brokerSpecificValues },
        calculations
      );
      await downloadDocs({
        data_placeholders: downloadQuotationPayload,
        template_type: DocumentTemplateType.QUOTATION,
        template_name: "My_Quotation_Template_Word_2.docx",
        generate_base64: false,
        is_bookmark: false,
      });
    } catch (error) {
      const response = error.response;
      const statusCode = response?.status;
      if (statusCode === 403) {
        setSnackbar({
          open: true,
          message: response.data?.message,
          variant: "error",
        });
        return;
      }
      if (statusCode === 400) {
        setSnackbar({
          open: true,
          message: response.data?.detail,
          variant: "error",
        });
        return;
      }
      setSnackbar({
        open: true,
        message: "Error occurred while Downloading Quotation",
        variant: "error",
      });
    }
  };

  const handleOptionClick = (_, key: TableRowOptions, value: string) => {
    switch (key) {
      case TableRowOptions.CONVERT_TO_PROPOSAL:
        handleConvertToProposal();
        break;
      case TableRowOptions.DOWNLOAD:
        handleDownloadQuotation();
        break;
    }
  };

  return (
    <FormProvider {...formMethods}>
      <PageHeader
        title={false}
        hasInput
        inputName="name"
        inputPlaceholder="Enter Quotation Name..."
        shouldUseForm={true}
        actionArea={
          <>
            <NewQuotationFiltersHeader />
            <Menu
              theme={theme}
              options={[
                {
                  optionText: "Convert to proposal",
                  optionkey: TableRowOptions.CONVERT_TO_PROPOSAL,
                  disabled: !isEditMode && disableBtn,
                },
                {
                  optionText: "Download Quotation",
                  optionkey: TableRowOptions.DOWNLOAD,
                  disabled: !isEditMode && disableBtn,
                },
              ]}
              handleOptionClick={handleOptionClick}
              render={(cb) => (
                <Button
                  secondary
                  theme={theme}
                  iconText={<Icon name="MoreIcon" />}
                  onClick={cb}
                ></Button>
              )}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            />
            <Button
              theme={theme}
              type="button"
              primary
              text="Save Quotation"
              disabled={disableBtn}
              onClick={handleSaveQuotation}
            />
          </>
        }
      />
    </FormProvider>
  );
};
