import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const FinancialConfigWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.financial-config-wrap": {
    ".text-primary": {
      color: theme.palette.primary.main,
    },
    ".fw-medium": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    ".link": {
      textDecoration: "none",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
}));
