import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const CompanyHouseDialogContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.company-house-dialog-content": {
    ".fw-sbold": {
      fontWeight: theme.customVariables.fontWeightSemiBold,
    },
    ".u-scrollable-tabs": {
      ".tab-list-underline": {
        borderBottom: "none",
        backgroundColor: "#F6F7F9",
        borderRadius: theme.shape.borderRadius,
        minHeight: 40,
      },
      ".MuiTabs-flexContainer": {
        padding: 2,
      },
      ".u-tab-btn": {
        padding: "8px 20px",
        color: theme.palette.grey[700],
        borderRadius: 6,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        fontFamily: theme.typography.fontFamily,
        whiteSpace: "nowrap",
        position: "relative",
        marginRight: 0,
        flexDirection: "row",
        gap: 8,
        minHeight: 40,
        flexGrow: 1,
        maxWidth: "100%",
        "&.Mui-selected": {
          backgroundColor: theme.palette.common.white,
          fontWeight: theme.typography.fontWeightBold,
          boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.16)",
          color: theme.palette.grey[900],
        },
      },
      ".MuiTabs-indicator": {
        display: "none",
      },
    },
  },
}));
