import { useTheme } from "@mui/material";
import { Box, Icon, Input, Typography } from "@ntpkunity/controls";
import { PageHeaderProps } from "@components/page-header/page-header.props";
import { PageHeaderWrap } from "@components/page-header/page-header.style";
import { useFormContext, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { setQuotationDetails } from "@store/actions/financialCalculation.actions";
import { RootState } from "@store/reducers";
import clsx from "clsx";

// TODO: As on Proposal We are using react-hook-form and on quotation
// we are not, need to make it consistent when we move to react hook form on quotation

const ControlledTitleInput = ({
  inputName,
  inputPlaceholder,
}: {
  inputName: string;
  inputPlaceholder: string;
}) => {
  const theme = useTheme();
  const formMethods = useFormContext();
  return (
    <Controller
      name={inputName}
      control={formMethods?.control}
      render={({ field }) => (
        <Input
          theme={theme}
          fullWidth
          placeholder={inputPlaceholder}
          type="text"
          {...field}
          onChange={(value) => {
            const sanitizedValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
            field.onChange(sanitizedValue);
          }}
        />
      )}
    />
  );
};

const QuotationPageTitleInput = ({
  inputPlaceholder,
}: {
  inputPlaceholder: string;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const quotationDetails = useSelector(
    (state: RootState) => state.reducerFinancialCalculation.quotationDetails
  );

  return (
    <Input
      theme={theme}
      fullWidth
      placeholder={inputPlaceholder}
      type="text"
      value={quotationDetails.name}
      onChange={(value) => {
        dispatch(
          setQuotationDetails({
            ...quotationDetails,
            name: value,
          })
        );
      }}
    />
  );
};

export const PageHeader = ({
  title,
  icon,
  hasInput,
  inputName,
  inputPlaceholder,
  actionArea,
  shouldUseForm = true,
  referenceNumber,
  hasSeperator = false,
}: PageHeaderProps) => {
  const theme = useTheme();
  return (
    <PageHeaderWrap className="page-header-wrap" theme={theme}>
      {title && (
        <Box theme={theme} className="title-area">
          <Typography
            className="page-title text-h2"
            theme={theme}
            variant={"h2"}
            component={"h2"}
          >
            {title}
          </Typography>
          {icon && (
            <Box theme={theme} className="icon-area">
              <Icon name={icon as any} />
            </Box>
          )}
        </Box>
      )}
      {hasInput && (
        <Box theme={theme} className="input-area">
          {shouldUseForm ? (
            <ControlledTitleInput
              inputName={inputName}
              inputPlaceholder={inputPlaceholder}
            />
          ) : (
            <QuotationPageTitleInput inputPlaceholder={inputPlaceholder} />
          )}
        </Box>
      )}
      {actionArea && (
        <Box theme={theme} className="action-area">
          {referenceNumber && (
            <Box theme={theme} className="action-item">
              <Box
                theme={theme}
                className={clsx({
                  "ref-num": true,
                  seperator: hasSeperator,
                })}
              >
                <Typography
                  theme={theme}
                  variant="caption"
                  component={"small"}
                  className="fw-medium"
                >
                  Reference No:
                </Typography>
                <Typography
                  theme={theme}
                  variant="caption"
                  component={"small"}
                  className="fw-sbold text-primary"
                >
                  {referenceNumber}
                </Typography>
              </Box>
            </Box>
          )}
          {actionArea && (
            <Box theme={theme} className="action-item">
              {actionArea}
            </Box>
          )}
        </Box>
      )}
    </PageHeaderWrap>
  );
};
