import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const FooterWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.footer-wrap": {
    minHeight: 64,
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    padding: 12,
    position: "fixed",
    bottom: 16,
    width: "calc(100% - 112px)",
    borderRadius: 16,
    zIndex: 1299,
    margin: "auto",
    left: 80,
    right: 0,
    transition: theme.transitions.create(["width", "left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: "0px 16px 16px -8px rgba(0, 0, 0, 0.12)",
    border: "1px solid" + theme.palette.divider,
    ".back-btn": {
      ".btn": {
        "&.with-icon": {
          "&.btn-default": {
            fontWeight: theme.customVariables.fontWeightSemiBold,
            lineHeight: "16px",
            svg: {
              path: {
                stroke: theme.palette.grey[900],
              },
            },
          },
        },
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    ".action-area": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 24px)",
      left: 0,
    },
  },
}));
