import * as React from "react";
import { PatternFormat } from "react-number-format";

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  prefix: string;
  selectAllOnFocus: boolean;
  format: string;
};

export const PatternFormatCustom = React.forwardRef<HTMLElement, CustomProps>(
  function PatternFormatCustom(props, ref) {
    const {
      onChange,
      prefix = "",
      selectAllOnFocus = false,
      format = "",
      ...other
    } = props;

    return (
      <PatternFormat
        format={format}
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        // onFocus={event => {
        //     selectAllOnFocus && event.target.select();
        // } }
        allowEmptyFormatting
        valueIsNumericString
        prefix={prefix}
      />
    );
  }
);
