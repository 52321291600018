import { useTheme } from "@mui/material";
import { Button } from "@ntpkunity/controls";
import { FinancialConfigWrap } from "./financial-configurations.style";
import { FinancialConfigurationTabs } from "./config-tabs/config-tabs.component";
import { Stack } from "@components";

export const FinancialConfigurations = () => {
  const theme = useTheme();
  return (
    <FinancialConfigWrap theme={theme} className="financial-config-wrap">
      <Stack paddingMd={3} paddingXs={3}>
        <FinancialConfigurationTabs />
      </Stack>
    </FinancialConfigWrap>
  );
};
