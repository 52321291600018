import { TableCell, TableRow, useTheme } from "@mui/material";
import { Box, DataTable, Typography } from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";
import { DashboardTableProps } from "./dashboard-table.props";
import { useGetDashboardProposals } from "@hooks/queries";
import {
  DashboardAnalyticsResponse,
  ManualStatusChangeData,
  TProposalSearchParams,
  TSortParams,
  WithdrawConfirmationData,
} from "@_types";
import {
  AppDialogKeys,
  OrderStage,
  SortOrder,
  WorkQueueTabs,
} from "@helpers/enum";
import { useEffect, useMemo, useState } from "react";
import { TRACK_QUEUE_STATUSES, QUEUE_TO_STATUS_MAP } from "@helpers/const";
import { ManualStatusChangeDialog } from "../../work-queue";
import DashboardFilter from "./dashboard-filter/dashboard-filter.component";
import DashboardTableRow from "./dashboard-table-row/dashboard-table-row.component";
import { useDialogState } from "@hooks";
import { WithdrawConfirmationDialog } from "@components/work-queue/withdraw-confirmation-dialog/withdraw-confirmation-dialog.component";

const sortInitialState: TSortParams<keyof TProposalSearchParams> = {
  sort_by: "updated_at",
  sort_order: SortOrder.DESC,
};

export const DashboardTable = ({
  searchParams,
  setSearchParams,
}: DashboardTableProps) => {
  const searchInitialState: TProposalSearchParams = {
    name: "",
    product_type: "",
    finance_amount: undefined,
    updated_at: "",
    order_stage: OrderStage.APPLICATION,
    customer_name: "",
    date_submitted: "",
    introducer_name: "",
    lender_name: "",
    updated_at_from: "",
    updated_at_to: "",
    statuses: QUEUE_TO_STATUS_MAP[WorkQueueTabs.DASHBOARD_PROPOSALS],
  };

  const [sortParams, setSortParams] =
    useState<TSortParams<keyof TProposalSearchParams>>(sortInitialState);
  const { dialogKey, dialogData, openDialog, closeDialog } = useDialogState<
    ManualStatusChangeData | WithdrawConfirmationData
  >();
  const theme = useTheme();

  const {
    isLoading: isTotalProposalsLoading,
    isError: isTotalProposalsError,
    data,
  } = useGetDashboardProposals<
    TProposalSearchParams,
    DashboardAnalyticsResponse
  >(searchInitialState, sortParams);

  const proposals = useMemo(() => {
    let { order_stage, updated_at_from, updated_at_to, ...params } =
      searchParams || searchInitialState;
    params.date_submitted = params.date_submitted
      ? params.date_submitted.slice(0, 10)
      : params.date_submitted;
    params.updated_at = params.updated_at
      ? params.updated_at.slice(0, 10)
      : params.updated_at;

    return data?.filter((proposal) => {
      return Object.keys(params).every((key) =>
        key == "statuses"
          ? params.statuses.includes(proposal.status)
          : params[key]?.trim()
          ? proposal[key]
              ?.toLowerCase()
              .includes(params[key]?.trim().toLowerCase())
          : true
      );
    });
  }, [searchParams, data]);

  const commonFilterParams = {
    searchParams,
    setSearchParams,
    sortParams,
    setSortParams,
  };

  useEffect(() => {
    setSearchParams(searchInitialState);
    setSortParams(sortInitialState);
  }, []);

  return (
    <DataTableWrap className="table-pagination-button">
      <Box theme={theme} className="scroll">
        <Box className="scroll-hide spr-border" theme={theme} />
      </Box>
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow>
              <TableCell>State</TableCell>
              <TableCell>Proposal</TableCell>
              <TableCell>Product Type</TableCell>
              <TableCell>Customer Name</TableCell>
              {false && <TableCell>Lender Name</TableCell>}
              <TableCell>Broker Name</TableCell>
              <TableCell>Date Submitted</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Last Updated</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            {searchParams && (
              <TableRow className="filters-row">
                <TableCell>
                  <DashboardFilter
                    {...commonFilterParams}
                    searchKey="statuses"
                    statuses={TRACK_QUEUE_STATUSES}
                  />
                </TableCell>
                <TableCell>
                  <DashboardFilter {...commonFilterParams} searchKey="name" />
                </TableCell>
                <TableCell>
                  <DashboardFilter
                    {...commonFilterParams}
                    searchKey="product_type"
                  />
                </TableCell>
                <TableCell>
                  <DashboardFilter
                    {...commonFilterParams}
                    searchKey="customer_name"
                  />
                </TableCell>
                {false && (
                  <TableCell>
                    <DashboardFilter
                      {...commonFilterParams}
                      searchKey="lender_name"
                    />
                  </TableCell>
                )}
                <TableCell>
                  <DashboardFilter
                    {...commonFilterParams}
                    searchKey="introducer_name"
                  />
                </TableCell>
                <TableCell>
                  <DashboardFilter
                    {...commonFilterParams}
                    searchKey="date_submitted"
                    placeholder="DD/MM/YYYY"
                  />
                </TableCell>
                <TableCell>
                  <DashboardFilter
                    {...commonFilterParams}
                    searchKey="finance_amount"
                    type="number"
                  />
                </TableCell>
                <TableCell>
                  <DashboardFilter
                    {...commonFilterParams}
                    searchKey="updated_at"
                    placeholder="DD/MM/YYYY"
                  />
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            )}
          </>
        }
        tbodyChildren={
          <>
            {!proposals?.length ? (
              <TableRow>
                <TableCell colSpan={9}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="span"
                    display="block"
                    textAlign="center"
                  >
                    No Proposals found.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              proposals.map((proposal) => (
                <DashboardTableRow
                  key={proposal.identifier}
                  proposal={proposal}
                  openDialog={openDialog}
                />
              ))
            )}
          </>
        }
      />
      <ManualStatusChangeDialog
        open={dialogKey === AppDialogKeys.MANUAL_STATUS_CHANGE}
        closeDialog={closeDialog}
        dialogData={dialogData}
      />
      <WithdrawConfirmationDialog
        dialogData={dialogData as WithdrawConfirmationData}
        open={dialogKey === AppDialogKeys.WITHDRAW_CONFIRMATION}
        closeDialog={closeDialog}
      />
    </DataTableWrap>
  );
};
