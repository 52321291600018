import React from "react";
import { useTheme } from "@mui/material";
import {
  Accordion,
  Box,
  Button,
  Dialog,
  Switch,
  Textarea,
  Typography,
} from "@ntpkunity/controls";
import { DialogContentWrap } from "./view-condition-dialog.style";

type ViewConditionDialogProps = {
  open: boolean;
  closeDialog: () => void;
};

export const ViewConditionDialog = ({
  open,
  closeDialog,
}: ViewConditionDialogProps) => {
  const theme = useTheme();
  return (
    <Dialog
      theme={theme}
      size="sm"
      open={open}
      title="View Conditions"
      onCloseDialog={closeDialog}
      customFooter={
        <>
          <Button
            theme={theme}
            type="button"
            secondary
            text="Cancel"
            onClick={() => {}}
          />
          <Button
            theme={theme}
            type="button"
            primary
            text="Save Changes"
            onClick={() => {}}
          />
        </>
      }
    >
      <DialogContentWrap theme={theme} className="dialog-content-wrap">
        <Accordion
          theme={theme}
          items={[
            {
              title: "Sample Conditions",
              content: (
                <>
                  <Box theme={theme} className="accordion-content">
                    <Typography
                      theme={theme}
                      variant="caption"
                      component={"small"}
                      className="text-muted"
                      display={"block"}
                    >
                      If I change the type of financing that I am requesting, I
                      thereby request that a second entity in your group
                      offering the requested type of financing consider my
                      application; and I consent to both entities reviewing my
                      credit report.
                    </Typography>
                    <Box
                      theme={theme}
                      className="date-time"
                      mt={0.5}
                      textAlign={"right"}
                    >
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        className="text-muted"
                        display={"block"}
                      >
                        00 Nov 0000 - 00:00 AM
                      </Typography>
                    </Box>
                  </Box>
                  <Box theme={theme} className="comments-area" mt={3} pt={3}>
                    <Textarea
                      theme={theme}
                      rows={3}
                      placeholder="Type here"
                      label="Add Coment"
                      fullWidth
                      type={""}
                      id={""}
                    />
                  </Box>
                </>
              ),
              key: "string 1",
              isExpanded: false,
              actionBtn: (
                <>
                  <Switch
                    theme={theme}
                    varient="basic"
                    switchEnabled={false}
                    onChange={() => {}}
                    label="Not Resolved"
                  />
                </>
              ),
            },
            {
              title: "Sample Conditions",
              content: (
                <>
                  <Box theme={theme} className="accordion-content">
                    <Typography
                      theme={theme}
                      variant="caption"
                      component={"small"}
                      className="text-muted"
                      display={"block"}
                    >
                      If I change the type of financing that I am requesting, I
                      thereby request that a second entity in your group
                      offering the requested type of financing consider my
                      application; and I consent to both entities reviewing my
                      credit report.
                    </Typography>
                    <Box
                      theme={theme}
                      className="date-time"
                      mt={0.5}
                      textAlign={"right"}
                    >
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        className="text-muted"
                        display={"block"}
                      >
                        00 Nov 0000 - 00:00 AM
                      </Typography>
                    </Box>
                  </Box>
                  <Box theme={theme} className="comments-area" mt={3} pt={3}>
                    <Textarea
                      theme={theme}
                      rows={3}
                      placeholder="Type here"
                      label="Add Coment"
                      fullWidth
                      type={""}
                      id={""}
                    />
                  </Box>
                </>
              ),
              key: "string 2",
              isExpanded: true,
              actionBtn: (
                <>
                  <Switch
                    theme={theme}
                    varient="basic"
                    switchEnabled={true}
                    onChange={() => {}}
                    label="Resolved"
                  />
                </>
              ),
            },
            {
              title: "Sample Conditions",
              content: (
                <>
                  <Box theme={theme} className="accordion-content">
                    <Typography
                      theme={theme}
                      variant="caption"
                      component={"small"}
                      className="text-muted"
                      display={"block"}
                    >
                      If I change the type of financing that I am requesting, I
                      thereby request that a second entity in your group
                      offering the requested type of financing consider my
                      application; and I consent to both entities reviewing my
                      credit report.
                    </Typography>
                    <Box
                      theme={theme}
                      className="date-time"
                      mt={0.5}
                      textAlign={"right"}
                    >
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        className="text-muted"
                        display={"block"}
                      >
                        00 Nov 0000 - 00:00 AM
                      </Typography>
                    </Box>
                  </Box>
                  <Box theme={theme} className="comments-area" mt={3} pt={3}>
                    <Textarea
                      theme={theme}
                      rows={3}
                      placeholder="Type here"
                      label="Add Coment"
                      fullWidth
                      type={""}
                      id={""}
                    />
                  </Box>
                </>
              ),
              key: "string 3",
              isExpanded: false,
              actionBtn: (
                <>
                  <Switch
                    theme={theme}
                    varient="basic"
                    switchEnabled={false}
                    onChange={() => {}}
                    label="Not Resolved"
                  />
                </>
              ),
            },
          ]}
          shouldCollapse={true}
        />
      </DialogContentWrap>
    </Dialog>
  );
};
