import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Switch, SwitchProps, Theme } from "@mui/material";

export declare type ISwitchProps = Partial<SwitchProps> & {
  theme?: Theme;
  varient?: "default" | "basic";
  label?: string | null;
  forControlEnabled?: boolean;
  switchEnabled?: boolean;
  id?: string;
};

export const BasicSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      backgroundColor: theme.palette.grey[300],
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
    backgroundColor: theme.palette.common.white,
  },
  "& .MuiSwitch-track": {
    borderRadius: 16,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
  "+": {
    ".MuiFormControlLabel-label": {
      marginLeft: 8,
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.grey[600],
      fontFamily: theme.typography.fontFamily,
    },
  },
}));

export const CustomizedSwitch: React.FC<ISwitchProps> = ({
  theme,
  onChange: onChangePorps,
  value: defaultValue,
  label,
  varient,
  forControlEnabled,
  switchEnabled,
  id,
  disabled = false,
}) => {
  const [isEnabled, setValue] = React.useState(switchEnabled);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.checked);
    onChangePorps?.(e, e.target.checked);
  };

  React.useEffect(() => {
    setValue(switchEnabled);
  }, [switchEnabled]);

  return (
    <FormGroup>
      <FormControlLabel
        className="u-switch-label"
        checked={forControlEnabled}
        sx={{ margin: 0 }}
        control={
          <BasicSwitch
            theme={theme}
            onChange={onChange}
            checked={isEnabled}
            id={id}
          ></BasicSwitch>
        }
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  );
};
