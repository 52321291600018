import {
  Footer,
  LayoutWithSideNavComponent,
  PageHeader,
  SendForPayout,
} from "@components";
import { useTheme } from "@mui/material";
import { PageWrap } from "./send-for-payout-page.style";
import { Box } from "@ntpkunity/controls";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import { WorkQueueTabs } from "@helpers/enum";

export const SendForPayoutPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <LayoutWithSideNavComponent theme={theme}>
      <PageWrap theme={theme} className="page-wrap">
        <PageHeader
          title={"Sample Proposal Name"}
          referenceNumber={"O-R000BA6T"}
          actionArea
        />
        <Box theme={theme} className="page-content has-footer" pt={3}>
          <SendForPayout />
          <Footer
            actionButtonText={"Submit For Payouts"}
            actionButtonOnClick={() =>
              navigate(
                `${APP_ROUTES.WORKQUEUE}/${WorkQueueTabs.TRACK_PROPOSALS}`
              )
            }
            showBackButton
            backButtonOnClick={() =>
              navigate(
                `${APP_ROUTES.WORKQUEUE}/${WorkQueueTabs.TRACK_PROPOSALS}`
              )
            }
          />
        </Box>
      </PageWrap>
    </LayoutWithSideNavComponent>
  );
};
