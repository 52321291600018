import { combineReducers } from "redux";
import { reducerConfiguration } from "@store/reducers/configuration.reducer";
import { reducerFinancialCalculation } from "@store/reducers/financialCalculation.reducer";
const rootReducers = combineReducers({
  reducerConfiguration,
  reducerFinancialCalculation,
});

export type RootState = ReturnType<typeof rootReducers>;

export default rootReducers;
