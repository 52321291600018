import { FinanceType, VatTreatment } from "@flexCommon/enums";
export const AmortizationMethods = [
  { value: "Annuity30-360", text: "Annuity - 30/360" },
  { value: "AnnuityActual-360", text: "Annuity - Actual/360" },
  { value: "AnnuityActual-365", text: "Annuity - Actual/365" },
];
export const APRCalculationMethods = [
  { value: "Annuity30-360", text: "Annuity - 30/360" },
  { value: "AnnuityActual-360", text: "Annuity - Actual/360" },
  { value: "AnnuityActual-365", text: "Annuity - Actual/365" },
];
export const InitialCashOutFlowFlatRateMethods = [
  { value: "IncludingVATAndCommission", text: "Including VAT & Commission" },
  { value: "ExcludingVATAndCommission", text: "Excluding VAT & Commission" },
];
export const CommissionCalculationMethods = [
  { value: "PercentageOfFinancedAmount", text: "% of Financed Amount" },
  { value: "PercentageOfInterestIncome", text: "% of Interest Income" },
];
export const TrueFalseValues = [
  { value: true, text: "Yes" },
  { value: false, text: "No" },
];
export const FeeFrequencies = [
  { value: "OneTime", text: "One Time" },
  { value: "Periodic", text: "Periodic" },
];
export const FeeOccurancesOneTime = [
  // { value: "WithSpecificPayment", text: "With Specific Payment" },
  // { value: "OnSpecificDate", text: "On Particular Date" },
  { value: "OnStartDate", text: "On Start Date" },
  { value: "WithFirstPayment", text: "With 1st Regular Payment" },
  { value: "WithLastPayment", text: "With Last Regular Payment" },
];
export const FeeOccurancePeriodic = [
  { value: "WithEveryPayment", text: "With Every Payment" },
  { value: "Monthly", text: "Monthly" },
  { value: "Quarterly", text: "Quarterly" },
  { value: "SemiAnnual", text: "Semi-Annual" },
  { value: "Annual", text: "Annual" },
];
export const FeeOccurance = [
  { value: 1, text: "With Every Payment" },
  { value: 1, text: "Monthly" },
  { value: 3, text: "Quarterly" },
  { value: 6, text: "Semi-Annual" },
  { value: 12, text: "Annual" },
];
export const PaymentModes = [
  { value: "Advance", text: "Advance" },
  { value: "Arrear", text: "Arrear" },
] as const;
export const FinanceTypes = [
  { value: FinanceType.HirePurchase, text: "Hire Purchase" },
  { value: FinanceType.FinanceLease, text: "Finance Lease" },
  { value: FinanceType.Loan, text: "Loan" },
];
export const PaymentFrequencies = [
  { value: "Weekly", text: "Weekly" },
  { value: "Fortnightly", text: "Fortnightly" },
  { value: "Monthly", text: "Monthly" },
  { value: "Quarterly", text: "Quarterly" },
  { value: "SemiAnnual", text: "Semi-Annual" },
  { value: "Annual", text: "Annual" },
];
export const RentalTypes = [
  { value: "Structured", text: "Structured" },
  { value: "Zero", text: "Zero" },
  { value: "Bonus Payment", text: "Bonus" },
];
export const VATTreatments = [
  { value: VatTreatment.VATPaidUpfront, text: "VAT Upfront" },
  { value: VatTreatment.VATDeferral, text: "VAT Deferred" },
];
export const CommissionTypes = [
  { value: 0, text: "None" },
  { value: 1, text: "Percentage" },
  { value: 2, text: "Amount" },
];
export const BalloonCollectionsAdvance = [
  { value: 1, text: "1 Month after last Payment" },
  { value: 2, text: "2 Month after last Payment" },
  { value: 3, text: "3 Month after last Payment" },
  { value: 4, text: "4 Month after last Payment" },
  { value: 5, text: "5 Month after last Payment" },
  { value: 6, text: "6 Month after last Payment" },
];
export const BalloonCollectionsArrear = [
  { value: 0, text: "With Last Payment" },
  { value: 1, text: "1 Month after last Payment" },
  { value: 2, text: "2 Month after last Payment" },
  { value: 3, text: "3 Month after last Payment" },
  { value: 4, text: "4 Month after last Payment" },
  { value: 5, text: "5 Month after last Payment" },
  { value: 6, text: "6 Month after last Payment" },
];

export const rateTypes = [
  { value: "Nominal Rate", text: "Nominal Rate" },
  { value: "APR", text: "APR" },
  { value: "Flat Rate", text: "Flat Rate" },
];

export const annuityRateTypes = [
  { value: "Nominal Rate", text: "Nominal Rate" },
  { value: "Flat Rate", text: "Flat Rate" },
];

export const SolveForItems = [
  { key: "", value: "Periodic Payment", text: "Periodic Payment" },
  { key: "", value: "Finance Amount", text: "Finance Amount" },
  { key: "", value: "Deposit", text: "Deposit" },
  //{ key: "", value: "RV/Balloon", text: "RV/Balloon" },
  { key: "", value: "Commission", text: "Commission" },
  { key: "", value: "Rates", text: "Rates" },
];

export const exportWordPlanUrl =
  "/documents/word/plan/export-repayment-plan-in-word";

export const exportPdf = "/documents/pdf/export-in-pdf";

export const exportInWordUrl = "/documents/word/export-in-word";

export const exportDetail =
  "No Template found of this tenant_id and template_type";

export const Gross = [{ value: "GrossYield", text: "Gross Yield" }];

export const financeAmountRateType = [
  { value: "GrossYield", text: "Gross Yield" },
  { value: "NetYield", text: "Net Yield" },
];

export const financeAmountRateTypeforPercentage = [
  { value: "GrossYield", text: "Gross Yield" },
];
export const commissionType = [
  { value: "%", text: "%" },
  { value: "£", text: "£" },
];
export const vatDeferralType = [
  { value: "Month", text: "Month" },
  { value: "Rental", text: "Rental" },
];
export const stepType = [
  { value: "Up", text: "Step Up" },
  { value: "Down", text: "Step Down" },
];

export const frequency = [
  { value: "Monthly", text: "Monthly" },
  { value: "Quarterly", text: "Quarterly" },
];

export const nonDecimalFieldType = [
  "VAT.PaymentNumber",
  "AdvancePayments",
  "RegularPayments",
  "BalloonCollection",
  "StepFrequency",
];
