import {
  Box,
  Button,
  Dialog,
  FilePreview,
  Grid,
  ImageViewer,
  Typography,
} from "@ntpkunity/controls";
import { Divider, useTheme } from "@mui/material";
import { ViewDocumentDialogWrap } from "./view-document-dialog.style";
import { Stack } from "@components";
import { DocumentDialogProps } from "../document-dialog.props";
import { useProposalFieldArrays } from "@contexts/proposal-form";
import { usePreviewDocs } from "@hooks/mutations/useDownloadDocs";
import { useState } from "react";

const ViewDocumentDialog = ({
  openDialog,
  setOpenDialog,
  editDocumentId,
}: DocumentDialogProps) => {
  const theme = useTheme();
  const {
    documentMethods: { fields: documents },
  } = useProposalFieldArrays();
  const documentToView = documents.find((doc) => doc.id === editDocumentId);
  const { mutateAsync: previewURL } = usePreviewDocs();
  const [imageURL, setImageURL] = useState([]);
  const [index, setIndex] = useState(0);

  const getFilePreviewArray = () => {
    if (!documentToView?.documents.length) {
      return [];
    } else {
      return documentToView?.documents.map((fileDetail) => {
        const file = fileDetail.file;
        return {
          key: fileDetail.key,
          name: fileDetail.key,
          size: file.size,
          file: {
            location: fileDetail.previewUrl,
            name: fileDetail.key,
            type: file?.type,
          },
          viewIcon: true,
          preview: "fileIcon",
          isUploading: false,
          error: false,
          uploadingLabel: "Uploading",
          uploadingProgress: 0,
        };
      });
    }
  };

  const viewFile = (indexNumber) => {
    if (documentToView?.documents[indexNumber]?.key.includes(".pdf")) {
      previewURL(documentToView?.documents[indexNumber]?.key).then(
        (response) => {
          window.open(response, "_blank");
        }
      );
    } else {
      documentToView?.documents.map((fileDetail) => {
        if (!fileDetail.key.includes(".pdf")) {
          previewURL(fileDetail.key).then((response) => {
            fileDetail.previewUrl = response;
            setImageURL((prevItems) => [...prevItems, response]);
          });
        }
      });

      setIndex(indexNumber);
    }
  };

  const handleClose = () => setImageURL([]);

  return (
    <Dialog
      theme={theme}
      size={"sm"}
      onCloseDialog={() => setOpenDialog(false)}
      open={openDialog}
      disablePortal
      title="View Document"
      customFooter={
        <>
          <Button
            theme={theme}
            type="button"
            secondary
            text="Close"
            onClick={() => setOpenDialog(false)}
            fullWidth
          />
        </>
      }
    >
      <ViewDocumentDialogWrap
        theme={theme}
        className="view-document-dialog-wrap"
      >
        <Stack paddingXs={0} paddingMd={0} title="Document Details">
          <Grid
            theme={theme}
            container
            item
            columnSpacing={2}
            rowSpacing={0}
            mt={2}
          >
            <Grid theme={theme} item xs={5}>
              <Typography
                theme={theme}
                component="span"
                variant="body2"
                className="text-muted text-truncated"
                children={"Document Name"}
              />
            </Grid>
            <Grid theme={theme} item xs={7}>
              <Typography
                theme={theme}
                component="span"
                variant="body2"
                className="fw-medium text-truncated"
                children={documentToView?.name}
              />
            </Grid>
            <Grid theme={theme} item xs={5}>
              <Typography
                theme={theme}
                component="span"
                variant="body2"
                className="text-muted text-truncated"
                children={"Document Type"}
              />
            </Grid>
            <Grid theme={theme} item xs={7}>
              <Typography
                theme={theme}
                component="span"
                variant="body2"
                className="fw-medium text-truncated"
                children={documentToView?.type}
              />
            </Grid>
          </Grid>
        </Stack>
        <Box theme={theme} mt={3} mb={3}>
          <Divider className="divider" />
        </Box>
        <Stack paddingXs={0} paddingMd={0} title="Document Files">
          <Grid
            theme={theme}
            container
            item
            columnSpacing={2}
            rowSpacing={0}
            mt={1}
          >
            <Grid theme={theme} item xs={12}>
              <Box theme={theme}>
                <FilePreview
                  files={getFilePreviewArray()}
                  theme={theme}
                  viewIcon
                  onClickIcon={viewFile}
                  onRemoveFile={() => {}}
                />
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </ViewDocumentDialogWrap>

      <ImageViewer
        theme={theme}
        sliderImagesWithVisible={true}
        sliderImages={imageURL}
        isLoading={false}
        visible={imageURL.length > 0}
        onClose={handleClose}
        overlayCheck={true}
        index={index}
        setIndex={setIndex}
      />
    </Dialog>
  );
};

export default ViewDocumentDialog;
