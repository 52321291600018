import { styled } from "@mui/material";

export const ResponsiveWrap: any = styled("div")(({ theme }) => ({
  ".mobile, .desktop": {
    display: "none",
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    ".desktop": {
      display: "inline-block",
    },
  },
  [theme.breakpoints.down("md")]: {
    ".mobile": {
      display: "inline-block",
      ".mobile-hide": {
        display: "none",
      },
    },
  },
}));
