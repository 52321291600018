import { get, post } from "@flexCommon/api";
import {
  APIResponse,
  IConfigurationInputs,
} from "@interfaces/configuration.interface";

const baseUrl = `${process.env.FLEX_QUOTE_BASE_URL}`;

export const SaveConfiguration = async (
  param: IConfigurationInputs,
  financeType: string
): Promise<APIResponse> => {
  var response: APIResponse = {
    Success: false,
  };
  await post(`${baseUrl}quote-config/save-configuration`, {
    finance_type: financeType,
    config: JSON.stringify(param),
  })
    .then((res: any) => {
      response = {
        Success: true,
        Data: res.Message,
      };
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};
export const GetConfiguration = async (
  financeType: string
): Promise<APIResponse> => {
  var response: APIResponse = {
    Success: false,
  };
  await get(`${baseUrl}quote-config/get-configuration`, [
    {
      Key: "finance_type",
      Value: financeType,
    },
  ])
    .then((res: any) => {
      response = {
        Success: true,
        Data: res.Data,
      };
    })
    .catch((ex) => {
      response.Error = ex.Message;
    });
  return response;
};
