import React, { FC } from "react";
import * as icons from ".";

const Icon: FC<{
  name: keyof typeof icons;
  className?: string;
  onClick?: (e: unknown) => void;
}> = ({ name, className, onClick }) => {
  const Component = icons[name] as FC<{
    className?: string;
    onClick?: (e: unknown) => void;
  }>;
  return <Component className={className} onClick={onClick} />;
};

export default Icon;
