import { Stack } from "@components";
import { Divider, useTheme } from "@mui/material";
import { Accordion, Box, Grid, Typography } from "@ntpkunity/controls";
import { Rentals } from "./rentals/rentals.component";
import Rates from "./rates/rates.component";
import Entry from "./entry/entry.component";
import { PaymentDetailsWrap } from "./payment-details.style";

export const PaymentDetails = () => {
  const theme = useTheme();
  return (
    <PaymentDetailsWrap theme={theme} className="payment-details-wrap">
      <Stack paddingXs={3} paddingMd={3}>
        <Typography theme={theme} component="h4" variant="h4">
          £ 120,000.00
        </Typography>
        <Typography
          theme={theme}
          component="small"
          variant="caption"
          className="subtitle"
          children="Total Asset Cost"
        />

        <Divider className="divider" />

        <Grid theme={theme} container columnSpacing={3} rowSpacing={2}>
          <Entry title="Finance Amount:" value={"£120,400.00"} />
          <Entry title="Tenure:" value={"25 Months"} />
        </Grid>

        <Box theme={theme} className="accordion-list-item">
          <Accordion
            theme={theme}
            items={[
              {
                title: "Upfront Payment:",
                subtitle: "£7,033.89",
                content: (
                  <>
                    <Box theme={theme} pl={3} pt={2}>
                      <Grid
                        theme={theme}
                        container
                        columnSpacing={3}
                        rowSpacing={2}
                      >
                        <Entry title="Advance Rentals:" value={"£5,033.89"} />
                        <Entry title="Deposit:" value={"£2,000.00"} />
                      </Grid>
                    </Box>
                  </>
                ),
                key: "string-3",
                isExpanded: true,
              },
            ]}
          />
        </Box>

        <Divider className="divider" />

        <Typography
          theme={theme}
          component="h4"
          variant="h4"
          children="Payment Details"
          sx={{ mb: 2 }}
        />
        <Rentals />

        <Divider className="divider" />

        <Grid theme={theme} container columnSpacing={3} rowSpacing={2}>
          <Rates />
        </Grid>
      </Stack>
    </PaymentDetailsWrap>
  );
};
