import {
  DOCUMENTS_EXPORT_IN_PDF,
  DOCUMENTS_EXPORT_IN_WORD,
  DOCUMENTS_GENERATE_PRESIGNED_URL,
  UPLOAD_BASE_64,
} from "@helpers/endpoints";
import { flexReportingAPI } from "./instances";

export const exportDocumentsInPdf = async (payload: any) => {
  const response = await flexReportingAPI.post(
    DOCUMENTS_EXPORT_IN_PDF,
    payload
  );
  return response.data;
};

export const exportDocumentsInWord = async (payload: any) => {
  const response = await flexReportingAPI.post(
    DOCUMENTS_EXPORT_IN_WORD,
    payload
  );
  return response.data;
};

export const generateDocumentPresignedUrl = async (payload: any) => {
  const response = await flexReportingAPI.post(
    DOCUMENTS_GENERATE_PRESIGNED_URL,
    payload
  );
  return response.data;
};

export const uploadBase64 = async (payload: any) => {
  const response = await flexReportingAPI.post(UPLOAD_BASE_64, payload);
  return response.data;
};
