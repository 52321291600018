import {
  DocumentList,
  Footer,
  LayoutWithSideNavComponent,
  PageHeader,
  SendForPayout,
  Stack,
} from "@components";
import { useTheme } from "@mui/material";
import { PageWrap } from "./signatory-status-page.style";
import { Box } from "@ntpkunity/controls";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import { WorkQueueTabs } from "@helpers/enum";
import { DOCUMENT_SIGNATORY_STATUS } from "@helpers/const";
export const SignatoryStatusPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <LayoutWithSideNavComponent theme={theme}>
      <PageWrap theme={theme} className="page-wrap">
        <PageHeader
          title={"Sample Proposal Name"}
          referenceNumber={"A000000"}
          actionArea
        />
        <Box theme={theme} className="page-content has-footer" pt={3}>
          <Stack paddingMd={3} paddingXs={3} title="Documents">
            <Box theme={theme} mt={2}>
              <DocumentList statuses={DOCUMENT_SIGNATORY_STATUS} />
            </Box>
          </Stack>
        </Box>
        <Footer
          showBackButton
          backButtonOnClick={() =>
            navigate(`${APP_ROUTES.WORKQUEUE}/${WorkQueueTabs.TRACK_PROPOSALS}`)
          }
        />
      </PageWrap>
    </LayoutWithSideNavComponent>
  );
};
