import ReactInputMask from "react-input-mask";

type IInputMaskCustom = {
  mask: string;
  maskChar?: string;
};
export const InputMaskCustom = ({
  mask,
  maskChar = undefined,
  ...props
}: IInputMaskCustom) => {
  return <ReactInputMask {...props} mask={mask} maskChar=" " />;
};
