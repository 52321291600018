import "react";
import { AddressLayout, Box, Grid, Input, Select } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { GB_COUNTRY } from "@helpers/const";

const GuarantorForm = () => {
  const theme = useTheme();
  return (
    <>
      <Grid theme={theme} container item spacing={2}>
        <Grid theme={theme} item xs={12} md={4} lg={3}>
          <Input theme={theme} fullWidth label="Guarantor Name" type={"text"} />
        </Grid>
        <Grid theme={theme} item xs={12}>
          <Box theme={theme} className="address-form">
            <AddressLayout
              theme={theme}
              state={{
                data: [],
                selectedItemObject: {
                  text: "",
                  value: "",
                },
                controlName: "",
              }}
              hideAddressButton={true}
              isContactRequired={false}
              addressVerificationRequired={false}
              countries={[GB_COUNTRY]}
              value={undefined}
              validationProps={undefined}
              onChange={() => {}}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default GuarantorForm;
