import { DirectorDialogProps } from "./director-dialog.props";
import AddDirectorDialog from "./add-director-dialog/add-director-dialog.component";
import EditDirectorDialog from "./edit-director-dialog/edit-director-dialog.component";

export const DirectorDialog = ({
  open,
  setOpenDialog,
  editDirectorId,
  setEditDirectorId,
}: DirectorDialogProps) => {
  const isEditMode = !!editDirectorId;

  const commonProps = {
    open,
    setOpenDialog,
  };

  if (isEditMode) {
    return (
      <EditDirectorDialog
        {...commonProps}
        editDirectorId={editDirectorId}
        setEditDirectorId={setEditDirectorId}
      />
    );
  }

  return <AddDirectorDialog {...commonProps} />;
};
