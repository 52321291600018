import { useTheme } from "@mui/material";
import { PageContent } from "./dealer-proposal-page.style";
import {
  DealerProposalPageHeader,
  DealerProposalTable,
  LayoutWithSideNavComponent,
} from "@components";

export const DealerProposalPage = () => {
  const theme = useTheme();

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <DealerProposalPageHeader />
      <PageContent theme={theme} className="page-content" pt={3}>
        <DealerProposalTable />
      </PageContent>
    </LayoutWithSideNavComponent>
  );
};
