import { Box, Collapse, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { nonDecimalFieldType, stepType } from "@flexCommon/constants";
import { FieldType } from "@flexCommon/enums";
import { getDisabledProp, getHiddenProp } from "@helpers/common";
import { IFieldProp } from "@interfaces/configuration.interface";
import { setFinancialRequestObj } from "@store/actions/financialCalculation.actions";
import { IReducerFinancialCalculation } from "@store/reducers/financialCalculation.reducer";
import {
  CustomizedSwitch,
  Divider,
  Dropdown,
  TextBox,
  ToggleBtn,
} from "@components/quotation/shared/controls";
import { CalculationWrap } from "@components/quotation/configuration.style";
import { SectionWrap } from "@components/quotation/rental-structure/rental-structure.component";
import { CombinedControl } from "@components/quotation/vat/vat.component";

type IStep = {
  storeState: IReducerFinancialCalculation;
  fieldProps: IFieldProp[];
};
export const Step = ({ storeState, fieldProps }: IStep) => {
  var dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (
      fieldProps?.find((x) => x.Name == FieldType.StepPaymentsApplicable)
        ?.Disabled == true
    ) {
      setVisible(false);
    }
  }, [getDisabledProp(fieldProps, FieldType.StepPaymentsApplicable)]);
  const getFields = () => {
    if (
      visible == true &&
      !getHiddenProp(fieldProps, FieldType.StepPaymentsApplicable)
    ) {
      return (
        <>
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item lg={6} xs={12}>
              <TextBox
                // theme={theme}
                testid="txtFrequency"
                label="Frequency"
                thousandSeparater={false}
                masking
                decimalScale={0}
                fullWidth
                value={
                  storeState.RequestObj.Step.Frequency !== 0 ||
                  storeState.RequestObj.Step.Frequency > 0
                    ? storeState.RequestObj.Step.Frequency
                    : ""
                }
                placeHolder={
                  nonDecimalFieldType.includes("StepFrequency") ? "0" : "0.00"
                }
                onChange={(e) =>
                  dispatch(
                    setFinancialRequestObj({
                      ...storeState.RequestObj,
                      Step: {
                        ...storeState.RequestObj.Step,
                        Frequency: e.target.value,
                      },
                    })
                  )
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <CombinedControl>
                <TextBox
                  testid="txtStep%"
                  label="Step"
                  masking
                  endAdornment="%"
                  decimalScale={2}
                  value={
                    storeState.RequestObj.Step.Percentage !== 0 ||
                    storeState.RequestObj.Step.Percentage > 0
                      ? storeState.RequestObj.Step.Percentage
                      : ""
                  }
                  placeHolder={
                    nonDecimalFieldType.includes("StepPercent") ? "0" : "0.00"
                  }
                  onChange={(e) =>
                    dispatch(
                      setFinancialRequestObj({
                        ...storeState.RequestObj,
                        Step: {
                          ...storeState.RequestObj.Step,
                          Percentage: e.target.value,
                        },
                      })
                    )
                  }
                />
                <Dropdown
                  items={stepType}
                  testid="txtStepType"
                  label={"Step Type"}
                  value={storeState.RequestObj.Step.Type}
                  onChange={(e) => {
                    dispatch(
                      setFinancialRequestObj({
                        ...storeState.RequestObj,
                        Step: {
                          ...storeState.RequestObj.Step,
                          Type: e.target.value,
                        },
                      })
                    );
                  }}
                />
              </CombinedControl>
            </Grid>
          </Grid>
        </>
      );
    } else {
      return <></>;
    }
  };
  return (
    <>
      <CalculationWrap className="results">
        <SectionWrap>
          <Grid container columnSpacing={2} rowSpacing={3} sx={{ mb: 2 }}>
            <Grid item xs={10} sm={8}>
              <Typography
                variant="h4"
                component="h4"
                children={<>Step Payments</>}
              />
            </Grid>
            <Grid item xs={2} sm={4} className="col-switch">
              <CustomizedSwitch
                disabled={getDisabledProp(
                  fieldProps,
                  FieldType.StepPaymentsApplicable
                )}
                switchEnabled={visible}
                value={visible}
                onChange={(e) => {
                  setVisible(e.target.checked);
                  dispatch(
                    setFinancialRequestObj({
                      ...storeState.RequestObj,
                      hasStep: e.target.checked,
                    })
                  );
                }}
              />
            </Grid>
          </Grid>
          {getFields()}
        </SectionWrap>
      </CalculationWrap>
    </>
  );
};
