import * as React from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  OutlinedInput,
  Theme,
  SxProps,
  InputLabel,
} from "@mui/material";
import { NumericFormatCustom } from "@components/quotation/shared/controls/numericFormat.shared";
import { PatternFormatCustom } from "@components/quotation/shared/controls/patternFormat.shared";
import { InputMaskCustom } from "@components/quotation/shared/controls/inputMask.shared";
// import { TextMaskCustom } from "./maskedInput"
// import { NumberFormatCustom } from "./numberFormat"
import clsx from "clsx";
import { styled } from "@mui/material/styles";
import { ReactNode } from "react";

const CustomInputWrapper = styled(
  "div",
  {}
)<Partial<ITextBox>>(({ theme }) => ({
  "&.u-form-group": {
    marginBottom: 24,
    "&:last-child": {
      marginBottom: 0,
    },
    ".u-form-control": {
      height: 48,
      color: "rgba(0,0,0,0.87)",
      fontFamily: theme.typography.fontFamily,
      ".MuiInputBase-input": {
        padding: "12px 16px",
        "&::placeholder": {
          opacity: 0,
        },
      },
      '.MuiInputBase-input[type="number"]': {
        appearance: "textfield",
        "&::-webkit-outer-spin-button": {
          appearance: "none",
        },
        "&::-webkit-inner-spin-button": {
          appearance: "none",
        },
      },
      "&:hover": {
        fieldset: {
          borderColor: theme.palette.primary.main,
        },
      },
      "&.Mui-focused": {
        fieldset: {
          borderColor: theme.palette.primary.main,
        },
        ".MuiInputBase-input": {
          "&::placeholder": {
            opacity: 0.26,
          },
        },
      },
      "&.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.38)",
        ".MuiInputBase-input": {
          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.38)",
            textFillColor: "rgba(0, 0, 0, 0.38)",
            cursor: "not-allowed",
          },
        },
        fieldset: {
          borderColor: "rgba(0, 0, 0, 0.12)",
        },
        "+": {
          ".u-input-label": {
            color: "rgba(0, 0, 0, 0.38)",
            "+": {
              ".u-help-text": {
                color: "rgba(0, 0, 0, 0.38)",
              },
            },
          },
        },
      },
      "&.MuiInputBase-adornedStart": {
        ".MuiInputBase-input": {
          padding: "12px 16px 12px 0px",
        },
        fieldset: {
          legend: {
            maxWidth: 0.01,
          },
        },
        "&:hover": {
          fieldset: {
            "&.MuiOutlinedInput-notchedOutline": {
              // borderColor: theme.palette.primary.main
            },
          },
        },
        "&.Mui-focused": {
          fieldset: {
            legend: {
              maxWidth: "100%",
            },
          },
        },
        "~": {
          ".u-input-label": {
            transform: "translate(38px, 12px) scale(1)",
            "&.Mui-focused": {
              transform: "translate(14px, -9px) scale(0.85)",
            },
            "&.MuiFormLabel-filled": {
              transform: "translate(14px, -9px) scale(0.85)",
              paddingLeft: 6,
              left: -5,
            },
          },
        },
      },
      "&.MuiInputBase-adornedEnd": {
        ".MuiInputBase-input": {
          padding: "12px 0px 12px 16px",
        },
      },
      "&.Mui-error": {
        fieldset: {
          borderWidth: 2,
          borderColor: theme.palette.error.main,
        },
        "&:hover": {
          fieldset: {
            borderColor: theme.palette.error.main,
          },
        },
        "&.Mui-focused": {
          fieldset: {
            borderColor: theme.palette.error.main,
          },
        },
        "~": {
          ".u-input-label": {
            color: theme.palette.error.main,
            "&.Mui-focused": {
              color: theme.palette.error.main,
            },
            "&.MuiFormLabel-filled": {
              color: theme.palette.error.main,
            },
          },
        },
        ".MuiInputAdornment-root": {
          color: theme.palette.error.main,
          svg: {
            path: {
              stroke: theme.palette.error.main,
            },
          },
        },
        ".adornment-text": {
          color: theme.palette.error.main,
        },
      },
    },
    ".u-input-label": {
      color: "rgba(0,0,0,0.87)",
      fontFamily: theme.typography.fontFamily,
      lineHeight: theme.typography.body1.lineHeight,
      transform: "translate(14px, 12px) scale(1)",
      padding: "0 5px 0 0px",
      backgroundColor: theme.palette.common.white,
      userSelect: "none",
      pointerEvents: "none",
      "&.Mui-focused": {
        color: theme.palette.grey[600],
        transform: "translate(14px, -9px) scale(0.85)",
        maxWidth: "100%",
      },
      "&.MuiFormLabel-filled": {
        color: theme.palette.grey[600],
        transform: "translate(14px, -9px) scale(0.85)",
        maxWidth: "100%",
      },
    },
    ".u-help-text": {
      marginLeft: 15,
      lineHeight: theme.typography.caption.lineHeight,
    },
    ".u-error-text": {
      color: theme.palette.error.main,
      marginLeft: 15,
      lineHeight: theme.typography.caption.lineHeight,
    },
    ".text-capitalize": {
      textTransform: "capitalize",
    },
    ".text-uppercase": {
      textTransform: "uppercase",
    },
    ".adornment-focus": {
      ".MuiInputAdornment-root": {
        visibility: "hidden",
        opacity: 0,
        transition: "all 0.3s",
      },
      ".Mui-focused": {
        ".MuiInputAdornment-root": {
          visibility: "visible",
          opacity: 1,
        },
      },
    },
    ".adornment-text": {
      paddingRight: 5,
      display: "inline-block",
    },
  },
}));

export type ITextBox = {
  id?: string;
  label: string;
  value: string | number;
  disabled?: boolean;
  helperText?: string;
  onChange?(e: any): void;
  testid?: string;
  type?: string;
  masking?: boolean;
  currency?: boolean;
  decimalScale?: number;
  maskPrefix?: string;
  maskSuffix?: string;
  startAdornment?: string;
  endAdornment?: string;
  thousandSeparater?: boolean;
  selectAllOnFocus?: boolean;
  hidden?: boolean;
  formatType?: "pattern" | "inputMask";
  maskFormat?: string;
  theme?: Theme;
  inputWrapperSx?: SxProps<Theme>;
  wrapperClass?: string;
  inputClass?: string;
  fullWidth?: boolean;
  formControlSx?: SxProps<Theme>;
  formControlClass?: string;
  adornmentOnFocus?: boolean;
  InputLableSx?: SxProps<Theme>;
  capitalizeLabel?: boolean;
  uppercaseLabel?: boolean;
  maskCurrency?: boolean;
  maskDecimalScale?: number;
  inputMask?: string;
  format?: string;
  maskAllowNegative?: boolean;
  thousandSeparator?: boolean;
  maskNumeric?: boolean;
  unMaskedValue?: boolean;
  placeHolder?: string;
};

export const TextBox = ({
  id = "textbox1",
  disabled = false,
  label,
  value,
  helperText = "",
  onChange,
  type = "text",
  masking = false,
  currency = false,
  decimalScale = 2,
  maskPrefix = "",
  maskSuffix = "",
  startAdornment = "",
  endAdornment = "",
  thousandSeparater = true,
  selectAllOnFocus = false,
  hidden = false,
  formatType,
  maskFormat = "",
  theme,
  inputWrapperSx,
  wrapperClass,
  inputClass,
  fullWidth,
  formControlSx,
  formControlClass,
  adornmentOnFocus,
  InputLableSx,
  capitalizeLabel,
  uppercaseLabel,
  maskCurrency,
  maskDecimalScale,
  inputMask,
  format,
  maskAllowNegative,
  thousandSeparator,
  unMaskedValue,
  maskNumeric,
  placeHolder = "",
  testid = "",
}: ITextBox) => {
  return (
    <CustomInputWrapper
      theme={theme}
      sx={inputWrapperSx}
      hidden={hidden}
      className={clsx("u-form-group", wrapperClass)}
    >
      <FormControl
        fullWidth={fullWidth}
        sx={formControlSx}
        className={clsx(formControlClass, {
          "adornment-focus": adornmentOnFocus,
        })}
      >
        <OutlinedInput
          type={type}
          id={testid}
          value={value}
          label={label}
          fullWidth
          disabled={disabled}
          // size="small"
          className={clsx("u-form-control", inputClass)}
          onChange={onChange}
          startAdornment={
            startAdornment.length > 0 ? (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : undefined
          }
          endAdornment={
            endAdornment.length > 0 ? (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ) : undefined
          }
          inputComponent={(() => {
            if (masking) {
              return NumericFormatCustom as any;
            } else if (formatType === "pattern") {
              return PatternFormatCustom as any;
            } else if (formatType === "inputMask") {
              return InputMaskCustom as any;
            } else {
              return undefined;
            }
          })()}
          placeholder={placeHolder}
          // InputProps={{
          //     startAdornment: startAdornment.length > 0 ? <InputAdornment position="start">{startAdornment}</InputAdornment> : undefined,
          //     endAdornment: endAdornment.length > 0 ? <InputAdornment position="end">{endAdornment}</InputAdornment> : undefined,
          //     inputComponent: (() => {
          //         if (masking) {
          //             return NumericFormatCustom as any
          //         }
          //         else if (formatType === 'pattern') {
          //             return PatternFormatCustom as any
          //         }
          //         else if (formatType === 'inputMask') {
          //             return InputMaskCustom as any
          //         }
          //         else {
          //             return undefined
          //         }
          //     })(),
          // }}
          inputProps={(() => {
            if (masking) {
              return {
                scale: decimalScale,
                prefix: maskPrefix,
                suffix: maskSuffix,
                allowNegative: false,
                thousandSeparator: thousandSeparater,
                selectAllOnFocus: selectAllOnFocus,
              };
            } else if (formatType === "pattern") {
              return {
                prefix: maskPrefix,
                selectAllOnFocus: false,
                format: maskFormat,
              };
            } else if (formatType === "inputMask") {
              return {
                mask: maskFormat,
              };
            }
          })()}
          // inputComponent={
          //     masking ? (maskCurrency || maskNumeric ? (NumberFormatCustom as any) : (TextMaskCustom as any)) : undefined
          //   }
          //   inputProps={
          //     masking
          //       ? {
          //           scale: maskDecimalScale,
          //           currency: maskCurrency,
          //           mask: inputMask,
          //           unmask: unMaskedValue,
          //           prefix: maskPrefix,
          //           suffix: maskSuffix,
          //           format,
          //           allowNegative: maskAllowNegative,
          //           thousandSeparator
          //         }
          //       : undefined
          //   }
        />
        {label && (
          <InputLabel
            sx={InputLableSx}
            className={clsx({
              "u-input-label": true,
              "text-capitalize": capitalizeLabel,
              "text-uppercase": uppercaseLabel,
            })}
            htmlFor={`outlined-adornment-${id}`}
          >
            {label}
          </InputLabel>
        )}
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </CustomInputWrapper>
  );
};
