import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const StepperProgressWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.stepper-progress-wrap": {
    backgroundColor: theme.palette.common.white,
    borderBottom: "1px solid" + theme.palette.divider,
    ".desktop-steps": {
      display: "flex",
      padding: "18px 40px 0px 40px",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "18px",
      alignSelf: "stretch",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    ".mobile-steps": {
      display: "none",
      gap: 24,
      padding: "20px 24px",
      ".steps-content": {
        display: "flex",
        gap: 12,
        flexGrow: 1,
        ".counter": {
          position: "relative",
          width: 40,
          height: 40,
          ".MuiTypography-root": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          },
        },
        ".title": {
          display: "flex",
          alignItems: "center",
          "&.title-with-subtitle": {
            display: "block",
          },
        },
      },
      ".MuiButtonBase-root": {
        "&.icon-only": {
          padding: 0,
        },
        "&.btn-default": {
          svg: {
            path: {
              stroke: theme.palette.grey[500],
            },
          },
          "&:hover": {
            svg: {
              path: {
                stroke: theme.palette.grey[500],
              },
            },
          },
        },
      },
      ".action-area": {
        display: "flex",
        gap: "24px",
        flexShrink: 0,
        ".btn-default": {
          "&:hover": {
            svg: {
              path: {
                stroke: theme.palette.grey[500],
              },
            },
          },
        },
      },
      [theme.breakpoints.down("md")]: {
        display: "flex",
      },
    },
    ul: {
      display: "flex",
      paddingLeft: 0,
      margin: 0,
      gap: 24,
      width: "100%",
      overflow: "auto",
      "::-webkit-scrollbar": {
        display: "none",
      },
      ".step-item": {
        position: "relative",
        listStyle: "none",
        display: "flex",
        gap: 24,
        width: "100%",
        paddingRight: 40,
        cursor: "pointer",
        ".content": {
          textDecoration: "none",
          color: theme.palette.grey[600],
          display: "flex",
          whiteSpace: "nowrap",
          gap: 4,
        },
        "&.active": {
          ".content": {
            color: "#080808",
            ".MuiTypography-root": {
              fontWeight: theme.customVariables.fontWeightSemiBold,
            },
          },
        },
        "&.disabled": {
          cursor: "not-allowed",
          opacity: 0.5,
        },
      },
    },
    ".MuiLinearProgress-root": {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      width: "100%",
      zIndex: 1201,
      top: 165,
    },
  },
}));
