import { MainContainer } from "@components/quotation/shared/controls";
import { QuotationTool } from "@components/new-quotation";
import {
  CalculationResults,
  QuotationForm,
  useCalculationsActionsContext,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types";

const FinanceDetails = ({
  calculations,
}: {
  calculations: CalculationResults;
}) => {
  const { setValue } = useFormContext<TProposalForm>();
  const { watch } = useQuotationFormContext<QuotationForm>();
  const { setCalculationResults } = useCalculationsActionsContext();
  useEffect(() => {
    if (calculations) {
      setCalculationResults(calculations);
    }
  }, []);

  const productType = watch("productType");

  useEffect(() => {
    setValue("proposalDetails.productType", productType);
  }, [productType]);

  return (
    <MainContainer>
      <QuotationTool />
    </MainContainer>
  );
};

export default FinanceDetails;
