import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material";
import DataTable from "@components/quotation/shared/controls/data-table.shared";
import { pounds } from "@helpers/utils";

interface IRepaymentPlan {
  rows: [];
}
export default function RepaymentPlan({ rows }: IRepaymentPlan) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const theme = useTheme();

  if (rows.length <= 0) return <div></div>;

  return (
    <>
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow>
              <TableCell>Payment Number</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell className="text-right">Opening Principal</TableCell>
              <TableCell className="text-right">Payment Amount</TableCell>
              <TableCell className="text-right">Principal</TableCell>
              <TableCell className="text-right">Interest</TableCell>
              <TableCell className="text-right">Periodic Interest</TableCell>
              <TableCell className="text-right">Closing Principal</TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {rows.map((row: any, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.content[0]}</TableCell>
                <TableCell>{row.content[1]}</TableCell>
                <TableCell className="text-right">
                  {pounds.format(row.content[2])}
                </TableCell>
                <TableCell className="text-right">
                  {`£${row.content[3]}`}
                </TableCell>
                <TableCell className="text-right">
                  {pounds.format(row.content[4])}
                </TableCell>
                <TableCell className="text-right">
                  {pounds.format(row.content[5])}
                </TableCell>
                <TableCell className="text-right">
                  {pounds.format(row.content[6])}
                </TableCell>
                <TableCell className="text-right">
                  {pounds.format(row.content[7])}
                </TableCell>
              </TableRow>
            ))}
          </>
        }
      />
      {/* <TableContainer sx={{ maxHeight: 440 }} component={Paper}>
                    <Table sx={{ minWidth: 450 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Payment Number</TableCell>
                                <TableCell align="right">Due Date</TableCell>
                                <TableCell align="right">Opening Principal</TableCell>
                                <TableCell align="right">Payment Amount</TableCell>
                                <TableCell align="right">Principal</TableCell>
                                <TableCell align="right">Interest</TableCell>
                                <TableCell align="right">Periodic Interest</TableCell>
                                <TableCell align="right">Closing Principal</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row: any, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{row.content[0]}</TableCell>
                                    <TableCell align="right">{row.content[1]}</TableCell>
                                    <TableCell align="right">{row.content[2]}</TableCell>
                                    <TableCell align="right">{row.content[3]}</TableCell>
                                    <TableCell align="right">{row.content[4]}</TableCell>
                                    <TableCell align="right">{row.content[5]}</TableCell>
                                    <TableCell align="right">{row.content[6]}</TableCell>
                                    <TableCell align="right">{row.content[7]}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer> */}
    </>
  );
}
