import { post } from "@flexCommon/api";

const baseUrl = `${process.env.FLEX_REPORTING_BASE_URL}`;

const getReport = async (
  url: string,
  requestBody: any,
  fileName: string,
  checkWord: boolean = false,
  checkPdf: boolean = false
) => {
  try {
    const response: any = await post(baseUrl + url, requestBody);

    const res: any = await getPublicUrl(response.key);

    const link = document.createElement("a");
    link.href = res;
    link.setAttribute(
      "download",
      `${fileName}_${new Date()}` + `${checkPdf ? ".pdf" : ".docx"}`
    );
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  } catch (error) {
    throw error;
  }
};

const getPublicUrl = async (key: string) => {
  let response = undefined;
  await post(baseUrl + "/documents/configuration/generate-presigned-url", {
    key,
  })
    .then((res: any) => {
      response = res.template_url;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

export { getReport };
