import { styled } from "@mui/material";
import ChevronDown from "@components/quotation/shared/assets/chevron-down.svg";

export const CustomDropdown = styled("div")(({ theme }) => ({
  "&.dropDown-with-label": {
    display: "flex",
    justifyContent: "flex-end",
    gap: 4,
    "&.with-checkbox": {
      alignItems: "center",
      ".MuiCheckbox-root": {
        padding: 0,
      },
    },
    ".u-form-group": {
      ".MuiFormControl-root": {
        ".MuiInputBase-root": {
          height: "auto",
          ".MuiPopover-root": {
            left: -100,
            width: 275,
            ".MuiPaper-root": {
              top: "45px !important",
            },
          },
        },
        ".u-select": {
          ".MuiSelect-select": {
            padding: "0 !important",
            float: "left",
            width: "auto",
            color: theme.palette.common.black,
            fontSize: theme.typography.fontSize,
            fontWeight: 600,
            "&:after": {
              backgroundImage: `url(${ChevronDown})`,
              backgroundRepeat: "no-repeat",
              float: "right",
              display: "block",
              content: '" "',
              width: 24,
              height: 24,
            },
            "&[aria-expanded=true]": {
              "&:after": {
                transform: "scaleY(-1)",
              },
            },
          },
          ".MuiSelect-icon": {
            float: "left",
            position: "relative",
            display: "none",
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },

          // "&.Mui-focused": {
          //     ".MuiSelect-select": {
          //         "&:after": {
          //             transform: "scaleY(-1)"
          //         }
          //     }
          // }
        },
      },
    },
  },

  "@media screen and (max-width: 599px)": {
    "&.dropDown-with-label": {
      justifyContent: "flex-start",
    },
  },
}));
