import { LayoutWithSideNavComponent, QuotationPageContent } from "@components";
import { useTheme } from "@mui/material";
import {
  ConfigurationContextProvider,
  CalculationsContextProvider,
} from "@ntpkunity/controls-common";

export const NewQuotationPage = () => {
  const theme = useTheme();
  return (
    <LayoutWithSideNavComponent theme={theme}>
      <ConfigurationContextProvider>
        <CalculationsContextProvider>
          <QuotationPageContent />
        </CalculationsContextProvider>
      </ConfigurationContextProvider>
    </LayoutWithSideNavComponent>
  );
};
