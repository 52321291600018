import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PostCodeDialogContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.post-code-dialog-content": {
    ".post-code-list-wrap": {
      ".post-code-list": {
        ".custom-checkbox-wrap": {
          ".u-custom-control-label": {
            margin: "24px 0 0",
          },
        },
      },
    },
  },
}));
