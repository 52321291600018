import { updateQuotationTwo } from "@services/dms.service";
import { useMutation, useQueryClient } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";

export const useUpdateQuotation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateQuotationTwo,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.QUOTATION_BY_VENDOR],
      });
    },
  });
};
