import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const CommentListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.comment-list-wrap": {
    ".comment-item": {
      borderRadius: 8,
      padding: "16px 24px",
      marginTop: 16,
      border: "1px solid" + theme.palette.divider,
      ".header": {
        display: "flex",
        alignItems: "center",
        gap: 24,
        ".user-name": {
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          ".avatar": {
            backgroundColor: theme.palette.grey[600],
            fontSize: theme.typography.caption.fontSize,
            lineHeight: theme.typography.subtitle2.lineHeight,
            fontWeight: theme.customVariables.fontWeightSemiBold,
            marginRight: 16,
            width: 32,
            height: 32,
            fontFamily: theme.typography.fontFamily,
          },
          ".avatar-text": {
            ".subtitle": {
              display: "flex",
              alignItems: "center",
              gap: 8,
              ".time": {
                borderLeft: "1px solid" + theme.palette.grey[100],
                paddingLeft: 8,
              },
            },
          },
        },
        ".action-area": {
          flexShrink: 0,
          ".btn": {
            "&.icon-only": {
              padding: 0,
            },
          },
        },
      },
    },
  },
}));
