import { Controller, useFormContext } from "react-hook-form";
import { DashboardForm, TProposalSearchParams } from "@_types";
import { useTheme } from "@mui/material";
import { Select } from "@ntpkunity/controls";
import { DASHBOARD_RANGE_FILTER_VALUES } from "@helpers/const";
import { DashboardRangeFilter } from "@helpers/enum";
import {
  convertIsoDateStrToddmmyyyy,
  convertIsoDateStrToddmmyyyyhms,
  deleteNDays,
} from "@helpers/utils";

export const RangeFilter = ({
  name,
  searchParams,
  setSearchParams,
}: {
  name: any;
  searchParams;
  setSearchParams: React.Dispatch<React.SetStateAction<TProposalSearchParams>>;
}) => {
  const { control } = useFormContext<DashboardForm>();
  const theme = useTheme();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          theme={theme}
          disablePortal={false}
          items={DASHBOARD_RANGE_FILTER_VALUES}
          {...field}
          onChange={(e) => {
            if (e.target.value == DashboardRangeFilter.WEEK) {
              setSearchParams({
                ...searchParams,
                updated_at_from: convertIsoDateStrToddmmyyyyhms(
                  deleteNDays(7).toISOString()
                ),
                updated_at_to: convertIsoDateStrToddmmyyyyhms(
                  new Date().toISOString()
                ),
              });
            } else if (e.target.value == DashboardRangeFilter.MONTH) {
              setSearchParams({
                ...searchParams,
                updated_at_from: convertIsoDateStrToddmmyyyyhms(
                  deleteNDays(30).toISOString()
                ),
                updated_at_to: convertIsoDateStrToddmmyyyyhms(
                  new Date().toISOString()
                ),
              });
            } else if (e.target.value == DashboardRangeFilter.QUARTER) {
              setSearchParams({
                ...searchParams,
                updated_at_from: convertIsoDateStrToddmmyyyyhms(
                  deleteNDays(90).toISOString()
                ),
                updated_at_to: convertIsoDateStrToddmmyyyyhms(
                  new Date().toISOString()
                ),
              });
            } else if (e.target.value == DashboardRangeFilter.CALENDER_YEAR) {
              const today = new Date();
              const lastMonth = new Date(
                today.getFullYear() - 1,
                today.getMonth(),
                today.getDate()
              );
              setSearchParams({
                ...searchParams,
                updated_at_from: convertIsoDateStrToddmmyyyyhms(
                  lastMonth.toISOString()
                ),
                updated_at_to: convertIsoDateStrToddmmyyyyhms(
                  today.toISOString()
                ),
              });
            }
            field.onChange(e);
          }}
          value={field.value}
        />
      )}
    />
  );
};
