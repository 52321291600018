import axios from "axios";

type IQueryParam = {
  Key: string;
  Value: string | number | boolean;
};

const post = async (
  url: string,
  requestBody: any,
  queryParams?: IQueryParam[]
) => {
  let response = undefined;

  let search = window.location.search;
  let params = new URLSearchParams(search);
  const headers: any = {};
  let apiKey = params.get("x-api-key");
  if (apiKey != null) {
    headers["x-api-key"] = apiKey;
  }
  var settings: any = localStorage.getItem("settings");
  if (settings != null) {
    headers["Authorization"] = "Bearer " + JSON.parse(settings).accessToken;
  }
  const newException = (title: string, message: string) => {
    return {
      response: {
        data: {
          Title: title,
          Message: message,
        },
      },
    };
  };

  var _queryParams: any = {};
  queryParams?.map((_qp) => {
    _queryParams[_qp.Key] = _qp.Value;
  });

  await axios
    .post(url, requestBody, {
      params: _queryParams,
      headers: headers,
    })
    .then((res) => {
      if (res.data.status_code !== undefined) {
        throw newException("Unauthorized", res.data.message);
      } else {
        response = res.data;
      }
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const get = async (url: string, queryParams?: IQueryParam[]) => {
  let response = undefined;
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const headers: any = {};
  let apiKey = params.get("x-api-key");
  if (apiKey != null) {
    headers["x-api-key"] = apiKey;
  }
  var settings: any = localStorage.getItem("settings");
  if (settings != null) {
    headers["Authorization"] = "Bearer " + JSON.parse(settings).accessToken;
  }
  var _queryParams: any = {};
  queryParams?.map((_qp) => {
    _queryParams[_qp.Key] = _qp.Value;
  });

  await axios
    .get(url, {
      params: _queryParams,
      headers: headers,
    })
    .then((res) => {
      response = res.data;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const put = async (url: string) => {
  let response = undefined;

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let apiKey = params.get("x-api-key");

  await axios
    .put(url, {
      headers: {
        "x-api-key": apiKey,
      },
    })
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};
export { post, get, put };
