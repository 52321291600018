import { Box } from "@ntpkunity/controls";
import {
  QuotationForm,
  QuotationRatesContent,
  QuotationResultContent,
  QuotationToolWrap,
  useCalculationsContext,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import { useTheme } from "@mui/material";

type QuotationResultsProps = {
  headerText: string;
  isSelected: boolean;
  handleSelectQuotation: () => void;
};

export const QuotationResults = ({
  headerText,
  isSelected,
  handleSelectQuotation,
}: QuotationResultsProps) => {
  const theme = useTheme();
  const { watch } = useQuotationFormContext<QuotationForm>();
  const { data: calculations, inProgress } = useCalculationsContext();
  return (
    <Box theme={theme} className="comparison-box">
      <QuotationToolWrap theme={theme} className="quotation-tool-wrap">
        <Box theme={theme} pl={3} pt={3} pb={3}>
          <QuotationResultContent
            theme={theme}
            headerText={headerText}
            categoryText={watch("productType")}
            buttonText={isSelected ? "Selected" : "Select"}
            isSelected={isSelected}
            onClick={handleSelectQuotation}
          />
          <Box theme={theme} className="divider" pt={1} mt={1}>
            <QuotationRatesContent
              theme={theme}
              isLoading={inProgress}
              {...calculations.rates}
            />
          </Box>
        </Box>
      </QuotationToolWrap>
    </Box>
  );
};
