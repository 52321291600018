import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const ViewDocumentDialogWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.view-document-dialog-wrap": {
    ".text-truncated": {
      display: "block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
    },
    ".file-preview": {
      marginTop: 8,
    },
  },
}));
