import { useTheme } from "@mui/material";
import { FinancialConfigFieldsWrap } from "./config-fields.style";
import { Box, Grid, Select, Typography } from "@ntpkunity/controls";
import { FinancialConfigurationFee } from "@components";

export const FinancialConfigurationFields = () => {
  const theme = useTheme();
  return (
    <FinancialConfigFieldsWrap
      theme={theme}
      className="financial-config-fields-wrap"
    >
      <Grid theme={theme} container item rowSpacing={3} columnSpacing={2}>
        <Grid theme={theme} item xs={12} md={4}>
          <Select
            theme={theme}
            fullWidth
            label="Amortization Method"
            items={[
              {
                text: "Annuity Actual /360",
                value: 0,
              },
            ]}
            value={0}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Select
            theme={theme}
            fullWidth
            label="APR Calculation method"
            items={[
              {
                text: "Annuity Actual /360",
                value: 0,
              },
            ]}
            value={0}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Select
            theme={theme}
            fullWidth
            label="Initial Cash Outflow for Flat Rate"
            items={[
              {
                text: "Including VAT & Commission",
                value: 0,
              },
            ]}
            value={0}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Select
            theme={theme}
            fullWidth
            label="Commission Calculation Method"
            items={[
              {
                text: "%age of Finance Amount",
                value: 0,
              },
            ]}
            value={0}
          />
        </Grid>
        <Grid theme={theme} item xs={12} md={4}>
          <Select
            theme={theme}
            fullWidth
            label="Commission Pass to Customers"
            items={[
              {
                text: "Yes",
                value: 0,
              },
            ]}
            value={0}
          />
        </Grid>
      </Grid>
      <Box theme={theme} mt={3}>
        <Typography
          theme={theme}
          variant="caption"
          component={"a"}
          className="fw-medium text-primary link"
          onClick={() => {}}
        >
          Settings
        </Typography>
      </Box>
      <Box theme={theme} className="fee-container">
        <FinancialConfigurationFee />
      </Box>
    </FinancialConfigFieldsWrap>
  );
};
