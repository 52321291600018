import "react";
import { PageHeader } from "@components/page-header";
import { Button } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { useGetSaveProposalPayload } from "@hooks/proposal";
import { useSaveProposal, useUpdateProposal } from "@hooks/mutations";
import { useNavigate, useParams } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import { useSnackbarContext } from "@contexts/snackbar";
import { OrderStatus, WorkQueueTabs, SnackbarVariants } from "@helpers/enum";
import { PageHeaderProps } from "./page-header.props";
import { CustomerProposalPage } from "@pages";

const ProposalPageHeader = ({
  showActionBtn = true,
  isLastStep,
}: PageHeaderProps) => {
  const params = useParams<{ proposalId?: string }>();
  const isEditMode = !!params.proposalId;
  const navigate = useNavigate();
  const theme = useTheme();

  const { mutateAsync: saveProposal } = useSaveProposal();
  const { mutateAsync: updateProposal } = useUpdateProposal();
  const { setSnackbar } = useSnackbarContext();
  const { getPayload, validateProposalPayload } = useGetSaveProposalPayload(
    params.proposalId
  );

  const mutateProposal = isEditMode ? updateProposal : saveProposal;

  const handleSaveProposal = async () => {
    const payload = getPayload(OrderStatus.DRAFT);
    if (validateProposalPayload(payload)) {
      try {
        setSnackbar({ open: true, message: "Saving Proposal" });
        await mutateProposal(payload);
        navigate(`${APP_ROUTES.WORKQUEUE}/${WorkQueueTabs.DRAFT_PROPOSALS}`);
      } catch (err) {
        setSnackbar({
          open: true,
          message: "Error Occurred while saving Proposal",
          variant: SnackbarVariants.ERROR,
        });
      }
    }
  };
  const handleNavigateToDiary = () => {
    if (params.proposalId) {
      navigate(`${APP_ROUTES.DIARY}/${params.proposalId}`);
    } else {
      navigate(APP_ROUTES.DIARY);
    }
  };
  return (
    <PageHeader
      title={false}
      hasInput
      inputPlaceholder="Enter Proposal Name..."
      inputName="proposalDetails.name"
      actionArea={
        showActionBtn && (
          <>
            <Button
              theme={theme}
              type="button"
              text="Save Proposal"
              secondary
              onClick={handleSaveProposal}
            />
            <Button
              theme={theme}
              type="button"
              text="Diary"
              secondary
              onClick={handleNavigateToDiary}
            />
            {isLastStep && (
              <Button
                theme={theme}
                type="button"
                text="Send To Customer"
                primary
                onClick={() =>
                  window.open(APP_ROUTES.CUSTOMER_PROPOSAL, "_blank")
                }
              />
            )}
          </>
        )
      }
    />
  );
};

export default ProposalPageHeader;
