import { TableCell, TableRow, useTheme } from "@mui/material";
import { Button, Icon, Menu } from "@ntpkunity/controls";
import { TableRowOptions } from "@helpers/enum";

const DirectorTableRow = () => {
  const theme = useTheme();

  function handleOptionClick(e: any, key?: any, value?: any): void {}

  return (
    <TableRow className="child-tr">
      <TableCell>Sample Name</TableCell>
      <TableCell>sample@sample.com</TableCell>
      <TableCell>000-000-0000</TableCell>
      <TableCell>Acacia Avenue, Silhurst, SH15 6BP</TableCell>
      <TableCell className="action-cell fixed-cell">
        <Menu
          theme={theme}
          options={[
            {
              optionText: "Edit",
              optionkey: TableRowOptions.VIEW_EDIT,
            },
            {
              optionText: "Delete",
              optionkey: TableRowOptions.DELETE,
            },
          ]}
          handleOptionClick={handleOptionClick}
          render={(cb) => (
            <Button
              defaultBtn
              theme={theme}
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            ></Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default DirectorTableRow;
