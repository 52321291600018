import {
  LayoutWithSideNavComponent,
  ComparisonTool,
  Snackbar,
} from "@components";
import { ConfigurationContextProvider } from "@ntpkunity/controls-common";
import { useTheme } from "@mui/material";

export const QuoteComparisonPage = () => {
  const theme = useTheme();
  return (
    <LayoutWithSideNavComponent theme={theme}>
      <ConfigurationContextProvider>
        <ComparisonTool />
        <Snackbar />
      </ConfigurationContextProvider>
    </LayoutWithSideNavComponent>
  );
};
