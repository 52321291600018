import { LayoutWithSideNavComponent, Quotation } from "@components";
import { useTheme } from "@mui/material";
import { Provider } from "react-redux";
import store from "@store";
import { useEffect } from "react";
import { resetFinancialCalculation } from "@store/actions/financialCalculation.actions";
import { PageContent } from "./quotation-page.style";
import QuotationPageHeader from "@components/quotation/page-header/page-header.component";
import { QuotationFiltersHeader } from "@components/quotation/quotation-header/quotation-header.component";
import { Box } from "@ntpkunity/controls";

export const QuotationPage = () => {
  const theme = useTheme();

  useEffect(() => {
    return () => {
      store.dispatch(resetFinancialCalculation());
    };
  }, []);

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <Provider store={store}>
        <QuotationPageHeader />
        <QuotationFiltersHeader />
        <PageContent theme={theme} className="page-content">
          <Box
            theme={theme}
            className="quotation-tool-container"
            m={{ xs: 0, md: "16px -24px -8px -24px" }}
          >
            <Quotation />
          </Box>
        </PageContent>
      </Provider>
    </LayoutWithSideNavComponent>
  );
};
