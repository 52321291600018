import React from "react";
import { useTheme } from "@mui/material";
import { AddCommentsWrap } from "./add-comment.style";
import { Stack } from "@components/stack";
import {
  Box,
  Button,
  Input,
  Select,
  Textarea,
  Typography,
} from "@ntpkunity/controls";

export const AddComments = () => {
  const theme = useTheme();
  return (
    <AddCommentsWrap theme={theme} className="add-comments-wrap">
      <Stack
        paddingMd={3}
        paddingXs={3}
        title="Add Comments"
        actionArea={
          <>
            <Box theme={theme} className="custom-dropdown">
              <Typography
                theme={theme}
                className="text-muted"
                variant="body2"
                component={"span"}
                display={"block"}
              >
                Catagory:
              </Typography>
              <Select
                theme={theme}
                disablePortal={false}
                items={[
                  {
                    text: "All",
                    value: "0",
                  },
                  {
                    text: "General",
                    value: "1",
                  },
                  {
                    text: "Underwriting",
                    value: "2",
                  },
                ]}
                value={"0"}
              />
            </Box>
          </>
        }
      >
        <Box theme={theme} mt={3}>
          <Textarea
            theme={theme}
            placeholder="Type here..."
            label="Add a Comment"
            fullWidth
            type={"text"}
            id={""}
            rows={1}
          />
          <Typography
            mt={0.5}
            theme={theme}
            variant="caption"
            component={"small"}
            display={"block"}
            className="word-count text-secondary"
            textAlign={"right"}
          >
            10/60
          </Typography>
          <Box theme={theme} mt={2}>
            <Button
              theme={theme}
              type="button"
              text="Post Comment"
              primary
              onClick={() => {}}
              className="w-m-100"
            />
          </Box>
        </Box>
      </Stack>
    </AddCommentsWrap>
  );
};
