import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@styles";

export const BannerWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.banner-wrap": {
    textAlign: "center",
    maxWidth: 600,
    margin: "auto",
    img: {
      height: 160,
    },
    ".actions": {
      display: "flex",
      justifyContent: "center",
      gap: 16,
    },
  },
}));
