import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  useTheme,
  styled,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  Button,
  DatePicker,
  Dialog,
  Divider,
  Dropdown,
  List,
  MenuList,
  Scroll,
  TextBox,
} from "@components/quotation/shared/controls";
import { Rentals } from "@components/quotation/rentals/rentals.component";
import { RatesCard } from "@components/quotation/create-quotation/create-quotation.component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/reducers";
import DataTable from "@components/quotation/shared/controls/data-table.shared";
import {
  PaymentFrequencies,
  VATTreatments,
  exportDetail,
} from "@flexCommon/constants";
import {
  CommissionType,
  Currency,
  HaydockTemplateName,
  SolveFor,
  StepType,
} from "@flexCommon/enums";
import {
  setCustomerDetail,
  setNotification,
  setResetValues,
  setRetrieveDetail,
  setRetrivedData,
} from "@store/actions/financialCalculation.actions";
import {
  GetAllConfigurationDms,
  GetConfigurationDms,
  SaveConfigurationDms,
} from "@services/dms.service";
import { ValidateSaveConfiguration } from "@validations/validate-save-configuration";
import { NotificationSnackbar } from "@components/quotation/snackbar/snackbar.component";
import { CustomerDetail } from "@interfaces/requestparams.flex";
import { IReducerFinancialCalculation } from "@store/reducers/financialCalculation.reducer";
import { APP_ROUTES } from "@helpers";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ClassNames, jsx } from "@emotion/react";
import Icon from "@components/quotation/shared/assets/Icon";
import { Link } from "@mui/icons-material";
import MenuButton from "@components/quotation/shared/controls/menu-button.shared";
import { ResetData } from "@data/reset.data";
import { GenerateHaydockTemplate } from "@templates/haydock.template";
import { generateQuotation } from "@templates/quotation-summary.template";
import { AxiosError } from "axios";
import { ValidateRetrieveQuotation } from "@validations/validate-retrieve-quotation";

export const SectionWrap: any = styled("div")(({ theme }) => ({
  ".MuiTabs-root": {
    padding: "0 24px 0 24px",
    ".MuiTabs-flexContainer": {},
    [theme.breakpoints.down("md")]: {
      padding: "24px 24px 0 24px",
    },
  },

  ".btn-mbl": {
    display: "none",
  },
  ".btn-desktop": {
    display: "block",
    ".btn": {
      marginRight: 24,
      float: "right",
      "&:first-child": {
        marginRight: 0,
      },
    },
  },
  ".heading-with-icon": {
    ".title": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
    // button: {
    //     padding: 0
    // },
    ".btn-back": {
      padding: "13px 12px 13px 0 !important",
    },
    ".btn.with-icon": {
      svg: {
        height: "20px !important",
      },
    },
  },
  "&.setting": {
    ".input-fields-text": {
      margin: 0,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.subtitle1.fontWeight,
      lineHeight: theme.typography.subtitle1.lineHeight,
    },
  },

  [theme.breakpoints.down("md")]: {
    ".btn-mbl": {
      display: "block",
    },
    ".btn-desktop": {
      display: "none",
    },
    "&.setting": {
      ".input-fields-text": {
        marginBottom: 22,
      },
    },
    "&.actions": {
      ".MuiButtonBase-root": {
        widht: "100%",
      },
    },
    // ".MuiTabs-root": {
    //     padding: "0 24px 0 24px",
    //     ".MuiTabs-flexContainer" :{
    //         overflow: "auto",
    //       ".MuiButtonBase-root": {
    //           width: "auto !important",
    //       }
    //     },
    //     [theme.breakpoints.down('md')]: {
    //       padding: "24px 24px 0 24px",
    //     }
    //   },
  },
}));

const PaymentSummary = styled("div")(({ theme }) => ({
  ".results, .card-rates": {
    ".card-header": {
      display: "none",
    },
    ".card-body": {
      borderTop: "0 solid transparent",
      ".scroll.h-lg-b, .scroll.h-md": {
        overflow: "initial",
        height: "initial",
        padding: 0,
      },
      ".footer-buttons": {
        display: "none",
      },
    },
  },
  ".card-rates": {
    ".card-body": {
      ul: {
        li: {
          padding: 0,
          margin: "0 0 16px 0",
          ".right": {
            fontWeight: theme.customVariables.fontWeightSemiBold,
          },
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&:last-child": {
            margin: 0,
          },
        },
      },
    },
  },
  ul: {
    li: {
      ".right": {
        fontWeight: theme.customVariables.fontWeightSemiBold,
      },
      ".editable .u-form-group .MuiFormControl-root .u-form-control.Mui-disabled":
        {
          cursor: "text",
          color: theme.palette.grey[900],
          ".MuiInputBase-input.Mui-disabled": {
            cursor: "text",
            color: theme.palette.grey[900],
            textFillColor: theme.palette.grey[900],
            padding: 0,
          },
          "& ~ .MuiFormHelperText-root": {
            marginTop: 0,
          },
        },
    },
  },
  ".border-left": {
    borderLeft: "1px solid" + theme.palette.divider,

    "&.with-pl": {
      paddingLeft: 24,
    },

    [theme.breakpoints.down("sm")]: {
      borderLeft: "0 solid transparent",
      borderTop: "1px solid" + theme.palette.divider,
      "&.with-pl": {
        paddingLeft: 0,
        paddingTop: 24,
      },
    },
  },
}));

const DataTableWrap = styled("div")(({ theme }) => ({
  ".u-table-wrap .u-table-container": {
    maxHeight: "calc(100vh - 364px) !important",
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "0 1px 0 0",
    },
    "&::-webkit-scrollbar-track": {
      marginTop: 43,
      borderRadius: "0 3px 0 0",
      backgroundColor: "#474747",
    },
  },
  ".scroll": {
    width: "100%",
    position: "relative",
    ".scroll-hide": {
      backgroundColor: theme.palette.common.white,
      borderBottom: "1px solid" + theme.palette.divider,
      position: "absolute",
      width: 7,
      height: 43,
      right: 1,
      top: 1,
      display: "block",
      borderRadius: "0 8px 0 0",

      "&.spr-border": {
        "&::after": {
          display: "inline-block",
          content: `''`,
          backgroundColor: theme.palette.divider,
          width: 7,
          height: 1,
          position: "absolute",
          top: "49%",
        },
        "&.chart-header": {
          fontWeight: theme.customVariables.fontWeightSemiBold,
        },
      },

      "&.sh-fixed-cell": {
        height: 100.5,
        top: 0.5,
      },
    },
  },
}));

const normalizeInput = (value: any, previousValue: any) => {
  // return nothing if no value
  if (!value) return value;
  if (value.charAt(0) == "+" && value.length == 4) return "";
  if (value.charAt(0) == "+") {
    value = value.slice(4);
  }
  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (previousValue.charAt(0) == "+") {
    previousValue = previousValue.slice(4);
  }
  const preValue = previousValue?.replace(/[^\d]/g, "");
  const preValueLength = preValue?.length;

  if (!previousValue || value.length > preValueLength) {
    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return "+44 " + currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7)
      return "+44 " + `${currentValue.slice(0, 3)} ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return (
      "+44 " +
      `${currentValue.slice(0, 3)} ${currentValue.slice(
        3,
        6
      )} ${currentValue.slice(6, 10)}`
    );
  }
  if (value.length == preValueLength) {
    if (cvLength < 4) return "+44 " + currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7)
      return "+44 " + `${currentValue.slice(0, 3)} ${currentValue.slice(3)}`;
  }
  if (value.length < preValueLength) {
    if (cvLength < 4) return "+44 " + currentValue;
  }
};

export const PrintQuote = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [row, setRow] = useState<CustomerDetail[]>([]);
  const navigate = useNavigate();
  const storeState = useSelector(
    (state: RootState) => state.reducerFinancialCalculation
  );
  const getTable = () => {
    return (
      <DataTableWrap>
        <Box className="scroll">
          <Box className="scroll-hide" />
        </Box>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                <TableCell
                  sx={{
                    maxWidth: "80px !important",
                    minWidth: "80px !important",
                  }}
                >
                  Sr. No.
                </TableCell>
                <TableCell>Quotation Date</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Customer Email Address</TableCell>
                <TableCell>Vendor Name</TableCell>
                <TableCell>Vendor Email Address</TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {row.map((row: any, index) => (
                <TableRow
                  className="child-tr onclick"
                  key={index}
                  sx={{
                    maxWidth: "80px !important",
                    minWidth: "80px !important",
                  }}
                  onClick={() => {
                    GetConfigurationDms(row.Identifier).then((r) => {
                      saveRetrieveDataToStore(r);
                    });
                  }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.QuotationDate}</TableCell>
                  <TableCell>{row.CustomerFullName}</TableCell>
                  <TableCell>{row.CustomerEmailAddress}</TableCell>
                  <TableCell>{row.VendorPersonName}</TableCell>
                  <TableCell>{row.VendorEmailAddress}</TableCell>
                </TableRow>
              ))}
            </>
          }
        />
      </DataTableWrap>
    );
  };
  const saveRetrieveDataToStore = (Data: any) => {
    const state: IReducerFinancialCalculation = {
      ...storeState,
      CalculatedCommission: Data.commission_amount,
      CalculatedUpfrontPayment: Data.due_at_signing,
      CalculatedNFA: Data.finance_amount,
      CustomerDetail: {
        CustomerFullName: Data.customer.first_name,
        CustomerEmailAddress: Data.customer.email,
        CustomerContactNumber: Data.customer.mobile_number,
        VendorPersonName: Data.introducer_name,
        VendorEmailAddress: Data.introducer_identifier,
        TrackingID: Data.reference_number,
        Comments: Data.comments,
      },
      RequestObj: {
        ...storeState.RequestObj,
        AssetCost: Data.selling_price,
        RegularPayments: Data.no_of_regular_payments,
        AdvancePayments: Data.no_of_advance_payments,
        StartDate: Data.start_date,
        PaymentMode: Data.no_of_advance_payments == 0 ? "Arrear" : "Advance",
        Frequency: "Monthly",
        Rate: 0,
        toggle: "scroll h-md",
        DepositAmount: Data.down_payment,
        Deposit: Data.down_payment,
        ResidualValue: Data.rv_balloon_value,
        BalloonPayment: Data.rv_balloon_value,
        BalloonCollection: 0,
        VAT: {
          Amount: Data.deferral_amount,
          Treatment: Data.vat_treatment,
          PaymentNumber: 0,
          type: storeState.RequestObj.VAT.type,
        },
        CommissionPercentage: 0,
        StructureRentals: [],
        CommissionAmount: 0,
        ExtensionDays: 0,
        Fee: [],
        RateType: "",
        HasStructureRentals: false,
        CommissionType: "",
        DepositType: CommissionType.Amount,
        PeriodicPayment: 0,
        FundingCost: 0,
        FinanceType: Data.finance_type,
        hideFlatRateExcl: false,
        Step: {
          Frequency: 0,
          Percentage: 0,
          Type: StepType.StepUp,
        },
        StepPaymentStructure: [],
        hasStepPaymentStructure: false,
        hasStep: false,
        IsAutoCalculated: false,
        Tax: 0,
        IrregularPaymentsApplicable: true,
        VATAmountAutoCalculateApplicable: false,
      },
      GrossProfit: Data.gross_profit,
      ResponseObj: {
        rentals: [],
        repaymentPlan: [],
        rates: {
          grossYield: Data.meta_data.gross_yield,
          netYield: Data.meta_data.net_yield,
          aprExclCommission: Data.meta_data.apr,
          aprInclCommission: Data.meta_data.apr,
          flatRateExclCommission: Data.meta_data.flat_rate_commission_exc,
          flatRateInclCommission: Data.meta_data.flat_rate_commission_inc,
        },
        repaymentPlanWithFee: [],
      },
    };
    Data.order_payments?.map((r: any) => {
      state.ResponseObj.rentals.push({
        startTerm: r.payment_number_from,
        endTerm: r.payment_number_to,
        rentalAmount: r.amount,
        rentalType: r.payment_type,
      });
    });
    Data.order_fees?.map((r: any) => {
      state.RequestObj.Fee.push({
        Name: r.dealer_fee_name,
        Frequency: "",
        Amount: r.applied_price,
        IsEditable: false,
      });
    });
    dispatch(setResetValues(state));
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let apiKey = params.get("x-api-key");
    if (apiKey != null) {
      navigate({
        pathname: APP_ROUTES.DASHBOARD, //TODO: Retrieve Quotation Route
        search: `${"?x-api-key="}${apiKey}`,
      });
    } else {
      navigate(APP_ROUTES.DASHBOARD); //TODO: Retrieve Quotation Route
    }
  };
  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={2} sx={{ mb: 3 }}>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <TextBox
            fullWidth
            testid="txtRefrenceNumber"
            label={"Refrence Number"}
            value={storeState.RetrieveDetail.TrackingID}
            placeHolder="Sample Name"
            type="text"
            onChange={(e) => {
              dispatch(
                setRetrieveDetail({
                  ...storeState.RetrieveDetail,
                  TrackingID: e.target.value,
                })
              );
            }}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <TextBox
            fullWidth
            testid="txtCustomerFullName"
            label={"Customer Full Name"}
            value={storeState.RetrieveDetail.CustomerFullName}
            placeHolder="Sample Name"
            type="text"
            onChange={(e) => {
              dispatch(
                setRetrieveDetail({
                  ...storeState.RetrieveDetail,
                  CustomerFullName: e.target.value,
                })
              );
            }}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <TextBox
            fullWidth
            testid="txtCustomerEmailAddress"
            label={"Customer Email Address"}
            value={storeState.RetrieveDetail.CustomerEmailAddress}
            placeHolder="Sample@sample.com"
            type="email"
            onChange={(e) => {
              dispatch(
                setRetrieveDetail({
                  ...storeState.RetrieveDetail,
                  CustomerEmailAddress: e.target.value,
                })
              );
            }}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <TextBox
            fullWidth
            testid="txtCustomerContactNumber"
            label={"Customer Contact Number"}
            value={storeState.RetrieveDetail.CustomerContactNumber}
            placeHolder="+44 000 000 0000"
            onChange={(e) => {
              dispatch(
                setRetrieveDetail({
                  ...storeState.RetrieveDetail,
                  CustomerContactNumber: normalizeInput(
                    e.target.value,
                    storeState.CustomerDetail.CustomerContactNumber
                  ),
                })
              );
            }}
            type="text"
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <TextBox
            fullWidth
            testid="txtIntroducerName"
            label={"Introducer Name"}
            value={storeState.RetrieveDetail.VendorPersonName}
            placeHolder="Sample Name"
            type="text"
            onChange={(e) => {
              dispatch(
                setRetrieveDetail({
                  ...storeState.RetrieveDetail,
                  VendorPersonName: e.target.value,
                })
              );
            }}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <TextBox
            fullWidth
            testid="txtIntroducerEmailAddress"
            label={"Introducer Email Address"}
            value={storeState.RetrieveDetail.VendorEmailAddress}
            placeHolder="sample@sample.com"
            type="text"
            onChange={(e) => {
              dispatch(
                setRetrieveDetail({
                  ...storeState.RetrieveDetail,
                  VendorEmailAddress: e.target.value,
                })
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            primary
            disabled={loading}
            fullWidth
            text="Search"
            onClick={() => {
              setLoading(true);
              var validate = ValidateRetrieveQuotation(
                storeState.RetrieveDetail
              );
              if (validate?.visible) {
                dispatch(
                  setNotification({
                    message: validate.error,
                    visible: validate.visible,
                  })
                );
                setLoading(false);
              } else {
                GetAllConfigurationDms(storeState.RetrieveDetail)
                  .then((r: any) => {
                    if (r.length == 0) {
                      setLoading(false);
                      dispatch(
                        setNotification({
                          message: "Detail Not Found.",
                          visible: true,
                        })
                      );
                    } else {
                      var response: CustomerDetail[] = [];
                      r.map((rr: any) => {
                        response.push({
                          Identifier: rr.identifier,
                          CustomerFullName:
                            rr.customer.first_name == undefined
                              ? ""
                              : rr.customer.first_name,
                          CustomerEmailAddress:
                            rr.customer.email == undefined
                              ? ""
                              : rr.customer.email,
                          CustomerContactNumber:
                            rr.customer.mobile_number == undefined
                              ? ""
                              : rr.customer.mobile_number,
                          VendorPersonName:
                            rr.introducer_name == undefined
                              ? ""
                              : rr.introducer_name,
                          VendorEmailAddress:
                            rr.introducer_identifier == undefined
                              ? ""
                              : rr.introducer_identifier,
                          QuotationDate:
                            rr.created_at == undefined ? "" : rr.created_at,
                          TrackingID:
                            rr.reference_number == undefined
                              ? ""
                              : rr.reference_number,
                          Comments: "",
                        });
                      });
                      setRow(response);
                      setLoading(false);
                    }
                  })
                  .catch((ex) => {
                    setLoading(false);
                    dispatch(
                      setNotification({
                        message: "Lane Subscription Required.",
                        visible: true,
                      })
                    );
                    throw ex;
                  });
              }
            }}
          />
        </Grid>
      </Grid>
      {getTable()}
    </>
  );
};

export const RetrieveQuotation = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeState = useSelector(
    (state: RootState) => state.reducerFinancialCalculation
  );
  const [saveloading, setSaveLoading] = useState(false);
  const [menuLoading, setMenuLoading] = useState(false);
  return (
    <>
      <SectionWrap>
        <Box className="with-bg p-0">
          <Box sx={{ p: 3.5, pb: 0, pt: 4 }}>
            <Grid container columnSpacing={1} className="heading-with-icon">
              <Grid item xs={12} sm={6}>
                <Box className="title">
                  <Button
                    defaultBtn
                    className="btn-back"
                    iconText={
                      <>
                        {" "}
                        <ArrowBackIcon />
                      </>
                    }
                    onClick={() => {
                      dispatch(setResetValues(ResetData()));
                      let search = window.location.search;
                      let params = new URLSearchParams(search);
                      let apiKey = params.get("x-api-key");
                      if (apiKey != null) {
                        navigate({
                          pathname: APP_ROUTES.QUOTATION,
                          search: `${"?x-api-key="}${apiKey}`,
                        });
                      } else {
                        navigate(APP_ROUTES.QUOTATION);
                      }
                    }}
                  />
                  <Typography
                    variant="h3"
                    component="h3"
                    children="Quotation Detail"
                    className="main-title"
                  />
                </Box>
              </Grid>
              <Grid item sm={6} className="btn-desktop">
                <Button
                  theme={theme}
                  disabled={saveloading}
                  text="Save Changes"
                  primary
                  onClick={() => {
                    setSaveLoading(true);
                    var validate = ValidateSaveConfiguration(
                      storeState.CustomerDetail
                    );
                    if (validate?.visible) {
                      dispatch(
                        setNotification({
                          message: validate.error,
                          visible: validate.visible,
                        })
                      );
                      setSaveLoading(false);
                    } else {
                      SaveConfigurationDms(storeState)
                        .then((r) => {
                          dispatch(
                            setNotification({
                              message: "Quotation Saved",
                              visible: true,
                            })
                          );
                          setSaveLoading(false);
                        })
                        .catch((ex) => {
                          dispatch(
                            setNotification({
                              message: "Lane Subscription Required.",
                              visible: true,
                            })
                          );
                          setSaveLoading(false);
                          throw ex;
                        });
                    }
                  }}
                />
                <MenuList
                  theme={theme}
                  disablePortal
                  open={false}
                  options={[
                    {
                      optionText: "Generate Word",
                      optionValue: "Generate Word",
                    },
                    {
                      optionText: "Generate Pdf",
                      optionValue: "Generate Pdf",
                    },
                  ]}
                  render={(cb) => (
                    <MenuButton
                      secondary
                      iconText={<Icon name="PrintIcon" />}
                      onChange={(e) => {}}
                      onClick={cb}
                      disabled={menuLoading}
                    />
                  )}
                  handleOptionClick={(_event, _key, value) => {
                    setMenuLoading(true);
                    if (value == "Generate Pdf") {
                      GenerateHaydockTemplate(storeState)
                        .then((ex: any) => {
                          setMenuLoading(false);
                        })
                        .catch((ex: any) => {
                          if (ex?.response?.data?.detail == exportDetail) {
                            dispatch(
                              setNotification({
                                message: "No Template found",
                                visible: true,
                              })
                            );
                            setMenuLoading(false);
                          } else {
                            dispatch(
                              setNotification({
                                message: "Please Subscribe Dock for Printing.",
                                visible: true,
                              })
                            );
                            setMenuLoading(false);
                          }
                          setMenuLoading(false);
                        });
                    } else if (value == "Generate Word") {
                      generateQuotation(storeState)
                        .then(() => {
                          setMenuLoading(false);
                        })
                        .catch((ex: any) => {
                          if (ex?.response?.data?.detail == exportDetail) {
                            dispatch(
                              setNotification({
                                message: "No Template found",
                                visible: true,
                              })
                            );
                            setMenuLoading(false);
                          } else {
                            dispatch(
                              setNotification({
                                message: "Please Subscribe Dock for Printing.",
                                visible: true,
                              })
                            );
                            setMenuLoading(false);
                          }
                          throw ex;
                        });
                    }
                    switch (value) {
                      case "Generate Pdf":
                        break;
                      case "Generate Word":
                    }
                  }}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                />
              </Grid>
            </Grid>
            <Divider text={""} />
          </Box>
          <Scroll className="h-xl-c">
            <Box className="scroll-container">
              <Typography
                variant="h4"
                component="h4"
                children="Details"
                sx={{ mb: 3 }}
              />
              <Grid container rowSpacing={3} columnSpacing={2} sx={{ mb: 3 }}>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <TextBox
                    fullWidth
                    testid="txtRefrenceNumber"
                    label={"Refrence Number"}
                    value={storeState.CustomerDetail.TrackingID}
                    placeHolder="1234"
                    type="text"
                    onChange={(e) => {
                      dispatch(
                        setCustomerDetail({
                          ...storeState.CustomerDetail,
                          TrackingID: e.target.value,
                        })
                      );
                    }}
                  />
                </Grid>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <TextBox
                    fullWidth
                    testid="txtCustomerFullName"
                    label={"Customer Full Name"}
                    value={storeState.CustomerDetail.CustomerFullName}
                    placeHolder="Sample Name"
                    type="text"
                    onChange={(e) => {
                      dispatch(
                        setCustomerDetail({
                          ...storeState.CustomerDetail,
                          CustomerFullName: e.target.value,
                        })
                      );
                    }}
                  />
                </Grid>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <TextBox
                    fullWidth
                    testid="txtCustomerEmailAddress"
                    label={"Customer Email Address"}
                    value={storeState.CustomerDetail.CustomerEmailAddress}
                    placeHolder="Sample@sample.com"
                    type="email"
                    onChange={(e) => {
                      dispatch(
                        setCustomerDetail({
                          ...storeState.CustomerDetail,
                          CustomerEmailAddress: e.target.value,
                        })
                      );
                    }}
                  />
                </Grid>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <TextBox
                    fullWidth
                    testid="txtCustomerContactNumber"
                    label={"Customer Contact Number"}
                    value={storeState.CustomerDetail.CustomerContactNumber}
                    placeHolder="+44 000 000 0000"
                    onChange={(e) => {
                      dispatch(
                        setCustomerDetail({
                          ...storeState.CustomerDetail,
                          CustomerContactNumber: normalizeInput(
                            e.target.value,
                            storeState.CustomerDetail.CustomerContactNumber
                          ),
                        })
                      );
                    }}
                    type="text"
                  />
                </Grid>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <TextBox
                    fullWidth
                    testid="txtIntroducerName"
                    label={"Introducer Name"}
                    value={storeState.CustomerDetail.VendorPersonName}
                    placeHolder="Sample Name"
                    type="text"
                    onChange={(e) => {
                      dispatch(
                        setCustomerDetail({
                          ...storeState.CustomerDetail,
                          VendorPersonName: e.target.value,
                        })
                      );
                    }}
                  />
                </Grid>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <TextBox
                    fullWidth
                    testid="txtIntroducerEmailAddress"
                    label={"Introducer Email Address"}
                    value={storeState.CustomerDetail.VendorEmailAddress}
                    placeHolder="sample@sample.com"
                    type="text"
                    onChange={(e) => {
                      dispatch(
                        setCustomerDetail({
                          ...storeState.CustomerDetail,
                          VendorEmailAddress: e.target.value,
                        })
                      );
                    }}
                  />
                </Grid>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <TextBox
                    fullWidth
                    testid="txtComments"
                    label={"Comments"}
                    value={storeState.CustomerDetail.Comments}
                    placeHolder="Comments"
                    type="text"
                    onChange={(e) => {
                      dispatch(
                        setCustomerDetail({
                          ...storeState.CustomerDetail,
                          Comments: e.target.value,
                        })
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Box sx={{ mb: 3 }}>
                <Divider text={""} />
              </Box>
              <Typography
                variant="h4"
                component="h4"
                children="Quotation Details"
                sx={{ mb: 3 }}
              />
              <Grid container rowSpacing={3} columnSpacing={2} sx={{ mb: 3 }}>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <TextBox
                    fullWidth
                    testid="txtProductType"
                    label={"Product Type"}
                    value={storeState.RequestObj.FinanceType}
                    disabled
                  />
                </Grid>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <DatePicker
                    testid="txtStartDate"
                    theme={theme}
                    value={storeState.RequestObj.StartDate}
                    disabled
                    onChange={() => {}}
                    label={"Start Date"}
                    id={"StartDate"}
                  />
                </Grid>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <TextBox
                    testid="txtAssetCost"
                    fullWidth
                    label={"Asset Cost"}
                    value={storeState.RequestObj.AssetCost}
                    disabled
                    masking
                    startAdornment={Currency.GBP}
                  />
                </Grid>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <TextBox
                    fullWidth
                    testid="txtDepositAmount"
                    label={"Deposit Amount"}
                    disabled
                    value={storeState.RequestObj.Deposit}
                    masking
                    startAdornment={Currency.GBP}
                  />
                </Grid>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <TextBox
                    fullWidth
                    testid="txtPaymentProfile"
                    theme={theme}
                    value={
                      storeState.RequestObj.AdvancePayments +
                      "+" +
                      storeState.RequestObj.RegularPayments
                    }
                    disabled
                    label={"Payment Profile"}
                    id={"PaymentProfile"}
                  />
                </Grid>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <Dropdown
                    testid="txtPaymentFrequency"
                    label={"Payment Frequency"}
                    disabled
                    value={"Monthly"}
                    items={PaymentFrequencies}
                  />
                </Grid>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <TextBox
                    fullWidth
                    testid="txtVatDeferralAmount"
                    label={"VAT Deferral Amount"}
                    disabled
                    value={storeState.RequestObj.VAT.Amount}
                    masking
                    startAdornment={Currency.GBP}
                  />
                </Grid>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <Dropdown
                    testid="txtVatTreatment"
                    label={"VAT Treatment"}
                    disabled
                    value={storeState.RequestObj.VAT.Treatment}
                    items={VATTreatments}
                  />
                </Grid>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <TextBox
                    fullWidth
                    testid="txtBalloonAmount"
                    label={"Balloon Amount"}
                    disabled
                    value={storeState.RequestObj.BalloonPayment}
                    masking
                    startAdornment={Currency.GBP}
                  />
                </Grid>
                <Grid item lg={2.4} md={4} sm={6} xs={12}>
                  <TextBox
                    fullWidth
                    testid="txtFinanceAmount"
                    label={"Finance Amount"}
                    disabled
                    value={storeState.CalculatedNFA}
                    masking
                    startAdornment={Currency.GBP}
                  />
                </Grid>
              </Grid>
              <Box sx={{ mb: 3 }}>
                <Divider text={""} />
              </Box>
              <PaymentSummary>
                <Grid container rowSpacing={3} columnSpacing={3} sx={{ mb: 3 }}>
                  <Grid item sm={6} xs={12}>
                    <Typography
                      variant="h4"
                      component="h4"
                      children="Payment Summary"
                      sx={{ mb: 3 }}
                    />
                    <Rentals />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Box className="border-left with-pl">
                      <Box sx={{ mb: 3 }}>
                        <Typography
                          variant="h4"
                          component="h4"
                          children="Rates"
                          sx={{ mb: 3 }}
                        />
                        <RatesCard storeState={storeState} />
                      </Box>
                      <Typography
                        variant="h4"
                        component="h4"
                        children="Fee & Charges"
                        sx={{ mb: 3 }}
                      />
                      <List>
                        <ul>
                          {storeState.RequestObj.Fee?.map((_fee, index) => (
                            <li>
                              <Box className="left">{_fee.Name}:</Box>
                              <Box className="right  editable">
                                <TextBox
                                  theme={theme}
                                  label=""
                                  selectAllOnFocus
                                  disabled={true}
                                  thousandSeparater={false}
                                  masking
                                  maskPrefix={`${Currency.GBP} `}
                                  decimalScale={2}
                                  value={_fee.Amount}
                                  onChange={(e) => {
                                    let newfees = [
                                      ...storeState.RequestObj.Fee,
                                    ];
                                    newfees[index].Amount = Number(
                                      e.target.value
                                    );
                                  }}
                                />
                              </Box>
                            </li>
                          ))}
                        </ul>
                      </List>
                    </Box>
                  </Grid>
                </Grid>
              </PaymentSummary>
              <Box className="btn-mbl" sx={{ mb: 2 }}>
                <Grid container columnSpacing={2} rowSpacing={2}>
                  <Grid item sm={6} xs={12}>
                    <MenuList
                      theme={theme}
                      disablePortal
                      open={false}
                      options={[
                        {
                          optionText: "Generate Word",
                          optionValue: "Generate Word",
                        },
                        {
                          optionText: "Generate Pdf",
                          optionValue: "Generate Pdf",
                        },
                      ]}
                      render={(cb) => (
                        <MenuButton
                          secondary
                          iconText={<Icon name="PrintIcon" />}
                          onChange={(e) => {}}
                          onClick={cb}
                          disabled={menuLoading}
                        />
                      )}
                      handleOptionClick={(_event, _key, value) => {
                        setMenuLoading(true);
                        if (value == "Generate Pdf") {
                          GenerateHaydockTemplate(storeState)
                            .then((ex: any) => {
                              setMenuLoading(false);
                            })
                            .catch((ex: any) => {
                              if (ex?.response?.data?.detail == exportDetail) {
                                dispatch(
                                  setNotification({
                                    message: "No Template found",
                                    visible: true,
                                  })
                                );
                              } else {
                                dispatch(
                                  setNotification({
                                    message:
                                      "Please Subscribe Dock for Printing.",
                                    visible: true,
                                  })
                                );
                              }
                              setMenuLoading(false);
                            });
                        } else if (value == "Generate Word") {
                          generateQuotation(storeState)
                            .then(() => {
                              setMenuLoading(false);
                            })
                            .catch((ex: any) => {
                              if (ex?.response?.data?.detail == exportDetail) {
                                dispatch(
                                  setNotification({
                                    message: "No Template found",
                                    visible: true,
                                  })
                                );
                                setMenuLoading(false);
                              } else {
                                dispatch(
                                  setNotification({
                                    message:
                                      "Please Subscribe Dock for Printing.",
                                    visible: true,
                                  })
                                );
                                setMenuLoading(false);
                              }
                              throw ex;
                            });
                        }
                        switch (value) {
                          case "Generate Pdf":
                            break;
                          case "Generate Word":
                        }
                      }}
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      transformOrigin={{ vertical: "top", horizontal: "right" }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Button
                      theme={theme}
                      disabled={saveloading}
                      fullWidth
                      text="Save Changes"
                      onClick={() => {
                        setSaveLoading(true);
                        var validate = ValidateSaveConfiguration(
                          storeState.CustomerDetail
                        );
                        if (validate?.visible) {
                          dispatch(
                            setNotification({
                              message: validate.error,
                              visible: validate.visible,
                            })
                          );
                          setSaveLoading(false);
                        } else {
                          SaveConfigurationDms(storeState)
                            .then((r) => {
                              dispatch(
                                setNotification({
                                  message: "Quotation Saved",
                                  visible: true,
                                })
                              );
                              setSaveLoading(false);
                            })
                            .catch((ex) => {
                              dispatch(
                                setNotification({
                                  message: "Lane Subscription Required.",
                                  visible: true,
                                })
                              );
                              setSaveLoading(false);
                              throw ex;
                            });
                        }
                      }}
                      primary
                    />
                  </Grid>
                </Grid>
              </Box>
              <NotificationSnackbar
                show={storeState.Notification.visible}
                message={storeState.Notification.message}
                onClose={() =>
                  dispatch(
                    setNotification({
                      message: "",
                      visible: false,
                    })
                  )
                }
              />
            </Box>
          </Scroll>
        </Box>
      </SectionWrap>
    </>
  );
};
