import { NewQuotationPageHeader, QuotationTool, Snackbar } from "@components";
import { useTheme } from "@mui/material";
import { PageContent } from "./page-content.styles";
import { useParams } from "react-router-dom";
import { useGetQuotationByIdentifier } from "@hooks/queries";
import {
  QuotationFormProvider,
  useCalculationsActionsContext,
} from "@ntpkunity/controls-common";

export const QuotationPageContent = () => {
  const theme = useTheme();
  const params = useParams<{ quotationId?: string }>();
  const quotationId = params.quotationId;
  const { setCalculationResults } = useCalculationsActionsContext();
  const {
    isLoading,
    data: quotationData,
    isError,
  } = useGetQuotationByIdentifier(quotationId, (data) => {
    setCalculationResults(data.calculations);
  });

  if (!!quotationId && (isLoading || isError)) {
    return <></>;
  }

  return (
    <QuotationFormProvider defaultValues={quotationData?.formValues}>
      <NewQuotationPageHeader
        brokerDefaultValues={quotationData?.brokerSpecificFormValues}
      />
      <PageContent theme={theme} className="page-content">
        <QuotationTool />
      </PageContent>
      <Snackbar />
    </QuotationFormProvider>
  );
};
