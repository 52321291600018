import { Stack } from "@components";
import { useTheme } from "@mui/material";
import { Grid } from "@ntpkunity/controls";
import { PROPOSAL_DETAILS } from "@helpers/const";
import {
  TBrokerNames,
  TDealerNames,
  TFinanceType,
  TLenderNames,
  TProductType,
  TInputDef,
} from "@components/proposal/proposal-details/types";
import { getSelectValues } from "@helpers/utils";
import ControlledFormElement from "@components/controlled-form-element/controlled-form-element.component";
import {
  QuotationForm,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { TProposalForm } from "@_types";

const inputsDefs: TInputDef[] = [
  {
    name: "proposalDetails.name",
    formElement: "Input",
    placeHolder: "Proposal Name",
    label: "Proposal Name",
    type: "text",
    sanitizeChange: (value) => {
      return value.replace(/[^a-zA-Z0-9 ]/g, "");
    },
  },
  {
    name: "proposalDetails.dealerName",
    formElement: "Select",
    label: "Dealer Name (Optional)",
    items: getSelectValues<TDealerNames>(PROPOSAL_DETAILS.dealerNames),
  },
  {
    name: "proposalDetails.brokerName",
    formElement: "Select",
    label: "Broker Name",
    items: getSelectValues<TBrokerNames>(PROPOSAL_DETAILS.brokerNames),
  },
  {
    name: "proposalDetails.financeType",
    formElement: "Select",
    label: "Finance Type",
    items: getSelectValues<TFinanceType>(PROPOSAL_DETAILS.financeTypes),
  },
  {
    name: "proposalDetails.productType",
    formElement: "Select",
    label: "Product Type",
    items: getSelectValues<TProductType>(PROPOSAL_DETAILS.productTypes),
  },
];

export const ProposalDetailsForm = () => {
  const theme = useTheme();
  const { setValue } = useQuotationFormContext<QuotationForm>();
  const { watch } = useFormContext<TProposalForm>();

  const productType = watch("proposalDetails.productType");
  useEffect(() => {
    if (productType) setValue("productType", productType);
  }, [productType]);

  return (
    <Stack paddingXs={3} paddingMd={3}>
      <Grid theme={theme} container item columnSpacing={2} rowSpacing={3}>
        {inputsDefs.map((value, index) => {
          return (
            <Grid
              key={`${index} - ${value.name}`}
              theme={theme}
              item
              xs={12}
              md={4}
              lg={3}
            >
              <ControlledFormElement {...value} />
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};
