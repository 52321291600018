import { styled } from "@mui/material";

export const AccordianWrap: any = styled("div")(({ theme }) => ({
  ".MuiPaper-root.MuiPaper-rounded.MuiAccordion-root.MuiAccordion-rounded": {
    borderRadius: 0,
    boxShadow: "0 0 0 transparent",
    marginBottom: 24,

    "&:before": {
      display: "none",
    },

    ".MuiButtonBase-root.MuiAccordionSummary-root": {
      padding: 0,
      minHeight: 30,

      "&:after": {
        backgroundColor: theme.palette.divider,
        height: 1,
        width: "100%",
        content: "''",
        display: "inline-block",
        position: "absolute",
      },
      ".MuiAccordionSummary-content": {
        margin: 0,
        color: theme.palette.grey[600],
        flexGrow: "initial",

        ".title": {
          width: "auto",
          display: "inline-block",
          margin: "auto",
          padding: "0 2px 0 5px",
          backgroundColor: theme.palette.common.white,
          fontSize: theme.typography.subtitle2.fontSize,
          fontWeight: theme.typography.subtitle2.fontWeight,
          // textTransform: "uppercase",
          position: "relative",
          zIndex: 1,
        },

        ".btn-delete": {
          padding: "0 0 0 5px",
          position: "absolute",
          right: 0,
          top: 3,
          zIndex: 1,
          backgroundColor: theme.palette.common.white,
        },
      },

      ".MuiAccordionSummary-expandIconWrapper": {
        position: "relative",
        zIndex: 1,
        padding: "0 5px 0 2px",
        backgroundColor: theme.palette.common.white,
      },
    },
    ".MuiCollapse-root": {
      ".MuiCollapse-wrapper": {
        ".MuiCollapse-wrapperInner": {
          ".MuiAccordion-region": {
            ".MuiAccordionDetails-root": {
              padding: "24px 0 0 0",
            },
          },
        },
      },
    },
  },
  "&.without-lines": {
    ".MuiPaper-root.MuiPaper-rounded.MuiAccordion-root.MuiAccordion-rounded": {
      "&.anim": {
        ".h-xs": {
          height: "calc(0px)",
        },
      },
      borderRadius: 8,
      marginBottom: 12,
      ".MuiButtonBase-root.MuiAccordionSummary-root": {
        paddingTop: 10,
        ".MuiAccordionSummary-content": {
          width: "100%",
        },
        "&:after": {
          backgroundColor: "transparent",
        },
      },
      ".MuiCollapse-root": {
        ".MuiAccordionDetails-root": {
          paddingTop: "10px !important",
        },
      },
    },
  },

  "@media screen and (max-width: 960px)": {
    "&.without-lines": {
      ".MuiPaper-root.MuiPaper-rounded.MuiAccordion-root.MuiAccordion-rounded":
        {
          borderRadius: 8,
          marginBottom: 0,
          ".MuiButtonBase-root.MuiAccordionSummary-root": {
            paddingTop: 20,
            margin: "0px 28px",
            ".title": {
              textTransform: "uppercase",
            },
            ".MuiAccordionSummary-content": {
              width: "auto",
            },
            "&:after": {
              backgroundColor: "#D8DAE5",
            },
          },
          ".MuiCollapse-root": {
            ".MuiAccordionDetails-root": {
              paddingTop: "0px !important",
              ".card-body": {
                border: "none",
              },
            },
          },
        },
    },
  },
}));
