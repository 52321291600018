import {
  ButtonGroup,
  LayoutWithSideNavComponent,
  PageHeader,
  Snackbar,
} from "@components";
import { useTheme } from "@mui/material";
import {
  ProposalTable,
  QuotationTable,
  DealerProposalTable,
} from "@components/work-queue";
import { WorkQueueTabs, ApplicationMode } from "@helpers/enum";
import {
  APP_ROUTES,
  WORK_QUEUE_TABS,
  DEALER_WORK_QUEUE_TABS,
  LOCAL_STORAGE_KEYS,
} from "@helpers/const";
import { useNavigate, useParams } from "react-router-dom";
import { PageContent } from "./work-queue-page.style";
import { useEffect } from "react";

const TAB_VALUE_TO_COMPONENT_MAP = {
  [WorkQueueTabs.QUOTATIONS]: <QuotationTable />,
  [WorkQueueTabs.DRAFT_PROPOSALS]: (
    <ProposalTable
      queue={WorkQueueTabs.DRAFT_PROPOSALS}
      key={WorkQueueTabs.DRAFT_PROPOSALS}
    />
  ),
  [WorkQueueTabs.TRACK_PROPOSALS]: (
    <ProposalTable
      queue={WorkQueueTabs.TRACK_PROPOSALS}
      key={WorkQueueTabs.TRACK_PROPOSALS}
    />
  ),
  [WorkQueueTabs.DEALER_PROPOSALS]: <DealerProposalTable />,
};

export const WorkQueuePage = () => {
  const { workQueueTab } = useParams<{ workQueueTab: WorkQueueTabs }>();
  const navigate = useNavigate();
  const theme = useTheme();
  const mode = localStorage.getItem(LOCAL_STORAGE_KEYS.MODE);
  const isDealer = mode === ApplicationMode.DEALER;

  useEffect(() => {
    if (isDealer && workQueueTab === WorkQueueTabs.DEALER_PROPOSALS)
      navigate(`${APP_ROUTES.WORKQUEUE}/${WorkQueueTabs.TRACK_PROPOSALS}`);
  }, []);

  const component = TAB_VALUE_TO_COMPONENT_MAP[workQueueTab];

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <PageHeader
        title="Workqueue"
        actionArea={
          <>
            <ButtonGroup
              items={(isDealer ? DEALER_WORK_QUEUE_TABS : WORK_QUEUE_TABS).map(
                (tab) => ({
                  title: tab.title,
                  onClick: () =>
                    navigate(`${APP_ROUTES.WORKQUEUE}/${tab.value}`),
                  selected: workQueueTab === tab.value,
                })
              )}
            />
          </>
        }
      />
      <PageContent theme={theme} className="page-content" pt={3}>
        {component}
      </PageContent>
      <Snackbar />
    </LayoutWithSideNavComponent>
  );
};
