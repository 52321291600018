import {
  APRCalculationMethod,
  AmortizationMethod,
  FeeName,
  FeeType,
} from "@flexCommon/enums";
import { FieldData } from "@data/fieldProps.data";
import { INotification } from "@interfaces";
import { IConfigurationInputs } from "@interfaces/configuration.interface";
import ActionTypes from "@store/actionTypes";

export type IReducerConfiguration = {
  FinanceLease: IConfigurationInputs;
  HirePurchase: IConfigurationInputs;
  Notification: INotification;
  Loan: IConfigurationInputs;
  SliderOpen: boolean;
};
const initialValue: IConfigurationInputs = {
  AmortizationMethod: AmortizationMethod.AnnuityActual365,
  APRCalculationMethod: APRCalculationMethod.AnnuityActual365,
  CommissionCalculationMethod: "",
  Fees: [
    {
      Amount: 195,
      FirstDueDate: new Date().toString(),
      Frequency: FeeType.OneTime,
      Name: FeeName.DocFee,
      Occurance: "WithFirstPayment",
      PaymentNumber: 0,
      IsEditable: true,
    },
    {
      Amount: 35,
      FirstDueDate: new Date().toString(),
      Frequency: FeeType.Periodic,
      Name: FeeName.AnnualAdminFee,
      Occurance: "Annual",
      PaymentNumber: 0,
      IsEditable: true,
    },
    {
      Amount: 200,
      FirstDueDate: new Date().toString(),
      Frequency: FeeType.OneTime,
      Name: FeeName.OTPFee,
      Occurance: "WithLastPayment",
      PaymentNumber: 0,
      IsEditable: true,
    },
  ],
  InitialCashOutFlowForFlatRate: "",
  IsCommissionPassToCustomer: false,
  IsVATApplicable: true,
  FieldProps: FieldData,
  Tax: 0,
  GrossProfit: false,
};
const initialLoanValue: IConfigurationInputs = {
  AmortizationMethod: AmortizationMethod.Annuity360,
  APRCalculationMethod: APRCalculationMethod.Annuity360,
  CommissionCalculationMethod: "",
  Fees: [
    {
      Amount: 195,
      FirstDueDate: new Date().toString(),
      Frequency: FeeType.OneTime,
      Name: FeeName.DocFee,
      Occurance: "WithFirstPayment",
      PaymentNumber: 0,
      IsEditable: true,
    },
    {
      Amount: 35,
      FirstDueDate: new Date().toString(),
      Frequency: FeeType.Periodic,
      Name: FeeName.AnnualAdminFee,
      Occurance: "Annual",
      PaymentNumber: 0,
      IsEditable: true,
    },
    {
      Amount: 200,
      FirstDueDate: new Date().toString(),
      Frequency: FeeType.OneTime,
      Name: FeeName.OTPFee,
      Occurance: "WithLastPayment",
      PaymentNumber: 0,
      IsEditable: true,
    },
  ],
  InitialCashOutFlowForFlatRate: "",
  IsCommissionPassToCustomer: false,
  IsVATApplicable: false,
  FieldProps: FieldData,
  Tax: 0,
  GrossProfit: false,
};
const initialState: IReducerConfiguration = {
  FinanceLease: initialValue,
  HirePurchase: initialValue,
  Notification: {
    message: "",
    visible: false,
  },
  Loan: initialLoanValue,
  SliderOpen: false,
};
export function reducerConfiguration(
  currentState = initialState,
  action: any
): IReducerConfiguration {
  switch (action.type) {
    case ActionTypes.SET_FINANCE_LEASE_CONFIG: {
      return {
        ...currentState,
        FinanceLease: action.payload,
      };
    }
    case ActionTypes.SET_HIRE_PURCHASE_CONFIG: {
      return {
        ...currentState,
        HirePurchase: action.payload,
      };
    }
    case ActionTypes.SET_LOAN_CONFIG: {
      return {
        ...currentState,
        Loan: action.payload,
      };
    }
    case ActionTypes.SET_SLIDER_OPEN: {
      return {
        ...currentState,
        SliderOpen: action.payload,
      };
    }
    case ActionTypes.SET_NOTIFICATION_CONFIGURATION: {
      return {
        ...currentState,
        Notification: action.payload,
      };
    }
    default:
      return currentState;
  }
}
