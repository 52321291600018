import { TableCell, TableRow, useTheme } from "@mui/material";
import {
  Box,
  Button,
  DataTable,
  Pagination,
  Typography,
} from "@ntpkunity/controls";
import { useConvertQuotationToProposal } from "@hooks/mutations";
import { DataTableWrap } from "@styles/data-table-wrap";
import { useGetOrders } from "@hooks/queries";
import QuotationTableRow from "./quotation-table-row/quotation-table-row.component";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import {
  TQuotationSearchParams,
  TSortParams,
  TQuotationWorkQueueEntry,
} from "@_types";
import QuotationFilter from "./quotation-filters/quotation-filters.component";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import { useSnackbarContext } from "@contexts/snackbar";
import { OrderStage, SortOrder, SnackbarVariants } from "@helpers/enum";
import { convertIsoDateStrToddmmyyyy } from "@helpers/utils";

const searchInitialState: TQuotationSearchParams = {
  introducer_name: "",
  name: "",
  product_type: "",
  finance_amount: null,
  updated_at: "",
  order_stage: OrderStage.QUOTATION,
};

export const QuotationTable = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] =
    useState<TQuotationSearchParams>(searchInitialState);
  const [sortParams, setSortParams] = useState<
    TSortParams<keyof TQuotationSearchParams>
  >({ sort_by: "updated_at", sort_order: SortOrder.DESC });
  const theme = useTheme();
  const [debouncedSearchParams] = useDebounce(
    useMemo(
      () => ({
        ...searchParams,
        updated_at: convertIsoDateStrToddmmyyyy(searchParams.updated_at),
      }),
      [searchParams]
    ),
    500
  );

  const { setSnackbar } = useSnackbarContext();
  const { mutateAsync } = useConvertQuotationToProposal();
  const {
    isLoading,
    isError,
    data: quotations,
  } = useGetOrders<TQuotationSearchParams, TQuotationWorkQueueEntry>(
    debouncedSearchParams,
    sortParams
  );

  const isNotSuccess = isLoading || isError;

  const commonFilterParams = {
    searchParams,
    setSearchParams,
    sortParams,
    setSortParams,
  };

  useEffect(() => {
    if (isError) {
      setSnackbar({
        open: true,
        message: "Error while fetching quotation.",
        variant: "error",
      });
    }
  }, [isError]);

  const convertToProposal = async (identifier: string) => {
    setSnackbar({
      open: true,
      message: "Converting To Proposal",
    });

    try {
      await mutateAsync(identifier);
      navigate(`${APP_ROUTES.PROPOSAL}/${identifier}`);
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Error Occurred while converting to Proposal",
        variant: SnackbarVariants.ERROR,
      });
    }
  };

  return (
    <DataTableWrap className="table-pagination-button">
      <Box theme={theme} className="scroll">
        <Box className="scroll-hide spr-border" theme={theme} />
      </Box>
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow>
              <TableCell>Broker</TableCell>
              <TableCell>Quotation Name</TableCell>
              <TableCell>Product Type</TableCell>
              <TableCell>Finance Amount</TableCell>
              <TableCell>Last Updated</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <QuotationFilter
                  {...commonFilterParams}
                  searchKey="introducer_name"
                />
              </TableCell>
              <TableCell>
                <QuotationFilter {...commonFilterParams} searchKey="name" />
              </TableCell>
              <TableCell>
                <QuotationFilter
                  {...commonFilterParams}
                  searchKey="product_type"
                />
              </TableCell>
              <TableCell>
                <QuotationFilter
                  {...commonFilterParams}
                  searchKey="finance_amount"
                  type="number"
                />
              </TableCell>
              <TableCell>
                <QuotationFilter
                  {...commonFilterParams}
                  searchKey="updated_at"
                  placeholder="DD/MM/YYYY"
                />
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {isNotSuccess || !quotations?.length ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="span"
                    display="block"
                    textAlign="center"
                  >
                    {isLoading
                      ? "Loading Quotations..."
                      : "No Quotations found."}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              quotations.map((quotation) => (
                <QuotationTableRow
                  key={quotation.identifier}
                  quotation={quotation}
                  convertToProposal={convertToProposal}
                />
              ))
            )}
          </>
        }
      />
      <Pagination theme={theme} />
      <Button
        theme={theme}
        secondary
        fullWidth
        className="btn-table"
        text="Create New Quotation"
        onClick={() => {
          navigate(APP_ROUTES.QUOTATION);
        }}
      />
    </DataTableWrap>
  );
};
