import { useParams } from "react-router-dom";
import { MainContainer } from "@components/quotation/shared/controls";
import { CreateQuotation } from "@components";
import { useGetDataForQuotation } from "@hooks/flex-quote";

const Quotation = () => {
  const params = useParams<{ quotationId?: string }>();
  const quotationId = params.quotationId;

  const { financeType, setFinanceType, fieldPropsState } =
    useGetDataForQuotation(quotationId);

  return (
    <>
      <MainContainer>
        <CreateQuotation
          fieldPropsState={fieldPropsState}
          financeType={financeType}
          setFinanceType={setFinanceType}
        />
      </MainContainer>
    </>
  );
};

export default Quotation;
