import { LayoutWithSideNavComponent } from "@components";
import { useTheme } from "@mui/material";
import store from "@store";
import { useEffect } from "react";
import { resetFinancialCalculation } from "@store/actions/financialCalculation.actions";
import { ProposalPageWrap } from "./proposal-page.style";
import { Proposal } from "@components/proposal";
import { useGetProposal } from "@hooks/queries";
import { useParams, useLocation } from "react-router-dom";

export const ProposalPage = () => {
  const theme = useTheme();
  const params = useParams<{ proposalId?: string }>();
  const isEditMode = !!params.proposalId;
  const location = useLocation();
  const redirectFromTrackProposals = location.state;
  const proposalSummaryPageReference = redirectFromTrackProposals
    ? redirectFromTrackProposals.summary
    : false;
  const creditDocumentPageReference = redirectFromTrackProposals
    ? redirectFromTrackProposals.credit_documents
    : false;
  const { data, isSuccess } = useGetProposal(params.proposalId);

  useEffect(() => {
    return () => {
      store.dispatch(resetFinancialCalculation());
    };
  }, []);

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <ProposalPageWrap theme={theme} className="proposal-page-wrap">
        {(!isEditMode || isSuccess) && (
          <Proposal
            proposalSummaryPageReference={proposalSummaryPageReference}
            creditDocumentPageReference={creditDocumentPageReference}
            proposalData={data}
          />
        )}
      </ProposalPageWrap>
    </LayoutWithSideNavComponent>
  );
};
