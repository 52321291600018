import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import ChevronDown from "@assets/images/arrow-down.svg";

export const DialogContentWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.dialog-content-wrap": {
    ".comments-area": {
      borderTop: "1px solid" + theme.palette.divider,
    },
    ".u-accordian-wrap": {
      marginBottom: 24,
      backgroundColor: "#F5F5F7",
      padding: 16,
      borderRadius: theme.shape.borderRadius,
      "&:last-child": {
        marginBottom: 0,
      },
      ".u-accordion-header": {
        padding: 0,
        minHeight: 24,
        ".MuiAccordionSummary-content": {
          margin: 0,
          ".main-title": {
            fontWeight: theme.typography.fontWeightMedium,
            position: "relative",
            "&::after": {
              display: "block",
              backgroundImage: `url(${ChevronDown})`,
              content: '" "',
              width: 20,
              height: 20,
              position: "absolute",
              right: -24,
              top: "50%",
              transform: "rotate(0deg) translateY(-50%)",
              transformOrigin: "top center",
              transition: "all 0.3s ease-in-out 0s",
              marginTop: -2,
            },
          },
        },
        ".MuiAccordionSummary-expandIconWrapper": {
          display: "none",
        },
      },
      ".u-accordian-body": {
        borderBottom: "none",
        padding: "12px 0 0 0",
        ".accordion-content": {
          backgroundColor: theme.palette.common.white,
          padding: 16,
          borderRadius: theme.shape.borderRadius,
          ".date-time": {
            ".MuiTypography-root": {
              fontSize: 10,
            },
          },
        },
        ".u-form-group": {
          ".u-input-label": {
            backgroundColor: "#F5F5F7",
          },
          ".u-form-control": {
            ".MuiInputLabel-formControl": {
              backgroundColor: "#F5F5F7",
            },
          },
        },
      },
      "&.Mui-expanded": {
        ".u-accordion-header": {
          ".MuiAccordionSummary-content": {
            ".main-title": {
              "&::after": {
                transform: "rotate(180deg) translateY(-50%)",
              },
            },
          },
        },
      },
    },
  },
}));
