import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { IDialogProps } from "@components/quotation/shared/controls/Dialog/Dialog.types";
import clsx from "clsx";
import { Icon, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@components/quotation/shared/controls/button.shared";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "&.u-dialog": {
    ".MuiDialog-paper": {
      borderRadius: 16,
      color: theme.palette.grey[900],
      maxWidth: 840,
      width: "100%",
      boxShadow: "0px 32px 64px rgba(0, 0, 0, 0.16)",
      border: "1px solid" + theme.palette.grey[50],
      overflow: "hidden",
      ".text-muted": {
        color: theme.palette.grey[600],
      },
      ".text-primary": {
        color: theme.palette.primary.main,
      },
      ".link": {
        textDecoration: "none",
      },
      [theme.breakpoints.down("sm")]: {
        margin: 0,
        bottom: 0,
        position: "absolute",
        maxHeight: "calc(100% - 60px)",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    "&.u-dialog-sm": {
      ".MuiDialog-paper": {
        maxWidth: 560,
      },
    },
    "&.u-dialog-xs": {
      ".MuiDialog-paper": {
        maxWidth: 456,
      },
    },
    "&.u-dialog-lg": {
      ".MuiDialog-paper": {
        maxWidth: 920,
      },
    },
    "&.u-dialog-xl": {
      ".MuiDialog-paper": {
        maxWidth: 1200,
      },
    },
    "&.u-dialog-md": {
      ".MuiDialog-paper": {
        maxWidth: 640,
      },
    },
    ".u-dialog-title": {
      padding: "24px 65px",
      textAlign: "center",
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        padding: "24px",
      },
    },
    ".u-dialog-close": {
      position: "absolute",
      right: 24,
      width: 24,
      height: 24,
      padding: 0,
      backgroundColor: "transparent !important",
      svg: {
        path: {
          stroke: theme.palette.grey[300],
        },
      },
      ".MuiTouchRipple-root": {
        display: "none",
      },
    },
    ".u-dialog-back": {
      position: "absolute",
      width: 24,
      height: 24,
      left: 24,
      padding: 0,
      backgroundColor: "transparent !important",
      svg: {
        path: {
          stroke: theme.palette.grey[300],
        },
      },
      ".MuiTouchRipple-root": {
        display: "none",
      },
    },
    ".u-dialog-content": {
      padding: "40px",
      borderTopColor: theme.palette.grey[100],
      borderBottom: "none",
      scrollbarWidth: "thin",
      overflowX: "hidden",
      "&.has-no-footer": {
        padding: "40px",
        [theme.breakpoints.down("sm")]: {
          padding: "24px",
        },
      },
      "&::-webkit-scrollbar": {
        width: 7,
        height: 7,
      },
      "&::-webkit-scrollbar-track": {
        padding: "0 1px",
        backgroundColor: "#f0f0f0",
      },
      "&::-webkit-scrollbar-thumb": {
        transition: "all 0.3s",
        backgroundColor: "#cdcdcd",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#a6a6a6",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "32px 24px 0 24px",
      },
    },
    ".u-dialog-action": {
      padding: "32px 60px 32px",
      justifyContent: "center",
      borderTop: "1px solid" + theme.palette.divider,
      [theme.breakpoints.down("sm")]: {
        padding: "32px 24px 32px",
        // flexDirection: 'column',
        // '.btn': {
        //   width: '100%'
        // }
      },
      ">": {
        ":not(:first-of-type)": {
          marginLeft: 16,
          flexGrow: 1,
          // [theme.breakpoints.down('sm')]: {
          //   marginLeft: 0,
          //   marginTop: 16
          // }
        },
      },
    },
    "&.confirmation-dialog": {
      textAlign: "center",
      ".u-dialog-title": {
        display: "none",
      },
      ".u-dialog-content": {
        borderTop: "none",
        ".dialog-icon": {
          width: 144,
          height: 144,
          margin: "auto auto 32px",
          display: "flex",
          alignItems: "center",
          svg: {
            width: "100%",
            height: "100%",
          },
          img: {
            maxHeight: 144,
            maxWidth: "100%",
            [theme.breakpoints.down("sm")]: {
              maxHeight: 114,
            },
          },
          [theme.breakpoints.down("sm")]: {
            width: 114,
            height: 114,
          },
        },
        ".content-title": {
          [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.h3.fontSize,
            lineHeight: theme.typography.h3.lineHeight,
          },
        },
        h2: {
          [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.h3.fontSize,
            lineHeight: theme.typography.h3.lineHeight,
          },
        },
      },
      ".u-dialog-action": {
        ".btn": {
          width: "100%",
        },
        ">": {
          ":not(:first-of-type)": {
            flexGrow: 0,
          },
        },
      },
    },
    "&.auth-dialog": {
      ".MuiBackdrop-root": {
        backgroundColor: theme.palette.grey[100],
      },
      ".MuiDialog-paper": {
        border: "none",
        minHeight: 640,
        [theme.breakpoints.up("md")]: {
          maxHeight: 640,
        },
      },
      ".u-dialog-title": {
        padding: "40px 40px 0",
      },
      ".u-dialog-close": {
        right: 40,
        zIndex: 20,
      },
      ".u-dialog-back": {
        left: "calc(100% - 600px)",
        zIndex: 20,
        [theme.breakpoints.down("md")]: {
          left: 40,
        },
      },
      ".u-dialog-content": {
        borderTop: "none",
        padding: "0",
        overflow: "hidden",
        marginTop: -40,
        display: "flex",
        alignItems: "center",
        ".auth-left-img": {
          position: "sticky",
          top: 0,
          width: 280,
          flex: "0 0 auto",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
          img: {
            width: "100%",
            height: "100%",
          },
        },
        ".auth-right-panel": {
          flexGrow: 1,
          padding: "0 20px",
          [theme.breakpoints.up("md")]: {
            padding: "0 40px",
          },
          ".right-content": {
            padding: "0 20px",
            [theme.breakpoints.up("md")]: {
              padding: "0 60px",
            },
            ".inner-scrollable-area": {
              maxHeight: 300,
              overflowY: "auto",
              scrollbarWidth: "none",
              paddingTop: 8,
              "&::-webkit-scrollbar": {
                width: 0,
                height: 0,
              },
              "&::-webkit-scrollbar-track": {
                padding: "0 0px",
                backgroundColor: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                transition: "all 0.3s",
                backgroundColor: "transparent",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "transparent",
              },
            },
            ".otp-field-wrap": {
              display: "flex",
              justifyContent: "center",
              ".u-form-group": {
                marginBottom: 0,
                marginRight: 16,
                "&:last-child": {
                  marginRight: 0,
                },
                ".u-form-control": {
                  height: 72,
                  fontSize: theme.typography.h2.fontSize,
                  lineHeight: theme.typography.h2.lineHeight,
                  fontWeight: theme.typography.fontWeightBold,
                  ".MuiInputBase-input": {
                    height: 72,
                    textAlign: "center",
                    "&::placeholder": {
                      opacity: 1,
                    },
                  },
                },
              },
            },
          },
          ".btn": {
            marginTop: 32,
          },
        },
      },
    },
  },
}));

const CustomizedDialogs: React.FC<IDialogProps> = ({
  title,
  size,
  children,
  theme,
  open: visible,
  onCloseDialog,
  customFooter,
  noFooter,
  variant,
}) => {
  return (
    <>
      <BootstrapDialog
        theme={theme}
        className={clsx({
          "u-dialog": true,
          "u-dialog-sm": size === "sm",
          "u-dialog-xs": size === "xs",
          "u-dialog-md": size === "md",
          "u-dialog-xl": size === "xl",
          "u-dialog-lg": size === "lg",
          "confirmation-dialog": variant === "confirmation",
          "auth-dialog": variant === "auth",
        })}
        open={visible || false}
        onClose={onCloseDialog}
      >
        {/*u-dialog-sm class will be added for small size dialog*/}
        <Typography variant="h4" component="h4" className="u-dialog-title">
          {title}
          {onCloseDialog ? (
            <IconButton
              className="u-dialog-close"
              aria-label="close"
              onClick={onCloseDialog}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          ) : null}
        </Typography>
        <DialogContent
          dividers
          className={clsx({
            "u-dialog-content": true,
            "has-no-action": noFooter,
          })}
        >
          {/*has-no-action class will be added if dialog has no action buttons*/}
          {children}
        </DialogContent>
        {!noFooter && !customFooter && (
          <DialogActions className="u-dialog-action">
            <Button text="Save Changes" onClick={onCloseDialog} />
          </DialogActions>
        )}
        {customFooter && (
          <DialogActions className="u-dialog-action">
            {customFooter}
          </DialogActions>
        )}
      </BootstrapDialog>
    </>
  );
};

export default CustomizedDialogs;
