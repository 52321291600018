import "react";
import {
  Accordion,
  Box,
  Button,
  Grid,
  Icon,
  Input,
  Select,
} from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { AdditionalDetailsContentWrap } from "./additional-detail.style";
import AssetForm from "./asset-detail-form.component";
import CoBorrowerForm from "./co-borrower-form.component";
import GuarantorForm from "./guarantor-form.component";
import BankDetailForm from "./bank-detail-form.component";

const AdditionalDetailContent = () => {
  const theme = useTheme();
  return (
    <AdditionalDetailsContentWrap
      theme={theme}
      className="additional-details-content-wrap"
    >
      <Box theme={theme} className="accordion-box desktop-disable bg-light">
        <Accordion
          theme={theme}
          items={[
            {
              content: (
                <>
                  <AssetForm />
                </>
              ),
              title: "Asset 1",
              key: "string",
              isExpanded: true,
              id: "string",
              ariaControls: "string",
            },
            {
              content: (
                <>
                  <AssetForm />
                </>
              ),
              title: "Asset 2",
              key: "string-1",
              isExpanded: false,
              id: "string-1",
              ariaControls: "string-1",
            },
            {
              content: (
                <>
                  <CoBorrowerForm />
                </>
              ),
              title: "Co-Borrower Details",
              key: "string-2",
              isExpanded: false,
              id: "string-2",
              ariaControls: "string-2",
            },
            {
              content: (
                <>
                  <GuarantorForm />
                </>
              ),
              title: "Guarantor Details",
              key: "string-3",
              isExpanded: false,
              id: "string-3",
              ariaControls: "string-3",
            },
            {
              content: (
                <>
                  <BankDetailForm />
                </>
              ),
              title: "Customer Bank Details",
              key: "string-4",
              isExpanded: false,
              id: "string-4",
              ariaControls: "string-4",
            },
          ]}
          shouldCollapse
        />
      </Box>
    </AdditionalDetailsContentWrap>
  );
};

export default AdditionalDetailContent;
