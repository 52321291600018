import {
  ProposalDetailsForm,
  CustomerDetail,
  AddressDetails,
} from "@components/proposal/proposal-details";

export const ProposalDetails = () => {
  return (
    <>
      <ProposalDetailsForm />
      <CustomerDetail />
      <AddressDetails />
    </>
  );
};
