export enum AuthenticationTypes {
  UNITY = "unity",
}

export enum ClientTypes {
  LIMITED_COMPANY = "Limited company",
  INDIVIDUAL = "Individual",
  PARTNERSHIP = "Partnership",
  SOLE_TRADER = "Sole Trader",
  GOV_ORG = "Government Organization",
  NON_PROFIT_ORG = "Not-for-profit Organization",
}

export enum AssetTypes {
  VEHICLE = "Vehicle",
  EQUIPMENT = "Equipment",
  SOFTWARE = "Software",
}

export enum AssetCategories {
  SOFT_ASSET = "Soft Asset",
  HARD_ASSET = "Hard Asset",
}

export enum TableRowOptions {
  VIEW_EDIT = "view-edit",
  DELETE = "delete",
  VIEW = "view",
  UPLOAD = "upload",
  EDIT = "edit",
  CONVERT_TO_PROPOSAL = "convert-to-proposal",
  DOWNLOAD = "download",
  CLONE = "clone",
  WITHDRAW = "withdraw",
  OPEN_DIARY = "open-diary",
  PROPOSAL_SUMMARY = "proposal-summary",
  GENERATE_DOCUMENTS = "generate-documents",
  CHECK_SIGNATORY_STATUS = "signatory-status",
  VIEW_CONDITIONS = "view-conditions",
  SEND_FOR_PAYOUT = "send-for-payout",
  MANUAL_STATUS_CHANGE = "manual-status-change",
  VIEW_SIGNATORIES = "view-signatories",
}

export enum ProposalProductTypes {
  HIRE_PURCHASE = "Hire Purchase - HP",
  PERSONAL_CONTACT_PURCHASE = "Personal Contact Purchase - PCP",
  FINANCE_LEASE = "Finance Lease - FL",
  LOAN = "Loan",
  ASSET_BASED_LOAN = "Asset Based Loan",
}

export enum ProposalTabs {
  PROPOSAL_DETAILS = "Proposal Details",
  ASSET_DETAILS = "Asset Details",
  FINANCE_DETAILS = "Finance Details",
  CREDIT_DOCUMENTS = "Credit Documents",
  SUMMARY = "Summary",
}

export enum OrderStage {
  APPLICATION = "Application",
  QUOTATION = "Quotation",
}

export enum WorkQueueTabs {
  QUOTATIONS = "quotations",
  DRAFT_PROPOSALS = "draft-proposals",
  TRACK_PROPOSALS = "track-proposals",
  DEALER_PROPOSALS = "dealer-proposals",
  DASHBOARD_PROPOSALS = "dashboard-proposals",
}

export enum DocumentTemplateType {
  QUOTATION = "Quote Summary Word",
  PROPOSAL = "Proposal",
}

export enum OrderStatus {
  DRAFT = "Draft",
  WITHDRAWN = "Withdrawn",
  SUBMITTED = "Submitted",
  CONDITIONED = "Conditioned",
  DECLINED = "Declined",
  DOCUMENT_SENT = "Documents Sent",
  DOCUMENT_RECEIVED = "Documents Received",
  SENT_FOR_PAYOUT = "Sent for Payout",
  PAID_OUT = "Paid Out",
  ACCEPTED = "Approved",
}

export enum OrderStatusCategory {
  ACTIVE = "Active",
  CLOSED = "Closed",
}

export enum QuoteDataPlaceholderOptions {
  CONTEXT = "Context",
  OBJECT = "Object",
}

export enum AmountType {
  POUND = "£",
  PERCENTAGE = "%",
}

export enum ProposalStakeholders {
  DEALER = "Dealer",
  LENDER = "Lender",
}

export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

export enum FeeName {
  DOC_FEE = "Document Fee",
  ANNUAL_ADMIN_FEE = "Annual Admin Fee",
  OTP_FEE = "OTP Fee",
}

export enum SnackbarVariants {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
}

export enum ComparisonViewMode {
  INPUT = "input",
  RESULT = "result",
}

export enum DashboardRangeFilter {
  WEEK = "Week",
  MONTH = "Month",
  QUARTER = "Quarter",
  CALENDER_YEAR = "CalenderYear",
}

export enum PromiseStates {
  REJECTED = "rejected",
}

export enum ApplicationMode {
  DEALER = "dealer",
}

export enum DocumentTypes {
  CREDIT_DOCUMENTS = "Credit Documents",
}

export enum AppDialogKeys {
  MANUAL_STATUS_CHANGE = "manual-status-change",
  VIEW_CONDITIONS = "view-conditions",
  WITHDRAW_CONFIRMATION = "withdraw-confirmation",
}
