import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AddDocumentWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.add-document-wrap": {
    ".checklist-item": {
      marginBottom: 8,
      gap: 8,
      flexWrap: "nowwrap",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        marginBottom: 24,
      },
      "&:last-child": {
        marginBottom: 0,
      },
      ".u-switch-label": {
        [theme.breakpoints.up("md")]: {
          ".MuiSwitch-root": {
            order: 2,
            "+": {
              ".MuiFormControlLabel-label": {
                order: 1,
                marginLeft: 0,
                marginRight: 8,
              },
            },
          },
        },
      },
      ".custom-checkbox-wrap": {
        ".u-custom-control-label": {
          margin: 0,
          ".custom-checkbox": {
            "&.Mui-checked": {
              "~": {
                ".MuiFormControlLabel-label": {
                  fontWeight: theme.typography.fontWeightMedium,
                },
              },
            },
          },
        },
      },
    },
  },
}));
