import { Dropdown } from "@components/quotation/shared/controls";
import { Typography, Box } from "@mui/material";
import { QuotationFiltersWrap } from "@components/quotation/quotation-header/quotation-header.style";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@store/reducers";
import { ASSET_INFO, CUSTOMER_TYPES, BROKER_NAMES } from "@helpers/const";
import { setQuotationDetails } from "@store/actions/financialCalculation.actions";

export const QuotationFiltersHeader = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducerFinancialCalculation
  );

  const quotationDetails = storeState.quotationDetails;

  const dispatch = useDispatch();

  const getSelectItems = (
    arr: typeof ASSET_INFO | typeof CUSTOMER_TYPES | typeof BROKER_NAMES
  ) => {
    return arr.map((value) => ({ text: value, value: value }));
  };

  return (
    <QuotationFiltersWrap className="quotation-filters-wrap">
      <Box className="filters-area">
        <Box className="filter-item">
          <Typography
            variant="body2"
            component={"span"}
            display={"block"}
            className="text-muted"
          >
            Broker:
          </Typography>
          <Dropdown
            disablePortal={false}
            items={getSelectItems(BROKER_NAMES)}
            label={""}
            value={quotationDetails.brokerName}
            onChange={(e) => {
              const value = e.target.value;
              dispatch(
                setQuotationDetails({
                  ...quotationDetails,
                  brokerName: value,
                })
              );
            }}
          />
        </Box>
        <Box className="filter-item">
          <Typography
            variant="body2"
            component={"span"}
            display={"block"}
            className="text-muted"
          >
            Asset Type:
          </Typography>
          <Dropdown
            disablePortal={false}
            items={getSelectItems(ASSET_INFO)}
            onChange={(e) => {
              const value = e.target.value;
              dispatch(
                setQuotationDetails({
                  ...quotationDetails,
                  asset: {
                    ...quotationDetails.asset,
                    type: value,
                  },
                })
              );
            }}
            label={""}
            value={quotationDetails.asset.type}
          />
        </Box>
        <Box className="filter-item">
          <Typography
            variant="body2"
            component={"span"}
            display={"block"}
            className="text-muted"
          >
            Client Type:
          </Typography>
          <Dropdown
            disablePortal={false}
            items={getSelectItems(CUSTOMER_TYPES)}
            onChange={(e) => {
              const value = e.target.value;
              dispatch(
                setQuotationDetails({
                  ...quotationDetails,
                  clientType: value,
                })
              );
            }}
            label={""}
            value={quotationDetails.clientType}
          />
        </Box>
      </Box>
    </QuotationFiltersWrap>
  );
};
