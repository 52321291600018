import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  BoxProps,
  Grid,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nonDecimalFieldType, RentalTypes } from "@flexCommon/constants";
import { FieldType, RentalType, SolveFor } from "@flexCommon/enums";
import { getDisabledProp } from "@helpers/common";
import { IStructureRental } from "@interfaces";
import { IFieldProp } from "@interfaces/configuration.interface";
import Icon from "@components/quotation/shared/assets/Icon";
import { setFinancialRequestObj } from "@store/actions/financialCalculation.actions";
import { RootState } from "@store/reducers";
import {
  AccordianWrap,
  Button,
  CustomizedSwitch,
  Dropdown,
  IconButton,
  ResponsiveWrap,
  TextBox,
} from "@components/quotation/shared/controls";
import { CalculationWrap } from "@components/quotation/configuration.style";
import { CombinedControl } from "@components/quotation/vat/vat.component";

export const SectionWrap: any = styled(
  Box,
  {}
)<Partial<BoxProps>>(({ theme }) => ({
  width: "100%",
  display: "inline-block",
  paddingLeft: 15,
  paddingTop: 24,
  ".col-switch": {
    ".MuiFormGroup-root": {
      float: "right",
    },
  },
  "@media screen and (max-width: 959px)": {
    ".col-switch": {
      ".MuiFormGroup-root": {},
    },
  },
}));

export const SubSectionFieldsWrap: any = styled(
  Box,
  {}
)<Partial<BoxProps>>(({ theme }) => ({}));
type IStructure = {
  fieldProps: IFieldProp[];
};
export const StructureRentals = ({ fieldProps }: IStructure) => {
  const storeState = useSelector(
    (state: RootState) => state.reducerFinancialCalculation
  );
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    !storeState.RequestObj.HasStructureRentals &&
      dispatch(
        setFinancialRequestObj({
          ...storeState.RequestObj,
          StructureRentals: [],
        })
      );
  }, [storeState.RequestObj.HasStructureRentals]);

  useEffect(() => {
    if (getDisabledProp(fieldProps, FieldType.IrregularPaymentsApplicable)) {
      dispatch(
        setFinancialRequestObj({
          ...storeState.RequestObj,
          HasStructureRentals: false,
        })
      );
    }
  }, [getDisabledProp(fieldProps, FieldType.IrregularPaymentsApplicable)]);

  const addRental = () => {
    var structures = storeState.RequestObj.StructureRentals;
    var count = structures.length;
    var _lastTerm = count > 0 ? structures[count - 1].StartTerm : 0;
    var newRental: IStructureRental = {
      RentalId: count + 1,
      RentalType: "Structured",
      Amount: 0.0,
      StartTerm: count > 0 ? ++_lastTerm : 1,
      EndTerm: storeState.RequestObj.RegularPayments,
      RentalFrequency: "",
    };
    structures.push(newRental);
    dispatch(
      setFinancialRequestObj({
        ...storeState.RequestObj,
        StructureRentals: structures,
      })
    );
  };

  const addDefaultRental = () => {
    var structures = storeState.RequestObj.StructureRentals;
    var count = structures.length;
    var newRental: IStructureRental = {
      RentalId: count + 1,
      RentalType: "Structured",
      Amount: 0.0,
      StartTerm: 1,
      EndTerm: 1,
      RentalFrequency: "",
    };
    structures.push(newRental);
    dispatch(
      setFinancialRequestObj({
        ...storeState.RequestObj,
        StructureRentals: structures,
      })
    );
  };

  const deleteRental = (index: number) => {
    var structures = storeState.RequestObj.StructureRentals;
    structures = structures.filter((_, i) => i !== index);
    dispatch(
      setFinancialRequestObj({
        ...storeState.RequestObj,
        StructureRentals: structures,
        HasStructureRentals: structures.length ? true : false,
      })
    );
  };

  const valuesChange = (
    index: number,
    fieldName: keyof IStructureRental,
    value: string | number | boolean
  ) => {
    var structures: any[] = storeState.RequestObj.StructureRentals;
    structures[index][fieldName] = value;
    dispatch(
      setFinancialRequestObj({
        ...storeState.RequestObj,
        StructureRentals: structures,
      })
    );
  };

  const RsForm = (structure: IStructureRental, index: number) => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <CombinedControl>
              <TextBox
                testid="txtStartTerm"
                fullWidth
                label="Start Term"
                type="number"
                value={structure.StartTerm}
                onChange={(e) =>
                  valuesChange(index, "StartTerm", e.target.value)
                }
              />
              <TextBox
                testid="txtEndTerm"
                fullWidth
                label="End Term"
                type="number"
                value={structure.EndTerm}
                onChange={(e) => valuesChange(index, "EndTerm", e.target.value)}
              />
            </CombinedControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CombinedControl>
              <TextBox
                theme={theme}
                testid="txtRentalAmount"
                label="Amount"
                type="text"
                fullWidth
                masking
                thousandSeparater
                disabled={structure.RentalType == RentalType.Zero}
                value={
                  structure.Amount !== 0 || structure.Amount > 0
                    ? structure.Amount
                    : ""
                }
                placeHolder={
                  nonDecimalFieldType.includes("RentalAmount") ? "0" : "0.00"
                }
                onChange={(e) => {
                  valuesChange(index, "Amount", e.target.value);
                }}
              />
              <Dropdown
                testid="txtRentalType"
                label="Type"
                items={
                  storeState.SolveFor == SolveFor.PeriodicPayment
                    ? RentalTypes
                    : RentalTypes.filter(
                        (item) => item.value != "Bonus Payment"
                      )
                }
                value={structure.RentalType}
                onChange={(e) => {
                  valuesChange(index, "RentalType", e.target.value);
                }}
              />
            </CombinedControl>
          </Grid>
        </Grid>
      </>
    );
  };

  const getStructureRentals = () => {
    if (storeState.RequestObj.HasStructureRentals) {
      return (
        <>
          {storeState.RequestObj.StructureRentals.map((structure, index) => (
            <AccordianWrap key={index}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box className="title">STRUCTURE PAYMENT {index + 1}</Box>
                  <Button
                    defaultBtn
                    className="btn-delete"
                    onClick={() => deleteRental(index)}
                    iconText={<Icon name="DeleteIcon" />}
                  />
                </AccordionSummary>
                <AccordionDetails>{RsForm(structure, index)}</AccordionDetails>
              </Accordion>
            </AccordianWrap>
          ))}
          <Box sx={{ pb: 2 }}>
            <Button
              fullWidth
              secondary
              text="Add More"
              onClick={() => {
                addRental();
              }}
            />
          </Box>
        </>
      );
    } else {
      return <div></div>;
    }
  };

  const getLength = () => {
    var lenght = storeState.RequestObj.HasStructureRentals
      ? storeState.RequestObj.StructureRentals.length
      : 0;
    return lenght;
  };

  return (
    <CalculationWrap className="results">
      <SectionWrap>
        <Grid item xs={12}>
          <Grid container columnSpacing={2} rowSpacing={3} sx={{ mb: 2 }}>
            <Grid item xs={10} sm={8}>
              <Typography
                variant="h4"
                component="h4"
                children={<>Irregular Payments {"(" + getLength() + ")"}</>}
              />
            </Grid>
            <Grid item xs={2} sm={4} className="col-switch">
              <CustomizedSwitch
                disabled={getDisabledProp(
                  fieldProps,
                  FieldType.IrregularPaymentsApplicable
                )}
                value={storeState.RequestObj.HasStructureRentals}
                switchEnabled={storeState.RequestObj.HasStructureRentals}
                onChange={(e) => {
                  if (
                    e.target.checked == true &&
                    storeState.RequestObj.StructureRentals.length == 0
                  ) {
                    addDefaultRental();
                  }
                  dispatch(
                    setFinancialRequestObj({
                      ...storeState.RequestObj,
                      HasStructureRentals: e.target.checked,
                    })
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {getStructureRentals()}
            </Grid>
          </Grid>
        </Grid>
      </SectionWrap>
    </CalculationWrap>
  );
};
