import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const FinancialConfigFeeWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.financial-config-fee-wrap": {
    borderTop: "1px solid" + theme.palette.divider,
    marginTop: 24,
    paddingTop: 24,
    ".field-box": {
      display: "flex",
      alignItems: "center",
      gap: 16,
      ".u-form-group": {
        marginBottom: 0,
        flexGrow: 1,
      },
    },
    ".fee-item": {
      [theme.breakpoints.down("md")]: {
        borderBottom: "1px solid" + theme.palette.divider,
        paddingBottom: 24,
        "&:last-child": {
          borderBottom: "none",
          paddinBottom: 0,
        },
      },
    },
  },
}));
