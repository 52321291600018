import {
  NewQuotation,
  useQuotationFormContext,
  ActionButtons,
  QuotationControlMode,
} from "@ntpkunity/controls-common";
import { useSnackbarContext } from "@contexts/snackbar";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import { ApplicationMode } from "@helpers/enum";
import { LOCAL_STORAGE_KEYS } from "@helpers/const";

export const QuotationTool = () => {
  const theme = useTheme();
  const { setSnackbar } = useSnackbarContext();
  const { reset, getValues } = useQuotationFormContext();
  const navigate = useNavigate();
  const mode = localStorage.getItem(LOCAL_STORAGE_KEYS.MODE);
  const isDealer = mode === ApplicationMode.DEALER;
  return (
    <NewQuotation
      mode={
        isDealer
          ? QuotationControlMode.BROKER_SIMPLE
          : QuotationControlMode.BROKER_ADVANCED
      }
      theme={theme}
      resultsActionArea={() => (
        <ActionButtons
          theme={theme}
          onCalculateError={(error) => {
            const errorMessage = error.response.data?.Message;
            setSnackbar({
              open: true,
              message:
                errorMessage || "Error Occurred while Calculating Quotation",
              variant: "error",
            });
          }}
          onCalculateSuccess={() => {
            reset(getValues());
            setSnackbar(
              { open: true, message: "Quotation Calculated Successfully" },
              2000
            );
          }}
          handleComparisonClick={() =>
            navigate(APP_ROUTES.QUOTATION_COMPARISON)
          }
        />
      )}
    />
  );
};
