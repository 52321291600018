import { Button, Icon, Menu } from "@ntpkunity/controls";
import { TableCell, TableRow, useTheme } from "@mui/material";
import { AssetDetailsRowProps } from "./asset-details-row.props";
import { TableRowOptions } from "@helpers/enum";
import { useProposalFieldArrays } from "@contexts/proposal-form";
import { pounds } from "@helpers/utils";

const AssetTableRow = ({
  index,
  asset,
  setEditAssetId,
  loadedFromSummary,
}: AssetDetailsRowProps) => {
  const theme = useTheme();
  const {
    assetMethods: { remove },
  } = useProposalFieldArrays();

  const handleOptionClick = (_, key, value) => {
    if (key === TableRowOptions.VIEW_EDIT) {
      setEditAssetId(value);
      return;
    }
    if (key === TableRowOptions.DELETE) {
      remove(value);
      return;
    }
  };

  return (
    <TableRow className="child-tr">
      <TableCell>{asset.subType}</TableCell>
      {!loadedFromSummary && <TableCell>{asset.description}</TableCell>}
      <TableCell>{asset.supplierName}</TableCell>
      <TableCell>{asset.quantity}</TableCell>
      <TableCell className="text-right">
        {pounds.format(asset.totalCost)}
      </TableCell>
      <TableCell className="action-cell fixed-cell">
        <Menu
          theme={theme}
          options={[
            {
              optionText: "View/Edit",
              optionkey: TableRowOptions.VIEW_EDIT,
              optionValue: asset.id,
            },
            {
              optionText: "Delete",
              optionkey: TableRowOptions.DELETE,
              optionValue: index,
            },
          ]}
          handleOptionClick={handleOptionClick}
          render={(cb) => (
            <Button
              defaultBtn
              theme={theme}
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            ></Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default AssetTableRow;
