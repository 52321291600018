import {
  APRCalculationMethod,
  AmortizationMethod,
  BalloonCollection,
  CommissionType,
  FeeName,
  FeeType,
  FinanceType,
  RateTypes,
  SolveFor,
  StepType,
  VatDeferralType,
  VatTreatment,
} from "@flexCommon/enums";
import { FieldData } from "@data/fieldProps.data";
import {
  INotification,
  IRequestObj,
  IResponseObj,
  QuotationDetails,
  RatesVisibility,
} from "@interfaces";
import {
  IConfigurationInputs,
  IFieldProp,
} from "@interfaces/configuration.interface";
import { CustomerDetail } from "@interfaces/requestparams.flex";
import ActionTypes from "@store/actionTypes";
import { BROKER_NAMES } from "@helpers/const";

export type IReducerFinancialCalculation = {
  CalculationInProgress: boolean;
  CalculationConfiguration?: IConfigurationInputs;
  RentalCalculationInProgress: boolean;
  CalculatedNFA: number;
  CalculatedCommission: number;
  CalculatedUpfrontPayment: number;
  RequestObj: IRequestObj;
  ResponseObj: IResponseObj;
  Notification: INotification;
  SliderOpen: boolean;
  RatesVisibility: RatesVisibility;
  Alert: boolean;
  SolveFor: string | number;
  GrossProfit: number;
  CustomerDetail: CustomerDetail;
  RetrieveDetail: CustomerDetail;
  RetrievedData: CustomerDetail[];
  GrossProfitApplicable: boolean;
  quotationDetails: QuotationDetails;
};
const initialState: IReducerFinancialCalculation = {
  CalculationInProgress: false,
  CalculationConfiguration: {
    AmortizationMethod: AmortizationMethod.AnnuityActual365,
    APRCalculationMethod: APRCalculationMethod.AnnuityActual365,
    CommissionCalculationMethod: "",
    Fees: [
      {
        Amount: 195,
        FirstDueDate: new Date().toString(),
        Frequency: FeeType.OneTime,
        Name: FeeName.DocFee,
        Occurance: "WithFirstPayment",
        PaymentNumber: 0,
        IsEditable: true,
      },
      {
        Amount: 35,
        FirstDueDate: new Date().toString(),
        Frequency: FeeType.Periodic,
        Name: FeeName.AnnualAdminFee,
        Occurance: "Annual",
        PaymentNumber: 0,
        IsEditable: true,
      },
      {
        Amount: 200,
        FirstDueDate: new Date().toString(),
        Frequency: FeeType.OneTime,
        Name: FeeName.OTPFee,
        Occurance: "WithLastPayment",
        PaymentNumber: 0,
        IsEditable: true,
      },
    ],
    InitialCashOutFlowForFlatRate: "",
    IsCommissionPassToCustomer: false,
    IsVATApplicable: true,
    FieldProps: FieldData,
    Tax: 0,
    GrossProfit: false,
  },
  RatesVisibility: {
    aprExclComm: true,
    aprInclComm: true,
    flatRateExclComm: true,
    flatRateInclComm: true,
    grossYield: true,
    netYield: true,
  },
  RentalCalculationInProgress: false,
  CalculatedCommission: 0,
  CalculatedUpfrontPayment: 0,
  SliderOpen: false,
  Alert: false,
  CalculatedNFA: 0,
  SolveFor: SolveFor.PeriodicPayment,
  Notification: {
    message: "",
    visible: false,
  },
  RequestObj: {
    AssetCost: 0,
    AdvancePayments: 0,
    RegularPayments: 0,
    StartDate: new Date().toISOString(),
    PaymentMode: "Arrear",
    Frequency: "Monthly",
    Rate: 0,
    DepositAmount: 0.0,
    Deposit: 0.0,
    ExtensionDays: 0,
    DepositType: CommissionType.Amount,
    ResidualValue: 0,
    FundingCost: 0,
    BalloonPayment: 0,
    PeriodicPayment: 0,
    toggle: "scroll h-md",
    RateType: RateTypes.NominalRate,
    BalloonCollection: BalloonCollection.OneMonthAfterLastPayment,
    StructureRentals: [],
    VAT: {
      PaymentNumber: 3,
      Amount: 0.0,
      Treatment: VatTreatment.VATDeferral,
      type: VatDeferralType.Rental,
    },
    CommissionAmount: 0.0,
    CommissionPercentage: 0.0,
    HasStructureRentals: false,
    CommissionType: CommissionType.Percentage,
    FinanceType: FinanceType.HirePurchase,
    Fee: [
      {
        Amount: 195,
        FirstDueDate: new Date().toString(),
        Frequency: FeeType.OneTime,
        Name: FeeName.DocFee,
        Occurance: "WithFirstPayment",
        PaymentNumber: 0,
        IsEditable: true,
      },
      {
        Amount: 35,
        FirstDueDate: new Date().toString(),
        Frequency: FeeType.Periodic,
        Name: FeeName.AnnualAdminFee,
        Occurance: "Annual",
        PaymentNumber: 0,
        IsEditable: true,
      },
      {
        Amount: 200,
        FirstDueDate: new Date().toString(),
        Frequency: FeeType.OneTime,
        Name: FeeName.OTPFee,
        Occurance: "WithLastPayment",
        PaymentNumber: 0,
        IsEditable: true,
      },
    ],
    hideFlatRateExcl: false,
    Step: {
      Frequency: 0,
      Percentage: 0,
      Type: StepType.StepUp,
    },
    hasStep: false,
    StepPaymentStructure: [],
    hasStepPaymentStructure: false,
    StepPaymentsApplicable: false,
    StepPaymentsStructureApplicable: false,
    IsAutoCalculated: false,
    Tax: 0,
    IrregularPaymentsApplicable: true,
    VATAmountAutoCalculateApplicable: false,
    PDFTemplateApplicable: true,
    WordTemplateApplicable: true,
    IFieldProps: [],
  },
  ResponseObj: {
    rentals: [],
    repaymentPlan: [],
    repaymentPlanWithFee: [],
    rates: {
      grossYield: 0.0,
      netYield: 0.0,
      aprExclCommission: 0.0,
      aprInclCommission: 0.0,
      flatRateExclCommission: 0.0,
      flatRateInclCommission: 0.0,
    },
  },
  GrossProfit: 0,
  CustomerDetail: {
    CustomerFullName: "",
    CustomerEmailAddress: "",
    CustomerContactNumber: "",
    VendorPersonName: "",
    VendorEmailAddress: "",
    TrackingID: "",
    Comments: "",
  },
  RetrieveDetail: {
    CustomerFullName: "",
    CustomerEmailAddress: "",
    CustomerContactNumber: "",
    VendorPersonName: "",
    VendorEmailAddress: "",
    TrackingID: "",
    Comments: "",
  },
  RetrievedData: [],
  GrossProfitApplicable: false,
  quotationDetails: {
    name: "",
    brokerName: BROKER_NAMES[0],
    asset: {
      identifier: "",
      type: "",
    },
    clientType: "",
  },
};
export function reducerFinancialCalculation(
  currentState = initialState,
  action: any
): IReducerFinancialCalculation {
  switch (action.type) {
    case ActionTypes.SET_FINANCIAL_REQUEST_DATA: {
      return {
        ...currentState,
        RequestObj: action.payload,
      };
    }
    case ActionTypes.SET_FINANCIAL_RESPONSE_DATA: {
      return {
        ...currentState,
        ResponseObj: action.payload,
      };
    }
    case ActionTypes.SET_CUSTOMER_DETAIL: {
      return {
        ...currentState,
        CustomerDetail: action.payload,
      };
    }
    case ActionTypes.SET_RETRIVE_DETAIL: {
      return {
        ...currentState,
        RetrieveDetail: action.payload,
      };
    }
    case ActionTypes.SET_RETRIVED_DATA: {
      return {
        ...currentState,
        RetrievedData: action.payload,
      };
    }
    case ActionTypes.SET_NOTIFICATION: {
      return {
        ...currentState,
        Notification: action.payload,
      };
    }
    case ActionTypes.SET_RENTAL_CALCULATION_PROGRESS: {
      return {
        ...currentState,
        RentalCalculationInProgress: action.payload,
      };
    }
    case ActionTypes.SET_RATES_VISIBILITY: {
      return {
        ...currentState,
        RatesVisibility: action.payload,
      };
    }
    case ActionTypes.SET_CALCULATED_COMMISSION: {
      return {
        ...currentState,
        CalculatedCommission: action.payload,
      };
    }
    case ActionTypes.SET_CALCULATION_UPFRONTPAYMENT: {
      return {
        ...currentState,
        CalculatedUpfrontPayment: action.payload,
      };
    }
    case ActionTypes.SET_CALCULATED_NFA: {
      return {
        ...currentState,
        CalculatedNFA: action.payload,
      };
    }
    case ActionTypes.SET_SLIDER_OPEN: {
      return {
        ...currentState,
        SliderOpen: action.payload,
      };
    }
    case ActionTypes.SET_CALCULATION_IN_PROGRESS: {
      return {
        ...currentState,
        CalculationInProgress: action.payload,
      };
    }
    case ActionTypes.SET_CALCULATION_CONFIGURATION: {
      return {
        ...currentState,
        CalculationConfiguration: action.payload,
      };
    }
    case ActionTypes.SET_ALERT: {
      return {
        ...currentState,
        Alert: action.payload,
      };
    }
    case ActionTypes.SET_SOLVE_FOR: {
      return {
        ...currentState,
        SolveFor: action.payload,
      };
    }
    case ActionTypes.SET_RESET_VALUES: {
      return action.payload;
    }
    case ActionTypes.SET_GROSS_PROFIT: {
      return {
        ...currentState,
        GrossProfit: action.payload,
      };
    }
    case ActionTypes.SET_GROSS_PROFIT_APPLICABLE: {
      return {
        ...currentState,
        GrossProfitApplicable: action.payload,
      };
    }
    case ActionTypes.SET_QUOTATION_DETAILS: {
      return {
        ...currentState,
        quotationDetails: action.payload,
      };
    }

    case ActionTypes.RESET_FINANCIAL_CALCULATION: {
      return initialState;
    }

    default:
      return currentState;
  }
}
