import "react";
import {
  AddressLayout,
  Box,
  Button,
  Checkbox,
  Dialog,
  FileUpload,
  FileUploadPreview,
  Grid,
  Input,
  Select,
  Typography,
} from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { DialogContentContainer } from "./document.style";

const UploadPackDialog = ({ open, onClose }) => {
  const theme = useTheme();
  return (
    <Dialog
      theme={theme}
      size={"sm"}
      onCloseDialog={onClose}
      open={open}
      title="Upload Pack"
      customFooter={
        <>
          <Button
            theme={theme}
            type="button"
            secondary
            text="Cancel"
            onClick={onClose}
          />
          <Button
            theme={theme}
            type="button"
            primary
            text="Save Documents"
            onClick={() => {}}
          />
        </>
      }
    >
      <DialogContentContainer
        theme={theme}
        className="dialog-content-container"
      >
        <Box theme={theme} className="upload-container">
          <FileUpload
            theme={theme}
            onChange={() => {}}
            inlinePreview={false}
            onDrop={() => {}}
            hoverLabel={
              <>
                Drag and drop file, or{" "}
                <span className="text-primary">browse</span>
              </>
            }
            allowMultiple
          />
          <FileUploadPreview
            theme={theme}
            files={[
              {
                key: "image-1",
                file: {
                  location:
                    "https://unitydmsbucket.s3.amazonaws.com/990705wheels.jpg",
                  key: "990705wheels.jpg",
                  size: 150,
                  name: "Sample File Name 1.jpg",
                  type: "image",
                },
                viewIcon: true,
                preview: "fileIcon",
                isUploading: false,
                error: false,
                uploadingLabel: "",
                uploadingProgress: undefined,
                onRemoveFile: () => {},
                onRetryForError: () => {},
              },
            ]}
          />
          <Box theme={theme} mt={2}>
            <Typography
              theme={theme}
              variant="caption"
              component={"small"}
              display={"block"}
              className="text-muted"
              textAlign={"center"}
            >
              You can upload multiple files. Files can be up to 30MB. You can
              upload jpg, jpeg, png and pdf file formats.
            </Typography>
          </Box>
        </Box>
        <Box theme={theme} className="doc-checklist-wrap" mt={3}>
          <Typography
            theme={theme}
            variant="caption"
            component={"small"}
            display={"block"}
            className="text-muted"
            textAlign={"center"}
          >
            *Please select the documents against which you have uploaded the
            files
          </Typography>
          <Box theme={theme} className="checklist-items-wrap">
            <Box theme={theme} className="checklist-item">
              <Checkbox
                theme={theme}
                label={"Sample Document Name 1"}
                checkBoxChecked
              />
            </Box>
            <Box theme={theme} className="checklist-item">
              <Checkbox theme={theme} label={"Sample Document Name 1"} />
            </Box>
            <Box theme={theme} className="checklist-item">
              <Checkbox
                theme={theme}
                label={"Sample Document Name 1"}
                checkBoxChecked
              />
            </Box>
          </Box>
        </Box>
      </DialogContentContainer>
    </Dialog>
  );
};

export default UploadPackDialog;
