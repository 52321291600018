import { IFieldProp } from "@interfaces/configuration.interface";
import { QuoteDataPlaceholderOptions, FeeName } from "./enum";
import { IReducerFinancialCalculation } from "@store/reducers/financialCalculation.reducer";

export const getDisabledProp = (
  fieldProps: IFieldProp[],
  fieldName: string
) => {
  return fieldProps?.find((x) => x.Name === fieldName)?.Disabled ?? false;
};
export const getHiddenProp = (fieldProps: IFieldProp[], fieldName: string) => {
  return fieldProps?.find((x) => x.Name === fieldName)?.Hidden ?? false;
};

export const getDataForQuoteDownload = ({
  from,
  data,
}:
  | {
      from: QuoteDataPlaceholderOptions.OBJECT;
      data: any;
    }
  | {
      from: QuoteDataPlaceholderOptions.CONTEXT;
      data: IReducerFinancialCalculation;
    }) => {
  switch (from) {
    case QuoteDataPlaceholderOptions.CONTEXT:
      return {
        no_of_advance_payment: data?.RequestObj?.AdvancePayments || 0,
        no_of_regular_payment: data?.RequestObj?.RegularPayments || 0,
        vat_payable: data?.RequestObj?.VAT?.Treatment || "",
        vat: Number(data?.RequestObj?.VAT?.Amount || 0).toFixed(2),
        finance_amount: Number(data?.CalculatedNFA || 0).toFixed(2),
        cost: Number(data?.RequestObj?.AssetCost || 0).toFixed(2),
        deposit: Number(data?.RequestObj?.Deposit).toFixed(2) || 0,
        balloon: Number(data?.RequestObj?.BalloonPayment).toFixed(2) || 0,
        interest: Number(0).toFixed(2),
        total_charge_credit: Number(0).toFixed(2),
        annual_admin_fee: Number(
          data?.RequestObj?.Fee?.filter(
            (f) => f.Name === FeeName.ANNUAL_ADMIN_FEE
          )?.[0]?.Amount || 0
        ).toFixed(2),
        rate_of_interest: Number(data?.RequestObj?.Rate || 0).toFixed(2),
        apr_rate: Number(
          data?.ResponseObj?.rates?.aprExclCommission || 0
        ).toFixed(),
        term:
          data?.RequestObj?.AdvancePayments ||
          0 + data?.RequestObj?.RegularPayments ||
          0,
        amount_1: Number(0).toFixed(2),
        amount_2: Number(
          data?.RequestObj?.Fee?.filter((f) => f.Name === FeeName.DOC_FEE)?.[0]
            ?.Amount || 0
        ).toFixed(2),
        amount_3: Number(
          data?.RequestObj?.Fee?.filter((f) => f.Name === FeeName.OTP_FEE)?.[0]
            ?.Amount || 0
        ).toFixed(2),
      };
    case QuoteDataPlaceholderOptions.OBJECT:
      return {
        no_of_advance_payment: data?.no_of_advance_payments || 0,
        no_of_regular_payment: data?.no_of_regular_payments || 0,
        vat_payable: data?.vat_treatment || "",
        vat: Number(data?.deferral_amount || 0).toFixed(2),
        finance_amount: Number(data?.finance_amount || 0).toFixed(2),
        cost: Number(data?.selling_price || 0).toFixed(2),
        deposit: Number(data?.down_payment || 0).toFixed(2),
        balloon: Number(data?.rv_balloon_value || 0).toFixed(2),
        interest: Number(0).toFixed(2),
        total_charge_credit: Number(0).toFixed(2),
        annual_admin_fee: Number(
          data?.order_fees?.filter(
            (f) => f.dealer_fee_name === FeeName.ANNUAL_ADMIN_FEE
          )?.[0]?.applied_price || 0
        ).toFixed(2),
        rate_of_interest: Number(data?.margin || 0).toFixed(2),
        apr_rate: Number(data?.meta_data?.apr || 0).toFixed(2),
        term:
          data?.no_of_advance_payments || 0 + data?.no_of_regular_payments || 0,
        amount_1: Number(0).toFixed(2),
        amount_2: Number(
          data?.order_fees?.filter(
            (f) => f.dealer_fee_name === FeeName.DOC_FEE
          )?.[0]?.applied_price || 0
        ).toFixed(2),
        amount_3: Number(
          data?.order_fees?.filter(
            (f) => f.dealer_fee_name === FeeName.OTP_FEE
          )?.[0]?.applied_price || 0
        ).toFixed(2),
      };
  }
};
