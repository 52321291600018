import { useEffect, useState } from "react";
import { ClientTypes } from "@helpers/enum";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types";

const PARTNER_SPECIFIC_NAMES = [
  "companyName",
  "companyRegNum",
  "tradingAs",
  "natureOfBusiness",
  "yearsInBusiness",
];

const FIELD_NAMES = {
  [ClientTypes.INDIVIDUAL]: [
    "firstName",
    "lastName",
    "idNumber",
    "emailAddress",
    "contactNumber",
  ],
  [ClientTypes.PARTNERSHIP]: PARTNER_SPECIFIC_NAMES,
  [ClientTypes.LIMITED_COMPANY]: [...PARTNER_SPECIFIC_NAMES, "sicCode"],
  [ClientTypes.GOV_ORG]: PARTNER_SPECIFIC_NAMES,
  [ClientTypes.NON_PROFIT_ORG]: PARTNER_SPECIFIC_NAMES,
  [ClientTypes.SOLE_TRADER]: PARTNER_SPECIFIC_NAMES,
};

const useCustomerFields = () => {
  const [customerFields, setCustomerFields] = useState<string[]>([
    "customerDetails.clientType",
  ]);

  const { watch } = useFormContext<TProposalForm>();
  const clientType: ClientTypes = watch("customerDetails.clientType");

  const updateCustomerFields = (clientType: ClientTypes) => {
    if (Object.values(ClientTypes).includes(clientType)) {
      setCustomerFields(
        ["clientType", ...FIELD_NAMES[clientType]]
          .filter(Boolean)
          .map((fieldName) => `customerDetails.${fieldName}`)
      );
    }
  };

  useEffect(() => {
    updateCustomerFields(clientType);
  }, [clientType]);

  return customerFields;
};

export default useCustomerFields;
