import { SnackbarProps, useTheme } from "@mui/material";
import { ConfirmationDialogContent } from "./confirmation-dialog.style";
import {
  Box,
  Dialog,
  Icon,
  ISnackbarProps,
  Snackbar,
  Typography,
} from "@ntpkunity/controls";
import React from "react";

type ConfirmationDialogProps = {
  dialogIcon: any;
  title: string;
  message: string | React.ReactNode;
  actionArea: React.ReactNode;
  open: boolean;
  snackbarState?: {
    open: boolean;
    message: string;
    variant?: ISnackbarProps["variant"];
  };
  resetSnackbar?: () => void;
};

export const ConfirmationDialog = ({
  open,
  dialogIcon,
  title,
  message,
  actionArea,
  snackbarState,
  resetSnackbar,
}: ConfirmationDialogProps) => {
  const theme = useTheme();
  return (
    <Dialog
      theme={theme}
      size="xs"
      variant="confirmation"
      customFooter={actionArea}
      open={open}
    >
      <ConfirmationDialogContent
        theme={theme}
        className="confirmation-dialog-content"
      >
        <Box theme={theme} className="dialog-icon">
          <Icon name={dialogIcon} />
        </Box>
        <Box theme={theme}>
          <Typography
            theme={theme}
            mb={2}
            className="content-title"
            variant="h2"
            component="h2"
          >
            {title}
          </Typography>
          <Typography
            theme={theme}
            className="text-muted"
            variant="body2"
            component="span"
          >
            {message}
          </Typography>
        </Box>
        <Snackbar
          open={snackbarState?.open}
          message={snackbarState?.message}
          variant={snackbarState?.variant}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          onClose={resetSnackbar}
          action={true}
        />
      </ConfirmationDialogContent>
    </Dialog>
  );
};
