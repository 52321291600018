import Entry from "../entry/entry.component";

const Rates = () => {
  return (
    <>
      <Entry title="Gross Yield" value={"13.41%"} />
      <Entry title="Net Yield" value={"11.00%"} />
      <Entry title="APR" value={"14.68%"} />
      <Entry title="Flat Rate Excl. Commission" value={"5.80%"} />
      <Entry title="Flat Rate Incl. Commission" value={"7.26%"} />
    </>
  );
};

export default Rates;
