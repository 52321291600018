import SnackbarContext from "./snackbar.context";

import { useState } from "react";
import { TSnackbarState } from "./snackbar.type";

const snackbarInitialState: TSnackbarState = {
  open: false,
  message: "",
  variant: null,
};

const SnackbarContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [snackbar, _setSnackbar] = useState(snackbarInitialState);

  const resetSnackbar = () => {
    _setSnackbar(snackbarInitialState);
  };

  const setSnackbar = (
    { open, message, variant = null }: TSnackbarState,
    timeout = null
  ) => {
    _setSnackbar({ open, message, variant });
    if (timeout)
      setTimeout(() => {
        resetSnackbar();
      }, timeout);
  };

  return (
    <SnackbarContext.Provider
      value={{
        snackbar,
        resetSnackbar,
        setSnackbar,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContextProvider;
