import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AddCommentsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.add-comments-wrap": {
    ".u-form-group": {
      marginBottom: 0,
    },
  },
}));
