import { AuthLayout, SignInForm } from "@components";
import { useTheme } from "@mui/material";

export const SignInPage = () => {
  const theme = useTheme();

  return (
    <AuthLayout>
      <SignInForm />
    </AuthLayout>
  );
};
