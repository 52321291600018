import "react";
import {
  AddressLayout,
  Box,
  Button,
  Dialog,
  Grid,
  Input,
  Select,
  Typography,
} from "@ntpkunity/controls";
import { Divider, useTheme } from "@mui/material";
import { GB_COUNTRY } from "@helpers/const";
import { CustomerDetailPopupWrap } from "./customer-detail-edit-popup.style";

const EditCustomerDetailPopup = ({ open, onClose }) => {
  const theme = useTheme();
  return (
    <Dialog
      theme={theme}
      size={"sm"}
      onCloseDialog={onClose}
      open={open}
      title="Edit Customer Details"
      customFooter={
        <>
          <Button
            theme={theme}
            type="button"
            secondary
            text="Cancel"
            onClick={onClose}
          />
          <Button
            theme={theme}
            type="button"
            primary
            text="Save"
            onClick={() => {}}
          />
        </>
      }
    >
      <CustomerDetailPopupWrap theme={theme} className="customer-proposal-wrap">
        <Grid theme={theme} container item columnSpacing={2} rowSpacing={2}>
          <Grid theme={theme} item xs={12}>
            <Box theme={theme} mb={1}>
              <Typography
                theme={theme}
                component="h4"
                variant="h4"
                children={"Customer Details"}
              />
              <Typography
                theme={theme}
                component="small"
                variant="caption"
                className="text-primary fw-medium"
                children={"Lookup from companies house"}
              />
            </Box>
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Select
              theme={theme}
              label="Client Type"
              fullWidth
              items={[
                {
                  text: "Company 1",
                  value: "0",
                },
                {
                  text: "Company 2",
                  value: "1",
                },
              ]}
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Input
              theme={theme}
              type="text"
              label="Company Name"
              fullWidth
              placeholder="Type here..."
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Input
              theme={theme}
              type="text"
              label="Trading As (Optional)"
              fullWidth
              placeholder="Type here..."
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Input
              theme={theme}
              type="text"
              label="Company Registration Number"
              fullWidth
              placeholder="Type here..."
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Select
              theme={theme}
              label="SIC Code"
              fullWidth
              items={[
                {
                  text: "Code 1",
                  value: "0",
                },
                {
                  text: "Code 2",
                  value: "1",
                },
              ]}
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Input
              theme={theme}
              type="text"
              label="Nature Of Business (Optional)"
              fullWidth
              placeholder="Type here..."
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Input
              theme={theme}
              type="text"
              label="Year Business Established (Optional)"
              fullWidth
              placeholder="Type here..."
            />
          </Grid>
        </Grid>

        <Box theme={theme} mt={3} mb={3}>
          <Divider className="divider" />
        </Box>

        <Box theme={theme} mb={3}>
          <Typography
            theme={theme}
            component="h4"
            variant="h4"
            children={"Address"}
          />
          <Typography
            theme={theme}
            component="small"
            variant="caption"
            className="text-primary fw-medium"
            children={"Lookup by post code"}
          />
        </Box>
        <Box theme={theme} className="address-form">
          <AddressLayout
            theme={theme}
            state={{
              data: [],
              selectedItemObject: {
                text: "",
                value: "",
              },
              controlName: "",
            }}
            hideAddressButton={true}
            isContactRequired={false}
            addressVerificationRequired={false}
            countries={[GB_COUNTRY]}
            value={undefined}
            validationProps={undefined}
            onChange={() => {}}
          />
        </Box>
      </CustomerDetailPopupWrap>
    </Dialog>
  );
};

export default EditCustomerDetailPopup;
