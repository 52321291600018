import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PieChartWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.pie-chart-wrap": {
    width: "100%",
    height: "100%",
    ".widget-main-number": {
      fontSize: 32,
      lineHeight: "40px",
    },
    ".sub-text": {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: "16px",
    },
    ".recharts-legend-wrapper": {
      position: "relative !important",
      bottom: "-12px !important",
      ".recharts-default-legend": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        rowGap: 8,
        columnGap: 16,
        ".recharts-legend-item": {
          display: "flex !important",
          alignItems: "center",
          marginRight: "0 !important",
          gap: 8,
          svg: {
            width: 10,
            height: 10,
            marginRight: "0 !important",
          },
          ".recharts-legend-item-text": {
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
            color: theme.palette.grey[600] + "!important",
          },
        },
      },
    },
  },
}));
