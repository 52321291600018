import { styled } from "@mui/material";

export const CalculationWrap: any = styled("div")(({ theme }) => ({
  "&.calculation": {
    fontFamily: theme.typography.fontFamily,
    position: "relative",
    ".card-body": {
      width: "100%",
    },
    "&.calc-mbl": {
      ".tabs-mbl": {
        ".flex-tab": {
          ".MuiTabs-root": {
            padding: "0 32px 0 24px",
          },
        },
      },
    },
    "&.vat-treatment": {
      paddingLeft: 16,
      marginTop: 16,
    },
    "&.has-quote-header": {
      ".h-lg": {
        height: "calc(100vh - 283px)",
      },
      ".h-xl-b": {
        height: "calc(100vh - 424px)",
      },
      ".h-xl-c": {
        height: "calc(100vh - 225px)",
      },
      ".h-lg-b": {
        height: "calc(100vh - 400px)",
        "&.max": {
          height: "calc(100vh - 278px)",
        },
      },
      ".h-md": {
        height: "calc(50vh - 198px)",
        "&.toggle": {
          height: "calc(100vh - 372px)",
        },
      },
      ".h-xs": {
        height: "calc(50vh - 190px)",
      },
      [theme.breakpoints.down("md")]: {
        ".h-xs, .h-sm, .h-md, .h-lg, .h-lg-b, .h-xl-b, .h-xl-c": {
          height: "auto",
          "&.toggle": {
            height: "auto",
          },
          "&.max": {
            height: "auto",
          },
        },
      },
      "@media screen and (min-width: 1280px) and (max-width: 1399px)": {
        ".h-lg-b": {
          height: "calc(100vh - 252px)",
        },
        ".h-xl-b": {
          height: "calc(100vh - 293px)",
        },
        ".h-md": {
          height: "calc(50vh - 175px)",
          "&.toggle": {
            height: "calc(100vh - 218px)",
          },
        },
        ".h-xs": {
          height: "calc(50vh - 55px)",
        },
      },
      "@media screen and (min-width: 960px) and (max-width: 1280px)": {
        ".h-lg-b": {
          height: "calc(100vh - 252px)",
        },
        ".h-xl-b": {
          height: "calc(100vh - 314px)",
        },
        ".h-md": {
          height: "calc(50vh - 131px)",
          "&.toggle": {
            height: "calc(100vh - 217px)",
          },
        },
        ".h-xs": {
          height: "calc(50vh - 100px)",
        },
        "@-moz-document url-prefix()": {
          ".h-xl-b": {
            height: "calc(100vh - 293px)",
          },
        },
      },
    },
  },
  "&.results": {
    width: "100%",
    ".btn-calculate-desktop": {
      display: "flex",
      gap: 16,
      button: {
        flex: 1,
        fontSize: theme.typography.body2.fontSize,
        padding: 12,
      },
      ".btn-group": {
        border: "1px solid #9295A6",
        borderRadius: 8,
        display: "flex",
        ".MuiButtonBase-root": {
          borderRight: "1px solid #9295A6",
          borderRadius: 0,
          "&:last-child": {
            border: "none",
          },
        },
      },
    },
    ".btn-calculate-mobile": {
      display: "none",
    },
  },

  "@media screen and (min-width: 960px) and (max-width: 1399px)": {
    "&.results": {
      ".create-quotation": {
        ".MuiGrid-root": {
          maxWidth: "100%",
          flexBasis: "100%",
          ".dropDown-with-label": {
            justifyContent: "start",
          },
        },
      },
      ".results": {
        ".footer-buttons": {
          ".MuiGrid-root": {
            maxWidth: "100%",
            flexBasis: "100%",
            marginLeft: "0px",
            paddingLeft: 0,
          },
        },
      },
    },
    "&.calculation": {
      "&.vat-treatment": {
        ".flex-tab": {
          ".MuiTabs-root": {
            ".MuiTabs-flexContainer": {
              ".MuiButtonBase-root": {
                width: "50% !important",
              },
            },
          },
        },
      },
    },
  },

  "@media screen and (max-width: 959px)": {
    "&.results": {
      ".btn-calculate-desktop": {
        display: "none",
      },
      ".btn-calculate-mobile": {
        display: "flex",
        gap: 16,
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 8,
        width: "100%",
        ".btn-group": {
          border: "1px solid #9295A6",
          borderRadius: 8,
          display: "flex",
          ".MuiButtonBase-root": {
            borderRight: "1px solid #9295A6",
            borderRadius: 0,
            "&:last-child": {
              border: "none",
            },
          },
        },
      },
    },
    "&.calculation": {
      "&.calc-mbl": {
        marginTop: 30,
        ".tabs-mbl": {
          ".flex-tab": {
            ".MuiTabs-root": {
              padding: "0 24px 0 24px",
            },
          },
        },
        ".mbl-result": {
          marginTop: 8,
          "&.card-results": {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        },
        ".rates-mbl": {
          "&.card-rates": {
            borderRadius: 0,
            marginBottom: 0,
          },
        },
        ".card-info": {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          paddingBottom: 24,
        },
      },
    },
  },
  ".MuiLink-root": {
    "&.disabled": {
      color: "#9295A6",
      pointerEvents: "none",
      cursor: "not-allowed",
      opacity: 0.6,
    },
  },
  "@media screen and (max-width: 500px)": {
    "&.calculation": {
      "&.vat-treatment": {
        paddingLeft: 16,
        marginTop: 16,
        ".flex-tab": {
          ".MuiTabs-root": {
            ".MuiTabs-flexContainer": {
              ".MuiButtonBase-root": {
                width: "50% !important",
              },
            },
          },
        },
      },
    },
  },
}));
