import {
  CURRENCY_CODES,
  LOCALES,
  PROPOSAL_OPTION_TEXT_MAP,
  PROPOSAL_STATUS_TO_ROW_OPTION_MAP,
  PROPOSAL_STATUSES_BY_CATEGORY,
} from "./const";
import {
  AmortizationMethod,
  FeeName,
  FeeType,
  FinanceType,
} from "@flexCommon/enums";
import { FieldData } from "@data/fieldProps.data";
import {
  BrokerQuotationForm,
  BrokerSpecifcFormFields,
  ChartBaseData,
  DashboardAnalyticsResponse,
  TCustomer,
  TOrderStakeholders,
  TProposalWorkQueueEntry,
} from "@_types";
import {
  ClientTypes,
  OrderStatus,
  OrderStatusCategory,
  ProposalStakeholders,
} from "./enum";
import {
  TDealerNames,
  TLenderNames,
} from "@components/proposal/proposal-details/types";
import {
  CalculationResults,
  FeeName as ControlFeeName,
  getPaymentMode,
} from "@ntpkunity/controls-common";
import dayjs from "dayjs";
import { DownloadQuotationRequestParams } from "@_types/quotation";
import { Theme } from "@mui/material";

export const getSelectValues = <T extends string>(
  arr: ReadonlyArray<T>,
  disabledInfo?: Partial<{ [key in T]: boolean }>
): { text: T; value: string; disabled?: boolean }[] => {
  return arr.map((val: T) => {
    const returnObj = { text: val, value: val };
    if (disabledInfo && disabledInfo[val]) {
      return { ...returnObj, disabled: true };
    }
    return returnObj;
  });
};

export const pounds = Intl.NumberFormat(LOCALES.GB, {
  style: "currency",
  currency: CURRENCY_CODES.GBP,
});

export const getQueryParamsString = <T extends {} = { [key: string]: string }>(
  params: T
) => {
  const queryString = Object.keys(params)
    .filter((key) => !!params[key])
    .map((key) => {
      if (Array.isArray(params[key])) {
        if (params[key].length) {
          const arrayParams = params[key].map((ele) => {
            return `${key}=${encodeURIComponent(ele)}`;
          });

          return arrayParams.join("&");
        } else {
          return null;
        }
      }
      return `${key}=${encodeURIComponent(params[key])}`;
    })
    .filter(Boolean)
    .join("&");
  return queryString ? `?${queryString}` : "";
};

export const getDefaultQuoteConfig = (financeType: FinanceType) => {
  return {
    AmortizationMethod:
      financeType === FinanceType.FinanceLease ||
      financeType === FinanceType.HirePurchase
        ? AmortizationMethod.AnnuityActual365
        : AmortizationMethod.Annuity360,
    APRCalculationMethod:
      financeType === FinanceType.FinanceLease ||
      financeType === FinanceType.HirePurchase
        ? AmortizationMethod.AnnuityActual365
        : AmortizationMethod.Annuity360,
    CommissionCalculationMethod: "",
    Fees: [
      {
        Amount: 195,
        FirstDueDate: new Date().toString(),
        Frequency: FeeType.OneTime,
        Name: FeeName.DocFee,
        Occurance: "WithFirstPayment",
        PaymentNumber: 0,
        IsEditable: true,
      },
      {
        Amount: 35,
        FirstDueDate: new Date().toString(),
        Frequency: FeeType.Periodic,
        Name: FeeName.AnnualAdminFee,
        Occurance: "Annual",
        PaymentNumber: 0,
        IsEditable: true,
      },
      {
        Amount: 200,
        FirstDueDate: new Date().toString(),
        Frequency: FeeType.OneTime,
        Name: FeeName.OTPFee,
        Occurance: "WithLastPayment",
        PaymentNumber: 0,
        IsEditable: true,
      },
    ],
    InitialCashOutFlowForFlatRate: "",
    IsCommissionPassToCustomer: false,
    IsVATApplicable: true,
    FieldProps: FieldData,
    Tax: 0,
    GrossProfit: false,
  };
};

export const utcToLocalDateTime = (
  dateString: string
): { dateStr: string; timeStr: string } => {
  let dateISO: string;
  if (dateString) {
    dateISO =
      dateString.replace(" ", "T") + (dateString.includes("Z") ? "" : "Z");
  } else {
    dateISO = new Date().toISOString();
  }
  const date = new Date(dateISO);
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit",
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formatterDate = new Intl.DateTimeFormat("en-US", dateOptions);
  const formatterTime = new Intl.DateTimeFormat("en-US", timeOptions);
  const dateStr = formatterDate.format(date);
  let timeStr = formatterTime.format(date);
  timeStr = timeStr.replace(/AM|PM/, (match) => match.toLowerCase());
  return { dateStr, timeStr };
};

export const convertIsoDateStrToddmmyyyy = (date: string) => {
  if (!date) return date;
  return date.slice(0, 10).split("-").reverse().join("/");
};

export const convertIsoDateStrToddmmyyyyhms = (date: string) => {
  if (!date) return date;
  const dateObj = new Date(date);
  return `${dateObj
    .toISOString()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join(
      "/"
    )} ${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`;
};
export const convertDateToISOString = (date: Date): string => {
  if (date.getFullYear() !== 1969 && date.getFullYear() !== 1970) {
    return date.toISOString();
  }
};

export const deleteNDays = (days: number) => {
  const date = new Date(Date.now());
  date.setDate(date.getDate() - days);
  return date;
};

export const getCustomerName = (customer: TCustomer) => {
  if (!customer || !customer.customer_type) return "";
  return customer.customer_type === ClientTypes.INDIVIDUAL
    ? `${customer.first_name || ""} ${customer.last_name || ""}`
    : customer.company_name;
};

export const getOrderStakeholders = (
  orderStakeholders: TOrderStakeholders
): { lenderName: TLenderNames; dealerName: TDealerNames } => {
  return {
    lenderName: orderStakeholders?.find(
      (stakeholder) => stakeholder.role === ProposalStakeholders.LENDER
    )?.name as TLenderNames,
    dealerName: orderStakeholders?.find(
      (stakeholder) => stakeholder.role === ProposalStakeholders.DEALER
    )?.name as TDealerNames,
  };
};

export const alphaNumericMask = (value: string) =>
  Array.from(value).map((_) => /[a-zA-Z0-9 ]/);

export const errorInQuotationDetails = (
  formValues: BrokerSpecifcFormFields
): { isError: true; message: string } | { isError: false } => {
  const errorValues: string[] = [];
  if (!formValues.name?.trim()) {
    errorValues.push("Quotation name");
  }
  if (!formValues.clientType) {
    errorValues.push("Client Type");
  }
  if (!formValues.brokerName) {
    errorValues.push("Broker");
  }
  if (!formValues.asset?.type) {
    errorValues.push("Asset Type");
  }

  if (errorValues.length) {
    let errorMsg = "";
    errorValues.forEach((value, index) => {
      errorMsg = errorMsg + (index === 0 ? value : ` and ${value} `);
    });
    errorMsg += " cannot be empty";
    return { isError: true, message: errorMsg };
  }
  return { isError: false };
};

export const getQuotationDownloadPayload = (
  formValues: BrokerQuotationForm,
  calculations: CalculationResults
): DownloadQuotationRequestParams => {
  const findFeeByName = (name: ControlFeeName) =>
    formValues.fees.find((fee) => fee.name === name);

  return {
    name: formValues.name,
    broker_name: formValues.brokerName,
    asset_type: formValues.asset.type,
    client_type: formValues.clientType,
    asset_cost: pounds.format(Number(formValues.assetCost)),
    deposit_amount: pounds.format(Number(calculations.depositAmount)),
    commission_amount: pounds.format(Number(calculations.commissionAmount)),
    rate: `${Number(formValues.rate).toFixed(2)}%`,
    rate_type: formValues.rateType,
    no_of_advance_payment: formValues.noOfAdvancePayments,
    no_of_regular_payments: formValues.noOfRegularPayments,
    balloon_payment: pounds.format(Number(formValues.balloonPayment)),
    ballon_collection: formValues.balloonCollection,
    vat_treatment: formValues.vatType,
    vat_amount: pounds.format(Number(formValues.vatAmount)),
    vat_number: formValues.vatNumber,
    vat_deferred_type: formValues.vatDeferredType,
    document_fee: pounds.format(
      Number(findFeeByName(ControlFeeName.DOC_FEE)?.amount)
    ),
    annual_admin_fee: pounds.format(
      Number(findFeeByName(ControlFeeName.ANNUAL_ADMIN_FEE)?.amount)
    ),
    otp_fee: pounds.format(
      Number(findFeeByName(ControlFeeName.OTP_FEE)?.amount)
    ),
    payment_frequency: formValues.paymentFrequency,
    payment_mode: getPaymentMode(formValues.noOfAdvancePayments),
    finance_amount: calculations.financeAmount,
    sum_of_advance_rentals: calculations.sumOfAdvanceRentals,
    sum_of_fees: calculations.sumOfFees,
    sum_of_periodic_interest: calculations.sumOfPeriodicInterest,
    sum_of_rentals: calculations.sumOfRentals,
    total_payables: calculations.totalPayables,
    gross_yield: calculations.rates.grossYield,
    net_yield: calculations.rates.netYield,
    apr: calculations.rates.apr,
    flat_rate_excl_commission: calculations.rates.flatRateExclCommission,
    flat_rate_incl_commission: calculations.rates.flatRateInclCommission,
    term:
      Number(formValues.noOfRegularPayments) +
      (Number(formValues.balloonPayment) > 0
        ? Number(formValues.balloonCollection)
        : 0),
  };
};

export const getBrokerQuotationPayload = (
  quotationPayload: any,
  brokerFields: BrokerSpecifcFormFields,
  forUpdate = false
) => {
  const additionalQuoteDetails = {
    introducer_name: brokerFields.brokerName,
    name: brokerFields.name,
    assets: [
      {
        identifier: forUpdate ? brokerFields.asset.identifier : undefined,
        asset_type: brokerFields.asset.type,
      },
    ],
  };
  if (forUpdate) {
    return {
      ...quotationPayload,
      ...additionalQuoteDetails,
      customer: {
        ...quotationPayload.customer,
        customer_type: brokerFields.clientType,
      },
    };
  } else {
    return {
      ...quotationPayload,
      quote_details: {
        ...quotationPayload.quote_details,
        ...additionalQuoteDetails,
      },
      quote_identifiers: {
        ...quotationPayload.quote_identifiers,
        customer_type: brokerFields.clientType,
      },
    };
  }
};

export const getStatusColorMap = (theme: Theme) => {
  return {
    [OrderStatus.ACCEPTED]: theme.palette.success.main,
    [OrderStatus.WITHDRAWN]: theme.palette.error.main,
    [OrderStatus.DECLINED]: theme.palette.error.main,
    [OrderStatus.CONDITIONED]: theme.palette.warning.main,
    [OrderStatus.DOCUMENT_RECEIVED]: "#40CBE0",
    [OrderStatus.SENT_FOR_PAYOUT]: "#5E5CE6",
    [OrderStatus.PAID_OUT]: "#BF5AF2",
    [OrderStatus.DRAFT]: theme.palette.info.main,
    [OrderStatus.SUBMITTED]: theme.palette.info.main,
    [OrderStatus.DOCUMENT_SENT]: "#FFD60A",
  };
};

export const getCategoryColorMap = (theme: Theme) => {
  return {
    [OrderStatusCategory.ACTIVE]: theme.palette.info.main,
    [OrderStatusCategory.CLOSED]: theme.palette.error.main,
  };
};

export const getActiveProposalBaseData = (theme: Theme): ChartBaseData[] => {
  const colorMap = getStatusColorMap(theme);
  return PROPOSAL_STATUSES_BY_CATEGORY[OrderStatusCategory.ACTIVE].map(
    (status) => {
      return {
        status: status,
        color: colorMap[status],
        count: 0,
      };
    }
  );
};

export const getProposalCategoryBaseData = (theme: Theme): ChartBaseData[] => {
  const colorMap = getCategoryColorMap(theme);
  return [OrderStatusCategory.ACTIVE, OrderStatusCategory.CLOSED].map(
    (status) => {
      return {
        status: status,
        color: colorMap[status],
        count: 0,
      };
    }
  );
};

export const getStatusCount = (
  proposals: DashboardAnalyticsResponse[],
  statuses: OrderStatus[]
) => {
  const statusToCount = statuses.reduce((prev, status) => {
    prev[status] = 0;
    return prev;
  }, {});

  proposals.forEach((dataItem) => {
    if (statusToCount[dataItem.status] !== undefined) {
      statusToCount[dataItem.status] = statusToCount[dataItem.status] + 1;
    }
  });

  return statusToCount;
};

export const reduceDataForProposalWidget = (
  theme: Theme,
  proposals: DashboardAnalyticsResponse[] | undefined
) => {
  if (!proposals) {
    return [];
  }
  const baseData = getActiveProposalBaseData(theme);
  const statusToCount = getStatusCount(
    proposals,
    PROPOSAL_STATUSES_BY_CATEGORY[OrderStatusCategory.ACTIVE]
  );
  return baseData.map((baseData) => {
    return {
      ...baseData,
      count: statusToCount[baseData.status],
    };
  });
};

export const reduceDataForTotalProposalWidget = (
  theme: Theme,
  proposals: DashboardAnalyticsResponse[]
) => {
  if (!proposals) {
    return [];
  }

  const activeStatusToCounts = getStatusCount(
    proposals,
    PROPOSAL_STATUSES_BY_CATEGORY[OrderStatusCategory.ACTIVE]
  );

  const totalActiveCounts = Object.keys(activeStatusToCounts).reduce(
    (prev, cur) => prev + activeStatusToCounts[cur],
    0
  );
  const closedStatusToCount = getStatusCount(
    proposals,
    PROPOSAL_STATUSES_BY_CATEGORY[OrderStatusCategory.CLOSED]
  );
  const totalClosedCounts = Object.keys(closedStatusToCount).reduce(
    (prev, cur) => prev + closedStatusToCount[cur],
    0
  );

  const baseData = getProposalCategoryBaseData(theme);

  const categoryToCount = {
    [OrderStatusCategory.ACTIVE]: totalActiveCounts,
    [OrderStatusCategory.CLOSED]: totalClosedCounts,
  };

  return baseData.map((baseData) => {
    return {
      ...baseData,
      count: categoryToCount[baseData.status],
    };
  });
};

export const getProposalRowOptions = (proposal: TProposalWorkQueueEntry) => {
  const { status } = proposal;
  const availableOptions = PROPOSAL_STATUS_TO_ROW_OPTION_MAP[status] || [];

  return availableOptions.map((optionKey) => ({
    optionText: PROPOSAL_OPTION_TEXT_MAP[optionKey],
    optionkey: optionKey,
    optionValue: proposal,
  }));
};
