import { Stack } from "@components";
import { useTheme } from "@mui/material";
import { Accordion, Grid, Typography } from "@ntpkunity/controls";
import { TClientType } from "../types";
import { CUSTOMER_TYPES } from "@helpers/const";
import { getSelectValues } from "@helpers/utils";
import { TInputDef } from "@components/proposal/proposal-details/types";
import ControlledFormElement from "@components/controlled-form-element/controlled-form-element.component";
import { useCustomerFields } from "@hooks/proposal";
import { DirectorsDetails } from "./director-details/director-details.component";
import { useFormContext } from "react-hook-form";
import { ClientTypes } from "@helpers/enum";
import { TProposalForm } from "@_types";

const inputsDefs: TInputDef[] = [
  {
    name: "customerDetails.clientType",
    formElement: "Select",
    label: "Client Type",
    items: getSelectValues<TClientType>(CUSTOMER_TYPES),
  },
  {
    name: "customerDetails.companyRegNum",
    formElement: "Input",
    placeHolder: "Company Registration Number",
    label: "Company Registration Number",
    type: "text",
  },
  {
    name: "customerDetails.companyName",
    formElement: "Input",
    placeHolder: "Company Name",
    label: "Company Name",
    type: "text",
  },
  {
    name: "customerDetails.tradingAs",
    formElement: "Input",
    placeHolder: "Trading As (Optional)",
    label: "Trading As (Optional)",
    type: "text",
  },
  {
    name: "customerDetails.natureOfBusiness",
    formElement: "Input",
    placeHolder: "Nature of Business (Optional)",
    label: "Nature of Business (Optional)",
    type: "text",
  },
  {
    name: "customerDetails.yearsInBusiness",
    formElement: "Input",
    placeHolder: "Years Business Established (Optional)",
    label: "Years Business Established (Optional)",
    type: "text",
  },
  {
    name: "customerDetails.sicCode",
    formElement: "Input",
    placeHolder: "SIC Code",
    label: "SIC Code",
    type: "text",
  },
  {
    name: "customerDetails.firstName",
    formElement: "Input",
    placeHolder: "First Name",
    label: "First Name",
    type: "text",
  },
  {
    name: "customerDetails.lastName",
    formElement: "Input",
    placeHolder: "Last Name",
    label: "Last Name",
    type: "text",
  },
  {
    name: "customerDetails.idNumber",
    formElement: "Input",
    placeHolder: "ID Number",
    label: "ID Number",
    type: "text",
  },
  {
    name: "customerDetails.emailAddress",
    formElement: "Input",
    placeHolder: "Email Address",
    label: "Email Address",
    type: "text",
  },
  {
    name: "customerDetails.contactNumber",
    formElement: "Input",
    placeHolder: "Contact Number (Optional)",
    label: "Contact Number (Optional)",
    type: "text",
  },
];

export const CustomerDetail = () => {
  const customerFields = useCustomerFields();
  const theme = useTheme();

  const { watch } = useFormContext<TProposalForm>();
  const clientType = watch("customerDetails.clientType");

  const showDirectorDetails = clientType !== ClientTypes.INDIVIDUAL;

  return (
    <>
      <Stack
        paddingXs={3}
        paddingMd={3}
        marginTopMd={2}
        marginTopXs={2}
        className="collapse-wrap desktop-disable"
      >
        <Accordion
          theme={theme}
          items={[
            {
              content: (
                <>
                  <Grid
                    theme={theme}
                    container
                    item
                    columnSpacing={2}
                    rowSpacing={3}
                  >
                    {customerFields.map((field) => {
                      const item = inputsDefs.find(
                        (item) => item.name === field
                      );
                      return (
                        <Grid
                          key={item.name}
                          theme={theme}
                          item
                          xs={12}
                          md={4}
                          lg={3}
                        >
                          <ControlledFormElement {...item} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              ),
              title: (
                <>
                  Customer Details
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="small"
                    className="text-primary fw-medium"
                    display={"block"}
                  >
                    <a href="#" className="link text-primary decoration-none">
                      Lookup from companies house
                    </a>
                  </Typography>
                </>
              ),
              key: "string",
              isExpanded: true,
              id: "string",
              ariaControls: "string",
            },
          ]}
        />
      </Stack>
      {showDirectorDetails && <DirectorsDetails />}
    </>
  );
};
