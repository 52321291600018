import "react";
import { PageHeader } from "@components/page-header";
import { Button } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";

const DocumentPageHeader = () => {
  const theme = useTheme();

  return (
    <PageHeader
      title={"Sample Proposal Name"}
      referenceNumber={"O-R000BA6T"}
      hasSeperator
      actionArea={
        <Button
          theme={theme}
          type="button"
          text="Save Changes"
          secondary
          onClick={() => {}}
        />
      }
    />
  );
};

export default DocumentPageHeader;
