import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { FormControl, FormHelperText, Theme } from "@mui/material";
import clsx from "clsx";
import { styled } from "@mui/material/styles";
import { CalendarIcon } from "@components/quotation/shared/assets";
// import theme from "@themes/mui-theme";

const DatePickerWrap = styled(
  "div",
  {}
)<Partial<IDatePicker>>(({ theme }) => ({
  "&.u-date-picker": {
    ".MuiPaper-root": {
      backgroundColor: theme.palette.common.white,
      borderRadius: 16,
      overflow: "hidden",
      boxShadow: "0px 32px 64px rgba(0, 0, 0, 0.16)",
      padding: 40,
      color: theme.palette.common.black,
      ".MuiCalendarPicker-root": {
        ">": {
          div: {
            margin: "auto",
            padding: 0,
            minHeight: 48,
            ">": {
              div: {
                margin: "auto",
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.h4.fontSize,
                lineHeight: "22px",
                fontWeight: theme.customVariables.fontWeightSemiBold,
                ".PrivatePickersFadeTransitionGroup-root": {
                  "+": {
                    ".MuiButtonBase-root": {
                      display: "none",
                    },
                  },
                },
                "+": {
                  div: {
                    width: 0,
                    ".MuiIconButton-root": {
                      position: "absolute",
                      top: 52,
                      padding: 0,
                      svg: {
                        width: 24,
                        height: 24,
                        fill: theme.palette.grey[300],
                      },
                      "&.MuiIconButton-edgeEnd": {
                        left: 48,
                      },
                      // '&.MuiIconButton-edgeStart': {
                      //   left: "100%"
                      // },
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ".MuiCalendarPicker-viewTransitionContainer": {
          margin: 0,
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            width: 7,
            height: 7,
          },
          "&::-webkit-scrollbar-track": {
            padding: "0 1px",
            backgroundColor: "#f0f0f0",
          },
          "&::-webkit-scrollbar-thumb": {
            transition: "all 0.3s",
            backgroundColor: "#cdcdcd",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#a6a6a6",
          },
          ">": {
            div: {
              "div:first-of-type": {
                justifyContent: "space-between",
              },
            },
          },
          ".MuiTypography-caption": {
            width: 40,
            height: 40,
            margin: 0,
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: "17px",
            color: theme.palette.grey[700],
          },
          ".PrivatePickersSlideTransition-root": {
            minHeight: 200,
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              width: 0,
              height: 0,
            },
            "div[role='grid']": {
              "div[role='row']": {
                margin: 0,
                justifyContent: "space-between",
              },
            },
            ".MuiPickersDay-root": {
              width: 40,
              height: 40,
              margin: 0,
              fontFamily: theme.typography.fontFamily,
              fontSize: theme.typography.body2.fontSize,
              lineHeight: "17px",
              color: theme.palette.common.black,
              "&.Mui-selected": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                fontWeight: theme.customVariables.fontWeightSemiBold,
              },
            },
          },
        },
      },
      ".MuiTouchRipple-root": {
        display: "none",
      },
    },

    "&.u-form-group": {
      marginBottom: 24,
      "&:last-child": {
        marginBottom: 0,
      },
      ".u-form-control, .MuiFormControl-root": {
        ".MuiFormLabel-root": {
          color: "rgba(0,0,0,0.87)",
          fontFamily: theme.typography.fontFamily,
          lineHeight: theme.typography.body1.lineHeight,
          transform: "translate(14px, 12px) scale(1)",
          padding: "0 5px 0 0px",
          backgroundColor: theme.palette.common.white,
          userSelect: "none",
          pointerEvents: "none",
          "&.Mui-focused": {
            color: theme.palette.grey[600],
            transform: "translate(14px, -9px) scale(0.85)",
            maxWidth: "100%",
          },
          "&.MuiFormLabel-filled": {
            color: theme.palette.grey[600],
            transform: "translate(14px, -9px) scale(0.85)",
            maxWidth: "100%",
            "&.Mui-error": {
              color: theme.palette.error.main,
            },
          },
          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.38) !important",
          },
        },
        ".MuiInputBase-formControl": {
          height: 48,
          color: "rgba(0,0,0,0.87)",
          borderRadius: theme.shape.borderRadius,
          fontFamily: theme.typography.fontFamily,
          ".MuiInputBase-input": {
            fontFamily: theme.typography.fontFamily,
            padding: "12px 16px",
            borderRadius: theme.shape.borderRadius,
          },
          "&:hover": {
            fieldset: {
              borderColor: theme.palette.primary.main,
            },
          },
          "&.Mui-focused": {
            fieldset: {
              borderColor: theme.palette.primary.main,
            },
          },
          "&.MuiInputBase-adornedStart": {
            ".MuiInputBase-input": {
              padding: "12px 16px 12px 0px",
            },
            fieldset: {
              legend: {
                maxWidth: 0,
              },
            },
            "&:hover": {
              fieldset: {
                "&.MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.primary.main,
                },
              },
            },
            "&.Mui-focused": {
              fieldset: {
                legend: {
                  maxWidth: "100%",
                },
              },
            },
          },
          "&.MuiInputBase-adornedEnd": {
            ".MuiInputBase-input": {
              padding: "12px 0px 12px 16px",
            },
          },
          "&.Mui-error": {
            fieldset: {
              borderWidth: 2,
              borderColor: theme.palette.error.main,
            },
            "&:hover": {
              fieldset: {
                borderColor: theme.palette.error.main,
              },
            },
            "&.Mui-focused": {
              fieldset: {
                borderColor: theme.palette.error.main,
              },
            },
            "~": {
              ".MuiInputLabel-formControl": {
                color: theme.palette.error.main,
                "&.Mui-focused": {
                  color: theme.palette.error.main,
                },
                "&.MuiFormLabel-filled": {
                  color: theme.palette.error.main,
                },
              },
            },
            ".MuiInputAdornment-root": {
              color: theme.palette.error.main,
              svg: {
                path: {
                  stroke: theme.palette.error.main,
                },
              },
            },
          },
          ".MuiInputAdornment-root": {
            ".MuiButtonBase-root": {
              "&:hover": {
                backgroundColor: "transparent",
              },
              ".MuiTouchRipple-root": {
                display: "none",
              },
            },
          },
        },
        ".MuiInputLabel-formControl": {
          color: "rgba(0,0,0,0.87)",
          fontFamily: theme.typography.fontFamily,
          lineHeight: theme.typography.body1.lineHeight,
          transform: "translate(14px, 12px) scale(1)",
          padding: "0 5px 0 0px",
          backgroundColor: theme.palette.common.white,
          "&.Mui-focused": {
            color: theme.palette.grey[600],
            transform: "translate(14px, -9px) scale(0.85)",
          },
          "&.MuiFormLabel-filled": {
            color: theme.palette.grey[600],
            transform: "translate(14px, -9px) scale(0.85)",
          },
        },
        ".u-help-text": {
          marginLeft: 15,
          lineHeight: theme.typography.caption.lineHeight,
        },
        ".MuiFormHelperText-root": {
          "&.Mui-error": {
            color: theme.palette.error.main,
          },
        },
        ".u-error-text": {
          color: theme.palette.error.main,
          marginLeft: 15,
          lineHeight: theme.typography.caption.lineHeight,
        },
        "&.capitalize-label": {
          ".MuiFormLabel-root, .MuiOutlinedInput-notchedOutline": {
            textTransform: "capitalize",
          },
        },
        "&.uppercase-label": {
          ".MuiFormLabel-root, .MuiOutlinedInput-notchedOutline": {
            textTransform: "uppercase",
          },
        },
        ".MuiInputBase-root": {
          "&.Mui-disabled": {
            "&:hover": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.12)",
              },
            },
            ".MuiInputAdornment-root": {
              ".MuiButtonBase-root": {
                pointerEvents: "none",
              },
              "svg path": {
                stroke: "rgba(0, 0, 0, 0.12)",
              },
            },
          },
        },
        ".Mui-disabled": {
          ".MuiInputBase-input": {
            "&.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.38)",
              textFillColor: "rgba(0, 0, 0, 0.38)",
              cursor: "not-allowed",
            },
          },
          fieldset: {
            borderColor: "rgba(0, 0, 0, 0.12)",
          },
        },
        "&.adornment-focus": {
          ".MuiInputAdornment-root": {
            visibility: "hidden",
            opacity: 0,
            transition: "all 0.3s",
          },
          ".Mui-focused": {
            ".MuiInputAdornment-root": {
              visibility: "visible",
              opacity: 1,
            },
          },
        },
      },
    },
    // ".MuiPickersPopper-root": {
    //     width: "100%",
    //     ".MuiPaper-root": {
    //       width: "100%",
    //       padding: 16,
    //       ".css-epd502": {
    //         width: "100%",
    //         ".MuiCalendarPicker-root": {
    //           width: "100%",
    //           overflow: "initial",
    //           ".MuiPickersCalendarHeader-root": {
    //             ".MuiPickersArrowSwitcher-root": {
    //               ".MuiButtonBase-root": {
    //                 top: 27,
    //                 "&.MuiIconButton-edgeEnd": {
    //                   left: 24
    //                 },
    //                 // "&.MuiIconButton-edgeStart": {
    //                 //   right: 24,
    //                 // }
    //               }
    //             },
    //             ".MuiPickersCalendarHeader-labelContainer ": {
    //               ".MuiButtonBase-root": {
    //                 display: "none"
    //               }
    //             }
    //           },
    //           ".MuiDayPicker-header": {
    //             justifyContent: "space-between",
    //             span: {
    //               width: 30
    //             }
    //           },
    //           ".MuiDayPicker-slideTransition": {
    //               ".MuiDayPicker-weekContainer": {
    //                 justifyContent: "inherit",
    //                 ".MuiPickersDay-root": {
    //                   width: "30px !important",
    //                   height: "30px !important",
    //                   color: theme.palette.common.black,
    //                   "&.Mui-selected":
    //                     {
    //                       color: theme.palette.common.white,
    //                       fontWeight: "initial"
    //                     }
    //                 }
    //               }
    //           }
    //         }
    //       }
    //     }
    //   },

    // "@media (min-width: 1200px) and (max-width: 1300px)": {
    //   ".MuiPickersPopper-root": {
    //     ".MuiPaper-root": {
    //       padding: 16,
    //       ".css-epd502": {
    //         width: "100%",
    //         ".MuiCalendarPicker-root": {
    //           width: "100%",
    //           overflow: "initial",
    //           ".MuiPickersCalendarHeader-root": {
    //             ".MuiPickersArrowSwitcher-root": {
    //               ".MuiButtonBase-root": {
    //                 top: 27,
    //                 "&.MuiIconButton-edgeEnd": {
    //                   left: 24
    //                 },
    //                 "&.MuiIconButton-edgeStart": {
    //                   right: 24
    //                 }
    //               }
    //             },

    //           },
    //           ".MuiDayPicker-header": {
    //             justifyContent: "space-between",
    //             span: {
    //               width: 30
    //             }
    //           },
    //           ".MuiDayPicker-slideTransition": {
    //             minHeight: "185px !important",
    //             overflowX: "hidden !important",
    //             ".MuiDayPicker-slideTransition": {
    //               minHeight: "185px !important",
    //               overflowX: "hidden !important",
    //               ".MuiDayPicker-weekContainer": {
    //                 justifycontent: "inherit",
    //                 ".MuiPickersDay-root": {
    //                   width: "30px !important",
    //                   height: "30px !important",
    //                   color: theme.palette.common.black
    //                 }
    //               },

    //               ".css-xfypgc-MuiPickersDay-root": {
    //                 width: "30px !important",
    //                 height: "30px !important"
    //               },
    //               ".css-1ap4glr-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
    //               {
    //                 color: theme.palette.common.white
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   },
    // ".MuiPickersDay-root": {
    //   fontFamily: theme.typography.fontFamily,
    //   color: theme.palette.common.black,
    //   "&.Mui-selected": {
    //     backgroundColor: theme.palette.primary.main,
    //     color: theme.palette.common.white,
    //     fontWeight: theme.customVariables.fontWeightSemiBold,
    //     "&:hover": {
    //       backgroundColor: theme.palette.primary.main,
    //       color: theme.palette.common.white,
    //       fontWeight: theme.customVariables.fontWeightSemiBold,
    //     }
    //   }
    // },
    // [theme.breakpoints.down("sm")]: {
    //   ".MuiPickersPopper-root": {
    //     ".MuiPaper-root": {
    //       padding: 16,
    //       ".MuiCalendarOrClockPicker-root": {
    //         width: "100%",
    //         ".css-epd502": {
    //           width: "100%",
    //           ".MuiCalendarPicker-root": {
    //             width: "100%",
    //             ".MuiPickersCalendarHeader-root": {
    //               ".MuiPickersArrowSwitcher-root": {
    //                 ".MuiButtonBase-root": {
    //                   top: 27,
    //                   "&.MuiIconButton-edgeEnd": {
    //                     left: 24,
    //                   },
    //                   "&.MuiIconButton-edgeStart": {
    //                     right: 24
    //                   }
    //                 }
    //               }
    //             },
    //             ".MuiDayPicker-header": {
    //               span: {
    //                 width: 30
    //               }
    //             },
    //             ".MuiCalendarPicker-viewTransitionContainer": {
    //               ".PrivatePickersSlideTransition-root": {
    //                 minHeight: "180px",
    //                 ".MuiPickersDay-root": {
    //                   width: 30,
    //                   height: 30,
    //                   // color: theme.palette.common.black,
    //                   // "&.Mui-selected": {
    //                   //   color: theme.palette.common.white
    //                   // }
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // },
    // [theme.breakpoints.between("xs", "sm")]: {
    //   ".MuiPickersPopper-root": {
    //     ".MuiPaper-root": {
    //       ".MuiCalendarOrClockPicker-root": {
    //         width: 200,
    //         ".css-epd502": {
    //           ".MuiCalendarPicker-root": {
    //             maxHeight: "initial",
    //             ".MuiDayPicker-header": {
    //               width: 200,
    //             },
    //             ".PrivatePickersSlideTransition-root": {
    //               width: 200,
    //               minHeight: "initial",

    //               ".MuiDayPicker-monthContainer": {
    //                 ".MuiDayPicker-weekContainer ": {
    //                   ".MuiPickersDay-root": {
    //                     height: 25,
    //                   },
    //                   ".MuiButtonBase-root": {
    //                     height: 25,
    //                     // color: theme.palette.common.black,
    //                     // "&[aria-selected='false']": {
    //                     //   color: theme.palette.common.black + "!important",
    //                     // },
    //                     "&.Mui-selected, &.MuiPickersDay-today": {
    //                       height: 25,
    //                       // color: theme.palette.common.white
    //                     }
    //                   }
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    // },
    // "@media (min-width: 960px) and (max-width: 1199px)": {
    //   ".MuiPickersPopper-root": {
    //     ".MuiPaper-root": {
    //       padding: 16,
    //       ".css-epd502": {
    //         width: "220px",
    //         ".MuiCalendarPicker-root": {
    //           width: "220px",
    //           overflow: "initial",
    //           ".MuiPickersCalendarHeader-root": {
    //             ".MuiPickersArrowSwitcher-root": {
    //               ".MuiButtonBase-root": {
    //                 top: 27,
    //                 "&.MuiIconButton-edgeEnd": {
    //                   left: 18
    //                 },
    //                 "&.MuiIconButton-edgeStart": {
    //                   right: 18
    //                 }
    //               }
    //             }
    //           },
    //           ".MuiDayPicker-header": {
    //             span: {
    //               width: 28
    //             }
    //           },
    //           ".MuiDayPicker-slideTransition": {
    //             minHeight: "170px",
    //             overflowX: "hidden !important",
    //             ".MuiDayPicker-weekContainer": {
    //               ".MuiPickersDay-root": {
    //                 width: "28px !important",
    //                 height: "28px !important",
    //                 // color: theme.palette.common.black
    //               }
    //             },

    //             ".css-xfypgc-MuiPickersDay-root": {
    //               width: "28px !important",
    //               height: "28px !important"
    //             },
    //             // ".css-1ap4glr-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
    //             // {
    //             //   color: theme.palette.common.white
    //             // }
    //           }
    //         }
    //       }
    //     }
    //   }
    // },
    // [theme.breakpoints.down("sm")]: {
    //   ".MuiPickersPopper-root": {
    //     ".MuiPaper-root": {
    //       padding: 16,
    //       ".MuiCalendarOrClockPicker-root": {
    //         width: "100%",
    //         ".css-epd502": {
    //           width: "100%",
    //           ".MuiCalendarPicker-root": {
    //             width: "100%",
    //             ".MuiPickersCalendarHeader-root": {
    //               ".MuiPickersArrowSwitcher-root": {
    //                 ".MuiButtonBase-root": {
    //                   top: 27,
    //                   "&.MuiIconButton-edgeEnd": {
    //                     left: 24,
    //                   },
    //                   "&.MuiIconButton-edgeStart": {
    //                     right: 24
    //                   }
    //                 }
    //               }
    //             },
    //             ".MuiDayPicker-header": {
    //               span: {
    //                 width: 30
    //               }
    //             },
    //             ".MuiCalendarPicker-viewTransitionContainer": {
    //               ".PrivatePickersSlideTransition-root": {
    //                 minHeight: "180px",
    //                 ".MuiPickersDay-root": {
    //                   width: 30,
    //                   height: 30,
    //                   // color: theme.palette.common.black,
    //                   // "&.Mui-selected": {
    //                   //   color: theme.palette.common.white
    //                   // }
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // },
    // [theme.breakpoints.between("xs", "sm")]: {
    //   ".MuiPickersPopper-root": {
    //     ".MuiPaper-root": {
    //       ".MuiCalendarOrClockPicker-root": {
    //         width: 200,
    //         ".css-epd502": {
    //           ".MuiCalendarPicker-root": {
    //             maxHeight: 235,
    //             ".MuiDayPicker-header": {
    //               width: 200,
    //             },
    //             ".PrivatePickersSlideTransition-root": {
    //               width: 200,
    //               ".MuiDayPicker-monthContainer": {
    //                 ".MuiDayPicker-weekContainer ": {
    //                   ".MuiPickersDay-root": {
    //                     height: 25,
    //                   },
    //                   ".MuiButtonBase-root": {
    //                     height: 25,
    //                     // color: theme.palette.common.black,
    //                     // "&[aria-selected='false']": {
    //                     //   color: theme.palette.common.black + "!important",
    //                     // },
    //                     "&.Mui-selected, &.MuiPickersDay-today": {
    //                       height: 25,
    //                       // color: theme.palette.common.white
    //                     }
    //                   }
    //                 }
    //               }
    //             },
    //             ".MuiYearPicker-root": {
    //               maxHeight: "166px"
    //             },
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  },
}));

export type IDatePicker = {
  theme?: Theme;
  helperText?: string;
  value: string;
  onChange(e: any): void;
  label: string;
  testid?: string;
  id: string;
  disabled?: boolean;
  hidden?: boolean;
  inputClass?: string;
  capitalizeLabel?: boolean;
  uppercaseLabel?: boolean;
  adornmentOnFocus?: boolean;
};
export default function DatePicker({
  theme,
  helperText,
  value,
  onChange,
  label,
  testid,
  id,
  inputClass,
  uppercaseLabel,
  capitalizeLabel,
  adornmentOnFocus,
  disabled = false,
  hidden = false,
}: IDatePicker) {
  //   const [value, setValue] = React.useState<Dayjs | null>(
  //     dayjs(new Date()),
  //   );

  //   const handleChange = (newValue: Dayjs | null) => {
  //     setValue(newValue);
  //   };
  const [open, setOpen] = React.useState(false);
  if (hidden) return <></>;
  return (
    <DatePickerWrap className="u-form-group u-date-picker" theme={theme}>
      <FormControl fullWidth sx={{ m: 0, minWidth: 120 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} theme={theme}>
          <DesktopDatePicker
            components={{
              OpenPickerIcon: CalendarIcon,
            }}
            PopperProps={{
              disablePortal: true,
              sx: {
                ".MuiPaper-root": {
                  backgroundColor: theme.palette.common.white,
                  borderRadius: "16px",
                  overflow: "hidden",
                  boxShadow: "0px 32px 64px rgba(0, 0, 0, 0.16)",
                  padding: "40px",
                  color: theme.palette.common.black,
                  ".MuiCalendarOrClockPicker-root": {
                    ".MuiPickersCalendarHeader-root .MuiPickersCalendarHeader-labelContainer .MuiButtonBase-root":
                      {
                        display: "none",
                      },
                    ".MuiCalendarPicker-root .MuiPickersFadeTransitionGroup-root .PrivatePickersSlideTransition-root .MuiDayPicker-monthContainer .MuiDayPicker-weekContainer":
                      {
                        justifyContent: "inherit",
                      },
                  },
                  ".MuiCalendarPicker-root": {
                    ">": {
                      div: {
                        margin: "auto",
                        padding: 0,
                        minHeight: 48,
                        ">": {
                          div: {
                            margin: "auto",
                            fontFamily: theme.typography.fontFamily,
                            fontSize: theme.typography.h4.fontSize,
                            lineHeight: "22px",
                            fontWeight:
                              theme.customVariables.fontWeightSemiBold,
                            ".PrivatePickersFadeTransitionGroup-root": {
                              "+": {
                                ".MuiButtonBase-root": {
                                  display: "none",
                                },
                              },
                            },
                            "+": {
                              div: {
                                width: 0,
                                ".MuiIconButton-root": {
                                  position: "absolute",
                                  top: 52,
                                  padding: 0,
                                  svg: {
                                    width: 24,
                                    height: 24,
                                    fill: theme.palette.grey[300],
                                  },
                                  "&.MuiIconButton-edgeEnd": {
                                    left: 48,
                                  },
                                  "&.MuiIconButton-edgeStart": {
                                    right: 48,
                                  },
                                  "&:hover": {
                                    backgroundColor: "transparent",
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    ".MuiCalendarPicker-viewTransitionContainer": {
                      margin: 0,
                      scrollbarWidth: "thin",
                      "&::-webkit-scrollbar": {
                        width: 7,
                        height: 7,
                      },
                      "&::-webkit-scrollbar-track": {
                        padding: "0 1px",
                        backgroundColor: "#f0f0f0",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        transition: "all 0.3s",
                        backgroundColor: "#cdcdcd",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#a6a6a6",
                      },
                      ">": {
                        div: {
                          "div:first-of-type": {
                            justifyContent: "space-between",
                          },
                        },
                      },
                      ".MuiTypography-caption": {
                        width: 40,
                        height: 40,
                        margin: 0,
                        fontFamily: theme.typography.fontFamily,
                        fontSize: theme.typography.body2.fontSize,
                        lineHeight: "17px",
                        color: theme.palette.grey[700],
                      },
                      ".PrivatePickersSlideTransition-root": {
                        minHeight: 200,
                        scrollbarWidth: "none",
                        "&::-webkit-scrollbar": {
                          width: 0,
                          height: 0,
                        },
                        "div[role='grid']": {
                          "div[role='row']": {
                            margin: 0,
                            justifyContent: "space-between",
                          },
                        },
                        ".MuiPickersDay-root": {
                          width: 40,
                          height: 40,
                          margin: 0,
                          fontFamily: theme.typography.fontFamily,
                          fontSize: theme.typography.body2.fontSize,
                          lineHeight: "17px",
                          color: theme.palette.common.black,
                          "&.Mui-selected": {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.common.white,
                            fontWeight:
                              theme.customVariables.fontWeightSemiBold,
                          },
                        },
                      },
                    },
                  },
                  ".MuiTouchRipple-root": {
                    display: "none",
                  },
                },
              },
            }}
            label={label}
            data-testid={testid}
            inputFormat="DD-MMM-YYYY"
            value={value}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={onChange}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                onClick={() => setOpen(true)}
                size="small"
                onKeyDown={(e) => e.preventDefault()}
                className={clsx(inputClass, {
                  "u-form-control": true,
                  "capitalize-label": capitalizeLabel,
                  "uppercase-label": uppercaseLabel,
                  "adornment-focus": adornmentOnFocus,
                })}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </DatePickerWrap>
  );
}
