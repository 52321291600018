import { styled } from "@mui/material";
import { CustomColors } from "@themes/mui-theme";

export const Header = styled("div")(({ theme }) => ({
  height: 200,
  width: "100%",
  background: CustomColors.gradient,
  // backgroundImage: `url(${Bg})`,
  // backgroundRepeat: "no-repeat",
  // backgroundAttachment: "fixed",
  // backgroundSize: "101%",
  borderBottomRightRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
  color: theme.palette.common.white,
  ".header-container": {
    padding: "40px 24px",
  },
  ".logo": {
    float: "left",
  },
  ".title": {
    fontSize: 48,
    fontWeight: theme.typography.fontWeightBold,
    position: "relative",
    top: 3,
    float: "left",

    "&:before": {
      content: "''",
      display: "inline-block",
      margin: "0 40px",
      height: 40,
      width: 1,
      backgroundColor: theme.palette.common.white,
      opacity: 0.5,
      float: "left",
    },
  },
  [theme.breakpoints.down("md")]: {
    height: 112,
    ".header-container": {
      padding: 24,
    },
    ".logo": {
      height: 24,
    },
    ".title": {
      fontSize: 18,
      paddingTop: 3,
      "&:before": {
        margin: "-3px 24px 0 24px",
        height: 24,
      },
    },
  },
}));
