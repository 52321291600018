import { useTheme } from "@mui/material";
import { Accordion, Box, Grid, Typography } from "@ntpkunity/controls";
import { Stack } from "@components/stack";
import { SendForPayoutWrap } from "./send-for-payout.style";
import { Documents } from "./documents/documents.component";
import { CustomerDetails } from "@components/document/summary/customer-details/customer-details.component";
import AssetDetailsTable from "@components/document/summary/asset-details-table/asset-details-table.component";
import { PaymentDetails } from "@components/document/summary/payment-details/payment-details.component";
import { ProposalSummaryWrap } from "@components/document/summary/proposal-summary.style";
import { TotalAssetCost } from "@components/document/summary/total-asset-cost.component";

export const SendForPayout = () => {
  const theme = useTheme();

  return (
    <ProposalSummaryWrap theme={theme} className="proposal-summary-wrap">
      <Grid theme={theme} container spacing={2}>
        <Grid theme={theme} item xl={9} lg={8} md={7} sm={12} xs={12}>
          <Box theme={theme} display={{ xs: "block", md: "none" }}>
            <TotalAssetCost />
          </Box>
          <Box theme={theme}>
            <Documents />
          </Box>
          <Box theme={theme} mt={2}>
            <CustomerDetails />
          </Box>
          <Stack
            className="collapse-wrap desktop-disable"
            paddingXs={3}
            paddingMd={3}
            marginTopMd={2}
            marginTopXs={2}
          >
            <Accordion
              theme={theme}
              items={[
                {
                  content: <AssetDetailsTable />,
                  title: "Asset Details",
                  key: "string",
                  isExpanded: true,
                  id: "string",
                  ariaControls: "string",
                },
              ]}
            />
          </Stack>
        </Grid>
        <Grid
          theme={theme}
          item
          xl={3}
          lg={4}
          md={5}
          sm={12}
          xs={12}
          display={{ xs: "none", md: "block" }}
        >
          <PaymentDetails />
        </Grid>
      </Grid>
    </ProposalSummaryWrap>
  );
};
