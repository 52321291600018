export const GET_QUOTATION_BY_IDENTIFIER =
  "/configure/quick-quotation-by-identifier/:identifier";
export const GET_QUOTATION_BY_VENDOR = "/configure/quick-quotation-by-vendor";
export const GET_ORDER_STATUS_HISTORY = "/configure/order/status/";
export const QUOTE_CONFIGURATIONS = "quote-config/get-configuration";
export const CONVERT_QUOTATION_TO_PROPOSAL =
  "/configure/quotation-to-order/:identifier";
export const UPDATE_QUOTATION = "/order/quick_quotation";
export const SAVE_UPDATE_PROPOSAL = "/order/proposal";
export const DOCUMENTS_EXPORT_IN_PDF = "/documents/pdf/export-in-pdf";
export const DOCUMENTS_EXPORT_IN_WORD = "/documents/word/export-in-word";
export const DOCUMENTS_GENERATE_PRESIGNED_URL =
  "/documents/configuration/generate-presigned-url";
export const GET_PROPOSAL_BY_REFERENCE_NUMBER =
  "/order/order_information/:referenceNumber";
export const CALCULATE_QUOTE = "CalculateQuote";
export const DASHBOARD_ANALYTICS = "/order/proposal-dashboard-analytics";
export const UPLOAD_BASE_64 = "/documents/configuration/upload-file-as-base64";
export const CHANGE_PROPOSAL_STATUS = "/order/change-proposal-status";
