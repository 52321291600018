import { Divider as MuiDivider, Chip, styled } from "@mui/material";
type IDivider = {
  text: string;
};
const Root = styled("div")(({ theme }) => ({
  width: "100%",
  marginBottom: 15,
  marginTop: 15,
  ...theme.typography.body2,
  "& > :not(style) + :not(style)": {
    marginTop: theme.spacing(1),
  },
}));
export const Divider = ({ text }: IDivider) => {
  return (
    <Root>
      <MuiDivider textAlign={"left"}>
        {/* <Chip label={text} /> */}
        {text}
      </MuiDivider>
    </Root>
  );
};
