import { Box, Button, Dialog, Icon } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { CommunicationHistoryWrap } from "./communication-history.style";
import { CommunicationHistoryList } from "./communication-history-list.component";
import { CommunicationHistoryDetails } from "./communication-history-details.component";

export const CommunicationHistoryDialog = ({ open, onClose }) => {
  const theme = useTheme();
  return (
    <Dialog
      theme={theme}
      size="sm"
      title="Communication History"
      onCloseDialog={onClose}
      noFooter
      open={open}
    >
      <CommunicationHistoryWrap
        theme={theme}
        className="communication-history-dialog communication-history-wrap"
      >
        <Box theme={theme} className="content-container">
          <Box theme={theme}>
            <CommunicationHistoryList />
            <Box theme={theme} display={"none"}>
              <Box theme={theme} className="back-btn-wrap">
                <Button
                  theme={theme}
                  defaultBtn
                  startIcon={<Icon name="IconLeftArrow" />}
                  text="Inbox"
                  onClick={() => {}}
                />
              </Box>
              <CommunicationHistoryDetails />
            </Box>
          </Box>
        </Box>
      </CommunicationHistoryWrap>
    </Dialog>
  );
};
