import { TableCell, TableRow, useTheme } from "@mui/material";
import { Button, Icon, Menu } from "@ntpkunity/controls";
import { ProposalTableRowProps } from "./proposal-table-row.props";
import {
  getCustomerName,
  getOrderStakeholders,
  pounds,
  getProposalRowOptions,
} from "@helpers/utils";
import dayjs from "dayjs";
import { AppDialogKeys, TableRowOptions, WorkQueueTabs } from "@helpers/enum";
import { useNavigate, useLocation } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import ProposalStatus from "./proposal-status/proposal-status.component";
import { TProposalWorkQueueEntry } from "@_types/workQueues";

const ProposalTableRow = ({
  proposal,
  queue,
  openDialog,
}: ProposalTableRowProps) => {
  let location = useLocation();

  const theme = useTheme();
  const navigate = useNavigate();
  const updatedAt = dayjs(proposal.updated_at, "YYYY-MM-DD");
  const dateSubmitted = proposal.date_submitted
    ? dayjs(proposal.date_submitted, "YYYY-MM-DD")
    : null;
  const customerName = getCustomerName(proposal.customer);
  const isTrackProposal = queue === WorkQueueTabs.TRACK_PROPOSALS;
  const lenderName = getOrderStakeholders(
    proposal.order_stakeholders
  )?.lenderName;

  const handleOptionClick = (
    _,
    key: TableRowOptions,
    proposal: TProposalWorkQueueEntry
  ) => {
    if (key === TableRowOptions.OPEN_DIARY) {
      navigate(`${APP_ROUTES.DIARY}/${proposal.identifier}`);
      return;
    }
    if (key === TableRowOptions.GENERATE_DOCUMENTS) {
      navigate(APP_ROUTES.DOCUMENT);
      return;
    }
    if (key === TableRowOptions.SEND_FOR_PAYOUT) {
      navigate(APP_ROUTES.SEND_FOR_PAYOUT);
      return;
    }
    if (key === TableRowOptions.CHECK_SIGNATORY_STATUS) {
      navigate(APP_ROUTES.SIGNATORY_STATUS);
      return;
    }
    if (key === TableRowOptions.VIEW_CONDITIONS) {
      openDialog(AppDialogKeys.VIEW_CONDITIONS);
      return;
    }
    if (key === TableRowOptions.VIEW_EDIT) {
      navigate(`${APP_ROUTES.PROPOSAL}/${proposal.identifier}`);
      return;
    }
    if (key === TableRowOptions.PROPOSAL_SUMMARY) {
      if (location.pathname.includes("track-proposals")) {
        navigate(`${APP_ROUTES.PROPOSAL}/${proposal.identifier}`, {
          state: { summary: true, credit_documents: false },
        });
      } else {
        navigate(`${APP_ROUTES.PROPOSAL}/${proposal.identifier}`);
      }
      return;
    }
    if (key === TableRowOptions.UPLOAD) {
      if (location.pathname.includes("track-proposals")) {
        navigate(`${APP_ROUTES.PROPOSAL}/${proposal.identifier}`, {
          state: { credit_documents: true, summary: false },
        });
      } else {
        navigate(`${APP_ROUTES.PROPOSAL}/${proposal.identifier}`);
      }
      return;
    }
    if (key === TableRowOptions.MANUAL_STATUS_CHANGE) {
      openDialog(AppDialogKeys.MANUAL_STATUS_CHANGE, {
        identifier: proposal.identifier,
        status: proposal.status,
      });
      return;
    }
    if (key === TableRowOptions.WITHDRAW) {
      openDialog(AppDialogKeys.WITHDRAW_CONFIRMATION, {
        identifier: proposal.identifier,
        status: proposal.status,
        referenceNumber: proposal.reference_number,
        name: proposal.name,
      });
      return;
    }
  };

  return (
    <TableRow className="child-tr">
      <TableCell>
        <ProposalStatus status={proposal.status} />
      </TableCell>
      <TableCell>{proposal.name}</TableCell>
      <TableCell>{proposal.product_type}</TableCell>
      <TableCell>{customerName}</TableCell>
      {isTrackProposal && (
        <>
          {false && <TableCell>{lenderName}</TableCell>}
          <TableCell>{proposal.introducer_name}</TableCell>
        </>
      )}
      <TableCell className="text-right">
        {pounds.format(proposal.finance_amount)}
      </TableCell>
      <TableCell>{updatedAt.format("DD/MM/YYYY")}</TableCell>
      {isTrackProposal && (
        <TableCell>
          {dateSubmitted ? dateSubmitted.format("DD/MM/YYYY") : null}
        </TableCell>
      )}
      <TableCell className="action-cell fixed-cell">
        <Menu
          theme={theme}
          options={getProposalRowOptions(proposal)}
          handleOptionClick={handleOptionClick}
          render={(cb) => (
            <Button
              defaultBtn
              theme={theme}
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            ></Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default ProposalTableRow;
