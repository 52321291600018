import { AddDocumentDialogProps } from "./edit-document-dialog.props";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useProposalFieldArrays } from "@contexts/proposal-form";
import { TDocumentDetails, DocumentType, TProposalForm } from "@_types";
import DocumentFormDialog from "../document-form-dialog/document-form-dialog.component";
import { useRef } from "react";

const EditDocumentDialog = ({
  openDialog,
  setOpenDialog,
  editDocumentId,
  setEditDocumentId,
}: AddDocumentDialogProps) => {
  const {
    documentMethods: { update: updateDocument, fields: documents },
  } = useProposalFieldArrays();

  const { watch } = useFormContext<TProposalForm>();
  const clientType = watch("customerDetails.clientType");

  const fileDetailsRef = useRef<DocumentType[]>();

  const documentToEdit = documents.find((doc) => doc.id === editDocumentId);
  const formMethods = useForm<TDocumentDetails>({
    defaultValues: { ...documentToEdit },
  });

  const handleSaveDocument = () => {
    const documentToEditIndex = documents.findIndex(
      (doc) => doc.id === editDocumentId
    );
    updateDocument(documentToEditIndex, {
      ...formMethods.getValues(),
      documents: fileDetailsRef.current,
    });
    formMethods.reset();
    setOpenDialog(false);
  };

  const closeDialog = () => {
    formMethods.reset();
    setOpenDialog(false);
    setEditDocumentId(null);
  };

  return (
    <FormProvider {...formMethods}>
      <DocumentFormDialog
        clientType={clientType}
        openDialog={openDialog}
        closeDialog={closeDialog}
        handleSaveDocument={handleSaveDocument}
        isEditMode
        filesPayload={documentToEdit.documents}
        ref={fileDetailsRef}
      />
    </FormProvider>
  );
};

export default EditDocumentDialog;
