import { useTheme } from "@mui/material";
import { Box, Typography } from "@ntpkunity/controls";
import { DocumentList, Stack } from "@components";
import { DocumentsWrap } from "./documents.style";
import UploadAdditionalDocuments from "./document-dialog/document-dialog.component";
import { PAYOUT_STATUS } from "@helpers/const";

import { useState } from "react";

export const Documents = () => {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      <DocumentsWrap theme={theme} className="documents-wrap">
        <Stack
          paddingXs={3}
          paddingMd={3}
          title="Documents"
          actionArea={
            <Typography
              theme={theme}
              variant="body2"
              component="p"
              className="text-primary fw-medium"
              display={"block"}
            >
              <a
                href="#"
                className="link text-primary decoration-none"
                onClick={() => setDialogOpen(true)}
              >
                Upload Additional Documents
              </a>
            </Typography>
          }
        >
          <Box theme={theme} mt={2}>
            <DocumentList statuses={PAYOUT_STATUS} />
          </Box>
        </Stack>
      </DocumentsWrap>
      {dialogOpen && (
        <UploadAdditionalDocuments
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        />
      )}
    </>
  );
};
