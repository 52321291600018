import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import {
  BalloonCollectionsAdvance,
  BalloonCollectionsArrear,
  FinanceTypes,
  Gross,
  SolveForItems,
  annuityRateTypes,
  commissionType,
  financeAmountRateType,
  financeAmountRateTypeforPercentage,
  nonDecimalFieldType,
  rateTypes,
} from "@flexCommon/constants";
import {
  AmortizationMethod,
  BalloonCollection,
  CommissionType,
  Currency,
  FieldType,
  FinanceAmountRateType,
  FinanceType,
  PaymentMode,
  RateTypes,
  SolveFor,
  VatDeferralType,
  VatTreatment,
} from "@flexCommon/enums";
import { getDisabledProp, getHiddenProp } from "@helpers/common";
import {
  IConfigurationInputs,
  IFieldProp,
} from "@interfaces/configuration.interface";
import { GetConfiguration } from "@services/configuration.service";
import Icon from "@components/quotation/shared/assets/Icon";
import {
  setFinancialRequestObj,
  setSolveFor,
} from "@store/actions/financialCalculation.actions";
import { RootState } from "@store/reducers";
import {
  Button,
  CustomDropdown,
  DatePicker,
  Dropdown,
  Scroll,
  TextBox,
  ToggleBtn,
} from "@components/quotation/shared/controls";
import { CalculationWrap } from "@components/quotation/configuration.style";
import { Calculate as CalculateActual } from "@flexCommon/calculation";
import { StructureRentals } from "@components/quotation/rental-structure/rental-structure.component";
import { Step } from "@components/quotation/step/step.component";
import { StepPaymentStructure } from "@components/quotation/step-payment-structure/step-payment-structure.component";
import { VAT } from "@components/quotation/vat/vat.component";

const CombinedControl = styled("div")(({ theme }) => ({
  display: "flex",
  ".u-form-group": {
    marginBottom: 0,
    marginRight: 10,
    width: "65%",
    "&:last-child": {
      width: "35%",
      marginRight: 0,
    },
  },
}));

type IInputs = {
  financeType: string;
  handleFinanceTypeChange(e: string): void;
  fieldProps: IFieldProp[];
  configState: IConfigurationInputs | undefined;
};
export const Inputs = ({
  financeType,
  handleFinanceTypeChange,
  fieldProps,
  configState,
}: IInputs) => {
  const storeState = useSelector(
    (state: RootState) => state.reducerFinancialCalculation
  );
  const dispatch = useDispatch();
  const [types, setTypes] = useState(rateTypes);
  const [commissionTypeDisable, setCommissionTypeDisable] = useState(false);
  const gridSize = 6;
  useEffect(() => {
    if (storeState.RequestObj.PaymentMode === PaymentMode.Advance)
      dispatch(
        setFinancialRequestObj({
          ...storeState.RequestObj,
          BalloonCollection: BalloonCollection.OneMonthAfterLastPayment,
        })
      );
  }, [storeState.RequestObj.PaymentMode]);
  useEffect(() => {
    dispatch(
      setFinancialRequestObj({
        ...storeState.RequestObj,
        PaymentMode:
          storeState.RequestObj.AdvancePayments > 0
            ? PaymentMode.Advance
            : PaymentMode.Arrear,
      })
    );
  }, [storeState.RequestObj.AdvancePayments]);
  useEffect(() => {
    if (storeState.SolveFor === SolveFor.Commmission)
      dispatch(
        setFinancialRequestObj({
          ...storeState.RequestObj,
          CommissionPercentage: 0,
          CommissionAmount: 0,
        })
      );
    else if (storeState.SolveFor === SolveFor.Deposit)
      dispatch(
        setFinancialRequestObj({
          ...storeState.RequestObj,
          Deposit: 0,
        })
      );
    else if (storeState.SolveFor === SolveFor.FinanceAmount)
      dispatch(
        setFinancialRequestObj({
          ...storeState.RequestObj,
          AssetCost: 0,
        })
      );
    else if (storeState.SolveFor === SolveFor.RV)
      dispatch(
        setFinancialRequestObj({
          ...storeState.RequestObj,
          BalloonPayment: 0,
        })
      );
  }, [storeState.SolveFor]);
  useEffect(() => {
    const type =
      storeState.SolveFor != SolveFor.PeriodicPayment
        ? VatDeferralType.Rental
        : storeState.RequestObj.VAT.type;
    if (
      storeState.SolveFor == SolveFor.FinanceAmount ||
      storeState.SolveFor == SolveFor.Deposit
    ) {
      setTypes(financeAmountRateType);
      dispatch(
        setFinancialRequestObj({
          ...storeState.RequestObj,
          RateType: "NetYield",
          VAT: {
            ...storeState.RequestObj.VAT,
            type: type,
          },
        })
      );
    } else {
      setTypes(rateTypes);
      dispatch(
        setFinancialRequestObj({
          ...storeState.RequestObj,
          RateType: RateTypes.NominalRate,
          VAT: {
            ...storeState.RequestObj.VAT,
            type: type,
          },
        })
      );
    }
  }, [storeState.SolveFor]);
  useEffect(() => {
    if (storeState.RequestObj.RateType == FinanceAmountRateType.NetYield) {
      dispatch(
        setFinancialRequestObj({
          ...storeState.RequestObj,
          CommissionType: CommissionType.Amount,
        })
      );
      setCommissionTypeDisable(true);
    } else {
      setCommissionTypeDisable(false);
    }
  }, [storeState.RequestObj.RateType]);

  const theme = useTheme();

  storeState.CalculationConfiguration?.AmortizationMethod ==
    AmortizationMethod.Annuity360 &&
  storeState.SolveFor !== SolveFor.FinanceAmount &&
  storeState.SolveFor !== SolveFor.Deposit
    ? annuityRateTypes
    : types;

  const getRateType = () => {
    if (storeState.SolveFor == SolveFor.FinanceAmount) {
      return financeAmountRateType;
    } else if (
      storeState.CalculationConfiguration?.AmortizationMethod ==
        AmortizationMethod.Annuity360 &&
      storeState.SolveFor !== SolveFor.FinanceAmount &&
      storeState.SolveFor !== SolveFor.Deposit
    ) {
      return annuityRateTypes;
    } else {
      return types;
    }
  };

  const checkRateRype = () => {
    if (
      storeState.SolveFor == SolveFor.PeriodicPayment ||
      storeState.SolveFor == SolveFor.FinanceAmount ||
      storeState.SolveFor == SolveFor.Deposit
    ) {
      return false;
    } else return true;
  };

  const getPeriodicPayment = () => {
    if (storeState.SolveFor != SolveFor.PeriodicPayment) {
      return (
        <Grid item md={6} xs={12}>
          <TextBox
            testid="txtPeriodicPaymentAmount"
            theme={theme}
            fullWidth
            label="Periodic Payment Amount"
            masking
            startAdornment={Currency.GBP}
            value={
              storeState.RequestObj.PeriodicPayment !== 0 ||
              storeState.RequestObj.PeriodicPayment > 0
                ? storeState.RequestObj.PeriodicPayment
                : ""
            }
            placeHolder={
              nonDecimalFieldType.includes("PeriodicPayment") ? "0" : "0.00"
            }
            onChange={(e) =>
              dispatch(
                setFinancialRequestObj({
                  ...storeState.RequestObj,
                  PeriodicPayment: e.target.value,
                })
              )
            }
          />
        </Grid>
      );
    } else {
      return <div></div>;
    }
  };

  const getStepPayment = () => {
    if (storeState.RequestObj.StepPaymentsApplicable) {
      if (!getHiddenProp(fieldProps, FieldType.StepPaymentsApplicable)) {
        return (
          <>
            <Step storeState={storeState} fieldProps={fieldProps} />
          </>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  const getStepPaymentStructure = () => {
    if (storeState.RequestObj.StepPaymentsStructureApplicable) {
      if (
        !getHiddenProp(fieldProps, FieldType.StepPaymentsStructureApplicable)
      ) {
        return (
          <>
            <StepPaymentStructure fieldProps={fieldProps} />
          </>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Box className="create-quotation" sx={{ p: 3.5, pb: 3, pt: 3.5 }}>
        <Grid container columnSpacing={1}>
          <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
            <Typography
              variant="h4"
              component="h4"
              children="Create Quotation"
              className="main-title"
            />
          </Grid>
          <Grid item xs={12} sm={7} md={7} lg={7} xl={7} sx={{ pt: 0.5 }}>
            <CustomDropdown className="dropDown-with-label">
              <Typography
                variant="body2"
                component="span"
                children="Calculate:"
                className="main-title"
              />
              <Dropdown
                disablePortal
                label=""
                items={SolveForItems}
                value={storeState.SolveFor}
                onChange={(e) => {
                  dispatch(setSolveFor(e.target.value));
                  if (e.target.value == SolveFor.PeriodicPayment) {
                    dispatch(
                      setFinancialRequestObj({
                        ...storeState.RequestObj,
                        PeriodicPayment: 0,
                      })
                    );
                  }
                  if (storeState.SolveFor === SolveFor.PeriodicPayment) {
                    dispatch(
                      setFinancialRequestObj({
                        ...storeState.RequestObj,
                        HasStructureRentals: false,
                        StructureRentals: [],
                      })
                    );
                  }
                  if (
                    e.target.value == SolveFor.FinanceAmount ||
                    e.target.value == SolveFor.Deposit
                  ) {
                    if (
                      storeState.RequestObj.DepositType ==
                      CommissionType.Percentage
                    ) {
                      dispatch(
                        setFinancialRequestObj({
                          ...storeState.RequestObj,
                          DepositAmount: storeState.RequestObj.Deposit,
                          DepositType: CommissionType.Amount,
                        })
                      );
                    }
                  }
                }}
              />
            </CustomDropdown>

            {/* <Box></Box> */}
          </Grid>
        </Grid>
      </Box>
      <Box className="tabs-mbl">
        <ToggleBtn
          items={FinanceTypes}
          value={financeType}
          onChange={(e) => handleFinanceTypeChange(e)}
        />
      </Box>

      <Scroll className="h-xl-b" sx={{ mt: 3 }}>
        <Box className="scroll-container">
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item md={6} xs={12} sx={{ p: 0 }}>
              <DatePicker
                theme={theme}
                testid="txtStartDate"
                id="txtStartDate"
                label="Start Date"
                value={storeState.RequestObj.StartDate}
                disabled={getDisabledProp(fieldProps, FieldType.StartDate)}
                hidden={getHiddenProp(fieldProps, FieldType.StartDate)}
                onChange={(e) =>
                  dispatch(
                    setFinancialRequestObj({
                      ...storeState.RequestObj,
                      StartDate: e,
                    })
                  )
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextBox
                theme={theme}
                fullWidth
                testid="txtAssetCost"
                label="Asset Cost"
                masking
                startAdornment={Currency.GBP}
                value={
                  storeState.RequestObj.AssetCost === 0 ||
                  storeState.RequestObj.AssetCost > 0
                    ? storeState.RequestObj.AssetCost
                    : ""
                }
                placeHolder={
                  nonDecimalFieldType.includes(FieldType.AssetCost)
                    ? "0"
                    : "0.00"
                }
                disabled={
                  getDisabledProp(fieldProps, FieldType.AssetCost) ||
                  storeState.SolveFor == SolveFor.FinanceAmount
                }
                hidden={getHiddenProp(fieldProps, FieldType.AssetCost)}
                onChange={(e) => {
                  if (
                    storeState.RequestObj.IsAutoCalculated &&
                    storeState.RequestObj.Tax > 0
                  ) {
                    dispatch(
                      setFinancialRequestObj({
                        ...storeState.RequestObj,
                        AssetCost: e.target.value
                          ? Number(e.target.value)
                          : e.target.value,
                        VAT: {
                          ...storeState.RequestObj.VAT,
                          Amount:
                            (Number(storeState.RequestObj.Tax) / 100) *
                            e.target.value,
                        },
                      })
                    );
                  } else {
                    dispatch(
                      setFinancialRequestObj({
                        ...storeState.RequestObj,
                        AssetCost: e.target.value
                          ? Number(e.target.value)
                          : e.target.value,
                      })
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <CombinedControl>
                <TextBox
                  theme={theme}
                  fullWidth
                  testid="txtDeposit"
                  label="Deposit"
                  endAdornment={
                    storeState.RequestObj.DepositType ==
                    CommissionType.Percentage
                      ? CommissionType.Percentage
                      : ""
                  }
                  startAdornment={
                    storeState.RequestObj.DepositType == CommissionType.Amount
                      ? Currency.GBP
                      : ""
                  }
                  masking
                  value={
                    storeState.RequestObj.DepositAmount === 0 ||
                    storeState.RequestObj.DepositAmount > 0
                      ? storeState.RequestObj.DepositAmount
                      : ""
                  }
                  placeHolder={
                    nonDecimalFieldType.includes(FieldType.Deposit)
                      ? "0"
                      : "0.00"
                  }
                  disabled={
                    getDisabledProp(fieldProps, FieldType.Deposit) ||
                    storeState.SolveFor == SolveFor.Deposit
                  }
                  hidden={getHiddenProp(fieldProps, FieldType.Deposit)}
                  onChange={(e) => {
                    if (
                      storeState.RequestObj.DepositType ==
                      CommissionType.Percentage
                    ) {
                      dispatch(
                        setFinancialRequestObj({
                          ...storeState.RequestObj,
                          Deposit:
                            (Number(e.target.value) / 100) *
                            Number(storeState.RequestObj.AssetCost),
                          DepositAmount: e.target.value
                            ? Number(e.target.value)
                            : e.target.value,
                        })
                      );
                    } else {
                      dispatch(
                        setFinancialRequestObj({
                          ...storeState.RequestObj,
                          Deposit: e.target.value,
                          DepositAmount: e.target.value
                            ? Number(e.target.value)
                            : e.target.value,
                        })
                      );
                    }
                  }}
                />
                <Dropdown
                  items={commissionType}
                  label={""}
                  testid="txtDepositType"
                  disabled={
                    storeState.SolveFor == SolveFor.FinanceAmount ||
                    storeState.SolveFor == SolveFor.Deposit
                  }
                  value={storeState.RequestObj.DepositType}
                  onChange={(e) => {
                    if (e.target.value == CommissionType.Percentage) {
                      dispatch(
                        setFinancialRequestObj({
                          ...storeState.RequestObj,
                          Deposit:
                            (Number(storeState.RequestObj.DepositAmount) /
                              100) *
                            Number(storeState.RequestObj.AssetCost),
                          DepositType: e.target.value,
                        })
                      );
                    } else {
                      dispatch(
                        setFinancialRequestObj({
                          ...storeState.RequestObj,
                          Deposit: storeState.RequestObj.DepositAmount,
                          DepositType: e.target.value,
                        })
                      );
                    }
                  }}
                />
              </CombinedControl>
              {/* <TextBox
                theme={theme}
                fullWidth
                label="Deposit"
                startAdornment={Currency.GBP}
                masking
                value={storeState.RequestObj.Deposit}
                disabled={getDisabledProp(fieldProps, FieldType.Deposit) || storeState.SolveFor == SolveFor.Deposit}
                hidden={getHiddenProp(fieldProps, FieldType.Deposit)}
                onChange={(e) =>
                  dispatch(
                    setFinancialRequestObj({
                      ...storeState.RequestObj,
                      Deposit: e.target.value,
                    })
                  )
                }
              /> */}
            </Grid>
            <Grid item xs={12} lg={6}>
              <CombinedControl>
                <TextBox
                  theme={theme}
                  fullWidth
                  testid="txtComission"
                  label="Commission"
                  endAdornment={
                    storeState.RequestObj.CommissionType ==
                    CommissionType.Percentage
                      ? CommissionType.Percentage
                      : ""
                  }
                  startAdornment={
                    storeState.RequestObj.CommissionType ==
                    CommissionType.Amount
                      ? Currency.GBP
                      : ""
                  }
                  masking
                  value={
                    storeState.RequestObj.CommissionPercentage === 0 ||
                    storeState.RequestObj.CommissionPercentage > 0
                      ? storeState.RequestObj.CommissionPercentage
                      : ""
                  }
                  placeHolder={
                    nonDecimalFieldType.includes(FieldType.CommissionPercentage)
                      ? "0"
                      : "0.00"
                  }
                  disabled={
                    getDisabledProp(
                      fieldProps,
                      FieldType.CommissionPercentage
                    ) || storeState.SolveFor == SolveFor.Commmission
                  }
                  hidden={getHiddenProp(
                    fieldProps,
                    FieldType.CommissionPercentage
                  )}
                  onChange={(e) =>
                    dispatch(
                      setFinancialRequestObj({
                        ...storeState.RequestObj,
                        CommissionPercentage: e.target.value
                          ? Number(e.target.value)
                          : e.target.value,
                      })
                    )
                  }
                />
                <Dropdown
                  items={commissionType}
                  disabled={commissionTypeDisable}
                  testid="txtComissionType"
                  label={""}
                  value={storeState.RequestObj.CommissionType}
                  onChange={(e) => {
                    dispatch(
                      setFinancialRequestObj({
                        ...storeState.RequestObj,
                        CommissionType: e.target.value,
                      })
                    );
                  }}
                />
              </CombinedControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextBox
                theme={theme}
                fullWidth
                testid="txtRate"
                label="Rate"
                masking
                endAdornment="%"
                decimalScale={2}
                disabled={
                  getDisabledProp(fieldProps, FieldType.Rate) ||
                  storeState.SolveFor == SolveFor.Rates
                }
                hidden={getHiddenProp(fieldProps, FieldType.Rate)}
                value={
                  storeState.RequestObj.Rate === 0 ||
                  storeState.RequestObj.Rate > 0
                    ? storeState.RequestObj.Rate
                    : ""
                }
                placeHolder={
                  nonDecimalFieldType.includes(FieldType.Rate) ? "0" : "0.00"
                }
                onChange={(e) =>
                  dispatch(
                    setFinancialRequestObj({
                      ...storeState.RequestObj,
                      Rate: e.target.value
                        ? Number(e.target.value)
                        : e.target.value,
                    })
                  )
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Dropdown
                label="Rate Type"
                testid="txtRateType"
                items={getRateType()}
                value={storeState.RequestObj.RateType}
                disabled={
                  getDisabledProp(fieldProps, FieldType.RateType) ||
                  checkRateRype()
                }
                hidden={getHiddenProp(fieldProps, FieldType.RateType)}
                onChange={(e) =>
                  dispatch(
                    setFinancialRequestObj({
                      ...storeState.RequestObj,
                      RateType: e.target.value,
                      VAT: {
                        ...storeState.RequestObj.VAT,
                        type:
                          e.target.value == RateTypes.FlatRate
                            ? VatDeferralType.Rental
                            : storeState.RequestObj.VAT.type,
                      },
                    })
                  )
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextBox
                theme={theme}
                fullWidth
                testid="txtAdvancePayments"
                label="Advance Payments"
                thousandSeparater={false}
                masking
                decimalScale={0}
                disabled={getDisabledProp(
                  fieldProps,
                  FieldType.AdvancePayments
                )}
                hidden={getHiddenProp(fieldProps, FieldType.AdvancePayments)}
                value={
                  storeState.RequestObj.AdvancePayments === 0 ||
                  storeState.RequestObj.AdvancePayments > 0
                    ? storeState.RequestObj.AdvancePayments
                    : ""
                }
                placeHolder={
                  nonDecimalFieldType.includes(FieldType.AdvancePayments)
                    ? "0"
                    : "0.00"
                }
                onChange={(e) =>
                  dispatch(
                    setFinancialRequestObj({
                      ...storeState.RequestObj,
                      AdvancePayments: e.target.value
                        ? Number(e.target.value)
                        : e.target.value,
                    })
                  )
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextBox
                theme={theme}
                fullWidth
                testid="txtRegularPayments"
                label="Regular Payments"
                thousandSeparater={false}
                masking
                decimalScale={0}
                disabled={getDisabledProp(
                  fieldProps,
                  FieldType.RegularPayments
                )}
                hidden={getHiddenProp(fieldProps, FieldType.RegularPayments)}
                value={
                  storeState.RequestObj.RegularPayments === 0 ||
                  storeState.RequestObj.RegularPayments > 0
                    ? storeState.RequestObj.RegularPayments
                    : ""
                }
                placeHolder={
                  nonDecimalFieldType.includes(FieldType.RegularPayments)
                    ? "0"
                    : "0.00"
                }
                onChange={(e) =>
                  dispatch(
                    setFinancialRequestObj({
                      ...storeState.RequestObj,
                      RegularPayments: e.target.value
                        ? Number(e.target.value)
                        : e.target.value,
                    })
                  )
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextBox
                theme={theme}
                fullWidth
                testid="txtBalloonPayments"
                label="Balloon Payment"
                startAdornment={Currency.GBP}
                masking
                disabled={getDisabledProp(fieldProps, FieldType.BalloonPayment)}
                hidden={getHiddenProp(fieldProps, FieldType.BalloonPayment)}
                value={
                  storeState.RequestObj.BalloonPayment === 0 ||
                  storeState.RequestObj.BalloonPayment > 0
                    ? storeState.RequestObj.BalloonPayment
                    : ""
                }
                placeHolder={
                  nonDecimalFieldType.includes(FieldType.BalloonPayment)
                    ? "0"
                    : "0.00"
                }
                onChange={(e) =>
                  dispatch(
                    setFinancialRequestObj({
                      ...storeState.RequestObj,
                      BalloonPayment: e.target.value
                        ? Number(e.target.value)
                        : e.target.value,
                    })
                  )
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Dropdown
                testid="txtBalloonCollection"
                label="Balloon Collection"
                disabled={getDisabledProp(
                  fieldProps,
                  FieldType.BalloonCollection
                )}
                hidden={getHiddenProp(fieldProps, FieldType.BalloonCollection)}
                items={
                  storeState.RequestObj.PaymentMode === PaymentMode.Advance ||
                  storeState.CalculationConfiguration?.AmortizationMethod ==
                    AmortizationMethod.Annuity360
                    ? BalloonCollectionsAdvance
                    : BalloonCollectionsArrear
                }
                value={storeState.RequestObj.BalloonCollection}
                onChange={(e) =>
                  dispatch(
                    setFinancialRequestObj({
                      ...storeState.RequestObj,
                      BalloonCollection: e.target.value,
                    })
                  )
                }
              />
            </Grid>
            {getPeriodicPayment()}
            <VAT
              fieldProps={fieldProps}
              gridSize={gridSize}
              financeType={financeType}
              storeState={storeState}
              visible={configState?.IsVATApplicable ?? false}
            />
            {storeState.RequestObj.IrregularPaymentsApplicable &&
            !getHiddenProp(
              fieldProps,
              FieldType.IrregularPaymentsApplicable
            ) ? (
              <StructureRentals fieldProps={fieldProps} />
            ) : (
              <></>
            )}
            {getStepPayment()}
            {getStepPaymentStructure()}
            <Box className="btn-calculate-mobile">
              <CalculateActual
                CalculationConfiguration={storeState.CalculationConfiguration}
              />
              <Box className="btn-group">
                <Button
                  // defaultBtn
                  theme={theme}
                  className="btn-reset"
                  iconText={<Icon name="ResetIcon" />}
                  onClick={() => {
                    // dispatch(setResetValues(getInitialState()));
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Box>
      </Scroll>
      {/* <CalculateActual
        CalculationConfiguration={storeState.CalculationConfiguration}
      /> */}
    </>
  );
};
