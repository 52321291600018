import { AxiosError } from "axios";
import { AmortizationMethod, RateTypes } from "@flexCommon/enums";
import { post } from "@flexCommon/api";
import { IRepaymentPlan, IRequestObj } from "@interfaces";
import {
  APRParams,
  MergeFeeAndRepaymentPlanRequestParam,
  PresentValueCalculationParam,
  RVOnActualRequestParam,
  RentalAmortizationParam,
  RentalAmortizationParamActual,
  RentalAmountRequestParam,
  RequestParamFlatRate,
} from "@interfaces/requestparams.flex";
import {
  APRResponse,
  FlatRateResponse,
  PresentValueCalculationResponse,
  RentalAmortizationResponse,
  RentalAmountAnnuity,
  RentalCalculationActual,
  ReverseRVCalculationResponse,
} from "@interfaces/responseParams.flex";
import { useSelector } from "react-redux";
import { RootState } from "@store/reducers";

const baseUrl = `${process.env.REACT_APP_FLEX_URL}`;

export const CalculateRentalAmount = async (
  rentalAmortizationParam: RentalAmountRequestParam,
  rentalCalculationMethod: string,
  calculateOnAnnualRate: boolean = false,
  checkAllStructureRental: boolean = false,
  RateType: string
): Promise<RentalCalculationActual> => {
  var response: RentalCalculationActual = {
    rentalSummary: [],
    repaymentPlan: [],
    updatedFinancedAmount: 0,
  };
  const methodUrl =
    rentalCalculationMethod === AmortizationMethod.Annuity360 &&
    RateType != RateTypes.FlatRate
      ? "RentalAmountAnnuityV2"
      : "RentalAmountActual";
  await post(`${baseUrl}${methodUrl}`, rentalAmortizationParam, [
    {
      Key: "calculateOnAnnualRate",
      Value: calculateOnAnnualRate,
    },
    {
      Key: "allRentalStructuresProvided",
      Value: checkAllStructureRental,
    },
  ])
    .then((res: any) => {
      response = res;
    })
    .catch((ex: AxiosError) => {
      throw ex;
    });
  return response;
};

export const CalculateAmortization = async (
  param: RentalAmortizationParamActual,
  calculationMethod: string,
  requestObject: IRequestObj,
  checkGrossProfitCalculation: boolean = false,
  FundingCost: number = 0
): Promise<RentalAmortizationResponse> => {
  var response: RentalAmortizationResponse = {
    iRR: 0,
    repaymentPlan: [],
  };
  let methodUrl = "CreateRentalAmortization";
  calculationMethod === AmortizationMethod.AnnuityActual360 &&
    (methodUrl = "CreateRentalAmortization360");
  calculationMethod === AmortizationMethod.AnnuityActual365 &&
    (methodUrl = "CreateRentalAmortization365");

  if (calculationMethod === AmortizationMethod.Annuity360) {
    let _param: RentalAmortizationParam = {
      startDate: param.startDate,
      ExtensionDays: 0,
      AllRentalStructuresProvided: true,
      requestParam: {
        Apr: checkGrossProfitCalculation ? FundingCost : param.Apr,
        contractTerms: param.contractTerms,
        FinancedAmount: param.FinancedAmount,
        ProductRateConversionMethod: param.ProductRateConversionMethod,
        productType: param.residualValue == 0 ? "None" : "Balloon",
        rentalFrequency: param.rentalFrequency,
        rentalMode: param.rentalMode,
        residualValue: param.residualValue,
        StructureRental: [],
      },
    };
    _param.balloonParamForAnnuity = {
      NumberOfAdvanceRentals: Number(requestObject.AdvancePayments),
      BaloonReceivedAfterTerms:
        param.residualValue > 0 ? requestObject.BalloonCollection : 0,
      BaloonTreatment: "AdditionToLastPayment",
    };

    param.RentalStructures?.map((rental: any) => {
      if (["Structured", "Auto", "Bonus Payment"].includes(rental.rentalType))
        _param.requestParam.StructureRental.push({
          Amount: rental.rentalAmount,
          StartTerm: rental.startTerm,
          EndTerm: rental.endTerm,
          RentalType: rental.rentalType,
          RentalFrequency: "Monthly",
        });
    });
    await post(`${baseUrl}${methodUrl}`, _param)
      .then((res: any) => {
        response = res;
      })
      .catch((ex) => {
        throw ex;
      });
    return response;
  } else {
    await post(`${baseUrl}${methodUrl}`, param)
      .then((res: any) => {
        response = res;
      })
      .catch((ex) => {
        throw ex;
      });
    return response;
  }
};

export const MergeFeeInRepaymentPlan = async (
  param: MergeFeeAndRepaymentPlanRequestParam
): Promise<IRepaymentPlan[]> => {
  var response: IRepaymentPlan[] = [];
  await post(`${baseUrl}MergeFeeInRepaymentPlan`, param)
    .then((res: any) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

export const CalculateAPR = async (param: APRParams): Promise<APRResponse> => {
  var response: APRResponse = {
    irr: 0,
  };
  await post(`${baseUrl}CalculateAPR`, param)
    .then((res: any) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

export const CalculateFlatRate = async (
  param: RequestParamFlatRate
): Promise<FlatRateResponse> => {
  var response: FlatRateResponse = {
    flatRate: 0,
  };
  await post(`${baseUrl}CalculateFlatRate`, param)
    .then((res: any) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

export const CalculatePresentValue = async (
  param: PresentValueCalculationParam
): Promise<PresentValueCalculationResponse> => {
  var response: PresentValueCalculationResponse = {
    presentValue: 0,
  };
  await post(`${baseUrl}PresentValueCalculation`, param)
    .then((res: any) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

export const CalculateReverseRV = async (
  param: RVOnActualRequestParam
): Promise<ReverseRVCalculationResponse> => {
  var response: ReverseRVCalculationResponse = {
    rv: 0,
  };
  await post(`${baseUrl}ReverseCalculateRV_Actual`, param)
    .then((res: any) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CreateRentalsAndRepaymentPlan = async (param: any) => {
  var response;
  let requestObject = {
    startDate: new Date(param.startDate),
    ExtensionDays:
      param.extensionDays === null ||
      param.extensionDays === undefined ||
      param.extensionDays === ""
        ? 0
        : param.extensionDays,
    requestParam: {
      apr: param.APR === "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      residualValue: param.residualValue === "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      financedAmount: param.financedAmount === "" ? 0 : param.financedAmount,
      structureRental: [],
    },
  };
  await post(`${baseUrl}CreateRentalAmortization`, requestObject)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};
