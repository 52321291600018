import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "@styles";
const ChevronDown =
  "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik01IDcuNUwxMCAxMi41TDE1IDcuNSIgc3Ryb2tlPSIjODA4MDgwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPC9zdmc+DQo=')";

export const ProposalSummaryWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.proposal-summary-wrap": {
    ".label, .value": {
      fontSize: theme.typography.fontSize,
      color: theme.palette.grey[600],
      lineHeight: "20px",
    },

    ".value": {
      color: CustomColors.textDark,
      fontWeight: theme.typography.fontWeightMedium,
    },

    ".subtitle": {
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.grey[600],
    },

    ".directors-details": {
      [theme.breakpoints.up("md")]: {
        ".u-accordian-wrap": {
          ".u-accordion-header": {
            cursor: "text",
            ".MuiAccordionSummary-expandIconWrapper": {
              display: "none",
            },
          },
          ".MuiCollapse-root.MuiCollapse-vertical ": {
            minHeight: "auto !important",
            height: "auto !important",
            visibility: "visible !important",
          },
        },
      },
      ".u-table-wrap": {
        ".u-table-container": {
          maxHeight: 280,
        },
      },
    },
    ".accordion-list-item": {
      ".u-accordian-wrap": {
        marginBottom: 0,
        ".u-accordion-header": {
          padding: "16px 0 0",
          minHeight: 24,
          ".MuiAccordionSummary-content": {
            margin: 0,
            ".main-title": {
              fontSize: theme.typography.body2.fontSize,
              lineHeight: theme.typography.body2.lineHeight,
              fontWeight: theme.typography.fontWeightRegular,
              color: theme.palette.grey[600],
              position: "relative",
              "&::after": {
                display: "block",
                backgroundImage: ChevronDown,
                content: '" "',
                width: 20,
                height: 20,
                position: "absolute",
                right: -20,
                top: "50%",
                transform: "rotate(0deg) translateY(-50%)",
                backgroundColor: theme.palette.common.white,
                transformOrigin: "top center",
                transition: "all 0.3s ease-in-out 0s",
              },
            },
            ".sub-title": {
              fontWeight: theme.customVariables.fontWeightSemiBold,
            },
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            display: "none",
          },
        },
        ".u-accordian-body": {
          borderBottom: "none",
          padding: 0,
        },
        "&.Mui-expanded": {
          ".u-accordion-header": {
            ".MuiAccordionSummary-content": {
              ".main-title": {
                "&::after": {
                  transform: "rotate(180deg) translateY(-50%)",
                },
              },
            },
          },
        },
      },
    },
  },
}));
