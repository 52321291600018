import AddDocumentDialog from "./add-document-dialog/add-document-dialog.component";
import EditDocumentDialog from "./edit-document-dialog/edit-document-dialog.component";
import { DocumentDialogProps } from "./document-dialog.props";

const DocumentDialog = ({
  openDialog,
  setOpenDialog,
  editDocumentId,
  setEditDocumentId,
}: DocumentDialogProps) => {
  const isEditMode = !!editDocumentId;

  const commonProps = {
    openDialog,
    setOpenDialog,
  };

  if (isEditMode) {
    return (
      <EditDocumentDialog
        {...commonProps}
        editDocumentId={editDocumentId}
        setEditDocumentId={setEditDocumentId}
      />
    );
  }

  return <AddDocumentDialog {...commonProps} />;
};

export default DocumentDialog;
