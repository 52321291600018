import { styled } from "@mui/material";
import { CustomColors } from "@themes/mui-theme";

export const Sidebar = styled("div")(({ theme }) => ({
  height: "calc(100vh - 75px)",
  width: 60,
  textAlign: "center",
  padding: "24px 8px",
  background: CustomColors.sidebarBg,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.common.white,

  ".MuiButtonBase-root": {
    width: "100%",
    display: "block",
    clear: "both",
    borderRadius: theme.shape.borderRadius,
    height: 50,

    img: {
      marginTop: 6,
    },

    "&:hover, &.selected": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },

    ".MuiTouchRipple-root": {
      display: "none",
    },
  },

  ".menu": {
    // margin: "34vh auto",
    height: "calc(100vh - 140px)",
    width: "100%",
    display: "inline-block",
    textAlign: "center",

    ".menu-items": {
      margin: "34vh auto",
    },
  },

  [theme.breakpoints.down("md")]: {
    ".logo": {
      height: 24,
    },
    ".title": {
      fontSize: 18,
      paddingTop: 3,
      "&:before": {
        margin: "-3px 24px 0 24px",
        height: 24,
      },
    },
  },
}));
