import {
  INotification,
  IRequestObj,
  IResponseObj,
  QuotationDetails,
  RatesVisibility,
} from "@interfaces";
import { IConfigurationInputs } from "@interfaces/configuration.interface";
import { CustomerDetail } from "@interfaces/requestparams.flex";
import ActionTypes from "@store/actionTypes";
export function setFinancialRequestObj(payload: IRequestObj) {
  return {
    type: ActionTypes.SET_FINANCIAL_REQUEST_DATA,
    payload: payload,
  };
}
export function setFinancialResponseObj(payload: IResponseObj) {
  return {
    type: ActionTypes.SET_FINANCIAL_RESPONSE_DATA,
    payload: payload,
  };
}
export function setNotification(payload: INotification) {
  return {
    type: ActionTypes.SET_NOTIFICATION,
    payload: payload,
  };
}
export function setRatesVisibility(payload: RatesVisibility) {
  return {
    type: ActionTypes.SET_RATES_VISIBILITY,
    payload: payload,
  };
}
export function setRentalCalculationProgress(payload: boolean) {
  return {
    type: ActionTypes.SET_RENTAL_CALCULATION_PROGRESS,
    payload: payload,
  };
}
export function setCalculatedNFA(payload: number) {
  return {
    type: ActionTypes.SET_CALCULATED_NFA,
    payload: payload,
  };
}
export function setCalculatedCommission(payload: number) {
  return {
    type: ActionTypes.SET_CALCULATED_COMMISSION,
    payload: payload,
  };
}
export function setCalculatedUpfrontPayment(payload: number) {
  return {
    type: ActionTypes.SET_CALCULATION_UPFRONTPAYMENT,
    payload: payload,
  };
}
export function setSliderOpen(payload: boolean) {
  return {
    type: ActionTypes.SET_SLIDER_OPEN,
    payload: payload,
  };
}
export function setCalculationInProgress(payload: boolean) {
  return {
    type: ActionTypes.SET_CALCULATION_IN_PROGRESS,
    payload: payload,
  };
}
export function setCalculationConfiguration(payload: IConfigurationInputs) {
  return {
    type: ActionTypes.SET_CALCULATION_CONFIGURATION,
    payload: payload,
  };
}
export function setAlert(payload: boolean) {
  return {
    type: ActionTypes.SET_ALERT,
    payload: payload,
  };
}
export function setSolveFor(payload: string) {
  return {
    type: ActionTypes.SET_SOLVE_FOR,
    payload: payload,
  };
}
export function setResetValues(payload: any) {
  return {
    type: ActionTypes.SET_RESET_VALUES,
    payload: payload,
  };
}
export function setGrossProfit(payload: any) {
  return {
    type: ActionTypes.SET_GROSS_PROFIT,
    payload: payload,
  };
}
export function setCustomerDetail(payload: CustomerDetail) {
  return {
    type: ActionTypes.SET_CUSTOMER_DETAIL,
    payload: payload,
  };
}
export function setRetrieveDetail(payload: CustomerDetail) {
  return {
    type: ActionTypes.SET_RETRIVE_DETAIL,
    payload: payload,
  };
}

export function setRetrivedData(payload: CustomerDetail[]) {
  return {
    type: ActionTypes.SET_RETRIVE_DETAIL,
    payload: payload,
  };
}

export function setGrossProfitApplicable(payload: boolean) {
  return {
    type: ActionTypes.SET_GROSS_PROFIT_APPLICABLE,
    payload: payload,
  };
}

export function setQuotationDetails(payload: QuotationDetails) {
  return {
    type: ActionTypes.SET_QUOTATION_DETAILS,
    payload: payload,
  };
}

export function resetFinancialCalculation() {
  return {
    type: ActionTypes.RESET_FINANCIAL_CALCULATION,
  };
}
