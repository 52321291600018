import React from "react";
import { useTheme } from "@mui/material";
import { Stack } from "@components/stack";
import { Avatar, Box, Typography } from "@ntpkunity/controls";

export const CommunicationHistoryDetails = () => {
  const theme = useTheme();
  return (
    <Stack paddingMd={3} paddingXs={3}>
      <Box theme={theme} className="detail-head">
        <Box theme={theme} className="list-img">
          <Avatar theme={theme}>SK</Avatar>
        </Box>
        <Box theme={theme} className="list-content">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
            className="fw-sbold"
          >
            Friedrich Gammill
          </Typography>
          <Typography
            theme={theme}
            variant="caption"
            component={"small"}
            display={"flex"}
            alignItems={"center"}
            gap={0.5}
          >
            <span className="text-muted">to </span>sample@sample.com
          </Typography>
        </Box>
      </Box>
      <Box theme={theme} className="deatils-content" mt={2}>
        <Typography
          theme={theme}
          variant="body2"
          component={"span"}
          display={"block"}
          className="fw-sbold"
        >
          Dear ABC,
        </Typography>
        <Typography
          theme={theme}
          variant="body2"
          component={"span"}
          display={"block"}
          className="text-muted"
          mt={1}
        >
          is simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently
          with desktop publishing software like Aldus PageMaker including
          versions of Lorem Ipsum. It is a long established fact that a reader
          will be distracted by the readable content of a page when looking at
          its layout. The point of using Lorem Ipsum is that it has a
          more-or-less normal distribution of letters, as opposed to using
          'Content here, content here', making it look like readable English.
          Many desktop publishing packages and web page editors now use Lorem
          Ipsum as their default model text, and a search for 'lorem ipsum' will
          uncover many web sites still in their infancy. Various versions have
          evolved over the years, sometimes by accident, sometimes on purpose
          (injected humour and the like).
        </Typography>
      </Box>
    </Stack>
  );
};
