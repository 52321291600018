import { get, post } from "@flexCommon/api";
import { AxiosError } from "axios";
import { IReducerFinancialCalculation } from "@store/reducers/financialCalculation.reducer";
import { CustomerDetail } from "@interfaces/requestparams.flex";
import dayjs from "dayjs";
import { VatTreatment } from "@flexCommon/enums";
import { BalloonCollectionsArrear } from "@flexCommon/constants";
import { dmsAPI } from "./instances";
import {
  GET_QUOTATION_BY_IDENTIFIER,
  GET_QUOTATION_BY_VENDOR,
  GET_ORDER_STATUS_HISTORY,
  CONVERT_QUOTATION_TO_PROPOSAL,
  UPDATE_QUOTATION,
  GET_PROPOSAL_BY_REFERENCE_NUMBER,
  SAVE_UPDATE_PROPOSAL,
  DASHBOARD_ANALYTICS,
  CHANGE_PROPOSAL_STATUS,
} from "@helpers/endpoints";
import { getQueryParamsString } from "@helpers/utils";
import { ChangeProposalStatusReq, TProposalPayloadReq } from "@_types";
import { TOrderStatusHistoryReq } from "@_types/auditTrail";

const baseUrl = `${process.env.REACT_APP_DMS_URL}`;

export const getPayloadForSaveOrUpdateQuotation = (
  store: IReducerFinancialCalculation,
  flattenObj = false,
  isEditMode = false
) => {
  const quote_identifiers = {
    email: "test@test.com",
    customer_type: store.quotationDetails.clientType,
  };

  let quoteDetails = {
    name: store.quotationDetails.name,
    start_date: dayjs(store.RequestObj.StartDate).format("YYYY-MM-DD"),
    selling_price: store.RequestObj.AssetCost,
    down_payment: store.RequestObj.Deposit,
    no_of_advance_payments: store.RequestObj.AdvancePayments,
    no_of_regular_payments: store.RequestObj.RegularPayments,
    introducer_name: store.quotationDetails.brokerName,
    rental_frequency: store.RequestObj.Frequency,
    deferred_type: store.RequestObj.VAT.type,
    deferral_amount: store.RequestObj.VAT.Amount,
    rv_balloon_value: store.RequestObj.ResidualValue,
    balloon_collection: BalloonCollectionsArrear.find(
      (collection) => collection.value === store.RequestObj.BalloonCollection
    ).text,
    finance_amount: store.CalculatedNFA,
    gross_profit: store.GrossProfit,
    finance_type: store.RequestObj.FinanceType,
    commission_type: store.RequestObj.CommissionType,
    commission_amount:
      store.RequestObj.CommissionType === "%"
        ? store.RequestObj.CommissionPercentage
        : store.CalculatedCommission,
    due_at_signing: store.CalculatedUpfrontPayment,
    comments: store.CustomerDetail.Comments,
    vat_treatment: store.RequestObj.VAT.Treatment,
    reference_number: store.CustomerDetail.TrackingID,
    margin: store.RequestObj.Rate,
    rate_type: store.RequestObj.RateType,
    rental_mode: store.RequestObj.PaymentMode,
    meta_data: {
      gross_yield: store.ResponseObj.rates.grossYield,
      net_yield: store.ResponseObj.rates.netYield,
      apr: store.ResponseObj.rates.aprExclCommission,
      flat_rate_commission_inc: store.ResponseObj.rates.flatRateInclCommission,
      flat_rate_commission_exc: store.ResponseObj.rates.flatRateExclCommission,
    },
    order_fees: store.RequestObj.Fee.map((fee) => {
      const feePayload = {
        dealer_fee_name: fee.Name,
        applied_price: fee.Amount,
      };
      if (isEditMode)
        return {
          ...feePayload,
          identifier: fee.Identifier,
        };
      return feePayload;
    }),
    order_payments: store.ResponseObj.rentals?.map((rental) => {
      const paymentPayload = {
        payment_number_from: rental.startTerm,
        payment_number_to: rental.endTerm,
        amount: rental.rentalAmount,
        payment_type: rental.rentalType,
      };
      if (isEditMode) {
        return { ...paymentPayload, identifier: rental.identifier };
      }
      return paymentPayload;
    }),
    assets: [{ asset_type: store.quotationDetails.asset.type }].map((asset) => {
      if (isEditMode) {
        return {
          ...asset,
          identifier: store.quotationDetails.asset.identifier,
        };
      }
      return asset;
    }),
  };

  let rateFields: any = {
    tax_amount: store.RequestObj.VAT.Amount,
  };

  if (store.RequestObj.VAT.Treatment === VatTreatment.VATDeferral) {
    rateFields = {
      ...rateFields,
      deferred_type: store.RequestObj.VAT.type,
      tax_amount_number: store.RequestObj.VAT.PaymentNumber,
    };
  }

  quoteDetails = { ...quoteDetails, ...rateFields };

  let requestBody: any = {};

  if (flattenObj) {
    requestBody = {
      ...quoteDetails,
      customer: quote_identifiers,
    };
  } else {
    requestBody = {
      quote_details: quoteDetails,
      quote_identifiers,
    };
  }

  return requestBody;
};

export const SaveConfigurationDms = async (
  store: IReducerFinancialCalculation
) => {
  const requestBody = getPayloadForSaveOrUpdateQuotation(store);
  var response;
  await post(`${baseUrl}${"order/quick_quotation"}`, requestBody)
    .then((res: any) => {
      response = res;
    })
    .catch((ex: AxiosError) => {
      throw ex;
    });
  return response;
};

export const GetConfigurationDms = async (identifier: string) => {
  var response;
  await get(
    `${baseUrl}${"configure/quick-quotation-by-identifier/"}${identifier}`
  )
    .then((res: any) => {
      response = res;
    })
    .catch((ex: AxiosError) => {
      throw ex;
    });
  return response;
};

export const GetAllConfigurationDms = async (
  CustomerDetail: CustomerDetail
) => {
  var response;
  var _queryParams: any = [];
  if (
    CustomerDetail.TrackingID != "" &&
    CustomerDetail.TrackingID != undefined
  ) {
    _queryParams.push({
      Key: "reference_number",
      Value: CustomerDetail.TrackingID,
    });
  }
  if (
    CustomerDetail.CustomerEmailAddress != "" &&
    CustomerDetail.CustomerEmailAddress != undefined
  ) {
    _queryParams.push({
      Key: "customer_email",
      Value: CustomerDetail.CustomerEmailAddress,
    });
  }
  if (
    CustomerDetail.CustomerFullName != "" &&
    CustomerDetail.CustomerFullName != undefined
  ) {
    _queryParams.push({
      Key: "customer_name",
      Value: CustomerDetail.CustomerFullName,
    });
  }
  if (
    CustomerDetail.CustomerContactNumber != "" &&
    CustomerDetail.CustomerContactNumber != undefined
  ) {
    _queryParams.push({
      Key: "contact_number",
      Value: CustomerDetail.CustomerContactNumber,
    });
  }
  if (
    CustomerDetail.VendorEmailAddress != "" &&
    CustomerDetail.VendorEmailAddress != undefined
  ) {
    _queryParams.push({
      Key: "vendor_email",
      Value: CustomerDetail.VendorEmailAddress,
    });
  }
  if (
    CustomerDetail.VendorPersonName != "" &&
    CustomerDetail.VendorPersonName != undefined
  ) {
    _queryParams.push({
      Key: "vendor_name",
      Value: CustomerDetail.VendorPersonName,
    });
  }
  await get(`${baseUrl}${"configure/quick-quotation-by-vendor"}`, _queryParams)
    .then((res: any) => {
      response = res;
    })
    .catch((ex: AxiosError) => {
      throw ex;
    });
  return response;
};

export const getQuotationByIdentifier = async (identifier: string) => {
  return dmsAPI
    .get(GET_QUOTATION_BY_IDENTIFIER.replace(":identifier", identifier))
    .then((response) => {
      const data = response.data;
      return data;
    });
};

export const getQuotationsByVendor = async (
  searchParams: { [key: string]: any },
  sortParams: { [key: string]: any }
) => {
  const queryParamsStr = getQueryParamsString({
    ...searchParams,
    ...sortParams,
  });

  let apiEndpoint = GET_QUOTATION_BY_VENDOR;
  if (!!queryParamsStr) {
    apiEndpoint += queryParamsStr;
  }
  return dmsAPI.get(apiEndpoint).then((response) => response.data);
};

export const getProposalByReferenceNumber = async (referenceNumber: string) => {
  return dmsAPI
    .get(
      GET_PROPOSAL_BY_REFERENCE_NUMBER.replace(
        ":referenceNumber",
        referenceNumber
      )
    )
    .then((response) => {
      const data = response.data;
      return data;
    });
};

export const getOrderStatusHistory = async (
  payload: TOrderStatusHistoryReq
) => {
  return dmsAPI
    .post(GET_ORDER_STATUS_HISTORY, payload)
    .then((response) => response.data);
};
export const convertQuotationToProposal = async (identifier: string) => {
  return dmsAPI.patch(
    CONVERT_QUOTATION_TO_PROPOSAL.replace(":identifier", identifier),
    { identifier: identifier }
  );
};

export const updateQuotation = async (
  store: IReducerFinancialCalculation,
  identifier: string
) => {
  const queryParamsStr = getQueryParamsString({
    identifier,
  });
  const requestBody = getPayloadForSaveOrUpdateQuotation(store, true, true);

  return dmsAPI.patch(UPDATE_QUOTATION + queryParamsStr, {
    ...requestBody,
    identifier,
  });
};

export const saveProposal = async (payload: TProposalPayloadReq) => {
  return dmsAPI
    .post(SAVE_UPDATE_PROPOSAL, payload)
    .then((response) => response.data);
};

export const updateProposal = async (payload: TProposalPayloadReq) => {
  return dmsAPI
    .patch(`${SAVE_UPDATE_PROPOSAL}?identifier=${payload.identifier}`, payload)
    .then((response) => response.data);
};

export const saveQuotation = async (payload: any) => {
  return dmsAPI.post(`${baseUrl}${"order/quick_quotation"}`, payload);
};

export const updateQuotationTwo = async (payload: any) => {
  const queryParamsStr = getQueryParamsString({
    identifier: payload.identifier,
  });

  return dmsAPI.patch(UPDATE_QUOTATION + queryParamsStr, payload);
};

export const getProposalForDashboard = async (
  searchParams: { [key: string]: any },
  sortParams: { [key: string]: any }
) => {
  const queryParamsStr = getQueryParamsString({
    ...searchParams,
    ...sortParams,
  });

  let apiEndpoint = DASHBOARD_ANALYTICS;
  if (!!queryParamsStr) {
    apiEndpoint += queryParamsStr;
  }
  return dmsAPI.get(apiEndpoint).then((response) => response.data);
};

export const changeProposalStatus = async (
  requestBody: ChangeProposalStatusReq
) => {
  return dmsAPI.patch(CHANGE_PROPOSAL_STATUS, requestBody);
};
