import { Grid, Typography } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { CustomerDetailRowProps } from "./customer-detail-row.props";

const CustomerDetailRow = ({ label, value }: CustomerDetailRowProps) => {
  const theme = useTheme();
  return (
    <>
      <Grid theme={theme} item lg={3} md={4} sm={6} xs={6}>
        <Typography
          theme={theme}
          component="span"
          variant="caption"
          className="label"
          children={label}
        />
      </Grid>
      <Grid theme={theme} item lg={9} md={8} sm={6} xs={6}>
        <Typography
          theme={theme}
          component="b"
          variant="caption"
          className="value"
          children={value ? value : ""}
        />
      </Grid>
    </>
  );
};

export default CustomerDetailRow;
