import { useTheme } from "@mui/material";
import {
  Box,
  Button,
  CircleProgress,
  Icon,
  LinearProgressBar,
  Typography,
} from "@ntpkunity/controls";
import clsx from "clsx";
import { StepperProgressWrap } from "@components/stepper-progress/stepper-progess.style";
import { StepperProgressProps } from "@components/stepper-progress/stepper-progess.props";

export const StepperProgress = ({
  activeIndex,
  setActiveIndex,
  onPrevButtonClick,
  stepItems,
  children,
  actionArea,
  stepTitle,
  nextStepTitle,
  hideStepItems = false,
}: StepperProgressProps) => {
  if (stepItems.length !== children.length) {
    throw new Error("Lengths of stepItems prop and children should be equal");
  }

  const theme = useTheme();
  const stepProgressPercentage = ((activeIndex + 1) / stepItems.length) * 100;
  const isFirstStep = activeIndex === 0;

  return (
    <>
      <StepperProgressWrap theme={theme} className="stepper-progress-wrap">
        {!hideStepItems && (
          <Box theme={theme} className="desktop-steps">
            <ul>
              {stepItems.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={clsx({
                      "step-item": true,
                      active: activeIndex === index,
                      disabled: item.disabled,
                    })}
                    onClick={() => {
                      if (item.disabled) return;
                      setActiveIndex(index);
                    }}
                  >
                    <div className="content">
                      <Typography
                        theme={theme}
                        variant="body2"
                        component={"p"}
                        display={"block"}
                      >
                        {index + 1}.
                      </Typography>
                      <Typography
                        theme={theme}
                        variant="body2"
                        component={"p"}
                        display={"block"}
                      >
                        {item.title}
                      </Typography>
                    </div>
                  </li>
                );
              })}
            </ul>
            <LinearProgressBar theme={theme} value={stepProgressPercentage} />
          </Box>
        )}

        <Box theme={theme} className="mobile-steps">
          {!isFirstStep && (
            <Button
              defaultBtn
              theme={theme}
              onClick={onPrevButtonClick}
              iconText={<Icon name="IconLeftArrow" />}
            />
          )}
          <Box theme={theme} className="steps-content">
            <Box theme={theme} className="counter">
              <CircleProgress
                value={stepProgressPercentage}
                theme={theme}
                thickness={2}
              />
              <Typography
                theme={theme}
                variant="caption"
                component={"small"}
                display={"block"}
                className="text-muted"
              >
                {activeIndex + 1}/{stepItems.length}
              </Typography>
            </Box>
            <Box
              theme={theme}
              className={clsx({
                title: stepTitle,
                "title-with-subtitle": nextStepTitle,
              })}
            >
              {stepTitle && (
                <Typography
                  theme={theme}
                  variant="body2"
                  component={"span"}
                  display={"block"}
                  className="fw-sbold"
                >
                  {stepTitle}
                </Typography>
              )}
              {nextStepTitle && (
                <Typography
                  theme={theme}
                  className="text-muted"
                  variant="caption"
                  component={"small"}
                  display={"block"}
                >
                  {nextStepTitle}
                </Typography>
              )}
            </Box>
          </Box>
          {actionArea && (
            <Box theme={theme} className="action-area">
              {actionArea}
            </Box>
          )}
        </Box>
      </StepperProgressWrap>
      <Box theme={theme} className="page-content has-footer" mt={2}>
        {children[activeIndex]}
      </Box>
    </>
  );
};
