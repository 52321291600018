import { styled } from "@mui/material";

export const Scroll = styled("div")(({ theme }) => ({
  overflowY: "auto",
  overflowX: "hidden",
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: 9,
    height: 9,
  },
  "&::-webkit-scrollbar-track": {
    padding: 0,
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    transition: "all 0.3s",
    backgroundColor: "rgba(0, 0, 0, 0.16)",
    borderRadius: 4,
    border: "3px solid transparent",
    backgroundClip: "content-box",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.16)",
  },
  "&:hover": {
    overflowY: "overlay",
    "@-moz-document url-prefix()": {
      overflowY: "auto",
    },
  },
  "&.h-xs": {
    transition: "1s",
    height: "calc(50vh - 72px)",
    "&.anim": {
      height: "calc(0vh - 72px)",
    },
  },
  // "&.h-xs": {
  //   height: "calc(50vh - 112px)",
  // },
  "&.h-sm": {
    height: "calc(50vh - 82px)",
  },
  // "&.h-md": {
  //   height: "calc(50vh - 114px)",
  // },
  "&.h-md": {
    transition: "0.8s",
    height: "calc(50vh - 67px)",
    "&.toggle": {
      height: "calc(100vh - 124px)",
    },
  },
  "&.h-lg": {
    height: "calc(100vh - 193px)",
  },
  "&.h-lg-b": {
    height: "calc(100vh - 223px)",
    "&.max": {
      height: "calc(100vh - 188px)",
    },
  },
  "&.h-xl-b": {
    height: "calc(100vh - 176px)",
  },
  "&.h-xl": {
    height: "calc(100vh - 110px)",
  },
  "&.h-xl-c": {
    height: "calc(100vh - 135px)",
  },
  ".scroll-container": {
    padding: "8px 24px",
  },
  [theme.breakpoints.down("md")]: {
    "&.h-xs, &.h-sm, &.h-md, &.h-lg, &.h-lg-b, &.h-xl-b, &.h-xl-c": {
      height: "auto",
      "&.toggle": {
        height: "auto",
      },
      "&.max": {
        height: "auto",
      },
    },
  },

  "@media screen and (min-width: 1280px) and (max-width: 1399px)": {
    "&.h-lg-b": {
      height: "calc(100vh - 289px)",
    },
    "&.h-xl-b": {
      height: "calc(100vh - 203px)",
    },
  },
  "@media screen and (min-width: 960px) and (max-width: 1280px)": {
    "&.h-lg-b": {
      height: "calc(100vh - 289px)",
    },
    "&.h-xl-b": {
      height: "calc(100vh - 208px)",
    },
    "@-moz-document url-prefix()": {
      "&.h-xl-b": {
        height: "calc(100vh - 203px)",
      },
    },
  },
}));
