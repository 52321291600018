import { useMemo } from "react";
import { TProposalForm } from "@_types";
import { useFormContext } from "react-hook-form";

const useSumOfAssetCost = () => {
  const { watch } = useFormContext<TProposalForm>();
  const assets = watch("assets");

  const sumAssetCost = () => {
    return assets.reduce((total, asset) => total + Number(asset.totalCost), 0);
  };

  const sumOfAssetCost = useMemo(() => sumAssetCost(), [assets]);

  return sumOfAssetCost;
};

export default useSumOfAssetCost;
