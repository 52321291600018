import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import React from "react";

type INotificationSnackbar = {
  message: string;
  show: boolean;
  onClose(): void;
};
export const NotificationSnackbar = ({
  message,
  show,
  onClose,
}: INotificationSnackbar) => {
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={onClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div>
      <Snackbar
        open={show}
        onClose={onClose}
        message={message}
        action={action}
      />
    </div>
  );
};
