import React, { useEffect, useState } from "react";
import { IconButton, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Icon,
  type ISideBarLayoutProps,
  Grid,
  Typography,
  Avatar,
  Box,
  List,
  Switch as CustomizedSwitches,
  Menu,
  Input,
} from "@ntpkunity/controls";

import {
  CustomizedContainer,
  openedMixin,
  closedMixin,
  CustomizedDrawer,
  LayoutWrap,
} from "@components/layout/LayoutWithSideNav/LayoutWithSideNav.style";
import AppLogo from "@assets/images/unity-logo.svg";
import LogoIcon from "@assets/images/logo-hsbc-icon.svg";
import Logo from "@assets/images/logo-hsbc-full.svg";
import { APP_ROUTES } from "@helpers";
import store from "@store";
import { resetFinancialCalculation } from "@store/actions/financialCalculation.actions";
import { ApplicationMode, WorkQueueTabs } from "@helpers/enum";
import { LOCAL_STORAGE_KEYS } from "@helpers/const";

export const LayoutWithSideNavComponent: React.FC<ISideBarLayoutProps> = ({
  children,
  theme,
}) => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleParentOptionClick = () => setOpen(true);

  const mode = localStorage.getItem(LOCAL_STORAGE_KEYS.MODE);
  const isDealer = mode === ApplicationMode.DEALER;
  const sideMenuList = [
    {
      icon: <Icon name="DashboardIcon" />,
      label: "Dashboard",
      expanded: true,
      onClick: handleParentOptionClick,
      selected: false,
      subOptions: [
        {
          label: "Introducer Dashboard",
          onClick: () => navigate(APP_ROUTES.ANALYTICS),
        },
        ...(!isDealer
          ? [
              {
                label: "Lender Dashboard",
                onClick: () => navigate(APP_ROUTES.LENDER_DASHBOARD),
              },
            ]
          : []),
      ],
    },
    {
      icon: <Icon name="PaperEditIcon" />,
      label: "Proposals & Quotations",
      onClick: handleParentOptionClick,
      selected: false,
      expanded: true,
      subOptions: [
        {
          label: "Create New Quotation",
          onClick: () => {
            store.dispatch(resetFinancialCalculation());
            navigate(APP_ROUTES.QUOTATION);
          },
        },
        {
          label: "Create New Proposal",
          onClick: () => navigate(APP_ROUTES.PROPOSAL),
        },
        {
          label: "Workqueue",
          onClick: () =>
            navigate(
              `${APP_ROUTES.WORKQUEUE}/${WorkQueueTabs.TRACK_PROPOSALS}`
            ),
        },
      ],
    },
    ...(!isDealer
      ? [
          {
            icon: <Icon name="PaperUserIcon" />,
            label: "Admin Options",
            onClick: handleParentOptionClick,
            selected: false,
            expanded: true,
            subOptions: [
              {
                label: "Configurations",
                onClick: () => navigate(APP_ROUTES.CONFIGURATIONS),
              },
              {
                label: "Setups",
                onClick: () => navigate(APP_ROUTES.SETUPS),
              },
            ],
          },
        ]
      : []),
  ];

  const MenuComp = (): JSX.Element => {
    return (
      <Menu
        theme={theme}
        disablePortal={true}
        handleOptionClick={() => {}}
        options={[{ optionText: "Logout", optionkey: "Logout" }]}
        render={(cb) => (
          <>
            <a
              className="header-link-item user-link"
              href="javascript:void(0)"
              onClick={cb}
            >
              <Box theme={theme} className="user-item">
                <Avatar theme={theme}>AJ</Avatar>
                <Box theme={theme} className="user-info">
                  <Typography
                    theme={theme}
                    className="fw-sbold text-dark"
                    variant="caption"
                    component={"small"}
                    display={"block"}
                  >
                    Asad Javaid
                  </Typography>
                  <Typography
                    theme={theme}
                    className="text-muted text-tiny"
                    variant="caption"
                    component={"small"}
                    display={"block"}
                  >
                    asad.javaid@sample.com
                  </Typography>
                </Box>
              </Box>

              {/* <Icon name="PersonIcon" /> */}
            </a>
          </>
        )}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
    );
  };

  return (
    <LayoutWrap theme={theme} component="div">
      <Box theme={theme} component="aside" className="u-aside-wrap">
        <CustomizedDrawer
          theme={theme}
          variant="permanent"
          open={open}
          className="u-side-nav"
        >
          <Box theme={theme} className="menu-top-wrap">
            <Box
              className="menu-action"
              theme={theme}
              display={{ xs: "block", md: "none" }}
            >
              <IconButton
                onClick={() => setOpen(!open)}
                className="menu-toggle"
              >
                <Box theme={theme} className="menu-icon">
                  <Box theme={theme} className="toggle-bar"></Box>
                </Box>
              </IconButton>
            </Box>
            <div className="logo-wrap">
              <div className="logo-area">
                <img src={LogoIcon} alt="" className="logo-icon" />
                <img src={Logo} alt="" className="full-logo" />
              </div>
              <Divider />
            </div>
            <Box
              className="header"
              theme={theme}
              display={{ xs: "flex", md: "none" }}
              gap={2}
            >
              <a
                className="header-link-item item-icon-only link-search"
                href="javascript;"
              >
                <Icon name="SearchIcon" />
              </a>
              <a
                className="header-link-item item-icon-only link-notification"
                href="javascript;"
              >
                <Icon name="BellIcon" />
                <span className="notify-icon"></span>
              </a>
            </Box>
          </Box>
          <div className="side-nav-area-wrap">
            <div className="aside-content-wrap content-top">
              <List theme={theme} options={sideMenuList} />
            </div>
            <div className="aside-content-wrap">
              <Divider />
              <Typography
                theme={theme}
                variant="caption"
                component="small"
                className="nav-group-title"
              >
                Support
              </Typography>
              <List
                theme={theme}
                options={[
                  {
                    icon: <Icon name="DocumentIcon" />,
                    label: "Documentation",
                    hideLabel: false,
                    hideIcon: false,
                    onClick: () => {},
                    listItemButtonSx: undefined,
                    listItemIconSx: undefined,
                    listItemTextSx: undefined,
                  },
                  {
                    icon: <Icon name="FeedbackChatBubbleIcon" />,
                    label: "Submit Feedback",
                    hideLabel: false,
                    hideIcon: false,
                    onClick: () => {},
                    listItemButtonSx: undefined,
                    listItemIconSx: undefined,
                    listItemTextSx: undefined,
                  },
                ]}
              />
            </div>
          </div>
          <div className="aside-content-wrap action-content">
            <Divider />
            <div className="side-nav-actions">
              <IconButton
                className="toggle-nav-btn"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <Icon name="ChevronRight" />
              </IconButton>
              <Box
                className="user-account"
                theme={theme}
                display={{ xs: "flex", md: "none" }}
              >
                <MenuComp />
                <div className="header-link-item item-theme-switch">
                  <CustomizedSwitches
                    onChange={(e) => {}}
                    theme={theme}
                  ></CustomizedSwitches>
                </div>
              </Box>
            </div>
          </div>
        </CustomizedDrawer>
      </Box>
      <CustomizedContainer theme={theme} open={open} component="main">
        <Box
          theme={theme}
          component="header"
          display={{ xs: "none", md: "flex" }}
        >
          <Grid theme={theme} direction="row" container>
            <Grid theme={theme} item xs={6} md={6}>
              <Box theme={theme} className="header-search">
                <Input
                  theme={theme}
                  placeholder="What are you looking for?"
                  startAdornment={<Icon name="SearchIcon" />}
                  type={"text"}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid
              theme={theme}
              item
              display="flex"
              justifyContent="right"
              alignItems={"center"}
              gap={"24px"}
              xs={6}
              md={6}
              className="header-nav-right"
            >
              <div className="header-link-item item-theme-switch">
                <CustomizedSwitches
                  onChange={(e) => {}}
                  theme={theme}
                ></CustomizedSwitches>
              </div>
              <a
                className="header-link-item item-icon-only link-notification"
                href="javascript;"
              >
                <Icon name="BellIcon" />
                <span className="notify-icon"></span>
              </a>
              <MenuComp />
            </Grid>
            <Divider />
          </Grid>
        </Box>
        <Box theme={theme} className="content-container">
          {children}
        </Box>
        {/* <Box component="footer">footer</Box> */}
      </CustomizedContainer>
    </LayoutWrap>
  );
};

export default LayoutWithSideNavComponent;
