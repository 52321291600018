import { useQuery, UseQueryResult } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { getQuotationsByVendor } from "@services/dms.service";
import { TSortParams } from "@_types";

const useGetOrders = <
  SearchParams extends { [key: string]: any },
  ReturnType extends { [key: string]: any }
>(
  searchParams: SearchParams,
  sortParams: TSortParams<keyof SearchParams>
): UseQueryResult<ReturnType[]> => {
  return useQuery<ReturnType[]>({
    queryKey: [QUERY_KEYS.QUOTATION_BY_VENDOR, searchParams, sortParams],
    queryFn: () => getQuotationsByVendor(searchParams, sortParams),
  });
};

export default useGetOrders;
