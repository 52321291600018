import { BalloonCollectionsArrear, exportPdf } from "@flexCommon/constants";
import {
  FeeName,
  FinanceType,
  HaydockTemplateName,
  VatTreatment,
} from "@flexCommon/enums";
import { getReport } from "@flexCommon/reportingHelper";
import { IReducerFinancialCalculation } from "@store/reducers/financialCalculation.reducer";
import dayjs from "dayjs";

export const GenerateHaydockTemplate = async (
  storeState: IReducerFinancialCalculation
) => {
  var templatetype;
  if (storeState.RequestObj.FinanceType == FinanceType.FinanceLease) {
    templatetype = "finance_lease";
  } else if (storeState.RequestObj.FinanceType == FinanceType.HirePurchase) {
    templatetype = "hire_purchase";
  } else {
    templatetype = "loan";
  }
  var terms = Number(storeState.RequestObj.RegularPayments);
  if (storeState.RequestObj.BalloonPayment > 0) {
    terms = terms + 1;
  }
  var PeriodicSum = 0;
  storeState.ResponseObj.repaymentPlan.forEach((x) => {
    PeriodicSum += x.periodicInterest;
  });
  var requestObject: any = {
    template_type: templatetype,
    generate_base64: false,
    download_template: true,
    data_placeholders: {},
  };
  var cost_with_Vat =
    Number(storeState.RequestObj.AssetCost) +
    Number(storeState.RequestObj.VAT.Amount);
  requestObject.data_placeholders = {
    date: DateHelper(storeState.RequestObj.StartDate),
    finance_quotation: "",
    term: terms,
    advance_payment:
      storeState.ResponseObj.rentals.length > 0 &&
      storeState.RequestObj.AdvancePayments > 0
        ? storeState.ResponseObj.rentals[0].rentalAmount.toLocaleString(
            undefined,
            { minimumFractionDigits: 2 }
          )
        : 0,
    customer_name: storeState.CustomerDetail.CustomerFullName,
    aprRate: Number(
      storeState.ResponseObj.rates.aprExclCommission
    ).toLocaleString(undefined, { minimumFractionDigits: 2 }),
    financeAmount: storeState.CalculatedNFA.toLocaleString(undefined, {
      minimumFractionDigits: 2,
    }),
    goods: storeState.CustomerDetail.Comments,
    total_asset_cost: Number(storeState.RequestObj.AssetCost).toLocaleString(
      undefined,
      { minimumFractionDigits: 2 }
    ),
    vat: Number(storeState.RequestObj.VAT.Amount).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    }),
    cost_inc_vat:
      Number(storeState.RequestObj.AssetCost) +
      Number(storeState.RequestObj.VAT.Amount),
    deposit: Number(storeState.RequestObj.Deposit).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    }),
    vat_payable: storeState.RequestObj.VAT.Treatment,
    def_month:
      storeState.RequestObj.VAT.Treatment == VatTreatment.VATDeferral
        ? storeState.RequestObj.VAT.PaymentNumber
        : 0,
    net_finance_amnt: storeState.CalculatedNFA.toLocaleString(undefined, {
      minimumFractionDigits: 2,
    }),
    monthly_payment:
      Number(storeState.RequestObj.RegularPayments) +
      Number(storeState.RequestObj.AdvancePayments),
    amount_1:
      storeState.ResponseObj.rentals.length > 0
        ? storeState.ResponseObj.rentals[0].rentalAmount.toLocaleString(
            undefined,
            { minimumFractionDigits: 2 }
          )
        : 0,
    amount_2: 0,
    amount_3: 0,
    total_charge_credit: PeriodicSum,
    total_amount_payable: PeriodicSum,
    interest: Number(PeriodicSum).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    }),
    total_deposit: Number(storeState.CalculatedUpfrontPayment).toLocaleString(
      undefined,
      { minimumFractionDigits: 2 }
    ),
    no_of_advance_payment: storeState.RequestObj.AdvancePayments,
    no_of_regular_payment: storeState.RequestObj.RegularPayments,
    balloon: Number(storeState.RequestObj.BalloonPayment).toLocaleString(
      undefined,
      { minimumFractionDigits: 2 }
    ),
    balloon_collection:
      storeState.RequestObj.BalloonPayment == 0
        ? "Not Applicable"
        : BalloonCollectionsArrear.find(
            (x) => x.value == storeState.RequestObj.BalloonCollection
          )?.text,
  };
  storeState.RequestObj.Fee.map((r) => {
    if (r.Name == FeeName.DocFee) {
      requestObject.data_placeholders.amount_2 = r.Amount;
      requestObject.data_placeholders.total_charge_credit += r.Amount;
    } else if (r.Name == FeeName.OTPFee) {
      requestObject.data_placeholders.amount_3 = r.Amount;
      requestObject.data_placeholders.total_charge_credit += r.Amount;
    }
  });

  requestObject.data_placeholders.total_charge_credit = Number(
    requestObject.data_placeholders.total_charge_credit
  ).toLocaleString(undefined, { minimumFractionDigits: 2 });

  requestObject.data_placeholders.total_amount_payable += Number(
    storeState.CalculatedNFA
  );

  requestObject.data_placeholders.total_amount_payable += Number(
    requestObject.data_placeholders.amount_2
  );

  requestObject.data_placeholders.total_amount_payable += Number(
    requestObject.data_placeholders.amount_3
  );

  requestObject.data_placeholders.total_amount_payable = Number(
    requestObject.data_placeholders.total_amount_payable
  ).toLocaleString(undefined, { minimumFractionDigits: 2 });

  requestObject.data_placeholders.amount_2 = Number(
    requestObject.data_placeholders.amount_2
  ).toLocaleString(undefined, { minimumFractionDigits: 2 });
  requestObject.data_placeholders.amount_3 = Number(
    requestObject.data_placeholders.amount_3
  ).toLocaleString(undefined, { minimumFractionDigits: 2 });

  await getReport(exportPdf, requestObject, "Quotation Detail", true, true)
    .then(() => {})
    .catch((ex: any) => {
      throw ex;
    });
};

function getMonthName(monthNumber: number) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return monthNames[monthNumber];
}

export const DateHelper = (pDate: string | number | Date) => {
  let date = new Date(pDate);
  let DD = null;
  if (date.getDate() < 10) {
    DD = `0${date.getDate()}`;
  } else {
    DD = date.getDate();
  }
  return `${DD} ${getMonthName(date.getMonth())} ${date.getFullYear()}`;
};
