import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PreviousCommentsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.previous-comments-wrap": {
    ".accordion-container": {
      ".title-wrap": {
        display: "flex",
        alignItems: "center",
        gap: 16,
        ".list-content": {
          ".MuiTypography-root": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            [theme.breakpoints.down("md")]: {
              maxWidth: 160,
            },
          },
        },
        ".seperator": {
          color: theme.palette.grey[50],
        },
      },
      ".u-accordian-wrap": {
        marginBottom: 16,
        backgroundColor: "#F7F7F7",
        padding: "16px 24px",
        borderRadius: theme.shape.borderRadius,
        "&:last-child": {
          marginBottom: 0,
        },
        ".u-accordion-header": {
          padding: 0,
          minHeight: 24,
          ".MuiAccordionSummary-content": {
            margin: 0,
            ".main-title": {
              position: "relative",
            },
            ".sub-title": {
              backgroundColor: theme.palette.common.white,
              padding: 8,
              maxWidth: "100%",
              borderRadius: theme.shape.borderRadius,
              color: theme.palette.grey[600],
              fontSize: theme.typography.caption.fontSize,
              lineHeight: theme.typography.caption.lineHeight,
              overflow: "visible",
              textOverflow: "none",
              whiteSpace: "normal",
            },
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            marginLeft: 16,
          },
        },
        ".u-accordian-body": {
          borderBottom: "none",
          padding: "16px 0 0 0",
        },
        "&.Mui-expanded": {
          backgroundColor: "transparent",
          border: "1px solid" + theme.palette.divider,
        },
      },
    },
  },
  "&.previous-comments-popup-wrap": {
    ".custom-dropdown": {
      display: "flex",
      alignItems: "center",
      gap: 4,
      ".u-form-group": {
        height: 20,
        ".u-form-control": {
          "&.u-select": {
            height: 20 + "px" + "!important",
            color: theme.palette.grey[900],
            fontWeight: theme.customVariables.fontWeightSemiBold,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            ".MuiInputBase-input": {
              padding: "0px 28px 0px 0px",
            },
            ".MuiSelect-icon": {
              right: 0,
              path: {
                stroke: theme.palette.grey[900],
              },
            },
            fieldset: {
              border: "none",
            },
          },
        },
      },
    },
  },
}));
