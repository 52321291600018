import { useProposalFieldArrays } from "@contexts/proposal-form";
import { FormProvider, useForm } from "react-hook-form";
import { AssetCategories, AssetTypes } from "@helpers/enum";
import { AssetDetailsForm } from "@components/proposal/asset-details";
import { TAssetDetails } from "@_types";
import { ASSET_SUB_TYPES } from "@helpers/const";

const AddAssetForm = () => {
  const formMethods = useForm<TAssetDetails>({
    defaultValues: {
      type: AssetTypes.VEHICLE,
      category: AssetCategories.HARD_ASSET,
      subType: ASSET_SUB_TYPES[AssetTypes.VEHICLE][0],
      totalCost: 0,
      quantity: 1,
    },
  });

  const {
    assetMethods: { append },
  } = useProposalFieldArrays();

  const { watch } = formMethods;

  const totalCost = watch("totalCost");

  const handleSaveAsset = () => {
    append({
      ...formMethods.getValues(),
      totalCost: totalCost,
    });
    formMethods.reset();
  };

  return (
    <FormProvider {...formMethods}>
      <AssetDetailsForm handleSaveAsset={handleSaveAsset} />
    </FormProvider>
  );
};

export default AddAssetForm;
