import { useTheme } from "@mui/material";
import { FinancialConfigFeeWrap } from "./fee.style";
import {
  Box,
  Button,
  Grid,
  Icon,
  Input,
  Select,
  Typography,
} from "@ntpkunity/controls";

export const FinancialConfigurationFee = () => {
  const theme = useTheme();
  return (
    <FinancialConfigFeeWrap theme={theme} className="financial-config-fee-wrap">
      <Typography theme={theme} component={"h4"} variant="h4">
        Fees
      </Typography>
      <Box theme={theme} className="fee-item" mt={3}>
        <Grid theme={theme} container item rowSpacing={3} columnSpacing={2}>
          <Grid theme={theme} item xs={12} md={2.5}>
            <Input
              theme={theme}
              fullWidth
              label="Fee name"
              placeholder="Type here..."
              type="text"
            />
          </Grid>
          <Grid theme={theme} item xs={12} md={1.75}>
            <Select
              theme={theme}
              fullWidth
              label="Fee Type"
              items={[
                {
                  text: "On Time",
                  value: 0,
                },
              ]}
              value={0}
            />
          </Grid>
          <Grid theme={theme} item xs={12} md={2.5}>
            <Select
              theme={theme}
              fullWidth
              label="Fee Frequency"
              items={[
                {
                  text: "On a Particular Date",
                  value: 0,
                },
              ]}
              value={0}
            />
          </Grid>
          <Grid theme={theme} item xs={6} md={1.75}>
            <Select
              theme={theme}
              fullWidth
              label="Due At Start Date"
              items={[
                {
                  text: "Yes",
                  value: 0,
                },
              ]}
              value={0}
            />
          </Grid>
          <Grid theme={theme} item xs={6} md={1.75}>
            <Input
              theme={theme}
              startAdornment={"$"}
              fullWidth
              label="Fee Amount"
              placeholder="Type here..."
              type="text"
            />
          </Grid>
          <Grid theme={theme} item xs={12} md={1.75}>
            <Box
              theme={theme}
              display={"flex"}
              alignItems={"center"}
              gap={2}
              className="field-box"
            >
              <Select
                theme={theme}
                fullWidth
                label="Editable"
                items={[
                  {
                    text: "Yes",
                    value: 0,
                  },
                ]}
                value={0}
              />
              <Box theme={theme} className="fee-action">
                <Button
                  theme={theme}
                  secondary
                  iconText={<Icon name="DeleteIcon" />}
                  onClick={() => {}}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box theme={theme} className="fee-item" mt={3}>
        <Grid theme={theme} container item rowSpacing={3} columnSpacing={2}>
          <Grid theme={theme} item xs={12} md={2.5}>
            <Input
              theme={theme}
              fullWidth
              label="Fee name"
              placeholder="Type here..."
              type="text"
            />
          </Grid>
          <Grid theme={theme} item xs={12} md={1.75}>
            <Select
              theme={theme}
              fullWidth
              label="Fee Type"
              items={[
                {
                  text: "On Time",
                  value: 0,
                },
              ]}
              value={0}
            />
          </Grid>
          <Grid theme={theme} item xs={12} md={2.5}>
            <Select
              theme={theme}
              fullWidth
              label="Fee Frequency"
              items={[
                {
                  text: "On a Particular Date",
                  value: 0,
                },
              ]}
              value={0}
            />
          </Grid>
          <Grid theme={theme} item xs={6} md={1.75}>
            <Select
              theme={theme}
              fullWidth
              label="Due At Start Date"
              items={[
                {
                  text: "Yes",
                  value: 0,
                },
              ]}
              value={0}
            />
          </Grid>
          <Grid theme={theme} item xs={6} md={1.75}>
            <Input
              theme={theme}
              startAdornment={"$"}
              fullWidth
              label="Fee Amount"
              placeholder="Type here..."
              type="text"
            />
          </Grid>
          <Grid theme={theme} item xs={12} md={1.75}>
            <Box
              theme={theme}
              display={"flex"}
              alignItems={"center"}
              gap={2}
              className="field-box"
            >
              <Select
                theme={theme}
                fullWidth
                label="Editable"
                items={[
                  {
                    text: "Yes",
                    value: 0,
                  },
                ]}
                value={0}
              />
              <Box theme={theme} className="fee-action">
                <Button
                  theme={theme}
                  secondary
                  iconText={<Icon name="DeleteIcon" />}
                  onClick={() => {}}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box theme={theme} className="fee-item" mt={3}>
        <Grid theme={theme} container item rowSpacing={3} columnSpacing={2}>
          <Grid theme={theme} item xs={12} md={2.5}>
            <Input
              theme={theme}
              fullWidth
              label="Fee name"
              placeholder="Type here..."
              type="text"
            />
          </Grid>
          <Grid theme={theme} item xs={12} md={1.75}>
            <Select
              theme={theme}
              fullWidth
              label="Fee Type"
              items={[
                {
                  text: "On Time",
                  value: 0,
                },
              ]}
              value={0}
            />
          </Grid>
          <Grid theme={theme} item xs={12} md={2.5}>
            <Select
              theme={theme}
              fullWidth
              label="Fee Frequency"
              items={[
                {
                  text: "On a Particular Date",
                  value: 0,
                },
              ]}
              value={0}
            />
          </Grid>
          <Grid theme={theme} item xs={6} md={1.75}>
            <Select
              theme={theme}
              fullWidth
              label="Due At Start Date"
              items={[
                {
                  text: "Yes",
                  value: 0,
                },
              ]}
              value={0}
            />
          </Grid>
          <Grid theme={theme} item xs={6} md={1.75}>
            <Input
              theme={theme}
              startAdornment={"$"}
              fullWidth
              label="Fee Amount"
              placeholder="Type here..."
              type="text"
            />
          </Grid>
          <Grid theme={theme} item xs={12} md={1.75}>
            <Box
              theme={theme}
              display={"flex"}
              alignItems={"center"}
              gap={2}
              className="field-box"
            >
              <Select
                theme={theme}
                fullWidth
                label="Editable"
                items={[
                  {
                    text: "Yes",
                    value: 0,
                  },
                ]}
                value={0}
              />
              <Box theme={theme} className="fee-action">
                <Button
                  theme={theme}
                  secondary
                  iconText={<Icon name="DeleteIcon" />}
                  onClick={() => {}}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box theme={theme} mt={3}>
        <Button
          theme={theme}
          fullWidth
          secondary
          text="Add Fee"
          onClick={() => {}}
        />
      </Box>
    </FinancialConfigFeeWrap>
  );
};
