import React, { ReactNode } from "react";
import cls from "clsx";
import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import isPropValid from "@emotion/is-prop-valid";

import { ButtonGroupProps, ButtonProps, Theme } from "@mui/material";
import { CustomColors } from "@themes/mui-theme";

export declare type IButtonProps = Partial<ButtonProps> & {
  text?: ReactNode | string;
  primary?: boolean;
  secondary?: boolean;
  defaultBtn?: boolean;
  disabled?: boolean;
  loading?: ReactNode;
  className?: string;
  theme?: Theme;
  iconText?: ReactNode;
};

export declare type IButtonGroupProps = Partial<ButtonGroupProps> & {
  theme?: Theme;
};

const StyledMuiButton = styled(MuiButton, {
  shouldForwardProp: (props) =>
    isPropValid(props.toString()) ||
    props === "startIcon" ||
    props === "variant" ||
    props === "endIcon" ||
    props === "theme" ||
    props === "fullWidth",
})<IButtonProps>(({ theme }) => ({
  "&.btn": {
    padding: "11px 24px",
    minWidth: "auto",
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.customVariables.fontWeightSemiBold,
    transition: "all 0.3s",
    "&.btn-primary": {
      background: CustomColors.gradient,
      backgroundColor: theme.palette.primary.main,
      borderColor: "transparent",
      border: "none",
      color: theme.palette.common.white,
      "&:hover": {
        color: theme.palette.common.white,
        background: theme.palette.grey[900],
        backgroundColor: theme.palette.grey[900],
        borderColor: "transparent",
      },
      "&:disabled": {
        borderColor: theme.palette.grey[100],
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[300],
      },
      "&.disabled": {
        borderColor: theme.palette.grey[100],
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[300],
      },
    },
    "&.btn-secondary": {
      backgroundColor: "transparent",
      borderColor: theme.palette.grey[300],
      color: theme.palette.grey[900],
      "&:hover": {
        backgroundColor: theme.palette.grey[900],
        borderColor: theme.palette.grey[900],
        color: theme.palette.common.white,
      },
      "&:disabled": {
        borderColor: theme.palette.grey[100],
        backgroundColor: "transparent",
        color: theme.palette.grey[300],
      },
      "&.disabled": {
        borderColor: theme.palette.grey[100],
        backgroundColor: "transparent",
        color: theme.palette.grey[300],
      },
    },
    "&.btn-default": {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
      border: "none",
      padding: 0,
      textTransform: "none",
      color: theme.palette.grey[900],
      ".MuiTouchRipple-root": {
        display: "none",
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus-visible": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        backgroundColor: "transparent",
      },
      "&:disabled": {
        color: theme.palette.grey[300],
      },
      "&.disabled": {
        color: theme.palette.grey[300],
      },
    },
    "&.btn-danger": {
      backgroundColor: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      color: theme.palette.common.white,
      "&:hover": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.grey[900],
        borderColor: theme.palette.grey[900],
      },
      "&:disabled": {
        borderColor: theme.palette.grey[100],
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[300],
      },
      "&.disabled": {
        borderColor: theme.palette.grey[100],
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[300],
      },
    },
    "&.with-icon": {
      display: "flex",
      alignItems: "center",
      ".MuiButton-iconSizeMedium": {
        width: "24px",
        height: "24px",
        marginRight: "8px",
        "&.MuiButton-startIcon": {
          marginLeft: 0,
        },
        "&.MuiButton-endIcon": {
          marginRight: 0,
        },
      },
      "&.icon-only": {
        padding: "12px",
        svg: {
          width: "24px",
          height: "24px",
        },
      },
      "&.btn-primary": {
        svg: {
          path: {
            stroke: theme.palette.common.white,
          },
        },
        "&:hover": {
          svg: {
            path: {
              stroke: theme.palette.common.white,
            },
          },
        },
        "&:disabled": {
          svg: {
            path: {
              stroke: theme.palette.grey[300],
            },
          },
        },
      },
      "&.btn-secondary": {
        svg: {
          path: {
            stroke: theme.palette.grey[900],
          },
        },
        "&:hover": {
          svg: {
            path: {
              stroke: theme.palette.common.white,
            },
          },
        },
        "&:disabled": {
          svg: {
            path: {
              stroke: theme.palette.grey[300],
            },
          },
        },
      },
      "&.btn-danger": {
        svg: {
          path: {
            stroke: theme.palette.common.white,
          },
        },
        "&:hover": {
          svg: {
            path: {
              stroke: theme.palette.common.white,
            },
          },
        },
        "&:disabled": {
          svg: {
            path: {
              stroke: theme.palette.grey[300],
            },
          },
        },
      },
      "&.btn-default": {
        svg: {
          path: {
            stroke: theme.palette.grey[300],
          },
        },
        "&:hover": {
          backgroundColor: "transparent",
          color: theme.palette.grey[900],
          svg: {
            path: {
              stroke: theme.palette.grey[300],
            },
          },
        },
        "&:focus-visible": {
          backgroundColor: "transparent",
          color: theme.palette.grey[900],
          svg: {
            path: {
              stroke: theme.palette.grey[300],
            },
          },
        },
      },
    },
    ".MuiTouchRipple-root": {
      display: "none",
    },
  },
}));

const MenuButton: React.FC<IButtonProps> = ({
  text,
  disabled,
  loading,
  onClick,
  className,
  primary,
  secondary,
  defaultBtn,
  theme,
  startIcon,
  endIcon,
  classes,
  sx,
  iconText,
  ...props
}) => {
  return (
    <StyledMuiButton
      {...props}
      startIcon={startIcon}
      endIcon={endIcon}
      theme={theme}
      loading={loading}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
          return;
        }
        onClick?.(e);
      }}
      disabled={disabled ?? !!loading}
      className={cls(className, {
        btn: true,
        className: !!className,
        classes: !(classes == null),
        "btn-primary": primary,
        "btn-secondary": secondary,
        "btn-default": defaultBtn,
        "with-icon": iconText ?? startIcon ?? endIcon,
        "icon-only": iconText && !text,
      })}
    >
      {loading ?? text ?? iconText}
    </StyledMuiButton>
  );
};

export default MenuButton;
