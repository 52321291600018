import { CustomerDetail } from "@interfaces/requestparams.flex";

export const ValidateSaveConfiguration = (state: CustomerDetail) => {
  if (state.TrackingID == "" || state.TrackingID == undefined) {
    return {
      visible: true,
      error: "Please provide valid refrence number",
    };
  } else if (
    state.CustomerFullName == "" ||
    state.CustomerFullName == undefined
  ) {
    return {
      visible: true,
      error: "Please provide valid customer name",
    };
  } else if (validateEmail(state.CustomerEmailAddress, true)) {
    return {
      visible: true,
      error: "Please provide valid customer email address.",
    };
  } else if (validateEmail(state.VendorEmailAddress, false)) {
    return {
      visible: true,
      error: "Please provide valid introducer email address.",
    };
  }
};

export const validateEmail = (email: any, mandatory: boolean) => {
  if (!email && mandatory) {
    return true;
  }
  if (!email) {
    return false;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return true;
  }
  return false;
};
