import {
  FinancialConfigurations,
  LayoutWithSideNavComponent,
  PageHeader,
} from "@components";
import { useTheme } from "@mui/material";
import { Box, Button } from "@ntpkunity/controls";
import { PageContent } from "./financial-configurations.style";

export const FinancialConfigurationsPage = () => {
  const theme = useTheme();
  return (
    <LayoutWithSideNavComponent theme={theme}>
      <PageHeader
        title={"Financial configurations"}
        actionArea={
          <>
            <Button
              theme={theme}
              primary
              text={"Save Changes"}
              onClick={() => {}}
            />
          </>
        }
      />
      <PageContent theme={theme} className="page-content" pt={3}>
        <Box
          theme={theme}
          className="financial-config-container"
          m={{ xs: 0, md: "-8px -24px -8px -24px" }}
        >
          <FinancialConfigurations />
        </Box>
      </PageContent>
    </LayoutWithSideNavComponent>
  );
};
