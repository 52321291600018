import { useForm, useFormContext, FormProvider } from "react-hook-form";
import { TDirector, TProposalForm } from "@_types";
import { useProposalFieldArrays } from "@contexts/proposal-form";
import { EditDirectorDialogProps } from "./edit-director-dialog.props";
import DirectorFormDialog from "../director-form-dialog/director-form-dialog.component";

const EditDirectorDialog = ({
  open,
  editDirectorId,
  setEditDirectorId,
  setOpenDialog,
}: EditDirectorDialogProps) => {
  const { watch } = useFormContext<TProposalForm>();
  const clientType = watch("customerDetails.clientType");

  const {
    directorMethods: { update, fields },
  } = useProposalFieldArrays();

  const directorToEdit = fields.find(
    (director) => director.id === editDirectorId
  );

  const formMethods = useForm<TDirector>({
    defaultValues: { ...directorToEdit },
  });

  const closeDialog = () => {
    formMethods.reset();
    setOpenDialog(false);
    setEditDirectorId(null);
  };

  const handleSaveDirector = () => {
    const indexOfDirectorToEdit = fields.findIndex(
      (director) => director.id === editDirectorId
    );
    update(indexOfDirectorToEdit, {
      firstName: "",
      lastName: "",
      emailAddress: "",
      contactNumber: "",
      address: "",
      ...formMethods.getValues(),
    });
    formMethods.reset();
    closeDialog();
  };
  return (
    <FormProvider {...formMethods}>
      <DirectorFormDialog
        clientType={clientType}
        open={open}
        closeDialog={closeDialog}
        handleSaveDirector={handleSaveDirector}
        isEditMode
      />
    </FormProvider>
  );
};

export default EditDirectorDialog;
