import React from "react";
import { useTheme } from "@mui/material";
import { PreviousCommentsWrap } from "./previous-comments.style";
import {
  Accordion,
  Avatar,
  Box,
  Icon,
  Select,
  Typography,
} from "@ntpkunity/controls";

export const PreviousComments = () => {
  const theme = useTheme();
  return (
    <PreviousCommentsWrap theme={theme} className="previous-comments-wrap">
      <Box
        theme={theme}
        className="tab-head"
        display={"flex"}
        alignItems={"center"}
        gap={2}
      >
        <Box theme={theme} className="custom-dropdown" flexGrow={1}>
          <Typography
            theme={theme}
            className="text-muted"
            variant="body2"
            component={"span"}
            display={"block"}
          >
            Catagory:
          </Typography>
          <Select
            theme={theme}
            disablePortal={false}
            items={[
              {
                text: "All",
                value: "0",
              },
              {
                text: "General",
                value: "1",
              },
              {
                text: "Underwriting",
                value: "2",
              },
            ]}
            value={"0"}
          />
        </Box>
        <Box theme={theme} flexShrink={0}>
          <Icon name="SearchIcon" />
        </Box>
      </Box>
      <Box theme={theme} className="accordion-container" mt={2}>
        <Accordion
          theme={theme}
          items={[
            {
              title: (
                <>
                  <Box theme={theme} className="title-wrap">
                    <Box theme={theme} className="list-img">
                      <Avatar theme={theme}>SK</Avatar>
                    </Box>
                    <Box theme={theme} className="list-content">
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        display={"block"}
                      >
                        Asad Javaid Added a Comment
                      </Typography>
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        display={"flex"}
                        mt={0.5}
                        className="text-muted"
                        alignItems={"center"}
                        gap={1}
                      >
                        <span>Aug 12, 2024</span>{" "}
                        <span className="seperator">|</span>{" "}
                        <span>10:00 am</span>
                      </Typography>
                    </Box>
                  </Box>
                </>
              ),
              subtitle: "General",
              content: (
                <>
                  <Typography
                    theme={theme}
                    variant="caption"
                    component={"small"}
                    className="text-muted"
                    display={"block"}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </Typography>
                </>
              ),
              key: "string 1",
              isExpanded: true,
            },
            {
              title: (
                <>
                  <Box theme={theme} className="title-wrap">
                    <Box theme={theme} className="list-img">
                      <Avatar theme={theme}>SK</Avatar>
                    </Box>
                    <Box theme={theme} className="list-content">
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        display={"block"}
                      >
                        Asad Javaid Added a Comment
                      </Typography>
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        display={"flex"}
                        mt={0.5}
                        className="text-muted"
                        alignItems={"center"}
                        gap={1}
                      >
                        <span>Aug 12, 2024</span>{" "}
                        <span className="seperator">|</span>{" "}
                        <span>10:00 am</span>
                      </Typography>
                    </Box>
                  </Box>
                </>
              ),
              subtitle: "General",
              content: (
                <>
                  <Typography
                    theme={theme}
                    variant="caption"
                    component={"small"}
                    className="text-muted"
                    display={"block"}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </Typography>
                </>
              ),
              key: "string 1",
              isExpanded: true,
            },
            {
              title: (
                <>
                  <Box theme={theme} className="title-wrap">
                    <Box theme={theme} className="list-img">
                      <Avatar theme={theme}>SK</Avatar>
                    </Box>
                    <Box theme={theme} className="list-content">
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        display={"block"}
                      >
                        Asad Javaid Added a Comment
                      </Typography>
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        display={"flex"}
                        mt={0.5}
                        className="text-muted"
                        alignItems={"center"}
                        gap={1}
                      >
                        <span>Aug 11, 2024</span>{" "}
                        <span className="seperator">|</span>{" "}
                        <span>10:00 am</span>
                      </Typography>
                    </Box>
                  </Box>
                </>
              ),
              subtitle: "Underwriting",
              content: (
                <>
                  <Typography
                    theme={theme}
                    variant="caption"
                    component={"small"}
                    className="text-muted"
                    display={"block"}
                  >
                    This comment is intended for the underwriter to provide
                    additional information for proposal evaluation. This is the
                    sample text and sample comment.
                  </Typography>
                </>
              ),
              key: "string 1",
              isExpanded: true,
            },

            {
              title: (
                <>
                  <Box theme={theme} className="title-wrap">
                    <Box theme={theme} className="list-img">
                      <Avatar theme={theme}>SK</Avatar>
                    </Box>
                    <Box theme={theme} className="list-content">
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        display={"block"}
                      >
                        Asad Javaid Added a Comment
                      </Typography>
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        display={"flex"}
                        mt={0.5}
                        className="text-muted"
                        alignItems={"center"}
                        gap={1}
                      >
                        <span>Aug 10, 2024</span>{" "}
                        <span className="seperator">|</span>{" "}
                        <span>09:00 am</span>
                      </Typography>
                    </Box>
                  </Box>
                </>
              ),
              subtitle: "General",
              content: (
                <>
                  <Typography
                    theme={theme}
                    variant="caption"
                    component={"small"}
                    className="text-muted"
                    display={"block"}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </Typography>
                </>
              ),
              key: "string 4",
              isExpanded: false,
            },
            {
              title: (
                <>
                  <Box theme={theme} className="title-wrap">
                    <Box theme={theme} className="list-img">
                      <Avatar theme={theme}>SK</Avatar>
                    </Box>
                    <Box theme={theme} className="list-content">
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        display={"block"}
                      >
                        Asad Javaid Added a Comment
                      </Typography>
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        display={"flex"}
                        mt={0.5}
                        className="text-muted"
                        alignItems={"center"}
                        gap={1}
                      >
                        <span>Aug 10, 2024</span>{" "}
                        <span className="seperator">|</span>{" "}
                        <span>09:00 am</span>
                      </Typography>
                    </Box>
                  </Box>
                </>
              ),
              subtitle: "General",
              content: (
                <>
                  <Typography
                    theme={theme}
                    variant="caption"
                    component={"small"}
                    className="text-muted"
                    display={"block"}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </Typography>
                </>
              ),
              key: "string 5",
              isExpanded: false,
            },
            {
              title: (
                <>
                  <Box theme={theme} className="title-wrap">
                    <Box theme={theme} className="list-img">
                      <Avatar theme={theme}>SK</Avatar>
                    </Box>
                    <Box theme={theme} className="list-content">
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        display={"block"}
                      >
                        Asad Javaid Added a Comment
                      </Typography>
                      <Typography
                        theme={theme}
                        variant="caption"
                        component={"small"}
                        display={"flex"}
                        mt={0.5}
                        className="text-muted"
                        alignItems={"center"}
                        gap={1}
                      >
                        <span>Aug 10, 2024</span>{" "}
                        <span className="seperator">|</span>{" "}
                        <span>10:00 am</span>
                      </Typography>
                    </Box>
                  </Box>
                </>
              ),
              subtitle: "General",
              content: (
                <>
                  <Typography
                    theme={theme}
                    variant="caption"
                    component={"small"}
                    className="text-muted"
                    display={"block"}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </Typography>
                </>
              ),
              key: "string 6",
              isExpanded: false,
            },
          ]}
          shouldCollapse={true}
        />
      </Box>
    </PreviousCommentsWrap>
  );
};
