import { useTheme } from "@mui/material";
import { PageContent } from "./diary-page.style";
import { WorkQueueTabs } from "@helpers/enum";
import {
  LayoutWithSideNavComponent,
  PageHeader,
  Stack,
  Footer,
} from "@components";
import { Box, DuoTab, Grid, Icon } from "@ntpkunity/controls";
import {
  AddComments,
  AuditTrail,
  AuditTrailDialog,
  CommunicationHistory,
  CommunicationHistoryDialog,
  PreviousComments,
  PreviousCommentsDialog,
} from "@components/diary";

import { useNavigate, useParams } from "react-router-dom";

import { useState } from "react";
import { APP_ROUTES } from "@helpers";

export const DiaryPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [auditTrailDialog, setAuditTrailDialog] = useState(false);
  const [previousCommentsDialog, setPreviousCommentsDialog] = useState(false);
  const [communicationHistoryDialog, setcommunicationHistoryDialog] =
    useState(false);

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <PageHeader title="Diary" />
      <PageContent theme={theme} className="page-content has-footer" pt={2}>
        <Grid theme={theme} container spacing={2} alignItems={"stretch"}>
          <Grid theme={theme} item xl={9} lg={8} md={7} sm={12} xs={12}>
            <AddComments />
            <Stack
              paddingMd={3}
              paddingXs={3}
              marginTopMd={2}
              marginTopXs={2}
              className="tabs-area"
            >
              <DuoTab
                theme={theme}
                varient="underline"
                items={[
                  {
                    title: "Previous Comments",
                    content: <PreviousComments />,
                  },
                  {
                    title: "Communication History",
                    content: <CommunicationHistory />,
                  },
                ]}
                defaultTabIndex={0}
              />
            </Stack>
            <Box theme={theme} display={{ xs: "block", md: "none" }}>
              <Box theme={theme} mt={2}>
                <Stack
                  className="cp"
                  paddingMd={3}
                  paddingXs={3}
                  title="Audit Trail"
                  onClick={() => setAuditTrailDialog(true)}
                  actionArea={<Icon name="IconRightArrow" />}
                />
              </Box>
              <Box theme={theme} mt={2}>
                <Stack
                  className="cp"
                  paddingMd={3}
                  paddingXs={3}
                  title="Previous Comments"
                  onClick={() => setPreviousCommentsDialog(true)}
                  actionArea={<Icon name="IconRightArrow" />}
                />
              </Box>
              <Box theme={theme} mt={2}>
                <Stack
                  className="cp"
                  paddingMd={3}
                  paddingXs={3}
                  title="Communication history"
                  onClick={() => setcommunicationHistoryDialog(true)}
                  actionArea={<Icon name="IconRightArrow" />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid theme={theme} item xl={3} lg={4} md={5} sm={12} xs={12}>
            <Box theme={theme} display={{ xs: "none", md: "block" }}>
              <AuditTrail />
            </Box>
          </Grid>
        </Grid>
        <Footer showBackButton backButtonOnClick={() => navigate(-1)} />
      </PageContent>
      {auditTrailDialog && (
        <AuditTrailDialog
          open={auditTrailDialog}
          onClose={() => setAuditTrailDialog(false)}
        />
      )}
      {previousCommentsDialog && (
        <PreviousCommentsDialog
          open={previousCommentsDialog}
          onClose={() => setPreviousCommentsDialog(false)}
        />
      )}
      {communicationHistoryDialog && (
        <CommunicationHistoryDialog
          open={communicationHistoryDialog}
          onClose={() => setcommunicationHistoryDialog(false)}
        />
      )}
    </LayoutWithSideNavComponent>
  );
};
