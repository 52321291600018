import { Grid, Skeleton } from "@mui/material";
import { NumericFormat } from "react-number-format";

interface IFormattedValue {
  scale?: number;
  prefix?: string;
  suffix?: string;
  value: string | number;
  lazyLoad?: boolean;
  loading?: boolean;
}
export const FormattedValue = ({
  scale = 2,
  suffix = "",
  prefix = "",
  value,
  lazyLoad = false,
  loading = false,
}: IFormattedValue) => {
  if (lazyLoad && loading) {
    return (
      <Grid container justifyContent="flex-end">
        <Skeleton variant="rounded" width={50} height={20} />
      </Grid>
    );
  } else {
    return (
      <NumericFormat
        thousandSeparator={true}
        decimalScale={scale}
        prefix={prefix + " "}
        suffix={" " + suffix}
        value={value}
        displayType={"text"}
        fixedDecimalScale
      />
    );
  }
};
