import { CommissionType, PaymentFrequency } from "@flexCommon/enums";
import { IReducerFinancialCalculation } from "@store/reducers/financialCalculation.reducer";

export const ValidationFinancialCalculation = (
  state: IReducerFinancialCalculation
) => {
  if (
    state.RequestObj.CommissionType == CommissionType.Percentage &&
    state.RequestObj.CommissionPercentage > 99
  ) {
    return {
      visible: true,
      error: "Please provide valid Commission Percentage.",
    };
  } else if (
    state.RequestObj.DepositType == CommissionType.Percentage &&
    state.RequestObj.DepositAmount > 99
  ) {
    return {
      visible: true,
      error: "Please provide valid Deposit Percentage.",
    };
  } else if (
    state.RequestObj.HasStructureRentals &&
    state.RequestObj.hasStepPaymentStructure &&
    state.RequestObj.StructureRentals.length > 0 &&
    state.RequestObj.StepPaymentStructure.length > 0
  ) {
    var array1: number[] = [];
    var array2: number[] = [];
    var check = false;
    state.RequestObj.StepPaymentStructure.forEach((r) => {
      for (var i = r.From; i <= r.To; i++) {
        array1.push(Number(i));
      }
    });
    state.RequestObj.StructureRentals.forEach((r) => {
      for (var i = r.StartTerm; i <= r.EndTerm; i++) {
        array2.push(Number(i));
      }
    });
    array1.forEach((r) => {
      if (array2.includes(r)) {
        check = true;
      }
    });
    if (check) {
      return {
        visible: true,
        error:
          "Irregular Payments and Step Payments Structure are Overlapping.",
      };
    }
  } else if (state.RequestObj.Frequency == PaymentFrequency.Quarterly) {
    var check = false;
    state.RequestObj.Fee.forEach((r) => {
      if (r.Frequency == PaymentFrequency.Monthly) {
        check = true;
      }
    });
    if (check) {
      return {
        visible: true,
        error: "Mismatch between payment frequency and fee frequency.",
      };
    }
  }
};
