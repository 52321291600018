import { useTheme } from "@mui/material";
import { Accordion, Box, Typography } from "@ntpkunity/controls";
import { WidgetsProps } from "./dashboard-widget.props";
import { WidgetWrap } from "./dashboard-widget.style";
import { Stack } from "@components/stack";
import InProgressIcon from "../../../public/assets/images/dashboard-filled-icon.svg";

const WidgetInProgress = () => {
  const theme = useTheme();
  return (
    <WidgetWrap className="widget-wrap" theme={theme}>
      <Stack paddingXs={3} paddingMd={3} className="in-progress-stack">
        <Box theme={theme} className="widget-in-progress">
          <img src={InProgressIcon} alt="In Progress" />
        </Box>
      </Stack>
    </WidgetWrap>
  );
};

export const Widget = ({
  title,
  actionArea,
  control,
  children,
  expand = true,
  loading = false,
}: WidgetsProps) => {
  const theme = useTheme();

  if (loading) {
    return <WidgetInProgress />;
  }
  return (
    <WidgetWrap className="widget-wrap" theme={theme}>
      <Stack
        paddingXs={3}
        paddingMd={3}
        className="collapse-wrap desktop-disable"
      >
        <Accordion
          theme={theme}
          items={[
            {
              content: (
                <Box theme={theme} className="widget-content">
                  {actionArea && (
                    <Box
                      theme={theme}
                      className="action-area"
                      mb={3}
                      display={{ xs: "block", md: "none" }}
                    >
                      {actionArea}
                    </Box>
                  )}
                  {children}
                </Box>
              ),
              title: title,
              actionBtn: (
                <>
                  {actionArea && (
                    <Box theme={theme} className="action-area">
                      {actionArea}
                    </Box>
                  )}
                </>
              ),
              key: control,
              isExpanded: expand,
              id: control,
              ariaControls: control,
            },
          ]}
        />
      </Stack>
    </WidgetWrap>
  );
};
