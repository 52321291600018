import { useTheme } from "@mui/material";
import { Avatar, Box, Typography } from "@ntpkunity/controls";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { utcToLocalDateTime } from "@helpers/utils";
import { TOrderStatusHistory } from "@_types/auditTrail";
import useGetOrderStatusHistory from "@hooks/queries/useGetOrderStatusHistory";

export const AuditTrailContent = () => {
  const theme = useTheme();
  const { proposalId } = useParams<{ proposalId?: string }>();
  const { mutate: fetchOrderStatusHistory, data: orderStatusHistory = [] } =
    useGetOrderStatusHistory();

  useEffect(() => {
    fetchOrderStatusHistory({ order_reference_number: proposalId });
  }, [proposalId, fetchOrderStatusHistory]);

  return (
    <Box theme={theme} className="audit-trail-list-wrap">
      <Box theme={theme} className="list-item">
        <Box theme={theme} className="list-img">
          <Avatar theme={theme}>SK</Avatar>
        </Box>
        <Box theme={theme} className="list-content">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
          >
            <span className="fw-sbold">Asad Javaid</span> Request sent for
            Payout.
            <Typography
              theme={theme}
              variant="body2"
              component={"span"}
              display={"block"}
            >
              ABC-1234
            </Typography>
          </Typography>
          <Typography
            theme={theme}
            variant="caption"
            component={"small"}
            display={"flex"}
            mt={0.5}
            className="text-muted"
            alignItems={"center"}
            gap={1}
          >
            <span>Aug 12, 2024</span> <span className="seperator">|</span>{" "}
            <span>10:00 am</span>
          </Typography>
        </Box>
      </Box>
      <Box theme={theme} className="list-item">
        <Box theme={theme} className="list-img">
          <Avatar theme={theme}>SK</Avatar>
        </Box>
        <Box theme={theme} className="list-content">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
          >
            <span className="fw-sbold">Asad Javaid </span> Contract Documents
            signed.
            <Typography
              theme={theme}
              variant="body2"
              component={"span"}
              display={"block"}
            >
              ABC-1234
            </Typography>
          </Typography>
          <Typography
            theme={theme}
            variant="caption"
            component={"small"}
            display={"flex"}
            mt={0.5}
            className="text-muted"
            alignItems={"center"}
            gap={1}
          >
            <span>Aug 11, 2024</span> <span className="seperator">|</span>{" "}
            <span>11:00 am</span>
          </Typography>
        </Box>
      </Box>
      <Box theme={theme} className="list-item">
        <Box theme={theme} className="list-img">
          <Avatar theme={theme}>SK</Avatar>
        </Box>
        <Box theme={theme} className="list-content">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
          >
            <span className="fw-sbold">Asad Javaid </span>Contract Documents
            generated for signing.
            <Typography
              theme={theme}
              variant="body2"
              component={"span"}
              display={"block"}
            >
              ABC-1234
            </Typography>
          </Typography>
          <Typography
            theme={theme}
            variant="caption"
            component={"small"}
            display={"flex"}
            mt={0.5}
            className="text-muted"
            alignItems={"center"}
            gap={1}
          >
            <span>Aug 10, 2024</span> <span className="seperator">|</span>{" "}
            <span>11:00 am</span>
          </Typography>
        </Box>
      </Box>
      <Box theme={theme} className="list-item">
        <Box theme={theme} className="list-img">
          <Avatar theme={theme}>SK</Avatar>
        </Box>
        <Box theme={theme} className="list-content">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
          >
            <span className="fw-sbold">Asad Javaid</span> Proposal Approved.
            <Typography
              theme={theme}
              variant="body2"
              component={"span"}
              display={"block"}
            >
              ABC-1234
            </Typography>
          </Typography>
          <Typography
            theme={theme}
            variant="caption"
            component={"small"}
            display={"flex"}
            mt={0.5}
            className="text-muted"
            alignItems={"center"}
            gap={1}
          >
            <span>Aug 9, 2024</span> <span className="seperator">|</span>{" "}
            <span>10:00 am</span>
          </Typography>
        </Box>
      </Box>
      <Box theme={theme} className="list-item">
        <Box theme={theme} className="list-img">
          <Avatar theme={theme}>SK</Avatar>
        </Box>
        <Box theme={theme} className="list-content">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
          >
            <span className="fw-sbold">Asad Javaid</span> Proposal Re-submitted
            <Typography
              theme={theme}
              variant="body2"
              component={"span"}
              display={"block"}
            >
              ABC-1234
            </Typography>
          </Typography>
          <Typography
            theme={theme}
            variant="caption"
            component={"small"}
            display={"flex"}
            mt={0.5}
            className="text-muted"
            alignItems={"center"}
            gap={1}
          >
            <span>Aug 8, 2024</span> <span className="seperator">|</span>{" "}
            <span>10:00 am</span>
          </Typography>
        </Box>
      </Box>
      <Box theme={theme} className="list-item">
        <Box theme={theme} className="list-img">
          <Avatar theme={theme}>SK</Avatar>
        </Box>
        <Box theme={theme} className="list-content">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
          >
            <span className="fw-sbold">Asad Javaid</span> Documents uploaded.
            <Typography
              theme={theme}
              variant="body2"
              component={"span"}
              display={"block"}
            >
              ABC-1234
            </Typography>
          </Typography>
          <Typography
            theme={theme}
            variant="caption"
            component={"small"}
            display={"flex"}
            mt={0.5}
            className="text-muted"
            alignItems={"center"}
            gap={1}
          >
            <span>Aug 07, 2024</span> <span className="seperator">|</span>{" "}
            <span>09:50 am</span>
          </Typography>
        </Box>
      </Box>
      <Box theme={theme} className="list-item">
        <Box theme={theme} className="list-img">
          <Avatar theme={theme}>SK</Avatar>
        </Box>
        <Box theme={theme} className="list-content">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
          >
            <span className="fw-sbold">Asad Javaid </span> Proposal declined
            <Typography
              theme={theme}
              variant="body2"
              component={"span"}
              display={"block"}
            >
              ABC-1234
            </Typography>
          </Typography>
          <Typography
            theme={theme}
            variant="caption"
            component={"small"}
            display={"flex"}
            mt={0.5}
            className="text-muted"
            alignItems={"center"}
            gap={1}
          >
            <span>Aug 06, 2024</span> <span className="seperator">|</span>{" "}
            <span>11:50 am</span>
          </Typography>
        </Box>
      </Box>
      <Box theme={theme} className="list-item">
        <Box theme={theme} className="list-img">
          <Avatar theme={theme}>SK</Avatar>
        </Box>
        <Box theme={theme} className="list-content">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
          >
            <span className="fw-sbold">Asad Javaid</span> Proposal submitted
            <Typography
              theme={theme}
              variant="body2"
              component={"span"}
              display={"block"}
            >
              ABC-1234
            </Typography>
          </Typography>
          <Typography
            theme={theme}
            variant="caption"
            component={"small"}
            display={"flex"}
            mt={0.5}
            className="text-muted"
            alignItems={"center"}
            gap={1}
          >
            <span>Aug 05, 2024</span> <span className="seperator">|</span>{" "}
            <span>11:00 am</span>
          </Typography>
        </Box>
      </Box>
      <Box theme={theme} className="list-item">
        <Box theme={theme} className="list-img">
          <Avatar theme={theme}>SK</Avatar>
        </Box>
        <Box theme={theme} className="list-content">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
          >
            <span className="fw-sbold">Asad Javaid</span> Proposal saved as
            draft
            <Typography
              theme={theme}
              variant="body2"
              component={"span"}
              display={"block"}
            >
              ABC-1234
            </Typography>
          </Typography>
          <Typography
            theme={theme}
            variant="caption"
            component={"small"}
            display={"flex"}
            mt={0.5}
            className="text-muted"
            alignItems={"center"}
            gap={1}
          >
            <span>Aug 04, 2024</span> <span className="seperator">|</span>{" "}
            <span>11:00 am</span>
          </Typography>
        </Box>
      </Box>
      <Box theme={theme} className="list-item">
        <Box theme={theme} className="list-img">
          <Avatar theme={theme}>SK</Avatar>
        </Box>
        <Box theme={theme} className="list-content">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
          >
            <span className="fw-sbold">Asad Javaid</span> Converted quotation to
            proposal
            <Typography
              theme={theme}
              variant="body2"
              component={"span"}
              display={"block"}
            >
              ABC-1234
            </Typography>
          </Typography>
          <Typography
            theme={theme}
            variant="caption"
            component={"small"}
            display={"flex"}
            mt={0.5}
            className="text-muted"
            alignItems={"center"}
            gap={1}
          >
            <span>Aug 03, 2024</span> <span className="seperator">|</span>{" "}
            <span>11:00 am</span>
          </Typography>
        </Box>
      </Box>
      <Box theme={theme} className="list-item">
        <Box theme={theme} className="list-img">
          <Avatar theme={theme}>SK</Avatar>
        </Box>
        <Box theme={theme} className="list-content">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
          >
            <span className="fw-sbold">Asad Javaid</span> Saved Quotation
            <Typography
              theme={theme}
              variant="body2"
              component={"span"}
              display={"block"}
            >
              ABC-1234
            </Typography>
          </Typography>
          <Typography
            theme={theme}
            variant="caption"
            component={"small"}
            display={"flex"}
            mt={0.5}
            className="text-muted"
            alignItems={"center"}
            gap={1}
          >
            <span>Aug 02, 2024</span> <span className="seperator">|</span>{" "}
            <span>10:00 am</span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
