import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PageContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.page-content": {
    ".tab-list-underline": {
      backgroundColor: theme.palette.common.white,
      marginBottom: 8,
    },
    ".configuration-iframe": {
      minHeight: "calc(100vh - 239px)",
      width: "100%",
      border: "none",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 81,
    },
  },
}));
