import { useTheme } from "@mui/material";
import { AuthLayoutWrap } from "./auth-layout.style";
import { ReactNode } from "react";
import { Box } from "@ntpkunity/controls";
import LoginSideBarImage from "../../../public/assets/images/hsbc-branding.svg";
import AppLogo from "../../../public/assets/images/logo-hsbc-full.svg";

export const AuthLayout = ({ children }: { children?: ReactNode }) => {
  const theme = useTheme();

  return (
    <>
      <AuthLayoutWrap theme={theme} className="auth-layout-wrap">
        <Box theme={theme} className="auth-left-img">
          <img src={LoginSideBarImage} alt="login" />
        </Box>
        <Box theme={theme} className="auth-right-panel">
          <Box theme={theme} className="right-content">
            <Box theme={theme} className="logo-area" mb={3}>
              <img src={AppLogo} alt="Lloyds" />
            </Box>
            {children}
          </Box>
        </Box>
      </AuthLayoutWrap>
    </>
  );
};
