import { TAssetDetails } from "@_types";
import { useFormContext } from "react-hook-form";
import { getSelectValues } from "@helpers/utils";
import {
  TAssetCategories,
  TAssetTypes,
  TAssetConditions,
  TVehicleSubTypes,
  TEquipmentSubTypes,
  TInputDef,
} from "@components/proposal/proposal-details/types";
import {
  ASSET_CATEGORIES,
  ASSET_CONDITIONS,
  ASSET_SUB_TYPES,
  ASSET_TYPES,
} from "@helpers/const";
import { AmountType, AssetCategories } from "@helpers/enum";
import { useMemo } from "react";

const useAssetInputDefs = () => {
  const { watch } = useFormContext<TAssetDetails>();

  const assetType = watch("type");
  const assetCategory = watch("category");

  const getInputDefs = (): TInputDef[] => {
    return [
      {
        name: "category",
        formElement: "Select",
        label: "Asset Category",
        items: getSelectValues<TAssetCategories>(ASSET_CATEGORIES),
      },
      {
        name: "type",
        formElement: "Select",
        label: "Asset Type",
        items: getSelectValues<TAssetTypes>(ASSET_TYPES[assetCategory]),
      },
      {
        name: "subType",
        formElement: "Select",
        label: "Asset Sub-Type",
        items: getSelectValues<TVehicleSubTypes | TEquipmentSubTypes>(
          ASSET_SUB_TYPES[assetType]
        ),
        disabled: assetCategory === AssetCategories.SOFT_ASSET,
      },
      {
        name: "condition",
        formElement: "Select",
        label: "Asset Condition",
        items: getSelectValues<TAssetConditions>(
          ASSET_CONDITIONS[assetCategory]
        ),
        disabled: assetCategory === AssetCategories.SOFT_ASSET,
      },
      {
        name: "cost",
        formElement: "Input",
        placeHolder: "0.00",
        label: "Asset Cost",
        type: "text",
        maskDecimalScale: 2,
        masking: true,
        maskNumeric: true,
        startAdornment: AmountType.POUND,
      },
      {
        name: "description",
        formElement: "Input",
        placeHolder: "Type Here...",
        label: "Asset Description",
        type: "text",
      },
      {
        name: "supplierName",
        formElement: "Input",
        placeHolder: "Type Here...",
        label: "Supplier Name",
        type: "text",
      },
      {
        name: "quantity",
        formElement: "Input",
        placeHolder: "0",
        label: "Quantity",
        type: "number",
      },
      {
        name: "totalCost",
        formElement: "Input",
        placeHolder: "Total Asset Cost",
        label: "Total Cost",
        type: "text",
        masking: true,
        maskNumeric: true,
        maskDecimalScale: 2,
        startAdornment: AmountType.POUND,
        disabled: true,
      },
    ];
  };

  return useMemo(getInputDefs, [assetCategory, assetType]);
};

export default useAssetInputDefs;
