import { useTheme } from "@mui/material";
import { Accordion, Box, Icon, Typography } from "@ntpkunity/controls";
import { DocumentsTable } from "./document-table/documents-table.component";
import { Stack } from "@components/stack";
export const DocumentList = ({ statuses }) => {
  const theme = useTheme();
  return (
    <>
      <Box theme={theme} className="list-collapse collapse-wrap with-border">
        <Accordion
          theme={theme}
          items={[
            {
              content: (
                <Stack bgLight paddingMd={2} paddingXs={2}>
                  <DocumentsTable statuses={statuses} />
                </Stack>
              ),
              title: (
                <>
                  <Box
                    theme={theme}
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Icon name={"DocumentIcon"} />
                    <Typography theme={theme} variant="body2" component={"p"}>
                      Hire purchase regulated - advance over £60,260 only
                    </Typography>
                  </Box>
                </>
              ),
              key: "key1",
              isExpanded: true,
              id: "first",
              ariaControls: "string",
            },
            {
              content: (
                <Stack bgLight paddingMd={2} paddingXs={2}>
                  <DocumentsTable statuses={statuses} />
                </Stack>
              ),
              title: (
                <>
                  <Box
                    theme={theme}
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Icon name={"DocumentIcon"} />
                    <Typography theme={theme} variant="body2" component={"p"}>
                      Hire purchase regulated - advance over £60,260 only
                    </Typography>
                  </Box>
                </>
              ),
              key: "key2",
              isExpanded: false,
              id: "second",
              ariaControls: "string",
            },
            {
              content: (
                <Stack bgLight paddingMd={2} paddingXs={2}>
                  <DocumentsTable statuses={statuses} />
                </Stack>
              ),
              title: (
                <>
                  <Box
                    theme={theme}
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Icon name={"DocumentIcon"} />
                    <Typography theme={theme} variant="body2" component={"p"}>
                      Hire purchase regulated - advance over £60,260 only
                    </Typography>
                  </Box>
                </>
              ),
              key: "key3",
              isExpanded: false,
              id: "third",
              ariaControls: "string",
            },
          ]}
          shouldCollapse
        />
      </Box>
    </>
  );
};
