import { Tabs, Tab, Box, Typography, styled, TabsProps } from "@mui/material";
import { ReactNode } from "react";
type IToggleBtn = {
  items: IToggleBtnItem[];
  value: number | string | boolean;
  onChange?(e: any): void;
};
type IToggleBtnItem = {
  value: string | number | boolean;
  text: string | number;
  body?: ReactNode;
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number | string | boolean;
}
interface CustomTabsProps extends TabsProps {
  tabCount: number;
}
const ToggleWrap = styled(
  "div",
  {}
)<Partial<CustomTabsProps>>(({ tabCount = 1 }) => ({
  "&.flex-tab": {
    ".MuiTabs-root": {
      // padding: "0 32px 0 24px",
      ".MuiTabs-flexContainer": {
        backgroundColor: "#EBECF2",
        borderRadius: 8,
        padding: 2,
        justifyContent: "space-between",
        "&::-webkit-scrollbar": {
          width: 9,
          height: 9,
        },
        "&::-webkit-scrollbar-track": {
          padding: 0,
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          transition: "all 0.3s",
          backgroundColor: "rgba(0, 0, 0, 0.16)",
          borderRadius: 4,
          border: "3px solid transparent",
          backgroundClip: "content-box",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.16)",
        },
        "&:hover": {
          overflowY: "overlay",
          "@-moz-document url-prefix()": {
            overflowY: "auto",
          },
        },
        ".MuiButtonBase-root": {
          color: "#3A3D4D",
          fontSize: 14,
          width: `${100 / tabCount}%`,
          maxWidth: "100%",
          minHeight: 40,
          padding: "8px 16px",
          "&.Mui-selected": {
            backgroundColor: "#ffffff",
            borderRadius: 6,
            boxShadow: "0 0 4px rgba(0, 0, 0, 0.16)",
            color: "#12131A",
            fontSize: 14,
            fontWeight: 700,
          },
        },
      },
      ".MuiTabs-indicator": {
        display: "none",
      },
      ".MuiTouchRipple-root": {
        display: "none",
      },
    },
    "@media screen and (min-width: 960px) and (max-width: 1400px)": {
      ".MuiTabs-root": {
        ".MuiTabs-flexContainer": {
          overflow: "auto",
          ".MuiButtonBase-root": {
            whiteSpace: "nowrap",
            width: "auto",
          },
        },
      },
    },
    // "@media screen and (max-width: 960px)": {

    // },
    "@media screen and (max-width: 500px)": {
      ".MuiTabs-root": {
        ".MuiTabs-flexContainer": {
          overflow: "auto",
          ".MuiButtonBase-root": {
            width: "auto !important",
          },
        },
      },
    },
  },
}));

interface ITabPanelExtProps extends TabPanelProps {
  strIndex: string | number | boolean;
}
function TabPanel(props: ITabPanelExtProps) {
  const { children, value, index, strIndex, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== strIndex}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === strIndex && (
        <Box sx={{ mt: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export const ToggleBtn = ({ value, items, onChange }: IToggleBtn) => {
  const handleChange = (e: any, newValue: any) => {
    onChange?.(newValue);
  };
  return (
    <>
      <ToggleWrap className="flex-tab" tabCount={items.length}>
        <Tabs value={value} onChange={handleChange} centered>
          {items.map((tab, index) => (
            <Tab label={tab.text} key={index} value={tab.value}></Tab>
          ))}
        </Tabs>
        {items.map((tab, index) => (
          <TabPanel
            strIndex={tab.value}
            index={index}
            key={index}
            value={value}
          >
            {tab.body}
          </TabPanel>
        ))}
      </ToggleWrap>
    </>
  );
};
