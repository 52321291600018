import { TableCell, TableRow, useTheme } from "@mui/material";
import { Button, Icon, Menu } from "@ntpkunity/controls";
import { DashboardTableRowProps } from "./dashboard-table-row.props";
import {
  getCustomerName,
  getOrderStakeholders,
  pounds,
  getProposalRowOptions,
} from "@helpers/utils";
import dayjs from "dayjs";
import {
  AppDialogKeys,
  OrderStatus,
  TableRowOptions,
  WorkQueueTabs,
} from "@helpers/enum";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import ProposalStatus from "./dashboard-status/dashboard-status.component";
import { TProposalWorkQueueEntry } from "@_types/workQueues";

const DashboardTableRow = ({
  proposal,
  openDialog,
}: DashboardTableRowProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const updatedAt = dayjs(proposal.updated_at, "YYYY-MM-DD");
  const dateSubmitted = proposal.date_submitted
    ? dayjs(proposal.date_submitted, "YYYY-MM-DD")
    : null;

  const handleOptionClick = (
    _,
    key: TableRowOptions,
    proposal: TProposalWorkQueueEntry
  ) => {
    switch (key) {
      case TableRowOptions.OPEN_DIARY:
        navigate(APP_ROUTES.DIARY);
        break;
      case TableRowOptions.VIEW_EDIT:
        navigate(`${APP_ROUTES.PROPOSAL}/${proposal.identifier}`);
        break;
      case TableRowOptions.MANUAL_STATUS_CHANGE:
        openDialog(AppDialogKeys.MANUAL_STATUS_CHANGE, {
          identifier: proposal.identifier,
          status: proposal.status,
        });
        break;
      case TableRowOptions.WITHDRAW:
        openDialog(AppDialogKeys.WITHDRAW_CONFIRMATION, {
          identifier: proposal.identifier,
          status: proposal.status,
          referenceNumber: proposal.reference_number,
          name: proposal.name,
        });
    }
  };

  return (
    <TableRow className="child-tr">
      <TableCell>
        <ProposalStatus status={proposal.status} />
      </TableCell>
      <TableCell>{proposal.name}</TableCell>
      <TableCell>{proposal.product_type}</TableCell>
      <TableCell>{proposal.sorted_customer_name}</TableCell>
      {false && <TableCell>{proposal.lender_name}</TableCell>}
      <TableCell>{proposal.introducer_name}</TableCell>
      <TableCell>
        {dateSubmitted ? dateSubmitted.format("DD/MM/YYYY") : null}
      </TableCell>
      <TableCell className="text-right">
        {pounds.format(proposal.finance_amount)}
      </TableCell>
      <TableCell>{updatedAt.format("DD/MM/YYYY")}</TableCell>
      <TableCell className="action-cell fixed-cell">
        <Menu
          theme={theme}
          options={getProposalRowOptions(proposal)}
          handleOptionClick={handleOptionClick}
          render={(cb) => (
            <Button
              defaultBtn
              theme={theme}
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            ></Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default DashboardTableRow;
