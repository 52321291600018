import ProposalFieldArraysContext from "./proposal-form.context";
import { ProposalFieldArrayControls } from "./proposal-form.type";

const ProposalFieldArraysProvider = ({
  value,
  children,
}: {
  value: ProposalFieldArrayControls;
  children: React.ReactNode;
}) => {
  return (
    <ProposalFieldArraysContext.Provider value={value}>
      {children}
    </ProposalFieldArraysContext.Provider>
  );
};

export default ProposalFieldArraysProvider;
