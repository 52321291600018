import {
  ButtonGroup,
  LayoutWithSideNavComponent,
  PageHeader,
  Stack,
  RangeFilter,
} from "@components";
import { Widget } from "@components/dashboard";
import { useTheme } from "@mui/material";
import { Box, Grid, Select, Typography } from "@ntpkunity/controls";
import TopPerformingBroker from "../../public/assets/images/top-performing-broker.png";
import ProposalAgingGraph from "../../public/assets/images/proposal-aging.png";
import RevenueGraph from "../../public/assets/images/revenue-widget.png";
import { PageContent } from "./dashboard-page.style";
import { OrderStage, SortOrder, WorkQueueTabs } from "@helpers/enum";
import { useForm, FormProvider } from "react-hook-form";
import {
  DashboardAnalyticsResponse,
  DashboardForm,
  TProposalSearchParams,
  TSortParams,
} from "@_types";
import {
  DASHBOARD_FORM_DEFAULT_VALUES,
  PROPOSAL_STATUSES_BY_CATEGORY,
  QUEUE_TO_STATUS_MAP,
} from "@helpers/const";
import { ProposalPieChart } from "@components/charts/pie-chart.component";
import { useGetDashboardProposals } from "@hooks/queries";
import { useMemo, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import {
  convertIsoDateStrToddmmyyyy,
  convertIsoDateStrToddmmyyyyhms,
  deleteNDays,
  reduceDataForProposalWidget,
  reduceDataForTotalProposalWidget,
} from "@helpers/utils";
import { DashboardTable } from "@components/dashboard/dashboard-table/dashboard-table.component";

const sortInitialState: TSortParams<keyof TProposalSearchParams> = {
  sort_by: "updated_at",
  sort_order: SortOrder.DESC,
};

const searchInitialState: TProposalSearchParams = {
  name: "",
  product_type: "",
  finance_amount: undefined,
  updated_at: "",
  order_stage: OrderStage.APPLICATION,
  customer_name: "",
  date_submitted: "",
  introducer_name: "",
  lender_name: "",
  updated_at_from: convertIsoDateStrToddmmyyyyhms(
    deleteNDays(30).toISOString()
  ),
  updated_at_to: convertIsoDateStrToddmmyyyyhms(new Date().toISOString()),
  statuses: QUEUE_TO_STATUS_MAP[WorkQueueTabs.DASHBOARD_PROPOSALS],
};

export const DashboardPage = () => {
  const theme = useTheme();
  const [searchParams, setSearchParams] =
    useState<TProposalSearchParams>(searchInitialState);
  const [tableSearchParams, setTableSearchParams] =
    useState<TProposalSearchParams>();
  const [totalSearchParams, setTotalSearchParams] =
    useState<TProposalSearchParams>(searchInitialState);
  const [sortParams, setSortParams] =
    useState<TSortParams<keyof TProposalSearchParams>>(sortInitialState);
  const [debouncedSearchParams] = useDebounce(
    useMemo(() => {
      return {
        ...searchParams,
        updated_at: convertIsoDateStrToddmmyyyy(searchParams.updated_at),
        date_submitted: convertIsoDateStrToddmmyyyy(
          searchParams.date_submitted
        ),
      };
    }, [searchParams]),
    500
  );

  const [debouncedTotalSearchParams] = useDebounce(
    useMemo(() => {
      return {
        ...totalSearchParams,
        updated_at: convertIsoDateStrToddmmyyyy(totalSearchParams.updated_at),
        date_submitted: convertIsoDateStrToddmmyyyy(
          totalSearchParams.date_submitted
        ),
      };
    }, [totalSearchParams]),
    500
  );

  const totalPieChartRef = useRef<any>();
  const activePieChartRef = useRef<any>();
  const { data: proposals } = useGetDashboardProposals<
    TProposalSearchParams,
    DashboardAnalyticsResponse
  >(debouncedSearchParams, sortParams);

  const {
    isLoading: isTotalProposalsLoading,
    isError: isTotalProposalsError,
    data: totalProposalsProposals,
  } = useGetDashboardProposals<
    TProposalSearchParams,
    DashboardAnalyticsResponse
  >(debouncedTotalSearchParams, sortParams);

  const formMethods = useForm<DashboardForm>({
    defaultValues: DASHBOARD_FORM_DEFAULT_VALUES,
  });

  const activeProposalWidgetData = useMemo(
    () => reduceDataForProposalWidget(theme, proposals),
    [proposals]
  );

  const totalProposalWidgetData = useMemo(
    () => reduceDataForTotalProposalWidget(theme, totalProposalsProposals),
    [totalProposalsProposals]
  );
  return (
    <LayoutWithSideNavComponent theme={theme}>
      <FormProvider {...formMethods}>
        <PageHeader title="Dashboard" />
        <PageContent theme={theme} className="page-content" pt={3}>
          <Grid theme={theme} container item spacing={2} alignItems={"stretch"}>
            <Grid theme={theme} item xs={12} md={6} lg={4}>
              <Widget
                control="active-proposal"
                title="Active Proposals"
                expand={true}
                actionArea={
                  <RangeFilter
                    name={"rangeFilter"}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                  />
                }
              >
                <Box
                  theme={theme}
                  className="graph-ph"
                  textAlign={"center"}
                  height={360}
                >
                  <ProposalPieChart
                    data={activeProposalWidgetData}
                    radius={120}
                    donutWidth={15}
                    centerText={"Total Active Proposals"}
                    ref={activePieChartRef}
                    onClick={(props, _index, activeIndex) => {
                      if (activeIndex) {
                        totalPieChartRef?.current?.updateIndex(null);
                        setTableSearchParams({
                          ...tableSearchParams,
                          statuses: [props.status],
                        });
                      } else {
                        setTableSearchParams({
                          ...tableSearchParams,
                          statuses:
                            QUEUE_TO_STATUS_MAP[
                              WorkQueueTabs.DASHBOARD_PROPOSALS
                            ],
                        });
                      }
                    }}
                  />
                </Box>
              </Widget>
            </Grid>
            <Grid theme={theme} item xs={12} md={6} lg={4}>
              <Widget
                control="total-proposal"
                title={"Proposals"}
                actionArea={
                  <RangeFilter
                    name={"totalRangeFilter"}
                    searchParams={totalSearchParams}
                    setSearchParams={setTotalSearchParams}
                  />
                }
              >
                <Box
                  theme={theme}
                  className="graph-ph"
                  textAlign={"center"}
                  height={360}
                >
                  <ProposalPieChart
                    data={totalProposalWidgetData}
                    radius={120}
                    donutWidth={15}
                    centerText={"Total Proposals"}
                    ref={totalPieChartRef}
                    onClick={(props, _index, activeIndex) => {
                      if (activeIndex) {
                        activePieChartRef?.current?.updateIndex(null);
                        setTableSearchParams({
                          ...tableSearchParams,
                          statuses: PROPOSAL_STATUSES_BY_CATEGORY[props.status],
                        });
                      } else {
                        setTableSearchParams({
                          ...tableSearchParams,
                          statuses:
                            QUEUE_TO_STATUS_MAP[
                              WorkQueueTabs.DASHBOARD_PROPOSALS
                            ],
                        });
                      }
                    }}
                  />
                </Box>
              </Widget>
            </Grid>
            <Grid theme={theme} item xs={12} md={6} lg={4}>
              <Widget
                control="proposal-aging"
                title={"Proposal Aging"}
                actionArea={
                  <>
                    <Select
                      disablePortal={false}
                      theme={theme}
                      items={[
                        { text: "Monthly", value: "1" },
                        { text: "Yearly", value: "2" },
                      ]}
                      value={"1"}
                    />
                  </>
                }
              >
                <Box theme={theme} className="graph-text">
                  <Typography
                    theme={theme}
                    component={"h2"}
                    variant="h2"
                    lineHeight={"32px"}
                  >
                    852
                  </Typography>
                  <Typography
                    theme={theme}
                    component={"p"}
                    variant="body1"
                    className="text-muted"
                    mt={1}
                  >
                    Total number of Proposals
                  </Typography>
                </Box>
                <Box
                  theme={theme}
                  className="graph-ph"
                  textAlign={"center"}
                  mt={3}
                >
                  <img src={ProposalAgingGraph} alt="" className="mw-100" />
                </Box>
              </Widget>
            </Grid>

            <Grid theme={theme} item xs={12} md={12} lg={8}>
              <Widget
                control="total-revenue"
                title={"Total Revenue"}
                actionArea={
                  <>
                    <ButtonGroup
                      items={[
                        {
                          title: "Daily",
                          onClick: () => {},
                          selected: false,
                        },
                        {
                          title: "Weekly",
                          onClick: () => {},
                          selected: true,
                        },
                        {
                          title: "Monthly",
                          onClick: () => {},
                          selected: false,
                        },
                        {
                          title: "Yearly",
                          onClick: () => {},
                          selected: false,
                        },
                      ]}
                    />
                  </>
                }
              >
                <Box theme={theme} className="graph-ph" textAlign={"center"}>
                  <img src={RevenueGraph} alt="" className="mw-100" />
                </Box>
              </Widget>
            </Grid>
            <Grid theme={theme} item xs={12} md={6} lg={4}>
              <Widget loading />
            </Grid>
            <Grid theme={theme} item xs={12}>
              <Stack
                paddingMd={3}
                paddingXs={3}
                className="stack-mobile"
                title="Proposals Table"
              >
                <Box theme={theme} mt={{ xs: 3, md: 0 }}>
                  <DashboardTable
                    searchParams={tableSearchParams}
                    setSearchParams={setTableSearchParams}
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </PageContent>
      </FormProvider>
    </LayoutWithSideNavComponent>
  );
};
