import { useTheme } from "@mui/material";
import { Stack } from "@components";
import { DirectorTable } from "./director-table/director-table.component";
import { Box } from "@ntpkunity/controls";

export const DirectorDetails = () => {
  const theme = useTheme();

  return (
    <>
      <Stack paddingXs={3} paddingMd={3} title="Directors Details">
        <Box theme={theme} mt={2}>
          <DirectorTable />
        </Box>
      </Stack>
    </>
  );
};
