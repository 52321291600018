import { Box, Checkbox, Collapse, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import {
  VATTreatments,
  nonDecimalFieldType,
  vatDeferralType,
} from "@flexCommon/constants";
import {
  AmortizationMethod,
  Currency,
  FieldType,
  FinanceType,
  RateTypes,
  SolveFor,
  VatTreatment,
} from "@flexCommon/enums";
import { getDisabledProp, getHiddenProp } from "@helpers/common";
import { IFieldProp } from "@interfaces/configuration.interface";
import { setFinancialRequestObj } from "@store/actions/financialCalculation.actions";
import { IReducerFinancialCalculation } from "@store/reducers/financialCalculation.reducer";
import {
  CustomDropdown,
  Divider,
  Dropdown,
  TextBox,
  ToggleBtn,
} from "@components/quotation/shared/controls";
import { CalculationWrap } from "@components/quotation/configuration.style";

export const CombinedControl = styled("div")(({ theme }) => ({
  display: "flex",
  ".u-form-group": {
    marginBottom: 0,
    marginRight: 10,
    width: "50%",
    "&:last-child": {
      width: "50%",
      marginRight: 0,
    },
  },
}));

type IVAT = {
  gridSize: number;
  storeState: IReducerFinancialCalculation;
  financeType: string;
  fieldProps: IFieldProp[];
  visible: boolean;
};
export const VAT = ({
  gridSize,
  storeState,
  financeType,
  fieldProps,
  visible = true,
}: IVAT) => {
  const getVATDeferred = () => {
    if (getHiddenProp(fieldProps, FieldType.VATDeferred)) {
      return (
        <TextBox
          // theme={theme}
          testid="txtNumber"
          label="Number"
          // type="number"
          fullWidth
          value={
            storeState.RequestObj.VAT.PaymentNumber === 0 ||
            storeState.RequestObj.VAT.PaymentNumber > 0
              ? storeState.RequestObj.VAT.PaymentNumber
              : ""
          }
          masking
          decimalScale={0}
          disabled={getDisabledProp(fieldProps, FieldType.VATPaymentNumber)}
          hidden={getHiddenProp(fieldProps, FieldType.VATPaymentNumber)}
          onChange={(e) =>
            dispatch(
              setFinancialRequestObj({
                ...storeState.RequestObj,
                VAT: {
                  ...storeState.RequestObj.VAT,
                  PaymentNumber: e.target.value
                    ? Number(e.target.value)
                    : e.target.value,
                },
              })
            )
          }
        />
      );
    } else
      return (
        <CombinedControl>
          <TextBox
            // theme={theme}
            testid="txtNumber"
            label="Number"
            // type="number"
            fullWidth
            value={
              storeState.RequestObj.VAT.PaymentNumber === 0 ||
              storeState.RequestObj.VAT.PaymentNumber > 0
                ? storeState.RequestObj.VAT.PaymentNumber
                : ""
            }
            masking
            decimalScale={0}
            disabled={getDisabledProp(fieldProps, FieldType.VATPaymentNumber)}
            placeHolder={
              nonDecimalFieldType.includes(FieldType.VATPaymentNumber)
                ? "0"
                : "0.00"
            }
            hidden={getHiddenProp(fieldProps, FieldType.VATPaymentNumber)}
            onChange={(e) => {
              dispatch(
                setFinancialRequestObj({
                  ...storeState.RequestObj,
                  VAT: {
                    ...storeState.RequestObj.VAT,
                    PaymentNumber: e.target.value
                      ? Number(e.target.value)
                      : e.target.value,
                  },
                })
              );
            }}
          />
          <Dropdown
            items={vatDeferralType}
            testid="txtDeferred"
            label={"Deferred"}
            disabled={
              storeState.CalculationConfiguration?.AmortizationMethod ==
                AmortizationMethod.Annuity360 ||
              storeState.RequestObj.RateType == RateTypes.FlatRate ||
              storeState.SolveFor != SolveFor.PeriodicPayment ||
              getDisabledProp(fieldProps, FieldType.VATDeferred)
            }
            hidden={getHiddenProp(fieldProps, FieldType.VATDeferred)}
            value={storeState.RequestObj.VAT.type}
            onChange={(e) => {
              dispatch(
                setFinancialRequestObj({
                  ...storeState.RequestObj,
                  VAT: {
                    ...storeState.RequestObj.VAT,
                    type: e.target.value,
                  },
                })
              );
            }}
          />
        </CombinedControl>
      );
  };
  var dispatch = useDispatch();
  if (!visible) return <></>;
  return (
    <>
      <CalculationWrap className="calculation vat-treatment">
        <Box className="create-quotation" sx={{ pb: 2 }}>
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
              <label style={{ fontWeight: "bold" }}>VAT Treatment</label>
            </Grid>
            <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
              <CustomDropdown className="dropDown-with-label with-checkbox">
                {storeState.RequestObj.VATAmountAutoCalculateApplicable &&
                !getHiddenProp(
                  fieldProps,
                  FieldType.VATAmountAutoCalculateApplicable
                ) ? (
                  <>
                    <Typography
                      variant="body2"
                      component="span"
                      children="Auto Calculate:"
                      className="main-title"
                    />
                    <Checkbox
                      disabled={getDisabledProp(
                        fieldProps,
                        FieldType.VATAmountAutoCalculateApplicable
                      )}
                      checked={storeState.RequestObj.IsAutoCalculated}
                      onChange={(e) => {
                        if (e.target.checked && storeState.RequestObj.Tax > 0) {
                          dispatch(
                            setFinancialRequestObj({
                              ...storeState.RequestObj,
                              IsAutoCalculated: e.target.checked,
                              VAT: {
                                ...storeState.RequestObj.VAT,
                                Amount:
                                  (Number(storeState.RequestObj.Tax) / 100) *
                                  Number(storeState.RequestObj.AssetCost),
                              },
                            })
                          );
                        } else {
                          dispatch(
                            setFinancialRequestObj({
                              ...storeState.RequestObj,
                              IsAutoCalculated: e.target.checked,
                            })
                          );
                        }
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
                {/* <Checkbox checked={storeState.RequestObj.IsAutoCalculated} onChange={(e)=>{
                  if(e.target.checked && storeState.RequestObj.Tax > 0){
                    dispatch(
                      setFinancialRequestObj({
                        ...storeState.RequestObj,
                        IsAutoCalculated: e.target.checked,
                        VAT:{
                          ...storeState.RequestObj.VAT,
                          Amount: (Number(storeState.RequestObj.Tax) / 100) * Number(storeState.RequestObj.AssetCost)
                        }
                      })
                    )
                  } else {
                    dispatch(
                      setFinancialRequestObj({
                        ...storeState.RequestObj,
                        IsAutoCalculated: e.target.checked,
                      })
                    )
                  }
                }} /> */}
              </CustomDropdown>
            </Grid>
          </Grid>
        </Box>
        <Grid item xs={12}>
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item sm={12} xs={12}>
              <Box className="val-50">
                <ToggleBtn
                  items={VATTreatments}
                  value={storeState.RequestObj.VAT.Treatment}
                  onChange={(e) =>
                    dispatch(
                      setFinancialRequestObj({
                        ...storeState.RequestObj,
                        VAT: {
                          ...storeState.RequestObj.VAT,
                          Treatment: e,
                        },
                      })
                    )
                  }
                />
              </Box>
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextBox
                // theme={theme}
                testid="txtVATAmount"
                label="VAT Amount"
                startAdornment={Currency.GBP}
                masking
                fullWidth
                value={
                  financeType === FinanceType.FinanceLease ||
                  financeType === FinanceType.Loan
                    ? storeState.RequestObj.VAT.Amount !== 0 ||
                      storeState.RequestObj.VAT.Amount > 0
                      ? storeState.RequestObj.VAT.Amount
                      : ""
                    : storeState.RequestObj.VAT.Amount === 0 ||
                      storeState.RequestObj.VAT.Amount > 0
                    ? storeState.RequestObj.VAT.Amount
                    : ""
                }
                placeHolder={
                  nonDecimalFieldType.includes("VATAmount") ? "0" : "0.00"
                }
                disabled={getDisabledProp(fieldProps, FieldType.VATAmount)}
                hidden={getHiddenProp(fieldProps, FieldType.VATAmount)}
                onChange={(e) =>
                  dispatch(
                    setFinancialRequestObj({
                      ...storeState.RequestObj,
                      VAT: {
                        ...storeState.RequestObj.VAT,
                        Amount:
                          financeType === FinanceType.FinanceLease ||
                          financeType === FinanceType.Loan
                            ? e.target.value
                            : e.target.value
                            ? Number(e.target.value)
                            : e.target.value,
                      },
                    })
                  )
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <Collapse
                in={
                  storeState.RequestObj.VAT.Treatment !==
                  VatTreatment.VATPaidUpfront
                }
              >
                {getVATDeferred()}
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </CalculationWrap>
    </>
  );
};
