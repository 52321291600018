import "react";
import {
  AddressLayout,
  Box,
  Button,
  Dialog,
  FileUpload,
  FileUploadPreview,
  Grid,
  Input,
  Select,
  Typography,
} from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { UploadAdditionalDocumentsWrap } from "./document-dialog.style";

const UploadAdditionalDocuments = ({ open, onClose }) => {
  const theme = useTheme();
  return (
    <Dialog
      theme={theme}
      size={"sm"}
      onCloseDialog={onClose}
      open={open}
      title="Upload Additional Documents"
      customFooter={
        <>
          <Button
            theme={theme}
            type="button"
            secondary
            text="Cancel"
            onClick={onClose}
          />
          <Button
            theme={theme}
            type="button"
            primary
            text="Save Document"
            onClick={() => {}}
          />
        </>
      }
    >
      <UploadAdditionalDocumentsWrap
        theme={theme}
        className="document-dialog-wrap"
      >
        <Grid theme={theme} container item columnSpacing={2} rowSpacing={3}>
          <Grid theme={theme} item xs={12}>
            <Input
              theme={theme}
              type="text"
              label="Supplier Invoice"
              fullWidth
              placeholder="Type here..."
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Select
              theme={theme}
              label="Document Type"
              fullWidth
              items={[
                {
                  text: "Invoice 1",
                  value: "0",
                },
                {
                  text: "Invoice 2",
                  value: "1",
                },
              ]}
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Input
              theme={theme}
              type="text"
              label="Invoice Number"
              fullWidth
              placeholder="Type here..."
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Box theme={theme}>
              <FileUpload
                theme={theme}
                onChange={() => {}}
                inlinePreview={false}
                onDrop={() => {}}
                hoverLabel={
                  <>
                    Drag and drop file, or{" "}
                    <span className="text-primary">browse</span>
                  </>
                }
                allowMultiple
              />
              <FileUploadPreview
                theme={theme}
                files={[
                  {
                    key: "image-1",
                    file: {
                      location:
                        "https://unitydmsbucket.s3.amazonaws.com/990705wheels.jpg",
                      key: "990705wheels.jpg",
                      size: 150,
                      name: "Sample File Name 1.jpg",
                      type: "image",
                    },
                    viewIcon: true,
                    preview: "fileIcon",
                    isUploading: false,
                    error: false,
                    uploadingLabel: "",
                    uploadingProgress: undefined,
                    onRemoveFile: () => {},
                    onRetryForError: () => {},
                  },
                  {
                    key: "image-1",
                    file: {
                      location:
                        "https://unitydmsbucket.s3.amazonaws.com/990705wheels.jpg",
                      key: "990705wheels.jpg",
                      size: 150,
                      name: "Sample File Name 1.jpg",
                      type: "image",
                    },
                    viewIcon: true,
                    preview: "fileIcon",
                    isUploading: false,
                    error: false,
                    uploadingLabel: "",
                    uploadingProgress: undefined,
                    onRemoveFile: () => {},
                    onRetryForError: () => {},
                  },
                ]}
              />
              <Box theme={theme} mt={2}>
                <Typography
                  theme={theme}
                  variant="caption"
                  component={"small"}
                  display={"block"}
                  className="text-muted"
                  textAlign={"center"}
                >
                  You can upload multiple files. Files can be up to 30MB. You
                  can upload jpg, jpeg, png and pdf file formats.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </UploadAdditionalDocumentsWrap>
    </Dialog>
  );
};

export default UploadAdditionalDocuments;
