import { useState } from "react";
import { Button, Icon, Menu } from "@ntpkunity/controls";
import { PageHeader } from "@components";
import { useTheme } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@store/reducers";
import { setNotification } from "@store/actions/financialCalculation.actions";
import { SaveConfigurationDms, updateQuotation } from "@services/dms.service";
import { useParams, useNavigate } from "react-router-dom";
import {
  DocumentTemplateType,
  QuoteDataPlaceholderOptions,
  TableRowOptions,
  WorkQueueTabs,
} from "@helpers/enum";
import {
  useConvertQuotationToProposal,
  useDownloadDocs,
} from "@hooks/mutations";
import { APP_ROUTES } from "@helpers";
import { useQueryClient } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { getDataForQuoteDownload } from "@helpers/common";

const QuotationPageHeader = () => {
  const params = useParams<{ quotationId?: string }>();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const storeState = useSelector(
    (state: RootState) => state.reducerFinancialCalculation
  );
  const [callInProgress, setCallInProgress] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mutateAsync } = useConvertQuotationToProposal();
  const { mutate: downloadDocs, isLoading: isLoadingDownloadDocs } =
    useDownloadDocs();

  const quotationId = params.quotationId;
  const isEditMode = !!quotationId;

  const validateQuotationDetails = () => {
    const quotationDetails = storeState.quotationDetails;
    const errorValues: string[] = [];
    if (!quotationDetails.name.trim()) {
      errorValues.push("Quotation name");
    }
    if (!quotationDetails.clientType) {
      errorValues.push("Client Type");
    }
    if (!quotationDetails.brokerName) {
      errorValues.push("Broker");
    }
    if (!quotationDetails.asset.type) {
      errorValues.push("Asset Type");
    }

    if (errorValues.length) {
      let errorMsg = "";
      errorValues.forEach((value, index) => {
        errorMsg = errorMsg + (index === 0 ? value : ` and ${value} `);
      });
      errorMsg += " cannot be empty";
      dispatch(setNotification({ message: errorMsg, visible: true }));
      return false;
    }
    return true;
  };

  const getServicePromise = () => {
    return isEditMode
      ? updateQuotation(storeState, quotationId)
      : SaveConfigurationDms(storeState);
  };

  const handleSaveQuotation = () => {
    if (validateQuotationDetails()) {
      setCallInProgress(true);

      getServicePromise()
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.QUOTATION_BY_VENDOR],
          });
          dispatch(
            setNotification({
              message: "Quotation Saved",
              visible: true,
            })
          );
          navigate(`${APP_ROUTES.WORKQUEUE}/${WorkQueueTabs.QUOTATIONS}`);
        })
        .catch((ex) => {
          dispatch(
            setNotification({
              message: "Lane Subscription Required.",
              visible: true,
            })
          );
          throw ex;
        })
        .finally(() => {
          setCallInProgress(false);
        });
    }
  };

  const convertToProposal = async (identifier: string) => {
    dispatch(
      setNotification({ message: "Converting to Proposal", visible: true })
    );

    try {
      await mutateAsync(identifier);
      dispatch(
        setNotification({ message: "Converted to Proposal", visible: true })
      );
      navigate(`${APP_ROUTES.PROPOSAL}/${identifier}`);
    } catch (err) {
      dispatch(
        setNotification({
          message: "Error Occurred whlie converting to Proposal",
          visible: true,
        })
      );
    }
  };

  const handleOptionClick = (_, key: TableRowOptions, value: string) => {
    switch (key) {
      case TableRowOptions.CONVERT_TO_PROPOSAL:
        convertToProposal(value);
        break;
      case TableRowOptions.DOWNLOAD:
        downloadDocs({
          data_placeholders: getDataForQuoteDownload({
            from: QuoteDataPlaceholderOptions.CONTEXT,
            data: storeState,
          }),
          template_type: DocumentTemplateType.QUOTATION,
          template_name: "My_Quotation_Template_Word_2.docx",
          generate_base64: false,
          is_bookmark: false,
        });
        break;
    }
  };

  return (
    <PageHeader
      title={false}
      hasInput
      inputPlaceholder="Enter Quotation Name..."
      shouldUseForm={false}
      actionArea={
        <>
          <Menu
            theme={theme}
            options={[
              {
                optionText: "Convert to proposal",
                optionkey: TableRowOptions.CONVERT_TO_PROPOSAL,
                optionValue: quotationId,
                disabled: !isEditMode,
              },
              {
                optionText: "Download Quotation",
                optionkey: TableRowOptions.DOWNLOAD,
                optionValue: quotationId,
                disabled: !isEditMode,
              },
            ]}
            handleOptionClick={handleOptionClick}
            render={(cb) => (
              <Button
                secondary
                theme={theme}
                iconText={<Icon name="MoreIcon" />}
                onClick={cb}
              ></Button>
            )}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
          <Button
            theme={theme}
            type="button"
            primary
            disabled={callInProgress}
            text="Save Quotation"
            onClick={handleSaveQuotation}
          />
        </>
      }
    />
  );
};

export default QuotationPageHeader;
