import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Theme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import ChevronDown from "@components/quotation/shared/controls/chevron-down";

const CustomizedSelectWrapper = styled("div", {
  // shouldForwardProp: props => isPropValid(props),
})<Partial<IDropdown>>(({ theme }) => ({
  "&.u-form-group": {
    marginBottom: 24,
    "&:last-child": {
      marginBottom: 0,
    },
    ".u-form-control": {
      height: 48,
      color: "rgba(0,0,0,0.87)",
      "&.u-select": {
        ".MuiInputBase-input": {
          padding: "12px 40px 12px 16px",
        },
        ".MuiSelect-icon": {
          position: "absolute",
          right: 12,
          top: "auto",
          cursor: "pointer",
          userSelect: "none",
          PointerEvent: "none",
          "&.Mui-disabled": {
            opacity: 0.5,
          },
        },
      },
      "&:hover": {
        fieldset: {
          borderColor: theme.palette.primary.main,
        },
      },
      "&.Mui-focused": {
        fieldset: {
          borderColor: theme.palette.primary.main,
        },
      },
      "&.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.38)",
        ".MuiInputBase-input": {
          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.38)",
            textFillColor: "rgba(0, 0, 0, 0.38)",
            cursor: "not-allowed",
          },
        },
        fieldset: {
          borderColor: "rgba(0, 0, 0, 0.12)",
        },
        "+": {
          ".u-input-label": {
            color: "rgba(0, 0, 0, 0.38)",
            "+": {
              ".u-help-text": {
                color: "rgba(0, 0, 0, 0.38)",
              },
            },
          },
        },
      },
      "&.MuiInputBase-adornedStart": {
        ".MuiInputBase-input": {
          padding: "12px 40px 12px 0px",
        },
        fieldset: {
          legend: {
            maxWidth: 0,
          },
        },
        "&.Mui-focused": {
          fieldset: {
            legend: {
              maxWidth: "100%",
            },
          },
        },
        "~": {
          ".u-input-label": {
            transform: "translate(38px, 12px) scale(1)",
            "&.Mui-focused": {
              transform: "translate(14px, -9px) scale(0.85)",
            },
            "&.MuiFormLabel-filled": {
              transform: "translate(14px, -9px) scale(0.85)",
            },
          },
        },
      },
      "&.MuiInputBase-adornedEnd": {
        ".MuiInputBase-input": {
          padding: "12px 0px 12px 16px",
        },
      },
      "&.Mui-error": {
        fieldset: {
          borderWidth: 2,
          borderColor: theme.palette.error.main,
        },
        "&:hover": {
          fieldset: {
            borderColor: theme.palette.error.main + "!important",
          },
        },
        "&.Mui-focused": {
          fieldset: {
            borderColor: theme.palette.error.main,
          },
        },
        "~": {
          ".u-input-label": {
            color: theme.palette.error.main,
            "&.Mui-focused": {
              color: theme.palette.error.main,
            },
            "&.MuiFormLabel-filled": {
              color: theme.palette.error.main,
            },
          },
        },
      },
    },
    ".u-input-label": {
      color: "rgba(0,0,0,0.87)",
      fontFamily: theme.typography.fontFamily,
      lineHeight: theme.typography.body1.lineHeight,
      transform: "translate(14px, 12px) scale(1)",
      padding: "0 5px 0 0px",
      backgroundColor: theme.palette.common.white,
      "&.Mui-focused": {
        color: theme.palette.grey[600],
        transform: "translate(14px, -9px) scale(0.85)",
        maxWidth: "100%",
      },
      "&.MuiFormLabel-filled": {
        color: theme.palette.grey[600],
        transform: "translate(14px, -9px) scale(0.85)",
        maxWidth: "100%",
      },
    },
    ".u-help-text": {
      marginLeft: 15,
      lineHeight: theme.typography.caption.lineHeight,
    },
    ".u-error-text": {
      color: theme.palette.error.main,
      marginLeft: 15,
      lineHeight: theme.typography.caption.lineHeight,
    },
    ".text-capitalize": {
      textTransform: "capitalize",
    },
    ".text-uppercase": {
      textTransform: "uppercase",
    },
    ".MuiPopover-root": {
      position: "absolute",
      top: -15,
      left: -15,
      width: "100%",
      ".MuiPaper-root": {
        position: "relative",
        top: "64px !important",
        left: "16px !important",
      },
    },
  },
}));

const MenuSx = {
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: "#EBECF2",
  boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.16)",
  padding: "0",
  color: "#515466",
  scrollbarWidth: "thin",
  maxHeight: 250,
  ".MuiMenu-list": {
    padding: "8px 0",
  },
  "&::-webkit-scrollbar": {
    width: 7,
    height: 7,
  },
  "&::-webkit-scrollbar-track": {
    padding: "0 1px",
    backgroundColor: "#f0f0f0",
  },
  "&::-webkit-scrollbar-thumb": {
    transition: "all 0.3s",
    backgroundColor: "#cdcdcd",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#a6a6a6",
  },
  ".u-dropdown-item": {
    padding: "12px 24px 12px 16px",
    borderRadius: "8px",
    whiteSpace: "normal",
    margin: "0 8px",
    "&:hover": {
      backgroundColor: "#F8F8F8",
      color: "#12131A",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#F8F8F8",
      color: "#12131A",
    },
    "&.Mui-selected": {
      backgroundColor: "rgba(0, 36, 156, 0.05)",
      "&:hover": {
        backgroundColor: "rgba(0, 36, 156, 0.05)",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(0, 36, 156, 0.05)",
      },
    },
    ".MuiTouchRipple-root": {
      display: "none",
    },
  },
};

export type IDropDownItem = {
  text: string;
  value: string | number | boolean;
};
export type IDropdown = {
  id?: string;
  label: string;
  value: string | number | boolean;
  items: IDropDownItem[] | undefined;
  onChange?(e: any): void;
  helperText?: string;
  testid?: string;
  disablePortal?: boolean;
  capitalizeLabel?: boolean;
  uppercaseLabel?: boolean;
  hidden?: boolean;
  disabled?: boolean;
};
export const Dropdown = ({
  label,
  id = "select",
  value,
  items = [],
  disabled = false,
  hidden = false,
  onChange,
  helperText = "",
  disablePortal = true,
  testid = "",
  capitalizeLabel,
  uppercaseLabel,
}: IDropdown) => {
  if (hidden) return <></>;
  else
    return (
      <CustomizedSelectWrapper className="u-form-group">
        <FormControl fullWidth disabled={disabled}>
          <InputLabel
            className={clsx({
              "u-input-label": true,
              "text-capitalize": capitalizeLabel,
              "text-uppercase": uppercaseLabel,
            })}
            id={"demo-simple-select-label"}
          >
            {label}
          </InputLabel>
          <Select
            className="u-form-control u-select"
            MenuProps={{
              // disablePortal,
              PaperProps: {
                sx: MenuSx,
              },
            }}
            labelId="demo-simple-select-label"
            id={id}
            data-testid={testid}
            value={value}
            label={label}
            onChange={onChange}
            size="small"
            IconComponent={ChevronDown}
          >
            {items.map((item, index) => (
              <MenuItem
                className="u-dropdown-item"
                key={index}
                value={item.value as any}
              >
                {item.text}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      </CustomizedSelectWrapper>
    );
};
