import { Button, Icon, Menu } from "@ntpkunity/controls";
import { TableCell, TableRow, useTheme } from "@mui/material";

const AssetTableRow = () => {
  const theme = useTheme();

  return (
    <>
      <TableRow className="child-tr">
        <TableCell>00</TableCell>
        <TableCell>Sample Supplier Name</TableCell>
        <TableCell>Sample Asset Type</TableCell>
        <TableCell className="text-right">£ 00,000.00</TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            options={[
              {
                optionText: "View/Edit",
                optionkey: "view-edit",
                optionValue: "view-edit",
              },
              {
                optionText: "Delete",
                optionkey: "delete",
                optionValue: "delete",
              },
            ]}
            handleOptionClick={() => {}}
            render={(cb) => (
              <Button
                defaultBtn
                theme={theme}
                iconText={<Icon name="MoreIcon" />}
                onClick={cb}
              ></Button>
            )}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow className="child-tr">
        <TableCell>00</TableCell>
        <TableCell>Sample Supplier Name</TableCell>
        <TableCell>Sample Asset Type</TableCell>
        <TableCell className="text-right">£ 00,000.00</TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            options={[
              {
                optionText: "View/Edit",
                optionkey: "view-edit",
                optionValue: "view-edit",
              },
              {
                optionText: "Delete",
                optionkey: "delete",
                optionValue: "delete",
              },
            ]}
            handleOptionClick={() => {}}
            render={(cb) => (
              <Button
                defaultBtn
                theme={theme}
                iconText={<Icon name="MoreIcon" />}
                onClick={cb}
              ></Button>
            )}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow className="child-tr">
        <TableCell>00</TableCell>
        <TableCell>Sample Supplier Name</TableCell>
        <TableCell>Sample Asset Type</TableCell>
        <TableCell className="text-right">£ 00,000.00</TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            options={[
              {
                optionText: "View/Edit",
                optionkey: "view-edit",
                optionValue: "view-edit",
              },
              {
                optionText: "Delete",
                optionkey: "delete",
                optionValue: "delete",
              },
            ]}
            handleOptionClick={() => {}}
            render={(cb) => (
              <Button
                defaultBtn
                theme={theme}
                iconText={<Icon name="MoreIcon" />}
                onClick={cb}
              ></Button>
            )}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow className="child-tr">
        <TableCell>00</TableCell>
        <TableCell>Sample Supplier Name</TableCell>
        <TableCell>Sample Asset Type</TableCell>
        <TableCell className="text-right">£ 00,000.00</TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            options={[
              {
                optionText: "View/Edit",
                optionkey: "view-edit",
                optionValue: "view-edit",
              },
              {
                optionText: "Delete",
                optionkey: "delete",
                optionValue: "delete",
              },
            ]}
            handleOptionClick={() => {}}
            render={(cb) => (
              <Button
                defaultBtn
                theme={theme}
                iconText={<Icon name="MoreIcon" />}
                onClick={cb}
              ></Button>
            )}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow className="child-tr">
        <TableCell>00</TableCell>
        <TableCell>Sample Supplier Name</TableCell>
        <TableCell>Sample Asset Type</TableCell>
        <TableCell className="text-right">£ 00,000.00</TableCell>
        <TableCell className="action-cell fixed-cell">
          <Menu
            theme={theme}
            options={[
              {
                optionText: "View/Edit",
                optionkey: "view-edit",
                optionValue: "view-edit",
              },
              {
                optionText: "Delete",
                optionkey: "delete",
                optionValue: "delete",
              },
            ]}
            handleOptionClick={() => {}}
            render={(cb) => (
              <Button
                defaultBtn
                theme={theme}
                iconText={<Icon name="MoreIcon" />}
                onClick={cb}
              ></Button>
            )}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default AssetTableRow;
