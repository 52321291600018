import { useTheme } from "@mui/material";
import { Stack } from "@components/stack";
import { Box, Button, Icon, Menu } from "@ntpkunity/controls";
import UploadPackDialog from "./upload-pack.component";
import { useState } from "react";
import { DocumentList } from "@components/send-for-payout";
import { PROPOSAL_SUMMARY_STATUS } from "@helpers/const";

export const SummaryDocument = () => {
  const theme = useTheme();
  const [openUploadPackDialog, setUploadPackDialog] = useState(false);
  return (
    <>
      <Stack
        paddingMd={3}
        paddingXs={3}
        title="Documents"
        actionArea={
          <Menu
            theme={theme}
            options={[
              {
                optionText: "Download All",
                optionkey: "download-all",
                optionValue: "download-all",
              },
              {
                optionText: "Download Wet Signature Pack",
                optionkey: "download-wet-signature-pack",
                optionValue: "download-wet-signature-pack",
              },
              {
                optionText: "Upload Wet Signature Pack",
                optionkey: "upload-wet-signature-pack",
                optionValue: "upload-wet-signature-pack ",
              },
            ]}
            handleOptionClick={() => setUploadPackDialog(true)}
            render={(cb) => (
              <Button
                defaultBtn
                theme={theme}
                iconText={<Icon name="MoreIcon" />}
                onClick={cb}
              ></Button>
            )}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        }
      >
        <Box theme={theme} mt={2}>
          <DocumentList statuses={PROPOSAL_SUMMARY_STATUS} />
        </Box>
      </Stack>
      {openUploadPackDialog && (
        <UploadPackDialog
          open={openUploadPackDialog}
          onClose={() => setUploadPackDialog(false)}
        />
      )}
    </>
  );
};
