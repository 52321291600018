import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const DialogContentContainer = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.dialog-content-container": {
    ".u-custom-file-upload": {
      marginBottom: 0,
      ".root-drag-file": {
        height: 254,
      },
    },
    ".file-preview": {
      marginTop: 8,
    },
    ".checklist-items-wrap": {
      marginTop: 8,
      borderTop: "1px solid" + theme.palette.divider,
      ".checklist-item": {
        padding: "8px 0",
        borderBottom: "1px solid" + theme.palette.divider,
        ".custom-checkbox-wrap": {
          ".u-custom-control-label": {
            margin: 0,
          },
        },
      },
    },
  },
}));
