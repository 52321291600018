import { Stack } from "@components";
import { useTheme } from "@mui/material";
import { Box, Button, Typography } from "@ntpkunity/controls";
import CelebrationIcon from "../../../public/assets/images/celebration-icon.gif";
import { BannerWrap } from "./banner.style";

export const Banner = () => {
  const theme = useTheme();
  return (
    <Stack paddingXs={3} paddingMd={5}>
      <BannerWrap theme={theme} className="banner-wrap">
        <img src={CelebrationIcon} alt="CelebrationIcon" />
        <Box theme={theme}>
          <Typography theme={theme} component="h2" variant="h2">
            Congratulations
          </Typography>
          <Typography
            mt={2}
            theme={theme}
            component="span"
            variant="body2"
            className="text-muted"
            display={"block"}
          >
            Your proposal{" "}
            <span className="fw-medium text-dark">Sample Proposal Name</span>{" "}
            reference number{" "}
            <span className="fw-medium text-dark">A000000</span> has been
            submitted to the broker for the review. Our representative will be
            in touch shortly to update you on progress.
          </Typography>
          <Box theme={theme} className="actions" mt={3}>
            <Button theme={theme} secondary text="Upload Documents" />
            <Button theme={theme} primary text="Download Proposal" />
          </Box>
        </Box>
      </BannerWrap>
    </Stack>
  );
};
