import { AppDialogKeys } from "@helpers/enum";
import { useState } from "react";

export const useDialogState = <TData>() => {
  const [dialogKey, setDialogKey] = useState<AppDialogKeys | null>(null);
  const [dialogData, setDialogData] = useState<TData | null>(null);

  const openDialog = (dialogKey: AppDialogKeys, dialogData?: TData) => {
    setDialogData(dialogData);
    setDialogKey(dialogKey);
  };

  const closeDialog = () => {
    setDialogKey(null);
    setDialogData(null);
  };

  return {
    dialogKey,
    dialogData,
    openDialog,
    closeDialog,
  };
};
