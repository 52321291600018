import { styled } from "@mui/material";
import { CustomColors } from "@themes/mui-theme";

export const Card = styled("div")(({ theme }) => ({
  width: "100%",
  display: "inline-block",
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  ".card-header": {
    width: "100%",
    display: "inline-block",
    fontSize: theme.typography.htmlFontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[900],
    lineHeight: "20px",
    textAlign: "center",
    padding: "10px 0",
  },
  ".card-body": {
    borderTop: "1px solid" + theme.palette.grey[100],
    display: "inline-block",
    padding: 24,
    width: "-webkit-fill-available",

    "&.with-scroll": {
      padding: 0,

      ".scroll": {
        padding: 24,
        paddingBottom: 0,
      },
    },
  },
  [theme.breakpoints.down("md")]: {},
}));
