import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  APRCalculationMethod,
  AmortizationMethod,
  CommissionType,
  FeeFrequencyOneTime,
  FeeFrequencyPeriodic,
  FeeName,
  FeeOccurances,
  FeeType,
  FieldType,
  FinanceAmountRateType,
  InitialCashOutFlowFlatRateMethod,
  PaymentFrequency,
  PaymentMode,
  PeriodicInterestCalculationMethod,
  RateTypes,
  SolveFor,
  VatTreatment,
} from "@flexCommon/enums";
import {
  CalculateAmortization,
  CalculateAPR,
  CalculateFlatRate,
  CalculatePresentValue,
  CalculateRentalAmount,
  CalculateReverseRV,
  MergeFeeInRepaymentPlan,
} from "@services/calculation.service";
import {
  IFeeStructure,
  IRentalStructure,
  IRepaymentPlan,
  IRequestObj,
  IResponseObj,
  IStructureRental,
  IVAT,
} from "@interfaces";
import {
  APRParams,
  MergeFeeAndRepaymentPlanRequestParam,
  RentalAmountRequestParam,
  RentalSlab,
  RequestParamFlatRate,
  RentalAmortizationParamActual,
  PresentValueCalculationParam,
  RVOnActualRequestParam,
} from "@interfaces/requestparams.flex";
import {
  PaymentPlan,
  RentalCalculationActual,
} from "@interfaces/responseParams.flex";
import {
  setCalculatedCommission,
  setCalculatedNFA,
  setCalculatedUpfrontPayment,
  setFinancialRequestObj,
  setFinancialResponseObj,
  setGrossProfit,
  setNotification,
  setRatesVisibility,
  setRentalCalculationProgress,
  setSliderOpen,
} from "@store/actions/financialCalculation.actions";
import { RootState } from "@store/reducers";
import { IConfigurationInputs } from "@interfaces/configuration.interface";
import { FeeOccurance, FeeOccurancePeriodic } from "@flexCommon/constants";
import { ValidationFinancialCalculation } from "@validations/validate-financial-calculation";
import { getHiddenProp } from "@helpers/common";
import { Button } from "@ntpkunity/controls";
// import { Button } from "@components/quotation/shared/controls";

type ICalculate = {
  CalculationConfiguration: IConfigurationInputs | undefined;
};
export const Calculate = ({ CalculationConfiguration }: ICalculate) => {
  const [calculationInProgress, setCalculationInProgress] = useState(false);
  const storeState = useSelector(
    (state: RootState) => state.reducerFinancialCalculation
  );
  const dispatch = useDispatch();
  var responseObject: IResponseObj = storeState.ResponseObj;
  var requestObject: IRequestObj = storeState.RequestObj;
  var ratesVisibility = storeState.RatesVisibility;
  const PerformCalculation = async () => {
    var validate = ValidationFinancialCalculation(storeState);
    if (validate?.visible) {
      dispatch(
        setNotification({
          message: validate.error,
          visible: validate.visible,
        })
      );
    } else {
      try {
        if (storeState.SolveFor !== SolveFor.PeriodicPayment) {
          if (
            !checkStructuredRentals() &&
            storeState.RequestObj.PeriodicPayment == 0
          ) {
            dispatch(
              setNotification({
                visible: true,
                message:
                  "Please provide complete payment structure or Periodic Payment.",
              })
            );
            return;
          }
        }
        dispatch(setSliderOpen(true));
        resetValues();
        setCalculationInProgress(true);
        calculateNFA(requestObject, true, false, true);
        requestObject.RateType === RateTypes.APR &&
          (await InductFeeInRepaymentPlan(true));
        await CalculateRentalsAndPlan();
        await InductFeeInRepaymentPlan();
        await CalculateRates();
        setCalculationInProgress(false);
      } catch (ex: any) {
        setCalculationInProgress(false);
        dispatch(setRentalCalculationProgress(false));
        if (ex.response !== undefined) {
          dispatch(
            setNotification({
              message: ex.response.data.Message,
              visible: true,
            })
          );
        } else {
          dispatch(
            setNotification({
              message: ex.message,
              visible: true,
            })
          );
        }
        throw ex;
      }
    }
  };

  const CalculateUpfrontPayment = (
    object: RentalCalculationActual,
    rentalSummary: IRentalStructure[]
  ) => {
    var calculationMethod =
      CalculationConfiguration?.APRCalculationMethod ??
      AmortizationMethod.Annuity360;
    var res = Number(storeState.RequestObj.Deposit);
    if (calculationMethod == AmortizationMethod.Annuity360) {
      rentalSummary.forEach((r) => {
        for (let i = r.startTerm; i <= r.endTerm; i++) {
          if (storeState.RequestObj.AdvancePayments >= i) {
            res = res + Number(r.rentalAmount);
          }
        }
      });
    } else {
      for (let i = 0; i < storeState.RequestObj.AdvancePayments; i++) {
        res = res + Number(object.repaymentPlan?.[i].rentalAmount);
      }
    }
    var fee = requestObject.Fee.filter((x) => x.Amount > 0);
    const picked = FeeOccurancePeriodic.map((a) => a.value);
    fee.length > 0 &&
      fee.map((r) => {
        if (picked.includes(String(r.Occurance))) {
          res = res + Number(r.Amount);
        } else if (r.Occurance == FeeOccurances.OnStartDate) {
          res = res + Number(r.Amount);
        }
      });
    dispatch(setCalculatedUpfrontPayment(res));
    return;
  };

  const getCalculationMethod = (param: string) => {
    switch (param) {
      case AmortizationMethod.AnnuityActual360:
        return "Actual360";
      case AmortizationMethod.AnnuityActual365:
        return "Actual365";
      default:
        return "Annuity";
    }
  };

  const InductBalloonPayment = (
    requestParam: RentalAmountRequestParam,
    balloonAmount: number
  ) => {
    requestObject.ResidualValue = 0;
    if (storeState.SolveFor === SolveFor.RV) {
      balloonAmount = 100; //setting dummy amount to get rental due date for RV rental.
    }
    if (balloonAmount > 0) {
      requestParam.requestParam.residualValue = requestObject.ResidualValue =
        balloonAmount;
      requestParam.requestParam.productType = "Balloon";
      requestParam.baloonTreatment = "AdditionToLastPayment";
      requestParam.baloonReceivedAfterTerms = requestObject.BalloonCollection;
    }
  };

  const IsCommissionPassedToCustomer = (): boolean => {
    let passToCustomer = true;
    if (requestObject.RateType === RateTypes.APR) {
      passToCustomer = false;
    } else if (!CalculationConfiguration?.IsCommissionPassToCustomer) {
      passToCustomer = false;
    }
    return passToCustomer;
  };

  const CalculateRentalsAndPlan = async () => {
    dispatch(setRentalCalculationProgress(true));
    const structure: IStructureRental[] = [];
    if (
      storeState.SolveFor !== SolveFor.PeriodicPayment &&
      storeState.RequestObj.PeriodicPayment > 0
    ) {
      structure.push({
        StartTerm: 1,
        EndTerm:
          Number(storeState.RequestObj.AdvancePayments) +
          Number(storeState.RequestObj.RegularPayments),
        Amount: storeState.RequestObj.PeriodicPayment,
        RentalType: "Structured",
        RentalFrequency: "",
        RentalId: 0,
      });
    }
    var requestParam: RentalAmountRequestParam = {
      requestParam: {
        Apr: Number(requestObject.Rate),
        contractTerms: CalculateContractTerms(),
        FinancedAmount:
          storeState.SolveFor && storeState.SolveFor === SolveFor.FinanceAmount
            ? CalculateDummyFinanceAmount(structure)
            : calculateNFA(requestObject) +
              (requestObject.RateType === RateTypes.APR
                ? 0
                : CalculateCommission()), //Commission is passed to customer,
        rentalFrequency: requestObject.Frequency,
        rentalMode: requestObject.PaymentMode,
        residualValue: 0,
        StructureRental: requestObject.PeriodicPayment > 0 ? structure : [],
        ProductRateConversionMethod: "Simple",
        productType: "None",
      },
      ExtensionDays: requestObject.ExtensionDays,
      NumberOfAdvanceRentals: Number(requestObject.AdvancePayments),
      RentalCalculationMethod:
        requestObject.RateType == RateTypes.FlatRate
          ? "Flat"
          : getCalculationMethod(
              CalculationConfiguration?.AmortizationMethod ?? ""
            ),
      RentalNumberForVatDefferal: requestObject.VAT.PaymentNumber,
      StartDate: dayjs(requestObject.StartDate).format("YYYY-MM-DD"),
      VatPercentage: 0,
      charges: [],
      VatAmount: 0,
    };
    if (storeState.CalculationConfiguration?.IsVATApplicable) {
      requestParam.VatAmount = Number(requestObject.VAT.Amount.toString());
      requestParam.VatTreatment = getVatTreatment(requestObject.VAT);
    }
    if (
      storeState.CalculationConfiguration?.AmortizationMethod !=
        AmortizationMethod.Annuity360 &&
      storeState.RequestObj.RateType != RateTypes.FlatRate
    ) {
      requestParam.VatDeferralType = requestObject.VAT.type;
    }
    if (
      requestObject.StepPaymentsStructureApplicable &&
      requestObject.hasStepPaymentStructure
    ) {
      requestParam.RentalSteps = {
        RentalStepStructure: requestObject.StepPaymentStructure,
      };
    } else if (requestObject.StepPaymentsApplicable && requestObject.hasStep) {
      requestParam.RentalSteps = {
        RentalStepInterval: Number(requestObject.Step.Frequency),
        RentalStepPercentage: Number(requestObject.Step.Percentage),
        RentalStepType: requestObject.Step.Type,
      };
    }

    InductBalloonPayment(requestParam, Number(requestObject.BalloonPayment));
    requestObject.RateType === RateTypes.APR &&
      responseObject.repaymentPlanWithFee.map((r) => {
        requestParam.charges.push({
          chargeName: r.feeName,
          amount: r.rentalAmount,
          rentalNumber: r.rentalNumber,
          ChargeDueDate: r.rentalDueDate,
        });
      });

    if (
      requestObject.HasStructureRentals == true &&
      requestObject.StructureRentals.length > 0 &&
      requestObject.PeriodicPayment == 0
    ) {
      requestObject.StructureRentals.forEach((x) => {
        requestParam.requestParam.StructureRental.push({
          StartTerm: x.StartTerm,
          EndTerm: x.EndTerm,
          Amount: x.Amount,
          RentalType: x.RentalType,
          RentalFrequency: "",
        });
      });
    }
    if (
      CalculationConfiguration?.AmortizationMethod ==
      AmortizationMethod.Annuity360
    ) {
      requestParam.AllRentalStructuresProvided = checkStructuredRentals();
    }
    await CalculateRentalAmount(
      requestParam,
      CalculationConfiguration?.AmortizationMethod ??
        AmortizationMethod.Annuity360,
      requestObject.RateType === RateTypes.APR,
      checkStructuredRentals(),
      requestObject.RateType
    ).then(async (res: any) => {
      var rentalStructures: IRentalStructure[] = [];
      res.rentalSummary.map((structure: any) => {
        rentalStructures.push({
          startTerm: structure.startTerm,
          endTerm: structure.endTerm,
          rentalAmount: structure.rentalAmount,
          rentalType: structure.rentalType,
        });
      });
      if (
        storeState.SolveFor !== SolveFor.Rates &&
        storeState.SolveFor !== SolveFor.PeriodicPayment
      ) {
        await PerformReverseCalculation(res?.repaymentPlan, rentalStructures);
        if (storeState.SolveFor === SolveFor.RV) {
          //Updating Rental Structure with the calculated RV in case of Reverse Calculation
          rentalStructures.find(
            (x) => x.rentalType === "Balloon"
          )!.rentalAmount = requestObject.ResidualValue;
          res.repaymentPlan.find(
            (x: { rentalType: string }) => x.rentalType === "Balloon"
          )!.rentalAmount = requestObject.ResidualValue;
        }
      }
      responseObject.rentals = rentalStructures;
      dispatch(
        setFinancialResponseObj({
          ...responseObject,
          rentals: rentalStructures,
        })
      );
      dispatch(setRentalCalculationProgress(false));
      CalculateUpfrontPayment(res, rentalStructures);
      await CalculateGrossYield(res.repaymentPlan, rentalStructures);
      if (storeState.SolveFor === SolveFor.Rates) {
        CalculateNetYield(res.repaymentPlan, rentalStructures);
      }
      await CalculateRepaymentPlan(res);
    });
  };

  const CalculateContractTerms = () => {
    var contractTerms = 0;
    contractTerms = Number(requestObject.RegularPayments.toString());
    if (requestObject.Frequency == PaymentFrequency.Quarterly) {
      contractTerms = Number(contractTerms) * 3;
    }
    return contractTerms;
  };

  const CalculateRepaymentPlan = async (
    rentalResponse: RentalCalculationActual,
    checkGrossProfit: Boolean = false
  ) => {
    var requestParam: RentalAmortizationParamActual = {
      Apr: checkGrossProfit
        ? storeState.RequestObj.FundingCost
        : responseObject.rates.grossYield,
      contractTerms: CalculateContractTerms(),
      FinancedAmount: calculateNFA(requestObject, true),
      rentalFrequency: requestObject.Frequency,
      rentalMode: requestObject.PaymentMode,
      residualValue: requestObject.ResidualValue,
      ProductRateConversionMethod: "Simple",
      RentalStream: [],
      RentalStructures: rentalResponse.rentalSummary,
      startDate: dayjs(requestObject.StartDate).format("YYYY-MM-DD"),
      RoundingAdjustmentInLastRental: false,
    };

    rentalResponse.repaymentPlan?.map((rental) =>
      requestParam.RentalStream.push({
        RentalNumber: rental.rentalNumber,
        Amount: rental.rentalAmount,
        RentalType: rental.rentalType,
        DueDate: rental.rentalDueDate,
      })
    );

    await CalculateAmortization(
      requestParam,
      CalculationConfiguration?.AmortizationMethod ?? "",
      requestObject,
      checkGrossProfit ? true : false,
      storeState.RequestObj.FundingCost
    ).then(async (res) => {
      if (!checkGrossProfit) {
        responseObject.repaymentPlan = res.repaymentPlan;
        dispatch(
          setFinancialResponseObj({
            ...responseObject,
            repaymentPlan: res.repaymentPlan,
          })
        );
      } else {
        var sum = 0;
        res.repaymentPlan.forEach((r) => {
          sum = sum + r.periodicInterest;
        });
        CalculateGrossProfit(sum);
      }
    });
  };

  const CalculateRates = async () => {
    var requestParam: APRParams = {
      rentalMode: requestObject.PaymentMode,
      FinancedAmount: 0, //calculated differently for each method below
      AdditionalInterestWithoutTax: 0,
      ContractStartDate: dayjs(requestObject.StartDate).format("YYYY-MM-DD"),
      RentalFrequency: requestObject.Frequency,
      IrrRoundingDetail: {
        IsRoundingRequired: true,
        Upto: 2,
      },
      RentalCalculationMethod: getCalculationMethod(
        CalculationConfiguration?.APRCalculationMethod ??
          AmortizationMethod.Annuity360
      ),
      residualValue: requestObject.ResidualValue,
      IsCompounded: false,
      CompoundingFrequency: "",
      RentalSlabs: [],
    };
    if (storeState.SolveFor !== SolveFor.Rates) {
      CalculateNetYield(responseObject.repaymentPlan, []);
    }
    if (storeState.GrossProfitApplicable) {
      CalculateGrossProfitFromFundingCost(storeState.RequestObj.FundingCost);
    }
    CalculateAPRExclCommission(
      requestParam,
      responseObject.repaymentPlanWithFee
    );
    //CalculateAPRInclCommission(requestParam, responseObject.repaymentPlanWithFee);
    CalculateFlatRateExclCommission(
      requestObject,
      responseObject.repaymentPlan
    );
    CalculateFlatRateInclCommission(
      requestObject,
      responseObject.repaymentPlan
    );
  };

  const CalculateGrossProfit = (PeriodicInterest: Number) => {
    var profit = 0;
    responseObject.repaymentPlan.forEach((r) => {
      profit = Number(r.rentalAmount) + profit;
    });
    profit =
      profit -
      Number(PeriodicInterest) -
      Number(calculateNFA(requestObject, true, true));
    dispatch(setGrossProfit(profit));
  };

  const CalculateGrossYield = async (
    rentalStream: IRepaymentPlan[],
    rentalSummary: IRentalStructure[]
  ) => {
    var calculationMethod =
      CalculationConfiguration?.APRCalculationMethod ??
      AmortizationMethod.Annuity360;
    var aprRequestParam: APRParams = {
      rentalMode: requestObject.PaymentMode,
      FinancedAmount: 0, //calculated differently for each method below
      AdditionalInterestWithoutTax: 0,
      ContractStartDate: dayjs(requestObject.StartDate).format("YYYY-MM-DD"),
      RentalFrequency: requestObject.Frequency,
      IrrRoundingDetail: {
        IsRoundingRequired: true,
        Upto: 2,
      },
      RentalCalculationMethod: getCalculationMethod(
        CalculationConfiguration?.APRCalculationMethod ??
          AmortizationMethod.Annuity360
      ),
      residualValue: requestObject.ResidualValue,
      IsCompounded: false,
      CompoundingFrequency: "",
      RentalSlabs: [],
    };
    aprRequestParam.FinancedAmount = calculateNFA(
      requestObject,
      true,
      false,
      false
    );
    aprRequestParam.RentalSlabs =
      calculationMethod == AmortizationMethod.Annuity360
        ? ConvertRentalSummaryToSlabs(rentalSummary)
        : ConvertRepaymentPlanToSlabs(rentalStream);

    if (calculationMethod == AmortizationMethod.Annuity360) {
      aprRequestParam.balloonParamForAnnuity = {
        NumberOfAdvanceRentals: Number(requestObject.AdvancePayments),
        BaloonReceivedAfterTerms:
          aprRequestParam.residualValue > 0
            ? requestObject.BalloonCollection
            : 0,
        BaloonTreatment: "AdditionToLastPayment",
      };
      if (aprRequestParam.residualValue > 0) {
        aprRequestParam.ProductType = "Balloon";
      }
    }
    await CalculateAPR(aprRequestParam).then((res) => {
      responseObject.rates.grossYield = res.irr;
      dispatch(
        setFinancialResponseObj({
          ...responseObject,
          rates: {
            ...responseObject.rates,
            grossYield: res.irr,
          },
        })
      );
      ratesVisibility.grossYield = true;
      dispatch(setRatesVisibility(ratesVisibility));
    });
  };

  const CalculateNetYield = async (
    rentalStream: IRepaymentPlan[],
    rentalSummary: IRentalStructure[]
  ) => {
    var calculationMethod =
      CalculationConfiguration?.APRCalculationMethod ??
      AmortizationMethod.Annuity360;
    var aprRequestParam: APRParams = {
      rentalMode: requestObject.PaymentMode,
      FinancedAmount: 0, //calculated differently for each method below
      AdditionalInterestWithoutTax: 0,
      ContractStartDate: dayjs(requestObject.StartDate).format("YYYY-MM-DD"),
      RentalFrequency: requestObject.Frequency,
      IrrRoundingDetail: {
        IsRoundingRequired: true,
        Upto: 2,
      },
      RentalCalculationMethod: getCalculationMethod(
        CalculationConfiguration?.APRCalculationMethod ??
          AmortizationMethod.Annuity360
      ),
      residualValue: requestObject.ResidualValue,
      IsCompounded: false,
      CompoundingFrequency: "",
      RentalSlabs: [],
    };
    aprRequestParam.FinancedAmount = calculateNFA(
      requestObject,
      true,
      true,
      false
    );
    if (calculationMethod == AmortizationMethod.Annuity360) {
      aprRequestParam.balloonParamForAnnuity = {
        NumberOfAdvanceRentals: Number(requestObject.AdvancePayments),
        BaloonReceivedAfterTerms:
          aprRequestParam.residualValue > 0
            ? requestObject.BalloonCollection
            : 0,
        BaloonTreatment: "AdditionToLastPayment",
      };
      if (aprRequestParam.residualValue > 0) {
        aprRequestParam.ProductType = "Balloon";
      }
    }
    aprRequestParam.RentalSlabs =
      calculationMethod == AmortizationMethod.Annuity360 &&
      storeState.SolveFor === SolveFor.Rates
        ? ConvertRentalSummaryToSlabs(rentalSummary)
        : ConvertRepaymentPlanToSlabs(rentalStream);
    await CalculateAPR(aprRequestParam).then((res) => {
      responseObject.rates.netYield = res.irr;
      dispatch(
        setFinancialResponseObj({
          ...responseObject,
          rates: {
            ...responseObject.rates,
            netYield: res.irr,
          },
        })
      );
      ratesVisibility.netYield = true;
      dispatch(setRatesVisibility(ratesVisibility));
    });
  };

  const CalculateAPRExclCommission = async (
    aprRequestParam: APRParams,
    rentalStream: IRepaymentPlan[]
  ) => {
    var calculationMethod =
      CalculationConfiguration?.APRCalculationMethod ??
      AmortizationMethod.Annuity360;
    aprRequestParam.FinancedAmount = calculateNFA(requestObject, true);
    if (
      aprRequestParam.RentalCalculationMethod == "Annuity" &&
      aprRequestParam.rentalMode == "Arrear" &&
      requestObject.Fee.length > 0
    ) {
      var checkRental = false;
      var fee = requestObject.Fee.filter((x) => x.Amount > 0);
      const picked = FeeOccurancePeriodic.map((a) => a.value);
      fee.map((r) => {
        if (picked.includes(String(r.Occurance))) {
          aprRequestParam.FinancedAmount =
            aprRequestParam.FinancedAmount - Number(r.Amount);
          checkRental = true;
        } else if (r.Occurance == FeeOccurances.OnStartDate) {
          aprRequestParam.FinancedAmount =
            aprRequestParam.FinancedAmount - Number(r.Amount);
          checkRental = true;
        }
      });
      checkRental && rentalStream.splice(0, 1);
    }
    aprRequestParam.RentalSlabs = ConvertRepaymentPlanToSlabs(rentalStream);
    if (requestObject.Frequency == PaymentFrequency.Monthly) {
      aprRequestParam.IsCompounded = true;
      aprRequestParam.CompoundingFrequency = "Monthly";
    }
    var residualValueRental = aprRequestParam.RentalSlabs.find(
      (x) => x.RentalType === "ResidualValue" || x.RentalType === "Balloon"
    );
    residualValueRental &&
      (aprRequestParam.residualValue = residualValueRental?.Amount ?? 0);
    if (calculationMethod == AmortizationMethod.Annuity360) {
      aprRequestParam.balloonParamForAnnuity = {
        NumberOfAdvanceRentals: Number(requestObject.AdvancePayments),
        BaloonReceivedAfterTerms:
          aprRequestParam.residualValue > 0
            ? requestObject.BalloonCollection
            : 0,
        BaloonTreatment: "AdditionToLastPayment",
      };
      if (aprRequestParam.residualValue > 0) {
        aprRequestParam.ProductType = "Balloon";
      }
    }

    await CalculateAPR(aprRequestParam).then((res) => {
      responseObject.rates.aprExclCommission = res.irr;
      dispatch(
        setFinancialResponseObj({
          ...responseObject,
          rates: {
            ...responseObject.rates,
            aprExclCommission: res.irr,
          },
        })
      );
      ratesVisibility.aprExclComm = true;
      dispatch(setRatesVisibility(ratesVisibility));
    });
  };

  const CalculateAPRInclCommission = async (
    aprRequestParam: APRParams,
    rentalStream: IRepaymentPlan[]
  ) => {
    aprRequestParam.FinancedAmount = calculateNFA(
      requestObject,
      true,
      true,
      false
    );
    aprRequestParam.RentalSlabs = ConvertRepaymentPlanToSlabs(rentalStream);
    await CalculateAPR(aprRequestParam).then((res) => {
      responseObject.rates.aprInclCommission = res.irr;
      dispatch(
        setFinancialResponseObj({
          ...responseObject,
          rates: {
            ...responseObject.rates,
            aprInclCommission: res.irr,
          },
        })
      );
      ratesVisibility.aprInclComm = true;
      dispatch(setRatesVisibility(ratesVisibility));
    });
  };

  const ConvertRepaymentPlanToSlabs = (
    repaymentPlan: IRepaymentPlan[]
  ): RentalSlab[] => {
    var calculationMethod =
      CalculationConfiguration?.APRCalculationMethod ??
      AmortizationMethod.Annuity360;
    var rentalSlabs: RentalSlab[] = [];
    try {
      repaymentPlan.map((plan) => {
        rentalSlabs.push({
          StartTerm: plan.rentalNumber,
          EndTerm: plan.rentalNumber,
          Amount: plan.rentalAmount,
          RentalDueDate: plan.rentalDueDate,
          RentalType:
            calculationMethod != AmortizationMethod.Annuity360 &&
            plan.rentalType == "Balloon"
              ? "ResidualValue"
              : plan.rentalType,
        });
      });
      return rentalSlabs;
    } catch (ex) {
      return rentalSlabs;
    }
  };

  const ConvertRentalSummaryToSlabs = (
    rentalSummary: IRentalStructure[]
  ): RentalSlab[] => {
    var rentalSlabs: RentalSlab[] = [];
    try {
      rentalSummary.map((plan) => {
        if (plan.rentalType != "Balloon")
          rentalSlabs.push({
            StartTerm: plan.startTerm,
            EndTerm: plan.endTerm,
            Amount: plan.rentalAmount,
            RentalType: plan.rentalType,
          });
      });
      return rentalSlabs;
    } catch (ex) {
      return rentalSlabs;
    }
  };

  const CalculateCommission = (): number => {
    if (storeState.SolveFor === SolveFor.Commmission) {
      return requestObject.CommissionAmount;
    } else {
      if (requestObject.CommissionType == CommissionType.Percentage) {
        var finalCommission = 0;
        if (requestObject.CommissionPercentage > 0) {
          finalCommission =
            (Number(requestObject.AssetCost.toString()) -
              Number(requestObject.Deposit.toString())) *
            (requestObject.CommissionPercentage / 100);
        } else {
          finalCommission = requestObject.CommissionAmount;
        }
        dispatch(setCalculatedCommission(finalCommission));
        return finalCommission;
      } else {
        dispatch(
          setCalculatedCommission(Number(requestObject.CommissionPercentage))
        );
        return Number(requestObject.CommissionPercentage);
      }
    }
  };

  const InductFeeInRepaymentPlan = async (isAPR: Boolean = false) => {
    var checkFeeNull = true;
    requestObject.Fee?.forEach((x) => {
      if (x.Amount > 0) {
        checkFeeNull = false;
      }
    });
    if (requestObject.Fee.length <= 0 || checkFeeNull) {
      responseObject.repaymentPlanWithFee = responseObject.repaymentPlan;
      dispatch(
        setFinancialResponseObj({
          ...responseObject,
          repaymentPlanWithFee: responseObject.repaymentPlan,
        })
      );
      return;
    }
    requestObject.Fee.map((fee) => {
      fee.Frequency === FeeType.Periodic &&
        (fee.Frequency = fee.Occurance ?? FeeFrequencyPeriodic.Monthly);
    });
    let requestParam: MergeFeeAndRepaymentPlanRequestParam = {
      Fees: [],
      RepaymentPlan: responseObject.repaymentPlan,
    };
    if (isAPR) {
      requestParam = {
        Fees: [],
        RepaymentPlan: [],
        noOfPayments: requestObject.RegularPayments,
        contractStartDate: requestObject.StartDate,
      };
    }
    requestObject.Fee.filter((x) => x.Amount > 0).map((r) => {
      requestParam.Fees.push({
        Amount: r.Amount,
        Frequency: r.Frequency,
        FirstDueDate: r.FirstDueDate,
        Occurance: r.Occurance,
        Name: r.Name,
        IsEditable: r.IsEditable,
        PaymentNumber: r.PaymentNumber,
      });
    });
    const feeOccurance = FeeOccurancePeriodic.map((a) => a.value);
    requestParam.Fees.forEach((fee) => {
      if (feeOccurance.includes(String(fee.Occurance))) {
        FeeOccurance.map((r) => {
          if (r.text == fee.Occurance) {
            fee.FirstDueDate = dayjs(requestObject.StartDate)
              .add(r.value, "month")
              .format("YYYY-MM-DD");
          }
        });
      }
      if (
        fee.Frequency === FeeType.OneTime &&
        fee.Occurance === FeeFrequencyOneTime.OnStartDate
      ) {
        fee.Occurance = FeeFrequencyOneTime.OnSpecificDate;
        fee.FirstDueDate = dayjs(requestObject.StartDate).format("YYYY-MM-DD");
      } else if (
        fee.Frequency === FeeType.OneTime &&
        fee.Occurance === FeeFrequencyOneTime.WithFirstPayment
      ) {
        fee.Occurance = FeeFrequencyOneTime.OnSpecificDate;
        if (requestObject.RateType === RateTypes.APR) {
          fee.FirstDueDate =
            requestObject.PaymentMode === PaymentMode.Advance
              ? dayjs(requestObject.StartDate)
                  .add(1, "month")
                  .format("YYYY-MM-DD")
              : dayjs(requestObject.StartDate)
                  .add(1, "month")
                  .format("YYYY-MM-DD");
        } else {
          fee.FirstDueDate = dayjs(
            responseObject.repaymentPlan
              .filter((plan) =>
                dayjs(plan.rentalDueDate).isAfter(requestObject.StartDate)
              )
              .sort((a, b) => a.rentalNumber - b.rentalNumber)[0].rentalDueDate
          ).format("YYYY-MM-DD");
        }
      } else if (
        fee.Frequency === FeeType.OneTime &&
        fee.Occurance === FeeFrequencyOneTime.WithLastPayment
      ) {
        if (requestObject.RateType === RateTypes.APR) {
          fee.FirstDueDate = dayjs(requestObject.StartDate)
            .add(requestObject.RegularPayments, "month")
            .format("YYYY-MM-DD");
          fee.Occurance = FeeFrequencyOneTime.OnSpecificDate;
        } else {
          fee.Occurance = FeeFrequencyOneTime.OnSpecificDate;
          var filteredPlanDateWise = responseObject.repaymentPlan
            .filter(
              (plan) =>
                dayjs(plan.rentalDueDate).isAfter(requestObject.StartDate) &&
                ["Auto", "Structured"].includes(plan.rentalType)
            )
            .sort((a, b) => a.rentalNumber - b.rentalNumber);
          fee.FirstDueDate = dayjs(
            filteredPlanDateWise[filteredPlanDateWise.length - 1].rentalDueDate
          ).format("YYYY-MM-DD");
        }
      }
    });
    requestObject.Fee.filter(
      (x) => x.Frequency !== "OneTime" && Number(x.Amount) > 0
    ).map((r) => {
      requestParam.Fees.push({
        Amount: r.Amount,
        FirstDueDate: dayjs(requestObject.StartDate).format("YYYY-MM-DD"),
        Frequency: "OneTime",
        Occurance: "OnSpecificDate",
        Name: r.Name,
        IsEditable: false,
      });
    });
    await MergeFeeInRepaymentPlan(requestParam).then((res) => {
      responseObject.repaymentPlanWithFee = res;
      dispatch(
        setFinancialResponseObj({
          ...responseObject,
          repaymentPlanWithFee: res,
        })
      );
    });
  };

  const CalculateFlatRateInclCommission = async (
    request: IRequestObj,
    rentalStream: IRepaymentPlan[]
  ) => {
    var requestParam: RequestParamFlatRate = {
      FinancedAmount: calculateNFA(requestObject, true),
      InitialCashOutflow: calculateNFA(requestObject),
      StartDate: getDate(requestObject.StartDate),
      EndDate: rentalStream[rentalStream.length - 1].rentalDueDate,
      RentalSlabs: [],
      ResidualValue: request.ResidualValue,
      Rounding: {
        IsRoundingRequired: true,
        Upto: 2,
      },
      Payables: [],
      Receivables: [],
    };
    rentalStream.map((rental) => {
      requestParam.RentalSlabs.push({
        Amount: rental.rentalAmount,
        StartTerm: rental.rentalNumber,
        EndTerm: rental.rentalNumber,
        RentalDueDate: rental.rentalDueDate,
        RentalType: rental.rentalType,
      });
      if (dayjs(rental.rentalDueDate).isBefore(dayjs(requestParam.StartDate)))
        requestParam.InitialCashOutflow -= rental.rentalAmount;
    });
    await CalculateFlatRate(requestParam).then((res) => {
      responseObject.rates.flatRateInclCommission = res.flatRate;
      dispatch(
        setFinancialResponseObj({
          ...responseObject,
          rates: {
            ...responseObject.rates,
            flatRateInclCommission: res.flatRate,
          },
        })
      );
      ratesVisibility.flatRateInclComm = true;
      dispatch(setRatesVisibility(ratesVisibility));
    });
  };

  const CalculateFlatRateExclCommission = async (
    request: IRequestObj,
    rentalStream: IRepaymentPlan[]
  ) => {
    var requestParam: RequestParamFlatRate = {
      FinancedAmount: calculateNFA(requestObject, true),
      InitialCashOutflow:
        calculateNFA(requestObject, true) + CalculateCommission(),
      StartDate: getDate(requestObject.StartDate),
      EndDate: rentalStream[rentalStream.length - 1].rentalDueDate,
      RentalSlabs: [],
      ResidualValue: request.ResidualValue,
      Rounding: {
        IsRoundingRequired: true,
        Upto: 2,
      },
      Payables: [
        {
          ComponentName: "Commission",
          Amount: CalculateCommission(),
        },
      ],
      Receivables: [],
    };
    rentalStream.map((rental) => {
      requestParam.RentalSlabs.push({
        Amount: rental.rentalAmount,
        StartTerm: rental.rentalNumber,
        EndTerm: rental.rentalNumber,
        RentalDueDate: rental.rentalDueDate,
        RentalType: rental.rentalType,
      });
      if (dayjs(rental.rentalDueDate).isBefore(dayjs(requestParam.StartDate)))
        requestParam.InitialCashOutflow -= rental.rentalAmount;
    });
    if (requestObject.RateType == RateTypes.FlatRate) {
      responseObject.rates.flatRateExclCommission = requestObject.Rate;
      dispatch(
        setFinancialResponseObj({
          ...responseObject,
          rates: {
            ...responseObject.rates,
            flatRateExclCommission: requestObject.Rate,
          },
        })
      );
      ratesVisibility.flatRateExclComm = true;
      dispatch(setRatesVisibility(ratesVisibility));
    } else {
      await CalculateFlatRate(requestParam).then((res) => {
        responseObject.rates.flatRateExclCommission = res.flatRate;
        dispatch(
          setFinancialResponseObj({
            ...responseObject,
            rates: {
              ...responseObject.rates,
              flatRateExclCommission: res.flatRate,
            },
          })
        );
        ratesVisibility.flatRateExclComm = true;
        dispatch(setRatesVisibility(ratesVisibility));
      });
    }
  };

  const getDate = (date: any) => {
    let dateObjj = new Date(date.toString());
    let DD = null;
    let MM = null;
    if (dateObjj.getDate() < 10) {
      DD = `0${dateObjj.getDate()}`;
    } else {
      DD = dateObjj.getDate();
    }
    if (dateObjj.getMonth() + 1 < 10) {
      MM = `0${dateObjj.getMonth() + 1}`;
    } else {
      MM = dateObjj.getMonth() + 1;
    }
    return `${dateObjj.getFullYear()}-${MM}-${DD}T00:00:00`;
  };

  const calculateNFA = (
    request: IRequestObj,
    addVAT: boolean = false,
    addCommission: boolean = false,
    updateState: boolean = false
  ) => {
    var financedAmount = 0;
    financedAmount =
      Number(request.AssetCost.toString()) - Number(request.Deposit.toString());
    if (
      addVAT &&
      (request.VAT.Treatment === VatTreatment.VATAmortizeOverTheTerm ||
        request.VAT.Treatment === VatTreatment.VATDeferral)
    ) {
      financedAmount += Number(request.VAT.Amount.toString());
    }

    addCommission && (financedAmount += Number(CalculateCommission()));
    updateState && dispatch(setCalculatedNFA(financedAmount));
    return financedAmount;
  };

  const calculateInitialCashOutflow = (): number => {
    if (
      CalculationConfiguration?.InitialCashOutFlowForFlatRate ===
      InitialCashOutFlowFlatRateMethod.ExcludingVATAndCommission
    ) {
      return calculateNFA(requestObject, false, false, false);
    } else {
      return calculateNFA(requestObject, true, true, false);
    }
  };

  const getVatTreatment = (vatRequest: IVAT) => {
    var treatment = VatTreatment.VATPaidUpfront;
    if (vatRequest.Treatment === VatTreatment.VATAmortizeOverTheTerm) {
      treatment = VatTreatment.VATAmortizeOverTheTerm;
    } else if (vatRequest.Treatment === VatTreatment.VATDeferral) {
      treatment = VatTreatment.VATDeferral;
    }
    return treatment;
  };

  const resetValues = () => {
    responseObject = {
      rates: {
        grossYield: 0,
        netYield: 0,
        aprExclCommission: 0,
        aprInclCommission: 0,
        flatRateExclCommission: 0,
        flatRateInclCommission: 0,
      },
      rentals: [],
      repaymentPlan: [],
      repaymentPlanWithFee: [],
    };
    dispatch(setFinancialResponseObj(responseObject));
    ratesVisibility = {
      grossYield: false,
      netYield: false,
      aprExclComm: false,
      aprInclComm: false,
      flatRateInclComm: false,
      flatRateExclComm: false,
    };
    dispatch(setRatesVisibility(ratesVisibility));
    dispatch(setGrossProfit(0));
  };

  const theme = useTheme();
  const getButton = () => {
    if (storeState.Alert) {
      return <div></div>;
    } else {
      return (
        <Button
          theme={theme}
          primary
          text="Calculate"
          fullWidth
          disabled={calculationInProgress}
          onClick={() => {
            PerformCalculation()
              .then(() => {
                //setCalculationInProgress(false);
              })
              .catch((ex) => {
                setCalculationInProgress(false);
              });
          }}
        />
      );
    }
  };

  const checkStructuredRentals = (): boolean => {
    if (
      storeState.SolveFor != SolveFor.PeriodicPayment &&
      storeState.RequestObj.PeriodicPayment > 0
    ) {
      return true;
    }
    if (
      requestObject.HasStructureRentals == true &&
      requestObject.StructureRentals.length > 0
    ) {
      var maxEndTerm = 0;
      requestObject.StructureRentals.forEach((r) => {
        if (r.RentalType == "Structured") {
          if (Number(r.StartTerm) == Number(r.EndTerm)) {
            maxEndTerm = maxEndTerm + 1;
          } else if (
            Number(r.StartTerm) == 1 &&
            Number(r.EndTerm) ==
              Number(requestObject.RegularPayments) +
                Number(requestObject.AdvancePayments)
          ) {
            maxEndTerm =
              Number(requestObject.RegularPayments) +
              Number(requestObject.AdvancePayments);
          } else {
            maxEndTerm += Number(r.EndTerm) - Number(r.StartTerm) + 1;
          }
        }
      });
      if (
        maxEndTerm ==
        Number(requestObject.RegularPayments) +
          Number(requestObject.AdvancePayments)
      ) {
        return true;
      }
    }
    return false;
  };

  const CalculateDummyFinanceAmount = (structure: IStructureRental[]) => {
    var financeAmount = 0;
    if (checkStructuredRentals()) {
      if (storeState.RequestObj.PeriodicPayment > 0) {
        structure.forEach((r) => {
          var noOfRentals = r.EndTerm - r.StartTerm + 1;
          var rentalAmount: number = +r.Amount;
          financeAmount = financeAmount + rentalAmount * noOfRentals;
        });
      } else {
        requestObject.StructureRentals.forEach((r) => {
          var noOfRentals = r.EndTerm - r.StartTerm + 1;
          var rentalAmount: number = +r.Amount;
          financeAmount = financeAmount + rentalAmount * noOfRentals;
        });
      }
    }
    return financeAmount;
  };

  const PerformReverseCalculationOnRV = async (
    repaymentPlan: IRepaymentPlan[]
  ) => {
    var requestParam: RVOnActualRequestParam = {
      NetFinancedAmount: requestObject.AssetCost - requestObject.Deposit,
      APR: Number(requestObject.Rate),
      APRCalculationMethod: PeriodicInterestCalculationMethod.Simple,
      RentalFrequency: requestObject.Frequency,
      RentalMode: requestObject.PaymentMode,
      ContractTerms:
        Number(requestObject.RegularPayments.toString()) +
        Number(requestObject.AdvancePayments.toString()),
      PaymentPlan: repaymentPlan,
      RentalCalculationMethod: getCalculationMethod(
        CalculationConfiguration?.AmortizationMethod ?? ""
      ),
      StartDate: dayjs(requestObject.StartDate).format("YYYY-MM-DD"),
    };
    await CalculateReverseRV(requestParam).then((res) => {
      requestObject.ResidualValue = requestObject.BalloonPayment = res.rv;
      //dispatch
      dispatch(setFinancialRequestObj(requestObject));
    });
  };

  const PerformReverseCalculation = async (
    repaymentPlan: IRepaymentPlan[] = [],
    rentalSummary: IRentalStructure[]
  ) => {
    var calculationMethod =
      CalculationConfiguration?.APRCalculationMethod ??
      AmortizationMethod.Annuity360;
    if (storeState.SolveFor === SolveFor.RV) {
      var planWithRemovedBalloonRental = repaymentPlan.filter(
        (x) => x.rentalType !== "Balloon"
      );
      await PerformReverseCalculationOnRV(planWithRemovedBalloonRental);
    } else {
      var requestParam: PresentValueCalculationParam = {
        DiscountRate: Number(requestObject.Rate),
        StartDate: dayjs(requestObject.StartDate).format("YYYY-MM-DD"),
        RentalMode: requestObject.PaymentMode,
        RentalCalculationMethod:
          calculationMethod === AmortizationMethod.Annuity360
            ? "Annuity"
            : getCalculationMethod(
                CalculationConfiguration?.AmortizationMethod ?? ""
              ),
        PaymentPlan:
          calculationMethod === AmortizationMethod.Annuity360
            ? GeneratePaymentPlanFromRentalSummary(rentalSummary)
            : repaymentPlan,
      };
      if (calculationMethod == AmortizationMethod.Annuity360) {
        requestParam.balloonParamForAnnuity = {
          NumberOfAdvanceRentals: Number(requestObject.AdvancePayments),
          BaloonReceivedAfterTerms:
            requestObject.ResidualValue > 0
              ? requestObject.BalloonCollection
              : 0,
          BaloonTreatment: "AdditionToLastPayment",
        };
        if (requestObject.ResidualValue > 0) {
          requestParam.ProductType = "Balloon";
          requestParam.ResidualValue = requestObject.ResidualValue;
        }
      }
      await CalculatePresentValue(requestParam).then((res) => {
        if (storeState.SolveFor === SolveFor.Commmission) {
          var financeAmount =
            Number(requestObject.AssetCost) - Number(requestObject.Deposit);
          if (requestObject.VAT.Treatment === VatTreatment.VATDeferral) {
            financeAmount =
              Number(financeAmount) +
              Number(requestObject.VAT.Amount.toString());
          }
          requestObject.CommissionAmount =
            Number(res.presentValue) - Number(financeAmount);
          requestObject.CommissionPercentage =
            (Number(requestObject.CommissionAmount) /
              (Number(requestObject.AssetCost) -
                Number(requestObject.Deposit))) *
            100;

          dispatch(setCalculatedCommission(requestObject.CommissionAmount));
        } else if (storeState.SolveFor === SolveFor.FinanceAmount) {
          requestObject.AssetCost =
            Number(requestObject.Deposit) + Number(res.presentValue);
          if (requestObject.VAT.Treatment === VatTreatment.VATDeferral) {
            requestObject.AssetCost =
              Number(requestObject.AssetCost) -
              Number(requestObject.VAT.Amount.toString());
          }
          if (
            requestObject.CommissionType == CommissionType.Amount &&
            requestObject.RateType == FinanceAmountRateType.NetYield
          ) {
            requestObject.AssetCost =
              Number(requestObject.AssetCost) -
              Number(requestObject.CommissionPercentage);
            dispatch(
              setCalculatedNFA(
                Number(res.presentValue) -
                  Number(requestObject.CommissionPercentage)
              )
            );
          } else {
            dispatch(setCalculatedNFA(Number(res.presentValue)));
          }
        } else if (storeState.SolveFor === SolveFor.Deposit) {
          var netFinanceAmount = 0;
          if (requestObject.VAT.Treatment === VatTreatment.VATDeferral) {
            requestObject.Deposit =
              Number(requestObject.AssetCost) +
              Number(requestObject.VAT.Amount.toString());
            requestObject.Deposit =
              Number(requestObject.Deposit) - Number(res.presentValue);

            netFinanceAmount =
              Number(requestObject.AssetCost) +
              Number(requestObject.VAT.Amount.toString());
            netFinanceAmount = netFinanceAmount - Number(requestObject.Deposit);
          } else {
            requestObject.Deposit =
              Number(requestObject.AssetCost) - Number(res.presentValue);
            netFinanceAmount =
              Number(requestObject.AssetCost) - Number(requestObject.Deposit);
          }
          if (
            requestObject.RateType == FinanceAmountRateType.NetYield &&
            requestObject.CommissionType == CommissionType.Amount
          ) {
            requestObject.Deposit =
              Number(requestObject.Deposit) +
              Number(requestObject.CommissionPercentage);
          }
          requestObject.DepositAmount = requestObject.Deposit;
          dispatch(setCalculatedNFA(netFinanceAmount));
        }
        //dispatch
        dispatch(setFinancialRequestObj(requestObject));
      });
    }
  };

  const GeneratePaymentPlanFromRentalSummary = (
    rentalSummary: IRentalStructure[]
  ): PaymentPlan[] => {
    var paymentPlan: any = [];
    rentalSummary.forEach((r) => {
      for (let i = r.startTerm; i <= r.endTerm; i++) {
        if (r.rentalType != "Balloon")
          paymentPlan.push({
            rentalNumber: i,
            rentalAmount: r.rentalAmount,
            rentalType: r.rentalType,
          });
      }
    });
    return paymentPlan;
  };

  const CalculateGrossProfitFromFundingCost = async (FundingCost: number) => {
    const structure: IStructureRental[] = [];
    if (
      storeState.SolveFor !== SolveFor.PeriodicPayment &&
      storeState.RequestObj.PeriodicPayment > 0
    ) {
      structure.push({
        StartTerm: 1,
        EndTerm:
          Number(storeState.RequestObj.AdvancePayments) +
          Number(storeState.RequestObj.RegularPayments),
        Amount: storeState.RequestObj.PeriodicPayment,
        RentalType: "Structured",
        RentalFrequency: "",
        RentalId: 0,
      });
    }
    var requestParam: RentalAmountRequestParam = {
      requestParam: {
        Apr: FundingCost,
        contractTerms: CalculateContractTerms(),
        FinancedAmount:
          storeState.SolveFor && storeState.SolveFor === SolveFor.FinanceAmount
            ? CalculateDummyFinanceAmount(structure)
            : calculateNFA(requestObject),
        rentalFrequency: requestObject.Frequency,
        rentalMode: requestObject.PaymentMode,
        residualValue: 0,
        StructureRental: requestObject.PeriodicPayment > 0 ? structure : [],
        ProductRateConversionMethod: "Simple",
        productType: "None",
      },
      ExtensionDays: requestObject.ExtensionDays,
      NumberOfAdvanceRentals: Number(requestObject.AdvancePayments),
      RentalCalculationMethod:
        requestObject.RateType == RateTypes.FlatRate
          ? "Flat"
          : getCalculationMethod(
              CalculationConfiguration?.AmortizationMethod ?? ""
            ),
      RentalNumberForVatDefferal: requestObject.VAT.PaymentNumber,
      StartDate: dayjs(requestObject.StartDate).format("YYYY-MM-DD"),
      VatPercentage: 0,
      charges: [],
      VatAmount: 0,
    };
    if (storeState.CalculationConfiguration?.IsVATApplicable) {
      requestParam.VatAmount = Number(requestObject.VAT.Amount.toString());
      requestParam.VatTreatment = getVatTreatment(requestObject.VAT);
    }
    if (
      storeState.CalculationConfiguration?.AmortizationMethod !=
        AmortizationMethod.Annuity360 &&
      storeState.RequestObj.RateType != RateTypes.FlatRate
    ) {
      requestParam.VatDeferralType = requestObject.VAT.type;
    }
    if (
      requestObject.StepPaymentsStructureApplicable &&
      requestObject.hasStepPaymentStructure
    ) {
      requestParam.RentalSteps = {
        RentalStepStructure: requestObject.StepPaymentStructure,
      };
    } else if (requestObject.StepPaymentsApplicable && requestObject.hasStep) {
      requestParam.RentalSteps = {
        RentalStepInterval: Number(requestObject.Step.Frequency),
        RentalStepPercentage: Number(requestObject.Step.Percentage),
        RentalStepType: requestObject.Step.Type,
      };
    }
    InductBalloonPayment(requestParam, Number(requestObject.BalloonPayment));
    requestObject.RateType === RateTypes.APR &&
      responseObject.repaymentPlanWithFee.map((r) => {
        requestParam.charges.push({
          chargeName: r.feeName,
          amount: r.rentalAmount,
          rentalNumber: r.rentalNumber,
          ChargeDueDate: r.rentalDueDate,
        });
      });

    if (
      requestObject.HasStructureRentals == true &&
      requestObject.StructureRentals.length > 0 &&
      requestObject.PeriodicPayment == 0
    ) {
      requestObject.StructureRentals.forEach((x) => {
        requestParam.requestParam.StructureRental.push({
          StartTerm: x.StartTerm,
          EndTerm: x.EndTerm,
          Amount: x.Amount,
          RentalType: x.RentalType,
          RentalFrequency: "",
        });
      });
    }
    if (
      CalculationConfiguration?.AmortizationMethod ==
      AmortizationMethod.Annuity360
    ) {
      requestParam.AllRentalStructuresProvided = checkStructuredRentals();
    }
    await CalculateRentalAmount(
      requestParam,
      CalculationConfiguration?.AmortizationMethod ??
        AmortizationMethod.Annuity360,
      requestObject.RateType === RateTypes.APR,
      checkStructuredRentals(),
      requestObject.RateType
    ).then(async (res: any) => {
      await CalculateRepaymentPlan(res, true);
    });
  };

  return (
    <>
      <Button
        theme={theme}
        primary
        text="Calculate"
        fullWidth
        disabled={calculationInProgress}
        onClick={() => {
          PerformCalculation()
            .then(() => {
              //setCalculationInProgress(false);
            })
            .catch((ex) => {
              setCalculationInProgress(false);
            });
        }}
      />
    </>
  );
};
