import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  BoxProps,
  Grid,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  nonDecimalFieldType,
  RentalTypes,
  stepType,
} from "@flexCommon/constants";
import { FieldType, RentalType } from "@flexCommon/enums";
import { getDisabledProp, getHiddenProp } from "@helpers/common";
import { IStepPaymentStructure, IStructureRental } from "@interfaces";
import { IFieldProp } from "@interfaces/configuration.interface";
import Icon from "@components/quotation/shared/assets/Icon";
import { setFinancialRequestObj } from "@store/actions/financialCalculation.actions";
import { RootState } from "@store/reducers";
import {
  AccordianWrap,
  Button,
  CustomizedSwitch,
  Dropdown,
  IconButton,
  ResponsiveWrap,
  TextBox,
} from "@components/quotation/shared/controls";
import { CalculationWrap } from "@components/quotation/configuration.style";
import { CombinedControl } from "@components/quotation/vat/vat.component";

type IStepStructure = {
  fieldProps: IFieldProp[];
};

export const SectionWrap: any = styled(
  Box,
  {}
)<Partial<BoxProps>>(({ theme }) => ({
  width: "100%",
  display: "inline-block",
  paddingLeft: 15,
  paddingTop: 24,
  ".col-switch": {
    ".MuiFormGroup-root": {
      float: "right",
    },
  },
  "@media screen and (max-width: 959px)": {
    ".col-switch": {
      ".MuiFormGroup-root": {},
    },
  },
}));

export const SubSectionFieldsWrap: any = styled(
  Box,
  {}
)<Partial<BoxProps>>(({ theme }) => ({}));

export const StepPaymentStructure = ({ fieldProps }: IStepStructure) => {
  const storeState = useSelector(
    (state: RootState) => state.reducerFinancialCalculation
  );
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    !storeState.RequestObj.hasStepPaymentStructure &&
      dispatch(
        setFinancialRequestObj({
          ...storeState.RequestObj,
          StepPaymentStructure: [],
        })
      );
  }, [storeState.RequestObj.hasStepPaymentStructure]);

  useEffect(() => {
    if (
      fieldProps?.find(
        (x) => x.Name == FieldType.StepPaymentsStructureApplicable
      )?.Disabled == true
    ) {
      dispatch(
        setFinancialRequestObj({
          ...storeState.RequestObj,
          hasStepPaymentStructure: false,
        })
      );
    }
  }, [getDisabledProp(fieldProps, FieldType.StepPaymentsStructureApplicable)]);

  const addRental = () => {
    var structures = storeState.RequestObj.StepPaymentStructure;
    var count = structures.length;
    var _lastTerm = count > 0 ? structures[count - 1].From : 0;
    var newRental: IStepPaymentStructure = {
      RentalStepType: "Up",
      Percentage: 0.0,
      From: count > 0 ? ++_lastTerm : 1,
      To: storeState.RequestObj.RegularPayments,
    };
    structures.push(newRental);
    dispatch(
      setFinancialRequestObj({
        ...storeState.RequestObj,
        StepPaymentStructure: structures,
      })
    );
  };

  const addDefaultRental = () => {
    var structures = storeState.RequestObj.StepPaymentStructure;
    var count = structures.length;
    var newRental: IStepPaymentStructure = {
      RentalStepType: "Up",
      Percentage: 0.0,
      From: 1,
      To: 1,
    };
    structures.push(newRental);
    dispatch(
      setFinancialRequestObj({
        ...storeState.RequestObj,
        StepPaymentStructure: structures,
      })
    );
  };

  const deleteRental = (index: number) => {
    var structures = storeState.RequestObj.StepPaymentStructure;
    structures = structures.filter((_, i) => i !== index);
    dispatch(
      setFinancialRequestObj({
        ...storeState.RequestObj,
        StepPaymentStructure: structures,
        hasStepPaymentStructure: structures.length ? true : false,
      })
    );
  };

  const valuesChange = (
    index: number,
    fieldName: keyof IStepPaymentStructure,
    value: string | number | boolean
  ) => {
    var structures: any[] = storeState.RequestObj.StepPaymentStructure;
    structures[index][fieldName] = value;
    dispatch(
      setFinancialRequestObj({
        ...storeState.RequestObj,
        StepPaymentStructure: structures,
      })
    );
  };

  const RsForm = (structure: IStepPaymentStructure, index: number) => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <CombinedControl>
              <TextBox
                testid="txtFrom"
                fullWidth
                label="From"
                type="number"
                value={structure.From}
                onChange={(e) => valuesChange(index, "From", e.target.value)}
              />
              <TextBox
                testid="txtTo"
                fullWidth
                label="To"
                type="number"
                value={structure.To}
                onChange={(e) => valuesChange(index, "To", e.target.value)}
              />
            </CombinedControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CombinedControl>
              <TextBox
                theme={theme}
                testid="txtPercentage"
                label="Percentage"
                type="text"
                fullWidth
                masking
                thousandSeparater
                value={
                  structure.Percentage !== 0 || structure.Percentage > 0
                    ? structure.Percentage
                    : ""
                }
                placeHolder={
                  nonDecimalFieldType.includes("StructurePercentage")
                    ? "0"
                    : "0.00"
                }
                onChange={(e) => {
                  valuesChange(index, "Percentage", e.target.value);
                }}
              />
              <Dropdown
                testid="txtStepType"
                label="Type"
                items={stepType}
                value={structure.RentalStepType}
                onChange={(e) => {
                  valuesChange(index, "RentalStepType", e.target.value);
                }}
              />
            </CombinedControl>
          </Grid>
        </Grid>
      </>
    );
  };

  const getStructureRentals = () => {
    if (
      storeState.RequestObj.hasStepPaymentStructure &&
      !getHiddenProp(fieldProps, FieldType.StepPaymentsStructureApplicable)
    ) {
      return (
        <>
          {storeState.RequestObj.StepPaymentStructure.map(
            (structure, index) => (
              <AccordianWrap key={index}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box className="title">STEP PAYMENT {index + 1}</Box>
                    <Button
                      defaultBtn
                      className="btn-delete"
                      onClick={() => deleteRental(index)}
                      iconText={<Icon name="DeleteIcon" />}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    {RsForm(structure, index)}
                  </AccordionDetails>
                </Accordion>
              </AccordianWrap>
            )
          )}
          <Box sx={{ pb: 2 }}>
            <Button
              fullWidth
              secondary
              text="Add More"
              onClick={() => {
                addRental();
              }}
            />
          </Box>
        </>
      );
    } else {
      return <div></div>;
    }
  };

  const getLength = () => {
    var lenght = storeState.RequestObj.hasStepPaymentStructure
      ? storeState.RequestObj.StepPaymentStructure.length
      : 0;
    return lenght;
  };

  return (
    <CalculationWrap className="results">
      <SectionWrap>
        <Grid item xs={12}>
          <Grid container columnSpacing={2} rowSpacing={3} sx={{ mb: 3 }}>
            <Grid item xs={10} sm={8}>
              <Typography
                variant="h4"
                component="h4"
                children={
                  <>Step Payments Structure {"(" + getLength() + ")"}</>
                }
              />
            </Grid>
            <Grid item xs={2} sm={4} className="col-switch">
              <CustomizedSwitch
                disabled={getDisabledProp(
                  fieldProps,
                  FieldType.StepPaymentsApplicable
                )}
                value={storeState.RequestObj.hasStepPaymentStructure}
                switchEnabled={storeState.RequestObj.hasStepPaymentStructure}
                onChange={(e) => {
                  if (
                    e.target.checked == true &&
                    storeState.RequestObj.StepPaymentStructure.length == 0
                  ) {
                    addDefaultRental();
                  }
                  dispatch(
                    setFinancialRequestObj({
                      ...storeState.RequestObj,
                      hasStepPaymentStructure: e.target.checked,
                    })
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item xs={12} sx={{ pr: 2 }}>
              {getStructureRentals()}
            </Grid>
          </Grid>
        </Grid>
      </SectionWrap>
    </CalculationWrap>
  );
};
