import { useQuery } from "react-query";
import QUERY_KEYS from "@helpers/query-keys";
import { getProposalByReferenceNumber } from "@services/dms.service";
import {
  GetProposalResponse,
  TAssetDetails,
  TCustomerDetails,
  TDirector,
  TDocumentDetails,
  TCommentsDetails,
  TProposalDetails,
  TProposalForm,
} from "@_types";
import {
  AmountType,
  BALLOON_COLLECTION_ARREAR,
  CalculationResults,
  QuotationForm,
  SolveFor,
  StepType,
} from "@ntpkunity/controls-common";
import { getOrderStakeholders } from "@helpers/utils";

export type GetProposalData = {
  proposal: TProposalForm;
  quotation?: {
    formValues: QuotationForm;
    calculations: CalculationResults;
  };
};

const getFormattedDocuments = (customerDocuments) => {
  return customerDocuments.reduce((acc, cur) => {
    let existingDoc = acc.find(
      (doc) => doc.type === cur.document_type && doc.name === cur.document_name
    );

    if (existingDoc) {
      existingDoc.documents.push({
        identifier: cur.identifier,
        previewUrl: cur.document_url,
        key: cur.document_reference_id,
        file: { size: cur.document_size },
      });
    } else {
      acc.push({
        type: cur.document_type,
        name: cur.document_name,
        documents: [
          {
            identifier: cur.identifier,
            file: { size: cur.document_size },
            previewUrl: cur.document_url,
            key: cur.document_reference_id,
          },
        ],
      });
    }

    return acc;
  }, []);
};

const useGetProposal = (referenceNumber: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_SINGLE_PROPOSAL, referenceNumber],
    queryFn: async () => getProposalByReferenceNumber(referenceNumber),
    enabled: !!referenceNumber,
    select: (data: GetProposalResponse): GetProposalData => {
      const orderStakeholders = getOrderStakeholders(data.order_stakeholders);

      const proposalDetails: TProposalDetails = {
        name: data.name,
        dealerName: orderStakeholders.dealerName,
        lenderName: orderStakeholders.lenderName,
        brokerName: data.introducer_name,
        financeType: data.finance_type,
        productType: data.product_type,
      };

      const customerInfo = data.customer_info;
      const customerAddress = customerInfo.customer_addresses?.[0];
      const directorDetails: TDirector[] = customerInfo.customer_partners?.map(
        (director) => {
          return {
            identifier: director.identifier,
            firstName: director.first_name,
            lastName: director.last_name,
            emailAddress: director.email,
            contactNumber: director.contact_number,
            address: director.address.address_line_1,
          };
        }
      );
      const customerDetails: TCustomerDetails = {
        identifier: customerInfo.identifier,
        clientType: customerInfo.customer_type,
        firstName: customerInfo.first_name,
        lastName: customerInfo.last_name,
        idNumber: customerInfo.id_number,
        emailAddress: customerInfo.email,
        contactNumber: customerInfo.mobile_number,
        companyName: customerInfo.company_name,
        companyRegNum: customerInfo.company_registration_number,
        tradingAs: customerInfo.trading_as,
        natureOfBusiness: customerInfo.nature_of_business,
        yearsInBusiness: customerInfo.year_business_established,
        sicCode: customerInfo.sic_code,
        directorDetails: directorDetails,
        address: {
          identifier: customerAddress?.identifier,
          addressLine1: customerAddress?.address_line_1,
          addressLine2: customerAddress?.address_line_2,
          city: customerAddress?.city,
          zipCode: customerAddress?.zip_code,
          county: customerAddress?.county,
        },
      };

      let assets: TAssetDetails[] = [];
      const orderAssets = data.order_asset;
      if (orderAssets) {
        if (Array.isArray(orderAssets)) {
          assets = orderAssets.map((asset) => {
            return {
              identifier: asset.identifier,
              category: asset.category,
              type: asset.asset_type,
              subType: asset.asset_sub_type,
              condition: asset.condition,
              cost: asset.unit_price,
              description: asset.description,
              supplierName: asset.supplier_name,
              quantity: asset.quantity,
              totalCost: asset.total,
            };
          });
        } else {
          // This is for Quotation Converted to Proposal.
          // TODO: Will be fixed once the backend is done
          if (!orderAssets.category) assets = [];
          else
            assets = [
              {
                identifier: orderAssets.identifier,
                category: orderAssets.category,
                type: orderAssets.asset_type,
                subType: orderAssets.asset_sub_type,
                condition: orderAssets.condition,
                cost: orderAssets.unit_price,
                description: orderAssets.description,
                supplierName: orderAssets.supplier_name,
                quantity: orderAssets.quantity,
                totalCost: orderAssets.total,
              },
            ];
        }
      }

      const customerDocuments = customerInfo.customer_documents;
      let documents: TDocumentDetails[];
      if (customerDocuments && Array.isArray(customerDocuments)) {
        documents = getFormattedDocuments(customerDocuments);
      }

      let comments: TCommentsDetails[] = [];
      const orderComments = data.order_comments;
      if (orderComments) {
        if (Array.isArray(orderComments)) {
          comments = orderComments.map((comment) => {
            return {
              identifier: comment.identifier,
              orderReferenceId: comment.order_reference_id,
              customerId: comment.customer_id,
              comment: comment.comment,
              createdAt: comment.created_at,
              createdBy: comment.created_by,
              updatedAt: comment.updated_at,
              updatedBy: comment.updated_by,
            };
          });
        }
      }

      const proposalForm = {
        proposalDetails,
        customerDetails,
        assets,
        documents,
        orderComments: comments,
      };

      // Finance Amount will always available if the quotation
      // was calculated
      if (!data.finance_amount) {
        return {
          proposal: proposalForm,
        };
      }

      const quotationFormValues: QuotationForm = {
        solveFor: data.calculations_type,
        productType: data.product_type,
        assetCost: data.selling_price,
        deposit: data.down_payment,
        depositType: AmountType.POUND,
        commission: data.commission_amount,
        commissionType: data.commission_type,
        rate: data.margin,
        rateType: data.rate_type,
        noOfAdvancePayments: data.no_of_advance_payments,
        noOfRegularPayments: data.no_of_regular_payments,
        balloonPayment: data.rv_balloon_value,
        balloonCollection: BALLOON_COLLECTION_ARREAR.find(
          (collection) => collection.text === data.balloon_collection
        ).value,
        vatType: data.vat_treatment,
        vatAmount: data.deferral_amount,
        vatNumber: data.tax_amount_number || 0,
        vatDeferredType: data.deferred_type,
        paymentFrequency: data.rental_frequency,
        startDate: data.start_date,
        periodicPaymentAmount: data.estimated_monthly_payment,
        structureRentalsApplied: false,
        structureStepPaymentsApplied: false,
        structureRentals: [],
        stepPayments: {
          applied: false,
          stepInterval: 0,
          stepPercentage: 0,
          stepType: StepType.STEP_UP,
        },
        fees: data.order_fees.map((fee) => {
          return {
            amount: fee.applied_price,
            type: fee.fee_frequency,
            name: fee.fee_name,
            frequency: fee.fee_occurance,
            isEditable: true,
            firstFeePaymentDueAtStartDate: false,
          };
        }),
      };

      const quotationCalculations: CalculationResults = {
        commissionAmount: data.commission_amount,
        financeAmount: data.finance_amount,
        rates: {
          grossYield: data.meta_data?.gross_yield,
          netYield: data.meta_data?.net_yield,
          flatRateInclCommission: data.meta_data.flat_rate_commission_inc,
          flatRateExclCommission: data.meta_data.flat_rate_commission_exc,
          apr: data.meta_data.apr,
        },
        sumOfAdvanceRentals: data.sum_of_advance_rentals,
        sumOfFees: data.sum_of_fees,
        sumOfPeriodicInterest: data.sum_of_periodic_interest,
        totalPayables: data.total_payables,
        sumOfRentals: data.sum_of_rentals,
        assetCost: data.selling_price,
        depositAmount: data.down_payment,
        rentalSummary: data.order_payments
          .map((payment) => {
            return {
              startTerm: payment.payment_number_from,
              endTerm: payment.payment_number_to,
              rentalAmount: payment.amount,
              rentalType: payment.payment_type,
            };
          })
          .sort((a, b) => {
            return a.startTerm - b.startTerm;
          }),
        repaymentPlan: [],
      };

      return {
        proposal: proposalForm,
        quotation: {
          formValues: quotationFormValues,
          calculations: quotationCalculations,
        },
      };
    },
  });
};
export default useGetProposal;
