import { useTheme } from "@mui/material";
import { Box, Button, Icon } from "@ntpkunity/controls";
import { FooterProps } from "@components/footer/footer.props";
import { FooterWrap } from "@components/footer/footer.style";

export const Footer = ({
  actionButtonText = null,
  actionButtonOnClick,
  showBackButton,
  backButtonOnClick,
  hasSecondaryButton,
  onSecondaryButtonClick,
}: FooterProps) => {
  const theme = useTheme();

  return (
    <FooterWrap className="footer-wrap" theme={theme}>
      <Box theme={theme} flexGrow={1} className="back-btn">
        {showBackButton && (
          <Button
            theme={theme}
            defaultBtn
            startIcon={<Icon name="IconLeftArrow" />}
            text="Go Back"
            onClick={backButtonOnClick}
          />
        )}
      </Box>
      {actionButtonText && (
        <Box
          theme={theme}
          flexShrink={0}
          display={"flex"}
          gap={2}
          className="action-area"
        >
          {hasSecondaryButton && (
            <Button
              theme={theme}
              secondary
              text={hasSecondaryButton}
              type="button"
              onClick={onSecondaryButtonClick}
              className="w-m-100"
            />
          )}
          <Button
            theme={theme}
            primary
            text={actionButtonText}
            type="button"
            onClick={actionButtonOnClick}
            className="w-m-100"
          />
        </Box>
      )}
    </FooterWrap>
  );
};
