import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/reducers";
import Skeleton from "@mui/material/Skeleton/Skeleton";
import { Button as ControlButton } from "@ntpkunity/controls";
import {
  BalloonCollection,
  CommissionType,
  Currency,
  FeeType,
  FieldType,
  RateTypes,
  SolveFor,
  StepType,
  VatDeferralType,
  VatTreatment,
} from "@flexCommon/enums";
import {
  Dialog,
  Card,
  FormattedValue,
  List,
  Scroll,
  TextBox,
  Button,
  MenuList,
} from "@components/quotation/shared/controls";
import {
  ButtonGroup,
  DialogProps,
  Grid,
  IconButton,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import RepaymentPlan from "@components/quotation/repayment-plan/repayment-plan.component";
import { DateHelper } from "@flexCommon/date";
import { Calculate as CalculateActual } from "@flexCommon/calculation";
import {
  setFinancialRequestObj,
  setNotification,
  setResetValues,
} from "@store/actions/financialCalculation.actions";
import { NotificationSnackbar } from "@components/quotation/snackbar/snackbar.component";
import Icon from "@components/quotation/shared/assets/Icon";
import { IReducerFinancialCalculation } from "@store/reducers/financialCalculation.reducer";
import { AxiosError } from "axios";
import {
  exportDetail,
  exportInWordUrl,
  exportPdf,
} from "@flexCommon/constants";
import { getReport } from "@flexCommon/reportingHelper";
import { CalculationWrap } from "@components/quotation/configuration.style";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers/const";
import { PrintQuote } from "@components/quotation/retrieve-quotation/retrieve-quotation.component";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { generateQuotation } from "@templates/quotation-summary.template";
import { GenerateHaydockTemplate } from "@templates/haydock.template";
import MenuButton from "@components/quotation/shared/controls/menu-button.shared";
import { getDisabledProp, getHiddenProp } from "@helpers/common";
import { IFieldProp } from "@interfaces/configuration.interface";

export const Rentals = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducerFinancialCalculation
  );

  const [repaymentPlan, setRepaymentPlan] = useState(false);
  const [row, setRows] = useState<any>([]);
  const [calculation, setCalculation] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const getSkeleton = () => {
    return (
      <TableRow>
        <TableCell colSpan={4}>
          <Skeleton variant="rectangular" />
        </TableCell>
      </TableRow>
    );
  };

  const getReverseCalculatedValue = () => {
    if (storeState.SolveFor === SolveFor.Deposit) {
      return (
        <li>
          <Grid item className="left">
            Deposit:
          </Grid>
          <Grid item className="right list-header">
            <FormattedValue
              prefix={Currency.GBP}
              value={storeState.RequestObj.Deposit}
            />
          </Grid>
        </li>
      );
    } else if (storeState.SolveFor === SolveFor.RV) {
      return (
        <li>
          <Grid item className="left">
            Balloon:
          </Grid>
          <Grid item className="right list-header">
            <FormattedValue
              prefix={Currency.GBP}
              value={storeState.RequestObj.BalloonPayment}
            />
          </Grid>
        </li>
      );
    } else if (storeState.SolveFor == SolveFor.FinanceAmount) {
      return (
        <li>
          <Grid item className="left">
            Asset Cost:
          </Grid>
          <Grid item className="right list-header">
            <FormattedValue
              prefix={Currency.GBP}
              value={storeState.RequestObj.AssetCost}
            />
          </Grid>
        </li>
      );
    } else {
      return <></>;
    }
  };

  const getRepaymentPlan = () => {
    // if (storeState.ResponseObj?.repaymentPlan.length > 0) {
    if (storeState.ResponseObj.repaymentPlan.length > 0) {
      return (
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            var PaymentPlan: { content: (string | number | JSX.Element)[] }[] =
              [];
            storeState.ResponseObj?.repaymentPlan.map((r) => {
              PaymentPlan.push({
                content: [
                  r.rentalNumber,
                  DateHelper(r.rentalDueDate),
                  r.openingPrincipal,
                  r.rentalAmount,
                  r.rentalPrincipal,
                  r.rentalInterest,
                  r.periodicInterest,
                  r.closingPrincipal,
                ],
              });
            });
            setRows(PaymentPlan);
            setRepaymentPlan(true);
          }}
        >
          View Repayment Plan
        </Link>
      );
    } else {
      return <div></div>;
    }
  };

  const getQuotation = () => {
    if (storeState.ResponseObj.repaymentPlan.length > 0) {
      return (
        <>
          {storeState.RequestObj.WordTemplateApplicable &&
          !getHiddenProp(
            storeState.RequestObj.IFieldProps,
            FieldType.WordTemplateApplicable
          ) ? (
            <li>
              <Typography
                variant="subtitle2"
                component="span"
                children={
                  <>
                    <Link
                      className={
                        getDisabledProp(
                          storeState.RequestObj.IFieldProps,
                          FieldType.WordTemplateApplicable
                        )
                          ? "disabled"
                          : ""
                      }
                      component="button"
                      variant="body2"
                      onClick={() => {
                        generateQuotation(storeState)
                          .then(() => {})
                          .catch((ex: any) => {
                            if (ex?.response?.data?.detail == exportDetail)
                              dispatch(
                                setNotification({
                                  message: "No Template found",
                                  visible: true,
                                })
                              );
                            else {
                              dispatch(
                                setNotification({
                                  message:
                                    "Please Subscribe Dock for Printing.",
                                  visible: true,
                                })
                              );
                            }
                            throw ex;
                          });
                      }}
                    >
                      Export Quotation in word
                    </Link>
                  </>
                }
              />
            </li>
          ) : (
            <></>
          )}
          {storeState.RequestObj.PDFTemplateApplicable &&
          !getHiddenProp(
            storeState.RequestObj.IFieldProps,
            FieldType.PDFTemplateApplicable
          ) ? (
            <li>
              <Typography
                variant="subtitle2"
                component="span"
                children={
                  <>
                    <Link
                      className={
                        getDisabledProp(
                          storeState.RequestObj.IFieldProps,
                          FieldType.WordTemplateApplicable
                        )
                          ? "disabled"
                          : ""
                      }
                      component="button"
                      variant="body2"
                      onClick={() => {
                        GenerateHaydockTemplate(storeState)
                          .then(() => {})
                          .catch((ex: any) => {
                            if (ex?.response?.data?.detail == exportDetail) {
                              dispatch(
                                setNotification({
                                  message: "No Template found",
                                  visible: true,
                                })
                              );
                            } else {
                              dispatch(
                                setNotification({
                                  message:
                                    "Please Subscribe Dock for Printing.",
                                  visible: true,
                                })
                              );
                            }
                          });
                      }}
                    >
                      Export Quotation in Pdf
                    </Link>
                  </>
                }
              />
            </li>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return <div></div>;
    }
  };

  const getInitialState = () => {
    const state = {
      CalculationInProgress: false,
      RatesVisibility: {
        aprExclComm: true,
        aprInclComm: true,
        flatRateExclComm: true,
        flatRateInclComm: true,
        grossYield: true,
        netYield: true,
      },
      RentalCalculationInProgress: false,
      CalculatedCommission: 0,
      CalculatedUpfrontPayment: 0,
      CalculationConfiguration: storeState.CalculationConfiguration,
      SliderOpen: false,
      Alert: false,
      CalculatedNFA: 0,
      SolveFor: SolveFor.PeriodicPayment,
      GrossProfit: 0,
      Notification: {
        message: "",
        visible: false,
      },

      RequestObj: {
        AssetCost: 0,
        AdvancePayments: 0,
        RegularPayments: 0,
        StartDate: new Date().toISOString(),
        PaymentMode: "Arrear",
        Frequency: "Monthly",
        Rate: 0,
        Deposit: 0.0,
        ExtensionDays: 0,
        ResidualValue: 0,
        BalloonPayment: 0,
        RateType: RateTypes.NominalRate,
        DepositType: CommissionType.Amount,
        FinanceType: storeState.RequestObj.FinanceType,
        DepositAmount: 0.0,
        toggle: "scroll h-md",
        BalloonCollection: BalloonCollection.OneMonthAfterLastPayment,
        StructureRentals: [],
        VAT: {
          PaymentNumber: 3,
          Amount: 0.0,
          Treatment: VatTreatment.VATDeferral,
          type: VatDeferralType.Rental,
        },
        CommissionAmount: 0.0,
        CommissionType: CommissionType.Percentage,
        CommissionPercentage: 0.0,
        PeriodicPayment: 0,
        HasStructureRentals: storeState.RequestObj.HasStructureRentals,
        Fee: storeState.RequestObj.Fee,
        Step: {
          Frequency: 0,
          Percentage: 0,
          Type: StepType.StepUp,
        },
        hasStep: false,
        StepPaymentStructure: [],
        hasStepPaymentStructure: false,
        StepPaymentsApplicable: storeState.RequestObj.StepPaymentsApplicable,
        StepPaymentsStructureApplicable:
          storeState.RequestObj.StepPaymentsStructureApplicable,
        Tax: storeState.RequestObj.Tax,
        IsAutoCalculated: false,
      },
      ResponseObj: {
        rentals: [],
        repaymentPlan: [],
        repaymentPlanWithFee: [],
        rates: {
          grossYield: 0.0,
          netYield: 0.0,
          aprExclCommission: 0.0,
          aprInclCommission: 0.0,
          flatRateExclCommission: 0.0,
          flatRateInclCommission: 0.0,
        },
      },
      CustomerDetail: {
        CustomerFullName: "",
        CustomerEmailAddress: "",
        CustomerContactNumber: "",
        VendorPersonName: "",
        VendorEmailAddress: "",
      },
      RetrieveDetail: {
        CustomerFullName: "",
        CustomerEmailAddress: "",
        CustomerContactNumber: "",
        VendorPersonName: "",
        VendorEmailAddress: "",
      },
    };
    return state;
  };

  const GenerateReport = (storeState: IReducerFinancialCalculation) => {
    setLoading(true);
    var settings: any = localStorage.getItem("settings");
    var requestObject: any = {
      tenant_id: JSON.parse(settings)?.tenant_id,
      template_type: "quote_repayment",
      data_placeholders: {
        financeAmount: storeState.CalculatedNFA,
        currency: "EUR",
        interestRate: storeState.ResponseObj.rates.grossYield,
        Planitems: [],
      },
      generated_doc_content_type: "DOCX",
      is_bookmark: false,
      generate_base64: false,
      tags: {},
    };
    storeState.ResponseObj.repaymentPlan.map((rental) => {
      requestObject.data_placeholders.Planitems.push({
        paymentNumber: rental.rentalNumber,
        dueDate: DateHelper(rental.rentalDueDate.split("T")[0]),
        openingPrincipal: rental.openingPrincipal.toLocaleString(),
        paymentAmount: rental.rentalAmount.toLocaleString(),
        principal: rental.rentalPrincipal.toLocaleString(),
        interest: rental.rentalInterest.toLocaleString(),
        periodicInterest: rental.periodicInterest.toLocaleString(),
        closingPrincipal: rental.closingPrincipal.toLocaleString(),
      });
    });
    getReport(exportInWordUrl, requestObject, "Repayment_plan", true)
      .then(() => {
        setLoading(false);
      })
      .catch((ex: any) => {
        if (ex?.response?.data?.detail == exportDetail)
          dispatch(
            setNotification({
              message: "No Template found",
              visible: true,
            })
          );
        else {
          dispatch(
            setNotification({
              message: "Please Subscribe Dock for Printing.",
              visible: true,
            })
          );
        }
        throw ex;
      });
  };

  const theme = useTheme();

  const getIcon = (isEditable: boolean) => {
    if (isEditable) {
      return <Icon name="EditIcon" />;
    } else {
      return <div></div>;
    }
  };

  return (
    <>
      <CalculationWrap className="results">
        <Card className="mbl-result card-results">
          <Box className="card-header">Results</Box>
          <Box className="card-body with-scroll">
            <Scroll className="scroll h-lg-b">
              <List className="list-with-bg" sx={{ mb: 3 }}>
                <ul>
                  <li className="list-header">
                    <Box className="left">From-To</Box>
                    <Box className="right">Amount (W/o VAT)</Box>
                  </li>
                  {storeState.RentalCalculationInProgress
                    ? getSkeleton()
                    : storeState.ResponseObj?.rentals?.map((row, index) => (
                        <li key={index}>
                          <Box className="left">
                            {row.startTerm} - {row.endTerm}
                          </Box>
                          <Grid item className="right">
                            <FormattedValue
                              prefix={Currency.GBP}
                              value={row.rentalAmount}
                            />
                          </Grid>
                        </li>
                      ))}
                </ul>
              </List>
              <List sx={{ mb: 2 }}>
                <ul>
                  <li>
                    <Grid item className="left">
                      Finance Amount:
                    </Grid>
                    <Grid item className="right list-header">
                      <FormattedValue
                        prefix={Currency.GBP}
                        value={storeState.CalculatedNFA}
                      />
                    </Grid>
                  </li>
                  <li>
                    <Grid item className="left">
                      Commission Amount:
                    </Grid>
                    <Grid item className="right list-header">
                      <FormattedValue
                        prefix={Currency.GBP}
                        value={storeState.CalculatedCommission}
                      />
                    </Grid>
                  </li>
                  <li>
                    <Box className="left">Upfront Payment:</Box>
                    <Grid item className="right list-header">
                      <FormattedValue
                        prefix={Currency.GBP}
                        value={storeState.CalculatedUpfrontPayment}
                      />
                    </Grid>
                  </li>
                  {storeState.GrossProfitApplicable ? (
                    <li>
                      <Box className="left">Gross Profit:</Box>
                      <Grid item className="right list-header">
                        <FormattedValue
                          prefix={Currency.GBP}
                          value={storeState.GrossProfit}
                        />
                      </Grid>
                    </li>
                  ) : (
                    <></>
                  )}
                  {getReverseCalculatedValue()}
                  <li>
                    <Typography
                      variant="subtitle2"
                      component="span"
                      children={<>{getRepaymentPlan()}</>}
                    />
                  </li>
                </ul>
              </List>
            </Scroll>
            <Box className="footer-buttons" sx={{ p: 3 }}>
              <Box className="btn-calculate-desktop">
                <ControlButton
                  theme={theme}
                  type="button"
                  secondary
                  text="Create Comparison"
                  onClick={() => {}}
                />
                <CalculateActual
                  CalculationConfiguration={storeState.CalculationConfiguration}
                />
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button
                    secondary
                    theme={theme}
                    className="btn-reset"
                    iconText={<Icon name="ResetIcon" />}
                    onClick={() => {
                      dispatch(setResetValues(getInitialState()));
                    }}
                  />
                </ButtonGroup>
              </Box>
              {/* {!isLoadedFromBroker && (
                <Box mt={2}>
                  <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item lg={6} xs={12}>
                      <Button
                        secondary
                        disabled={calculation}
                        fullWidth
                        text="Retrieve Quotation"
                        onClick={handleOpen}
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Button
                        secondary
                        disabled={calculation}
                        fullWidth
                        text="Save Quotation"
                        onClick={() => {
                          let search = window.location.search;
                          let params = new URLSearchParams(search);
                          let apiKey = params.get("x-api-key");
                          if (apiKey != null) {
                            navigate({
                              pathname: APP_ROUTES.DASHBOARD, // TODO: Retrieve Quotation
                              search: `${"?x-api-key="}${apiKey}`,
                            });
                          } else {
                            navigate(APP_ROUTES.DASHBOARD); // TODO: Retrieve Quotation
                          }
                        }}
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                    <Button secondary fullWidth text="Reset Values" onClick={() => {
                      dispatch(setResetValues(getInitialState()));
                    }} />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Button secondary fullWidth text="Export Plan" onClick={() => GenerateReport(storeState)} />
                  </Grid>
                  </Grid>
                </Box>
              )} */}
            </Box>
          </Box>
        </Card>
      </CalculationWrap>
      <Dialog
        size="xl"
        noFooter={true}
        open={repaymentPlan}
        title="Repayment Plan"
        onCloseDialog={() => {
          setRepaymentPlan(false);
        }}
        children={<RepaymentPlan rows={row} />}
      />
      <Dialog
        variant={undefined}
        size="xl"
        title="Retrieve Quote"
        open={openDialog}
        onCloseDialog={handleClose}
        noFooter
        theme={theme}
        children={<PrintQuote />}
      />
      <NotificationSnackbar
        show={storeState.Notification.visible}
        message={storeState.Notification.message}
        onClose={() =>
          dispatch(
            setNotification({
              message: "",
              visible: false,
            })
          )
        }
      />
    </>
  );
};
