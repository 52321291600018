import { TableCell, TableRow, useTheme } from "@mui/material";
import {
  Box,
  Button,
  DataTable,
  Icon,
  Input,
  Pagination,
  Typography,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";
import { DirectorTableProps } from "./director-table.props";
import { useProposalFieldArrays } from "@contexts/proposal-form";
import DirectorTableRow from "./director-table-row.component";
import { ClientTypes } from "@helpers/enum";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types";

export const DirectorTable = ({
  setOpenDialog,
  setEditDirectorId,
  showAddBtn,
}: DirectorTableProps) => {
  const {
    directorMethods: { fields: directors },
  } = useProposalFieldArrays();

  const theme = useTheme();
  const { watch } = useFormContext<TProposalForm>();
  const clientType = watch("customerDetails.clientType");

  const clientTypeText =
    clientType === ClientTypes.PARTNERSHIP ? "Partners" : "Directors";
  const noRowsMessage = `No ${clientTypeText} Found`;

  return (
    <>
      <DataTableWrap className="table-pagination-button">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DataTable
          theme={theme}
          theadChildren={
            <>
              <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Contact Number</TableCell>
                <TableCell>Address</TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
              <TableRow className="filters-row">
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {directors.length ? (
                directors.map((director, index) => (
                  <DirectorTableRow
                    key={director.id}
                    director={director}
                    index={index}
                    setOpenDialog={setOpenDialog}
                    setEditDirectorId={setEditDirectorId}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography
                      theme={theme}
                      variant="body2"
                      component="span"
                      display="block"
                      textAlign="center"
                    >
                      {noRowsMessage}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </>
          }
        />
        <Pagination theme={theme} />
      </DataTableWrap>
      {showAddBtn && (
        <Box theme={theme} mt={3}>
          <Button
            secondary
            fullWidth
            theme={theme}
            text={`Add ${clientTypeText}`}
            onClick={() => setOpenDialog(true)}
          />
        </Box>
      )}
    </>
  );
};
