import React, { FC } from "react";

const ResetIcon: FC<{ className?: string; onClick: (e: unknown) => void }> = ({
  className,
  onClick,
}) => (
  <svg
    className={className}
    onClick={onClick}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_13738_10447)">
      <path
        d="M4.33301 5V9H8.33301"
        stroke="#12131A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.39502 13C4.88712 16.9463 8.25351 20 12.3331 20C16.7514 20 20.3331 16.4183 20.3331 12C20.3331 7.58172 16.7514 4 12.3331 4C9.06492 4 6.25444 5.95977 5.01275 8.76825"
        stroke="#12131A"
        stroke-width="2"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13738_10447">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.333008)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ResetIcon;
